<template>
    <div class="appearance-rating">
        <div class="rating relative flex" :class="[afterRating ? 'after items-start' : 'items-center']">
            <div v-if="!afterRating" class="rating-content">
                <div class="title">
                    <span class="flex-row center">
                        호감도 선택 시 바닐라 지급!
                        <i
                            class="material-icons m-l-9 f-18"
                            @click="
                                $toast.success(
                                    `호감도 선택 시 바닐라 ${vanilla.profile_eval_point}개 (최대 ${vanilla.profile_eval_max_count}회) 지급!`
                                )
                            "
                        >
                            info
                        </i>
                    </span>
                </div>
                <StarRating :icon="'star'" @onRating="onRating" />
                <span class="content">상대방에게 공개되지 않아요!</span>
            </div>
            <div v-else class="after-rating flex-row items-center">
                <div class="btn-primary center rated-btn">
                    <StarRating :icon="'whiteStar'" :length="1" :default="1" />
                    <span class="f-bold">{{ checkedRating }}</span>
                </div>
                <span class="rated-info f-regular m-l-12">호감도 선택 완료</span>
            </div>
        </div>
    </div>
</template>

<script>
import cardService from '@/services/card'

export default {
    name: 'AppearanceRating',
    props: ['card'],
    mounted() {
        this.init()
    },
    computed: {
        vanilla() {
            return (this.$store.getters.settings || {}).vanilla
        },
    },
    data: () => ({
        afterRating: false,
        checkedRating: 0,
    }),
    watch: {
        card: {
            handler() {
                this.init()
            },
            deep: true,
        },
    },
    methods: {
        async init() {
            try {
                const { rating } = await cardService.interest.findRating(this.$store.getters.me.id, this.card.source_id)
                if (rating) {
                    this.afterRating = true
                    this.checkedRating = rating
                } else {
                    this.afterRating = false
                    this.checkedRating = 0
                }
            } catch (e) {
                console.error(e)
            }
        },
        async onRating(rating) {
            this.$loading(true)
            try {
                const result = await cardService.interest.create(this.$store.getters.me.id, this.card.source_id, rating)
                setTimeout(() => {
                    this.afterRating = true
                    this.checkedRating = rating
                    this.$toast.success(result.msg)
                })
                this.$store.dispatch('loadBadges')
                this.$emit('onRating')
            } catch (e) {
                this.$toast.error(e.msg)
            }
            this.$loading(false)
        },
    },
}
</script>
