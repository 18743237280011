<template>
    <div class="profile-update-pending">
        <div class="pending-info">
            <div class="title f-bold">프로필 심사 중입니다.</div>
            <div class="sub-title">프로필 심사 완료 후 서비스 이용이 가능합니다.</div>
            <p class="desc">
                프로필 심사는 <span class="f-bold c-primary">최대 1~2영업일</span>이 소요되며,<br>
                주말에는 프로필 심사가 어려울 수 있는 점 양해 부탁드립니다.<br>
                심사 완료 후, 알림 / SMS를 통해 안내드립니다.
            </p>
        </div>
        <div class="hr" />
        <div class="pending-list">
            <div class="title">승인 대기 중 항목</div>
            <ul class="items">
                <li
                    class="item"
                    v-for="item in items"
                    :key="item">
                    {{ item }}
                </li>
            </ul>
        </div>
        <img src="@/assets/images/profile_update_request_pending.png">
    </div>
</template>

<script>
export default {
    name: 'ProfileUpdatePending',
    props: ['items'],
}
</script>
