<template>
    <!-- <div class="special-feats" :class="{ secret: $isPrivacy() }">
        <div class="feat one-more m-r-4" @click="onClickMoreProfile">
            <div class="container">
                <img :src="iconUrl('more')" alt="" />
            </div>
        </div>
        <div class="feat secret-mode" @click="onClickSecretMode">
            <div class="container">
                <img :src="iconUrl('secret')" alt="" />
            </div>
        </div>
    </div> -->
    <div class="special-feats p-5">
        <div class="feat m-r-4" @click="onClickMoreProfile">
            <div class="container">
                <div>프로필 3명 더 받기</div>
                <i class="material-icons i-right">chevron_right</i>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'SpecialFeats',
    props: {
        chat: {
            type: Object,
            required: true,
        },
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        profile() {
            return (this.me || {}).profile
        },
        needEssentialProfile() {
            return (this.profile || {}).height === null
        },
        privacy() {
            if (!this.$isPrivacy()) return false

            return this.me.products.filter(p => p.ptype === 'privacy' && p.enabled === true)
        },
        settings() {
            return this.$store.getters.settings || {}
        },
        // payAlertWording() {
        //     return {
        //         essential: {
        //             body: '필수 프로필을 먼저 작성해 주세요',
        //             buttons: [
        //                 {
        //                     label: '아니오',
        //                     class: 'btn-default',
        //                 },
        //                 {
        //                     label: 'CONFIRM',
        //                     class: 'btn-primary',
        //                 },
        //             ],
        //         },
        //         noPoint: {
        //             body: '포인트가 부족합니다. 친구를 초대하고 포인트를 얻어보세요.',
        //             buttons: [
        //                 {
        //                     label: '아니오',
        //                     class: 'btn-default',
        //                 },
        //                 {
        //                     label: '포인트 얻기',
        //                     class: 'btn-primary',
        //                 },
        //             ],
        //         },
        //         moreProfile: {
        //             title: '프로필 1명 더 받기',
        //             body: `${this.pointNeed.moreProfile} 포인트가 차감됩니다`,
        //             buttons: [
        //                 {
        //                     label: '아니오',
        //                     class: 'btn-default',
        //                 },
        //                 {
        //                     label: '네',
        //                     class: 'btn-primary',
        //                 },
        //             ],
        //         },
        //         secret: {
        //             title: '시크릿모드 4주',
        //             body: `${this.pointNeed.secret} 포인트가 차감됩니다`,
        //             buttons: [
        //                 {
        //                     label: '아니오',
        //                     class: 'btn-default',
        //                 },
        //                 {
        //                     label: '네',
        //                     class: 'btn-primary',
        //                 },
        //             ],
        //         },
        //     }
        // },
    },
    methods: {
        // iconUrl(featName) {
        //     const secret = this.$isPrivacy() ? '_white' : ''

        //     return require(`@/assets/images/chat/special_${featName}${secret}.png`)
        // },
        // async payAlert(mode, handler) {
        //     const { invite_point: point } = this.$store.getters.badges
        //     const { noPoint, essential } = this.payAlertWording
        //     const confirm = this.payAlertWording[mode]

        //     // 필수프로필 미입력자 기능사용 불가
        //     if (this.needEssentialProfile) {
        //         const idx = await this.$modal.basic(essential)
        //         if (idx) this.$goProfileRegister()
        //         return
        //     }

        //     const res = await this.$modal.basic(confirm)
        //     if (res) {
        //         if (point < this.pointNeed[mode]) {
        //             const idx = await this.$modal.basic(noPoint)
        //             if (idx) this.$router.push({ name: 'InvitationFriendPage' })
        //             else return
        //         }

        //         this.$nativeBridge.postMessage({
        //             action: 'sendFirebaseEvent',
        //             value: {
        //                 category: `ChatDetail_Buy_${mode === 'moreProfile' ? 'AddOneProfile' : 'SecretMode'}`,
        //             },
        //         })

        //         const { res } = await handler()
        //         if (res === 'success') this.$store.dispatch('loadBadges')
        //     }
        // },

        async onClickMoreProfile() {
            try {
                const idx = await this.$modal.basic({
                    body: `3명의 프로필을 더 받습니다. 포인트 ${this.settings.point.more_profile}개를 사용합니다.`,
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx === 1) {
                    this.$loading(true)
                    const res = await userService.usePoint(this.chat.id)
                    if (res.res === 'success') {
                        this.$toast.success(res.msg)
                    } else {
                        this.$loading(false)
                        await this.$modal.basic({
                            body: res.msg,
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                    }
                }
            } catch (err) {
                this.$loading(false)
                const idx = await this.$modal.basic({
                    body: err.data.msg,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-default',
                        },
                        {
                            label: '충전하러 가기',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx === 1) {
                    this.$stackRouter.push({ name: 'InAppStorePage' })
                }
            } finally {
                this.$loading(false)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.special-feats {
    display: flex;
    color: black;
    position: absolute;
    width: 100%;
    background-color: #fff9ef;
    padding-right: 16px;

    &.secret {
        background: $grey-10;
        color: white !important;
    }

    .feat {
        width: 100%;
        padding: 11px 0;
        @include center;

        // &:nth-last-child(1) {
        //     .container {
        //         border-right: none;
        //     }
        // }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 20px;
            width: 100%;
            border-radius: 8px;
            margin: 0 20px;
            font-weight: 600;
            font-size: 15px;
            background: #5c5c77;
            color: #fff;

            .material-icons {
                color: #fff;
            }
        }
    }
}
</style>
