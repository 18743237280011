<template>
    <div class="disconnected center flex" v-if="!connected">
        <div class="overlay" />
        <div class="message">{{ 'DISCONNECTED' | translate }}</div>
        <button @click="reconnect" class="btn btn-primary flex-wrap">{{ 'RECONNECT' | translate }}</button>
    </div>
</template>

<script>
export default {
    computed: {
        connected() {
            return this.$store.getters.connected
        },
    },
    // watch: {
    //     connected() {
    //         this.reconnect()
    //     },
    // },
    mounted() {
        this.reconnect()
    },
    methods: {
        reconnect() {
            // if (!this.connected) {
            //     this.$loading(true)
            //     this.$bus.$emit('onRequestReconnect')
            //     this.$loading(false)
            //     setTimeout(() => {
            //         this.reconnect()
            //     }, 1000)
            // }
            this.$bus.$emit('onRequestReconnect')
        },
    },
}
</script>
