<template>
    <div class="signup additional-profiles height">
        <ProgressBar :step="$case.toSnake('height')" />
        <div class="content">
            <div class="category m-b-6">Personal</div>
            <div class="title">키</div>
            <div class="body">
                <DigitInput :initialVal="height ? height : null" @input="onInput" placeholder="키를 입력해 주세요" />
                <div class="error" v-if="disabled && height">
                    <i class="material-icons">error_outline</i>
                    <p v-html="error"></p>
                </div>
            </div>
        </div>
        <BottomButton :disabled="disabled" @click="next" :label="$translate('NEXT')" />
    </div>
</template>

<script>
const MIN_HEIGHT = 140
const MAX_HEIGHT = 199

export default {
    name: 'HeightPage',
    data: () => ({
        height: null,
        error: `${MIN_HEIGHT}cm ~ ${MAX_HEIGHT}cm 사이의 키를 입력해주세요`,
    }),
    created() {
        this.height = this.signupData.height || null
    },
    mounted() {
        this.$refs.height.focus()

        const { provider, gender } = this.$store.getters.payloads.signupData

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'HeightPage_Enter_HeightPage',
                option: {
                    provider: provider || 'email',
                    gender: gender.name === 'MALE' ? 0 : 1,
                },
            },
        })
    },
    computed: {
        disabled() {
            return !this.height || this.height < MIN_HEIGHT || this.height > MAX_HEIGHT
        },
        signupData() {
            const signupData = window.localStorage.getItem('signupData') || '{}'
            return JSON.parse(signupData)
        },
    },
    methods: {
        onInput(val) {
            this.height = val
        },
        async next() {
            const signupData = this.signupData
            signupData.height = Number(this.height)
            window.localStorage.setItem('signupData', JSON.stringify(signupData))
            this.$router.push({ name: 'ShapesPage' })
        },
    },
}
</script>
