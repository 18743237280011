<template>
    <div class="pay-account">
        <div class="grey-box m-b-24">
            <span>총 결제 금액</span>
            <span class="spoqa-f-bold f-18">{{ price.sellingPrice }}원</span>
        </div>
        <section class="section m-b-40">
            <div class="title">입금자 성함</div>
            <TextareaWithX :placeholder="placeholder.name" v-model="name" :is-input-mode="true" />
        </section>
        <section class="section m-b-40">
            <div class="title">현금영수증 발행</div>
            <div class="toggle-container">
                <img
                    @click="toggle = !toggle"
                    width="32px"
                    height="32px"
                    class="m-r-4"
                    :src="require(`@/assets/images/icons/toggle${toggle ? '-on' : '-off'}.png`)"
                    alt=""
                />
                <span>현금영수증(소득공제용) 발행</span>
            </div>
        </section>
        <section class="section bottom-40">
            <div class="title">휴대폰 번호</div>
            <TextareaWithX @focus="onfocus" :placeholder="placeholder.phone" v-model="phone" :is-input-mode="true" />
        </section>
        <div class="purchase-btn m-4 m-b-12">
            <button class="bottom-btn f-18 p-14" :disabled="!(me.name && me.phone_number)" @click="clickNext">
                {{ $translate('NEXT') }}
            </button>
        </div>
    </div>
</template>

<script>
import productService from '@/services/product'

export default {
    name: 'PayByAccount',
    data: () => ({
        name: null,
        phone: null,
        toggle: true,
    }),
    created() {
        this.name = this.me.name
        this.phone = this.me.phone_number
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        price() {
            return this.$store.getters.price
        },
        selectedProduct() {
            return this.$store.getters.selectedProduct
        },
        placeholder() {
            return { name: '입금하시는 분의 성함을 입력해주세요', phone: '숫자만 입력해주세요' }
        },
        selectedCoupon() {
            return this.$store.getters.selectedCoupon
        },
    },
    methods: {
        async clickNext() {
            const alreadyOrdered = JSON.parse(localStorage.getItem('order'))
            if (!(this.name && this.phone)) {
                alert('성함, 전화번호를 정확히 입력하세요')
                return
            }
            let order, virtualAccount
            try {
                if (alreadyOrdered) {
                    const idx = await this.$modal.basic({
                        body: '기존 무통장입금 상품의 결제를 취소하고, 새로운 상품을 결제하시겠어요?',
                        buttons: [
                            {
                                label: '아니오',
                                class: 'btn-default',
                            },
                            {
                                label: '네',
                                class: 'btn-primary',
                            },
                        ],
                    })

                    if (idx === 1) {
                        await productService.deleteWithdrawOrder()
                    } else {
                        return
                    }
                }

                const res = await productService.createOrder(this.selectedProduct.id, {
                    payment_type: 'withdraw',
                    user_id: this.selectedCoupon ? this.me.id : null,
                    discount_code: this.selectedCoupon ? this.selectedCoupon.discount_code : null,
                    name: this.name,
                    phone_number: this.phone,
                    agree_cash_bill: this.toggle,
                })

                order = res.order
                virtualAccount = res.user_virtual_account
            } catch (e) {
                this.$toast.error(e.data.msg)
            }

            localStorage.setItem('order', JSON.stringify(order))
            localStorage.setItem('virtualAccount', JSON.stringify(virtualAccount))

            setTimeout(() => {
                this.$router.push({
                    name: 'AccountPayMethodPage',
                })
            }, 2000)
            this.$router.push({
                name: 'PaymentLoadingPage',
            })
            // this.$router.push({
            //     name: 'AccountPayMethodPage',
            // })
        },
        onFocus() {
            const main = document.querySelector('.pay-account')
            setTimeout(() => main.scrollBy({ top: 99999, behavior: 'smooth' }), 400)
        },
    },
}
</script>

<style scoped lang="scss">
.grey-box {
    background: #f8eee4;
    padding: 13px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 12px;
    @include between;
}
.purchase-btn {
    bottom: 0px;
    width: calc(100% - 40px);
    // margin: 16px 0;
    // padding: 0 4px;
    // height: 48px;
    position: fixed;
    .bottom-btn {
        width: 100%;
        // height: 100%;
        border: none;
        background: #151360;
        border-radius: 36px;
        color: #f7f7f4;
    }
}
.pay-account {
    padding: 16px;
    overflow-y: auto;
    position: relative;

    .toggle-container {
        display: flex;
        align-items: center;
    }

    .title {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 8px;
    }
}
.bottom-40 {
    padding-bottom: 40px;
}
</style>
