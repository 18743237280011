<template>
    <div
        class="notification flex-row flex-between items-center bg-main"
        :class="{ clickable: json }"
        @click.stop="onClickNotification"
    >
        <div class="flex-fill">
            <div class="lines-2">{{ notification.content }}</div>
            <div class="time">{{ diff | humanizeSeconds }}</div>
        </div>
        <!-- toBeChecked -->
        <!-- <i v-if="json" class="material-icons flex-wrap">chevron_right</i> -->
    </div>
</template>

<script>
export default {
    name: 'Notification',
    props: ['notification'],
    computed: {
        diff() {
            return this.$moment().diff(this.notification.created_at, 'seconds')
        },
        json() {
            const json = this.$mustParse(this.notification.onload) || {}
            return json.action ? json : null
        },
        chats() {
            return this.$store.getters.chats || []
        },
    },
    methods: {
        onClickNotification() {
            // toBeChecked
            // if (!this.json || !this.json.action) return
            // if (this.json.action === 'click_suggest') {
            //     this.$router.push({ name: 'SuggestedDatingPage' })
            // }
            // if (this.json.action === 'chats') {
            //     // 기존에 chat_id 였는데 action_id로 통일
            //     const chatId = this.json.chat_id || this.json.action_id
            //     if (chatId) {
            //         const openChat = async () => {
            //             await this.$store.dispatch('loadChat', { chatId })
            //             if (this.$route.name !== 'ChatroomPage') {
            //                 this.$router.push({ name: 'ChatroomPage' })
            //             }
            //         }
            //         openChat()
            //     } else {
            //         this.$toast.error(
            //             this.$translate('ERROR_NON_EXIST_CHAT_WITH_NICKNAME').replace(/%s/, this.json.nickname)
            //         )
            //     }
            //     return
            // }
            // if (this.json.action.includes('friends')) {
            //     this.$router.push({ name: 'MyDatingPage' })
            // }
            // if (this.json.action.includes('introduces')) {
            //     const openRealFriendPostDetail = async () => {
            //         if (!this.$store.getters.realfriendMeeting.allPosts.length) {
            //             await this.$store.dispatch('loadRealfriendMeetingPosts')
            //         }
            //         const allPosts = this.$store.getters.realfriendMeeting.allPosts || []
            //         const id =
            //             typeof this.json.action_id === 'number' ? this.json.action_id : Number(this.json.action_id)
            //         const targetPost = allPosts.find(post => post.id === id)
            //         if (!targetPost) return
            //         this.$stackRouter.push({
            //             name: 'RealFriendMeetingPostDetailPage',
            //             props: {
            //                 post: targetPost,
            //                 from: 'all',
            //             },
            //         })
            //     }
            //     try {
            //         openRealFriendPostDetail()
            //     } catch (e) {
            //         this.$toast.error(e.data)
            //     }
            // }
        },
    },
}
</script>
