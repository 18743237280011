<template>
    <div class="signup basics introduce-myself">
        <ProgressBar :step="$case.toSnake('intro')" />
        <div class="content w-100">
            <div class="texts">
                <div class="category">Personal</div>
                <p class="f-medium m-b-8 title">자기소개</p>
            </div>
            <div class="m-t-40">
                <TextareaWithX v-model="content" :placeholder="`성격, 직업, 이상형 등에 대해 알려주세요`" />
            </div>
            <div class="warning">
                <span>연락처 및 SNS ID</span>
                를 적는 경우 즉시 영구 정지 처리됩니다
            </div>
        </div>
        <div class="hr"></div>
        <BottomButton :label="$translate('NEXT')" @click="clickCTA" :disabled="disabled" />
    </div>
</template>

<script>
export default {
    name: 'IntroduceMyselfPage',
    data: () => ({
        content: '',
    }),
    computed: {
        disabled() {
            return this.content.length < 1
        },
        signupData() {
            const signupData = window.localStorage.getItem('signupData') || '{}'
            const parsed = JSON.parse(signupData)

            return { photos: this.$store.getters.payloads.signupData.photos, ...parsed }
        },
    },
    methods: {
        clickCTA() {
            const signupData = this.signupData
            signupData.intro = this.content
            window.localStorage.setItem('signupData', JSON.stringify(signupData))

            this.$router.push({ name: 'UploadPhotoPage' })
        },
    },
}
</script>

<style lang="scss" scoped>
.introduce-myself {
    .content {
        padding: 0 16px;

        ::v-deep textarea {
            height: 360px !important;
        }

        .texts {
            .text-count {
                text-align: right;
                color: $purple-primary;
                font-size: 12px;
                margin-bottom: 8px;
                @include f-medium;

                &.disabled {
                    color: $pink-deep;
                }
            }
        }
        .warning {
            font-size: 12px;
            color: $grey-06;
            margin-top: 8px;
            @include center;
            span {
                @include f-bold;
            }
        }
        ::v-deep textarea {
            height: 48vh;
        }
    }
    .hr {
        margin: 16px 0 32px 0;
        border-bottom: 1px solid $grey-02;
        // height: 1px;
        // background: $grey-02;
    }

    .example-box {
        padding: 0 20px;
        margin-bottom: 80px;

        .example-title {
            font-size: 18px;
            @include f-medium;
            color: black;
            margin-bottom: 16px;
        }
        .example {
            width: 100%;
            margin-bottom: 12px;
        }
    }
    .test-b {
        ::v-deep textarea {
            //height: 250px !important;
        }
    }
}
</style>
