<template>
    <div class="digit-input">
        <div class="input-wrapper">
            <input
                ref="digitInput"
                type="number"
                pattern="\d*"
                class="flex-fill"
                :placeholder="placeholder"
                v-model="val"
                @input="onInput"
            />
            <img
                v-if="val"
                class="flex-wrap"
                :src="require(`@/assets/images/icons/delete.png`)"
                width="24px"
                height="24px"
                @click="onDelete"
            />
        </div>
        <div class="warning">*140cm ~ 199cm 사이의 키를 입력해주세요.</div>
    </div>
</template>

<script>
export default {
    name: 'DigitInput',
    data: () => ({
        val: null,
    }),
    props: {
        initialVal: {
            type: Number,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: '숫자만 입력',
        },
    },
    watch: {
        initialVal() {
            this.val = this.initialVal
        },
    },

    mounted() {
        if (this.initialVal) this.val = this.initialVal

        this.$refs.digitInput.focus()
    },
    methods: {
        onDelete() {
            this.val = null
            this.$emit('input', this.val)
        },
        onInput() {
            this.$emit('input', this.val)
        },
    },
}
</script>
<style scoped lang="scss">
.digit-input {
    .input-wrapper {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100%;
    }
    .warning {
        font-size: 12px;
    }
}
</style>
