<template>
    <div class="progress-bar m-b-28">
        <div class="bar" :style="`width: ${this.percent}%`" />
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        step: String,
        from: String,
    },
    computed: {
        profileRegisterFunnels() {
            return [
                'height',
                'shape',
                'state',
                'station',
                'region',
                'smoking',
                'religion',
                'school',
                'university',
                'job_category',
                'company',
                'job',
                'income',
                'asset',
                'family_asset',
                'marry_plan',
                'intro',
                'upload_photo',
            ]
        },
        subProfileRegisterFunnels() {
            return ['hometown', 'car_type', 'house_style', 'house_payment', 'exercise', 'pet', 'tag']
        },
        percent() {
            if (this.from === 'subProfile') {
                const percent =
                    ((this.subProfileRegisterFunnels.indexOf(this.step) + 1) / this.subProfileRegisterFunnels.length) *
                    100

                return Math.round(percent)
            } else {
                const percent =
                    ((this.profileRegisterFunnels.indexOf(this.step) + 1) / this.profileRegisterFunnels.length) * 100

                return Math.round(percent)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.progress-bar {
    .bar {
        height: 6px;
        border-radius: 0 3px 3px 0;
        //width: 30%;
        background: $blue-primary;
    }
}
</style>
