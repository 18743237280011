<template>
    <div class="pre-match-list">
        <ul class="list-wrapper m-b-10">
            <li
                class="list-item"
                v-for="(step, idx) in steps"
                :key="idx"
                :class="getCurrentStep === step.id ? 'primary' : step.style"
            >
                <div :class="getCurrentStep === step.id ? 'primary' : step.style">
                    <span>{{ step.title }}</span>
                    <button
                        class="btn btn-primary"
                        v-if="getCurrentStep >= step.id && step.action"
                        @click="onClickToStep(step)"
                        :class="getCurrentStep !== step.id ? 'disabled' : ''"
                    >
                        {{ step.action }}{{ getCurrentStep === step.id ? '하기' : '완료' }}
                    </button>
                </div>
            </li>
        </ul>
        <ChatItem v-if="supportChat" class="chat-item-wrap" :chat="supportChat" :key="supportChat.id" />
    </div>
</template>

<script>
import ChatItem from './ChatItem'

export default {
    name: 'PreMatchList',
    props: {},
    data: () => ({
        supportChat: null,
    }),
    components: {
        ChatItem,
    },
    watch: {
        getCurrentStep(newStep) {
            switch (newStep) {
                // case 2:
                //     this.$toast.success('작성 완료! 계약서는 마이페이지에서 확인할 수 있습니다.')
                //     break
                // case 3:
                //     this.$toast.success('작성 완료! 진실성 담보 약정서는 마이페이지에서 확인할 수 있습니다.')
                //     break
                case 2:
                    if (!this.certiSubmitted) {
                        this.$toast.success('작성이 완료되었습니다.')
                    }
                    break
                case 3:
                    // this.$toast.success('제출이 완료되었습니다.')
                    break
                case 0:
                    this.$store.dispatch('loadChats', true)
                    break
                default:
                    break
            }
        },
    },
    computed: {
        profile() {
            return (this.$store.getters.me || {}).profile || {}
        },
        steps() {
            return [
                {
                    id: 0,
                    title: '가입 심사',
                    style: 'start-end',
                },
                // {
                //     id: 1,
                //     title: '매너 보증금 입금',
                //     style: 'start-end',
                // },
                {
                    id: 1,
                    title: '프로필 입력',
                    style: 'default',
                    action: '입력',
                    route: 'ProfileSteps',
                },
                {
                    id: 2,
                    title: '서류 인증',
                    style: 'default',
                    action: this.certiSubmitted && !this.verifyStatus ? '수정' : '제출',
                    route: 'CertificatePage',
                    // props: {
                    //     onCertiAllSubmittedMove: this.onCertiAllSubmittedMove,
                    // },
                },
                {
                    id: 3,
                    title: '매칭 시작(프로필 전달)',
                    style: 'start-end',
                },
            ]
        },
        me() {
            return this.$store.getters.me
        },
        // style() {
        //     return this.$store.getters.me.style || {}
        // },
        myVerificationBadges() {
            return this.$store.getters.myVerificationBadges || []
        },
        certiSubmitted() {
            const requiredVeri = [13, 15]
            const hasAllCerties = requiredVeri.every(r => {
                return this.myVerificationBadges.some(b => b.verification_badge_id === r)
            })
            return hasAllCerties
        },
        verifyStatus() {
            const badges = this.$store.getters.verificationBadges
            if (!badges || !badges.length) return false
            // this.verifyStatus.some(b => b.status === 'DECLINED')

            return !this.$store.getters.verifyStatus.required.some(b => b.status === 'DECLINED')
        },
        // styleSubmitted() {
        //     const multiSelectItems = ['university', 'religion']
        //     const notAnswered = ['priority', 'max_age', 'min_age', 'max_height', 'min_height'].some(i => !this.style[i])
        //     const notAnsweredSmoking = this.style.smoking_type === null ? true : false
        //     const emptyListAnswer = multiSelectItems.some(i => !(this.$mustParse(this.style[`${i}_type`]) || []).length)
        //     return !(notAnswered || notAnsweredSmoking || emptyListAnswer)
        // },
        getCurrentStep() {
            // 계약서 작성 안 했다면 1 조건 추가
            // return 1
            // if (!this.me.user_contract.is_complete) return 1
            // else if (!this.me.user_contract.truth_complete) return 2
            // 진실성 담보 약정서 2 추가
            if (this.profile.status === 'created') return 1
            // else if (!this.certiSubmitted) return 2
            else if (
                this.profile.status === 'complete_profile' ||
                this.profile.status === 'complete_verify' ||
                this.profile.status === 'ongoing_verification' ||
                (this.profile.status === 'judging' && !this.verifyStatus)
            )
                return 2
            // else if (this.profile.status === 'judging') return 3
            else return 3
        },
        chats() {
            return this.$store.getters.chats
        },
    },
    methods: {
        // onCertiAllSubmittedMove() {
        //     this.$stackRouter.pop()
        // },
        onClickToStep(step) {
            if (!step.route) return
            if (step.id < this.getCurrentStep) return
            this.$stackRouter.push({
                name: step.route,
                props: step.props,
            })
        },
        async init() {
            this.supportChat = this.chats.filter(chat => chat.agent_id === 3)[0]
        },
    },
    async mounted() {
        await this.$store.dispatch('loadMyVerificationBadges')
        await this.$store.dispatch('loadProfile')
        await this.$store.dispatch('loadMe')
        await this.$store.dispatch('loadChats', true)
        await this.init()
    },
}
</script>

<style lang="scss" scoped>
.pre-match-list {
    padding: 0 6px 0 18px;

    ul {
        li {
            position: relative;
            margin-bottom: 0;
            padding-bottom: 0px;
            border-radius: 100px;
            padding-left: 6px;
            font-size: 16px;
            line-height: 26px;
            div {
                // padding-left: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px 0;
                height: 56px;
                margin-left: 10px;
                &.current-step {
                    font-weight: 700;
                }
                &.start-end {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 20px;
                    color: #666b70;
                    padding: 0;
                    height: 32px;
                }
                .btn {
                    height: 40px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 24px;
                    padding: 8px 16px;

                    &.disabled {
                        background: #f7f8f9;
                        color: #94999e;
                    }
                }
            }
            .chat-item-wrap {
                width: 100%;
                padding: 12px;
                margin-bottom: 12px;
                height: 100%;
            }
        }
        li:after {
            //총알
            content: '\A';
            width: 10px;
            height: 10px;
            background: red;
            border-radius: 100%;
            position: absolute;
            left: -4.5px;
            top: 22.5px; // 버튼 높이 (위아래 ㅍ패딩 포함)/2 - 총알 높이 / 2 , 이 높이만큼 first-child, last-child 위치조정
        }

        li:before {
            //세로선
            content: '';
            position: absolute;
            left: 0px;
            border-left: 1px solid #b9bdc1;
            height: 100%;
            width: 1px;
        }

        li:first-child:before {
            top: 10px;
        }
        li:last-child:before {
            bottom: 14px;
        }
        li.start-end:after {
            background: #94999e;
            top: 10px;
        }
        li.default:after {
            width: 12px;
            height: 12px;
            background: #ffffff;
            border: 1px solid #666b70;
            left: -5.5px;
        }
        li.primary:after {
            width: 12px;
            height: 12px;
            background: #03006e;
            border: 1px solid #03006e;
            left: -5.5px;
        }
    }
}
</style>
