import verificationBadgeService from '@/services/verification-badge'

const defaultState = () => ({
    verificationBadges: null,
    myVerificationBadges: null,
    verifyData: {},
})

const state = defaultState()

const getters = {
    verificationBadges: state => state.verificationBadges,
    myVerificationBadges: state => state.myVerificationBadges,
    verifyData: state => state.verifyData,
    verifyStatus: state => {
        const badges = state.verificationBadges || []
        const myBadges = state.myVerificationBadges || []

        if (!badges || !badges.length) return false

        const certCenterBadges = {
            required: badges.filter(item => item.verification === 'center').sort((a, b) => a.id - b.id),
            // required: badges.filter(item => item.category === 'certification_company'),
            mandated: badges.filter(item => item.verification === 'center_auto').sort((a, b) => a.id - b.id),
        }
        return {
            required: certCenterBadges.required.map(item => ({
                ...item,
                status:
                    myBadges.some(badge => Number(badge.verification_badge_id) === Number(item.id)) === false
                        ? 'NOT_YET'
                        : myBadges.find(badge => badge.verification_badge_id === item.id).enabled === false
                        ? 'DECLINED'
                        : myBadges.find(badge => badge.verification_badge_id === item.id).confirmed
                        ? 'CONFIRMED'
                        : 'PENDING',
            })),
            mandated: certCenterBadges.mandated.map(item => ({
                ...item,
                status:
                    myBadges.some(badge => badge.verification_badge_id === item.id) === false ||
                    myBadges.find(badge => badge.verification_badge_id === item.id).enabled === false
                        ? 'NOT_YET'
                        : myBadges.find(badge => badge.verification_badge_id === item.id).confirmed
                        ? 'CONFIRMED'
                        : 'PENDING',
            })),
        }
    },
    declined: state => {
        return (state.myVerificationBadges || []).filter(badge => !badge.enabled && badge.rejection_reason)
    },
}

const actions = {
    async loadVerificationBadges({ getters, commit }) {
        if (getters.verificationBadges) return

        try {
            const badges = await verificationBadgeService.all()
            commit('setVerificationBadges', badges)

            return badges
        } catch (e) {
            return e
        }
    },
    async loadMyVerificationBadges({ getters, commit }) {
        try {
            const badges = await verificationBadgeService.get(getters.me.id)
            commit('setMyVerificationBadges', badges)

            return badges
        } catch (e) {
            return e
        }
    },
}

const mutations = {
    setVerificationBadges(state, badges) {
        state.verificationBadges = [...badges]
    },
    setMyVerificationBadges(state, badges) {
        state.myVerificationBadges = [...badges]
    },
    setVerifyData(state, value) {
        state.verifyData = { ...state.verifyData, ...value }
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
