import userService from '@/services/user'
// initial state
const defaultState = () => ({
    toast: {
        message: null,
        type: null,
        show: false,
    },
    modals: [],
    loading: {
        agents: false,
        agentRankings: false,
        chats: false,
        global: false,
        friends: false,
        requested: false,
        cardRecommendations: false,
    },
    buildNumberFrontend: null,
    niceData: null,
    deviceToken: null,
    isAppFirstLoaded: false,
    contacts: [],
    registeredContactsLength: 0,
    hackleUser: null,
    firebaseVari: null,
})

const state = defaultState()

// getters
const getters = {
    toast: state => state.toast,
    modals: state => state.modals,
    loading: state => state.loading,
    global: state => state.global,
    buildNumberFrontend: state => state.buildNumberFrontend,
    niceData: state => state.niceData,
    deviceToken: state => state.deviceToken,
    isAppFirstLoaded: state => state.isAppFirstLoaded,
    contacts: state => state.contacts,
    registeredContactsLength: state => state.registeredContactsLength,
    hackleUser: state => state.hackleUser,
    firebaseVari: state => state.firebaseVari,
}

// actions
const actions = {
    setToast({ commit }, payload) {
        payload.show = true
        commit('setToast', payload)
    },
    async afterAuthCallbacks({ commit, dispatch, getters }) {
        await dispatch('getProducts')
        dispatch('loadMe')
            .then(() => {
                dispatch('loadChats')
            })
            .catch(async e => {
                console.log('app/store', e)
                if (e.status === 401) {
                    const header = JSON.parse(localStorage.getItem('header')) // header는 문자열로 저장되어 있을 것이므로, 객체로 변환 필요
                    if (header) {
                        await dispatch('signInPhoneNumber', {
                            email: header['X-User-Email'],
                            token: header['X-User-Token'],
                        })
                    }
                }
            })
        dispatch('loadVerificationBadges').then(() => dispatch('loadMyVerificationBadges'))
        dispatch('loadSettings')
        dispatch('loadBadges')
        dispatch('loadNotifications')
        dispatch('loadItems')

        let numTries = 0

        const interv = setInterval(() => (getters.deviceToken ? postToken() : null), 1000)
        const postToken = async () => {
            if (numTries >= 10) {
                clearInterval(interv)
                return
            }

            numTries++
            try {
                userService.registerDevice(getters.deviceToken)
            } catch (e) {}
            clearInterval(interv)
        }
    },
    async getRegisteredContactsLength({ getters, commit }) {
        try {
            const length = await userService.getContactsLength(getters.me.id)
            commit('setRegisteredContactsLength', length)
        } catch (e) {
            return e
        }
    },
    async registerDeviceId({ getters }) {
        if (getters.device) {
            await userService.registerDeviceId({
                userId: getters.me.id,
                deviceId: getters.device.id,
                adid: getters.device.adid,
            })
        }
    },
}

// mutations
const mutations = {
    setToast(state, payload) {
        if (!payload) {
            state.toast = {
                message: null,
                type: null,
                show: false,
            }

            return
        }

        if (payload.type !== undefined) {
            state.toast.type = payload.type
        }

        if (payload.message !== undefined) {
            if (typeof payload.message === 'string') {
                state.toast.message = payload.message
            } else if (payload.message) {
                // We decided that error will be response.data.msg
                state.toast.message = payload.message.msg
            } else {
                state.toast.message = null
            }
        }

        state.toast.show = payload.show
        state.toast.options = payload.options
    },
    setLoading(state, payload) {
        // If we use this.$loading(boolean), set global loader.
        if (typeof payload === 'boolean') {
            state.loading.global = payload
            return
        }

        // Otherwise, use given payload.
        Object.keys(payload).forEach(key => {
            if (typeof payload[key] === 'boolean') state.loading[key] = payload[key]
        })
    },
    setVersionInfo(state, buildNumberFrontend) {
        state.buildNumberFrontend = buildNumberFrontend
    },
    addModal(state, modal) {
        state.modals.push(modal)
    },
    popModal(state) {
        state.modals.pop()
    },
    setNiceData(state, niceData) {
        state.niceData = niceData
    },
    setDeviceToken(state, deviceToken) {
        state.deviceToken = deviceToken
    },
    setIsAppFirstLoaded(state, value) {
        state.isAppFirstLoaded = value
    },
    setContacts(state, contacts) {
        state.contacts = contacts || []
    },
    setRegisteredContactsLength(state, length) {
        state.registeredContactsLength = length
    },
    setHackleUser(state, user) {
        state.hackleUser = user
    },
    setFirebaseVari(state, variation) {
        state.firebaseVari = variation
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
