<template>
    <div class="chats bg-main">
        <Disconnected v-if="!loading" />
        <Loading :loading="loading" />
        <template v-if="!loading">
            <OnboardChatsPage v-if="isOnboarding" :chats="chats" />
            <template v-else>
                <div v-if="$isTester()" class="ver-scroll layout" @scroll="onScroll">
                    <div class="main-title m-t-8 m-l-4 m-b-12">매칭</div>
                    <button class="btn btn-personal-chat" @click="onClickChatItem('personal')">
                        매니저 추천 프로필을 확인해주세요 <i class="material-icons i-right">chevron_right</i>
                    </button>
                    <div class="welcoming-page m-t-12 m-b-40" @click="onClickChatItem('welcoming')">
                        궁금한 점이 있으세요?
                    </div>
                </div>
                <div v-else class="ver-scroll layout" @scroll="onScroll">
                    <div @click="onClickBanner">
                        <img
                            class="m-b-10"
                            src="@/assets/images/invitation-friend/invite.png"
                            :style="{ width: '100%', height: 'auto', borderRadius: '6px' }"
                        />
                    </div>
                    <div v-if="planDetail && profile.status === 'confirmed'" class="membership-card">
                        <div class="membership-wrap flex-row">
                            <div class="membership-content">
                                <h3 class="membership-title">{{ $translate(planDetailCode.toUpperCase()) }} 멤버십</h3>
                                <div v-if="planDetail.name !== 'Love Fit'" class="membership-info f-13">
                                    <div class="valid-month">
                                        {{ Math.floor(planDetail.valid_date / 30) }}개월 · {{ validUntil }} 종료
                                    </div>
                                    <div v-if="validDate <= 14" class="valid-date f-9 f-medium m-l-8">
                                        <h5>{{ validDate }}일 남음</h5>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!planDetail.name.includes('Marry Fit Plus')" class="upgrade-container">
                                <img
                                    @click="onClickUpgradeMembership"
                                    :src="require('@/assets/images/upgrade-btn.png')"
                                    class="upgrade-btn"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="main-title m-t-18 m-l-4 m-b-12">매칭</div>
                    <div ref="ver-scroll" class="flex m-b-40" v-if="chats.length > 0">
                        <component class="m-t-12" v-for="chat in chats" :is="'ChatItem'" :chat="chat" :key="chat.id" />
                    </div>
                    <!-- 푸터 -->
                    <div class="consent-text">
                        <div class="text-top">
                            <span v-html="'주식회사 바닐라브릿지'"></span>
                            <div class="toggle" @click="toggleVisibility">
                                <span class="text" v-html="'사업자 정보'" />
                                <img
                                    class="m-r-2"
                                    :src="
                                        require(`@/assets/images/icons/${
                                            isVisible ? 'arrow_open' : 'arrow_closed'
                                        }.png`)
                                    "
                                    width="12px"
                                    height="12px"
                                />
                            </div>
                        </div>
                        <div v-if="isVisible">
                            <span class="text" v-html="'주소 : 서울시 강남구 테헤란로 503, 14층<br>'" />
                            <span class="text" v-html="'대표: 최성만 | 사업자등록번호 : 165-86-00709<br>'" />
                            <span class="text" v-html="'통신판매번호 : 2020-서울강남-01813<br>'" />
                            <span class="text" v-html="'전화번호 : 1533-4680<br>'" />
                        </div>
                        <span class="text-bold" @click="openConsent('service')" v-html="'이용약관'" />
                        <span class="text" v-html="' | '" />
                        <span class="text-bold" @click="openConsent('privacy')" v-html="'개인정보처리방침'" />
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import ChatItem from './components/ChatItem'
import Disconnected from '@/components/common/Disconnected'
import OnboardChatsPage from '@/routes/chat/chats/OnboardChatsPage'
// import RepurchaseBanner from './components/RepurchaseBanner'
// import ExtendBanner from './components/ExtendBanner'
import { getPlanNameById } from '@/store/data/product'
import scheduleService from '@/services/schedule'
import userService from '@/services/user'
import inquiryService from '@/services/inquiry'

export default {
    name: 'ChatsPage',
    components: {
        ChatItem,
        Disconnected,
        OnboardChatsPage,
        // RepurchaseBanner,
        // ExtendBanner,
    },
    data: () => ({
        scrollTop: 0,
        feedback: {},
        oldStatus: undefined,
        newStatus: undefined,
        isVisible: false,
        initialized: false,
    }),
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        profile() {
            return (this.me || {}).profile
        },
        status() {
            return this.$store.getters.profile.status
        },
        loading() {
            return this.$store.getters.loading.chats
        },
        loadChats() {
            return this.$store.dispatch('loadChats')
        },
        connected() {
            return this.$store.getters.connected
        },
        chats() {
            const chats = (this.$store.getters.chats || []).filter(chat => chat.users !== null) || []
            if (this.$isTester()) {
                return chats.filter(chat => ![1, 2, 3].includes(chat.agent_id))
            } else {
                return chats
            }
        },
        personalManager() {
            const chats = (this.$store.getters.chats || []).filter(chat => chat.users !== null) || []

            return chats.filter(chat => [1].includes(chat.agent_id))
        },
        welcomingManager() {
            const chats = (this.$store.getters.chats || []).filter(chat => chat.users !== null) || []

            return chats.filter(chat => [3].includes(chat.agent_id))
        },

        // product_infos
        validTickets() {
            return this.$store.getters.validTickets
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || false
        },
        userSubscriptionPlans() {
            return this.$store.getters.userRatePlans.subscriptionPlans || false
        },
        isOnboarding() {
            if (!this.$store.getters.me) return false

            if ((this.me.profile || {}).status === 'created' || (this.me.profile || {}).status !== 'confirmed') {
                return true
            } else {
                return false
            }
        },
        oppositeGender() {
            return this.me.gender ? 'b' : 'a'
        },
        gender() {
            return this.me.gender ? 'a' : 'b'
        },
        myPlan() {
            return this.userRatePlans.onGoing.find(o => o.ptype === 'subscription_plan')
        },
        planDetail() {
            if (this.userRatePlans.onGoing && this.userRatePlans.onGoing.length > 0) {
                return getPlanNameById(this.myPlan.product_id)
            } else {
                return null
            }
        },
        planDetailCode() {
            if (!this.planDetail) {
                return null
            } else {
                if (this.planDetail.code.includes('mf_3month')) {
                    return 'mf_3month'
                } else if (this.planDetail.code.includes('mfplus_3month')) {
                    return 'mfplus_3month'
                } else {
                    return 'lovefit_eternal'
                }
            }
        },
        validUntil() {
            if (this.userRatePlans.onGoing.length > 0) {
                return this.$moment(this.myPlan.valid_until).format('MM/DD')
            } else {
                return null
            }
        },
        validDate() {
            if (this.userRatePlans.onGoing.length > 0) {
                return this.$moment(this.myPlan.valid_until).diff(this.$moment(), 'days')
            } else {
                return null
            }
        },
        variation() {
            this.$nativeBridge.postMessage({
                action: 'setFirebaseVariationName',
                value: 'onboarding_test',
            })
            return this.$store.getters.firebaseVari || 0
        },
    },
    watch: {
        'me.profile': {
            handler(newProfile) {
                if (newProfile && !this.initialized) {
                    this.init()
                    this.initialized = true
                }
            },
            immediate: true,
        },

        '$store.getters.profile.status': function (newStatus, oldStatus) {
            if (newStatus !== undefined) {
                this.newStatus = newStatus
                // this.init()
            }
            if (oldStatus !== undefined) {
                this.oldStatus = oldStatus
            }

            if (this.oldStatus && this.newStatus && this.oldStatus !== this.newStatus) {
                this.init()
                this.sendAirbridgeEvent()
            }
        },
    },
    methods: {
        async onClickChatItem(chatType) {
            // // 매니저가 링크 전송 시 챗아이템 클릭해서 브라우저 열리지 않도록
            // e.preventDefault()
            // if (chatType === 'personal') {
            //     let chat = this.personalManager
            // } else {
            //     let chat = this.welcomingManager
            // }

            try {
                this.$loading(true)
                if (chatType === 'personal') {
                    await this.$store.dispatch('loadChat', {
                        chatId: this.personalManager[0].id,
                        force: true,
                    })
                } else {
                    await this.$store.dispatch('loadChat', {
                        chatId: this.welcomingManager[0].id,
                        force: true,
                    })
                }

                this.$router.push({
                    name: 'ChatroomPage',
                })
            } catch (e) {
                this.$toast.error(e.data)
            } finally {
                this.$loading(false)
            }
        },
        async checkFeedbackNeeded() {
            await this.$store.dispatch('loadMe')
            const list = await scheduleService.scheduleList(this.me.id)
            const status = [`send_feedback_${this.gender}`, `send_feedback`, `complete_feedback_${this.oppositeGender}`]
            const needFeedback = list.data.some(sch => {
                if (
                    status.indexOf(sch.schedule.schedule_status) > -1 &&
                    this.$moment().isAfter(this.$moment(sch.schedule.date_confirmed).add(1, 'days').set('hour', 0)) &&
                    !sch.my_feedbacks.length &&
                    this.$moment(sch.schedule.date_confirmed).isAfter(this.$moment('2022-04-04'))
                ) {
                    this.feedback.scheduleId = sch.schedule.id
                    this.feedback.targetId = sch.schedule[`${this.oppositeGender}_user_id`]
                    this.feedback.messageId = sch.schedule[`${this.gender}_message_id`]
                    this.feedback.schedule = sch
                    return true
                }
            })

            if (needFeedback) this.openForceFeedbackModal()
        },
        async init() {
            this.$store.dispatch('loadConstants')
            this.$store.dispatch('loadChats', true)
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#FAF0E1',
                    bottom: '#FFFCF6',
                },
            })

            this.checkFeedbackNeeded()
            if (this.$store.getters.chat && !this.$route.params.preventSetChatNull) {
                this.$store.commit('setChat', { chat: null })
            }
            // 반려사유 알려주기
            const declinedBadges = (this.$store.getters.myVerificationBadges || []).filter(
                badge => !badge.enabled && badge.rejection_reason
            )
            // const showModal = !localStorage.getItem('check-decline-reason')
            // console.log(localStorage.getItem('check-decline-reason'))
            // if (declinedBadges.length && showModal) {
            if (declinedBadges.length) {
                localStorage.setItem('check-decline-reason', true)
                this.$modal
                    .custom({
                        component: 'ModalBadgeDeclined',
                        options: { reasons: declinedBadges, hideBottomBtn: false },
                    })
                    .then(res => {
                        if (res === 1) this.$stackRouter.push({ name: 'CertificatePage' })
                    })
            }
            // setTimeout(() => {
            //     this.showRepurchaseModal()
            // }, 1000)

            // 첫 로그인때 리워드 모달
            // const showRewardModal = this.me.sign_in_count === 6
            // if (showRewardModal) {
            //     this.showRewardModal()
            // }
            const showRewardModal = !localStorage.getItem('user-logged-in')
            if (showRewardModal && this.me.profile.status === 'created') {
                this.showRewardModal()
            }

            // if (this.me.profile.status === 'judged') {
            //     this.showManagerModal()
            // }

            if (
                (this.me.profile.marry === null || this.me.style.marry_type === null) &&
                this.me.profile.status === 'confirmed'
            ) {
                this.checkMarryModal()
            }

            if (!localStorage.getItem('HACKLE_ID')) {
                localStorage.setItem('HACKLE_ID', Number(new Date()) + Math.random())
            }
        },
        // showRepurchaseModal() {
        //     // 재구매 안내 모달

        //     const showRepurchaseModal = localStorage.getItem('check-expire-notice') === 'true' ? false : true
        //     if (this.showBanner && showRepurchaseModal) {
        //         localStorage.setItem('check-expire-notice', true)
        //         this.$modal.custom({
        //             component: 'ModalNoticeExpire',
        //         })
        //     }
        // },

        async showRewardModal() {
            localStorage.setItem('user-logged-in', true)
            const idx = await this.$modal.basic({
                body: '지인 추천 혹은 오프라인 이벤트를 통해 굿퍼슨을 알게 되셨나요?',
                buttons: [
                    {
                        label: '아니오',
                        class: 'btn-default',
                    },
                    {
                        label: '네',
                        class: 'btn-primary',
                    },
                ],
                preventCloseOnMousedownMask: true,
            })
            if (idx === 1) {
                this.$stackRouter.push({ name: 'EnterCodePage' })
            }
        },

        onScroll(event) {
            this.scrollTop.value = event.target.scrollTop
        },
        openConsent(type) {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://jj-api.vanillabridge.com/api/users/terms?term_type=${type}`,
            })
        },
        toggleVisibility() {
            this.isVisible = !this.isVisible
        },
        airbridgeEvent() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `complete_profile_${this.$store.getters.me.gender === 0 ? 'male' : 'female'}`,
                },
            })
        },
        sendAirbridgeEvent() {
            console.log('old', this.oldStatus)
            console.log('new', this.newStatus)
            if (this.oldStatus === 'created' && this.newStatus === 'complete_profile') {
                this.$nativeBridge.postMessage({
                    action: 'sendAirbridgeEvent',
                    value: {
                        category: `Complete_Profile_Story`,
                    },
                })
            } else if (
                (this.oldStatus === 'ongoing_verification' || this.oldStatus === 'complete_profile') &&
                this.newStatus === 'judging'
            ) {
                this.$nativeBridge.postMessage({
                    action: 'sendAirbridgeEvent',
                    value: {
                        category: `Complete_Verify_Badges`,
                    },
                })
            } else if (this.oldStatus === 'judging' && this.newStatus === 'judged') {
                this.$nativeBridge.postMessage({
                    action: 'sendAirbridgeEvent',
                    value: {
                        category: `Complete_Profile_Judged`,
                    },
                })
            } else if (
                (this.oldStatus === 'judged' || this.oldStatus === 'judging') &&
                this.newStatus === 'confirmed'
            ) {
                this.$nativeBridge.postMessage({
                    action: 'sendAirbridgeEvent',
                    value: {
                        category: `Complete_Profile_Confirmed`,
                    },
                })

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: `Complete_Profile_Confirmed`,
                    },
                })
            }
        },
        moveToProductList() {
            this.$router.push({ name: 'PrePaymentPage' })
        },

        async showManagerModal() {
            try {
                const idx = await this.$modal.custom({
                    component: 'ModalVerticalButtons',
                    options: {
                        title: '굿퍼슨에 오신 것을 환영합니다!',
                        body:
                            '매니저님께서 회원님에게 어울리는 분을 찾고 있어요. <br />가까운 영업시간 내에 설레는 인연을 소개시켜드릴게요 😊<br />(09:00 ~ 18:00, 주말ㆍ공휴일 제외)',
                        buttons: [
                            {
                                label: '확인',
                                style: {
                                    bgColor: '#03006E',
                                    color: '#FFFFFF',
                                    border: 'none',
                                },
                            },
                        ],
                        height: '36px',
                        preventCloseOnMousedownMask: true,
                    },
                })
                if (idx === 0) {
                    this.$loading(true)
                    const res = await userService.profileConfirm(this.me.id, 'start', this.me.my_manager)
                    this.$toast.success(res.msg)

                    // this.$nativeBridge.postMessage({
                    //     action: 'sendAirbridgeEvent',
                    //     value: {
                    //         category: `Complete_Profile_Confirmed`,
                    //     },
                    // })

                    // this.$nativeBridge.postMessage({
                    //     action: 'sendFirebaseEvent',
                    //     value: {
                    //         category: `Complete_Profile_Confirmed`,
                    //     },
                    // })

                    await this.$store.dispatch('loadMe')
                    this.$loading(false)
                }
            } catch (e) {
                this.$loading(false)
                console.error(e)
                this.$toast.error(e.data.msg)
            }
        },

        async checkMarryModal() {
            try {
                const idx = await this.$modal.custom({
                    component: 'ModalVerticalButtons',
                    options: {
                        title: '결혼여부를 선택해주세요',
                        body: `프로필 항목에 결혼 여부가 추가되었습니다.<br>미혼을 선택하신 경우 미혼 분들만 소개되며, 매칭 선호사항에서 수정하실 수 있습니다.`,
                        buttons: [
                            {
                                label: '입력하기',
                                style: {
                                    bgColor: '#03006E',
                                    color: '#FFFFFF',
                                    border: 'none',
                                },
                            },
                        ],
                        height: '36px',
                        preventCloseOnMousedownMask: true,
                    },
                })
                if (idx === 0) {
                    if (this.me.profile.marry === null) {
                        await this.$stackRouter.push({ name: 'EditProfilePage', props: { from: 'chats' } })
                        // } else if (this.me.style.marry_type === null) {
                        //     await this.$stackRouter.push({ name: 'EditStylePage' })
                    }
                }
            } catch (e) {
                this.$loading(false)
                console.error(e)
                this.$toast.error(e.data.msg)
            }
        },

        async openForceFeedbackModal() {
            const idx = await this.$modal.custom({
                component: 'ModalForceFeedback',
                options: {
                    preventClose: false,
                    schedule: this.feedback.schedule,
                },
            })
            if (idx === 1) {
                //     // 매니저에게 문의

                //     this.$nativeBridge.postMessage({
                //         action: 'sendFirebaseEvent',
                //         value: {
                //             category: 'Click_To_Manager',
                //         },
                //     })
                //     try {
                //         const chat = this.chats.find(chat => chat.agent_id && chat.agent_id !== 4)
                //         const unreadCount = chat.unread
                //         await this.$store.dispatch('loadChat', {
                //             chatId: chat.id,
                //             force: true,
                //         })
                //         this.$router.push({
                //             name: 'ChatroomPage',
                //             params: { unreadCount },
                //         })
                //         return
                //     } catch (e) {
                //         console.log('send to manager chat', e)
                //     }
                // } else {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Click_Feedback',
                    },
                })
                // 피드백 바로 진행
                const chat = this.chats.find(chat => chat.agent_id !== 4)
                await this.$store.dispatch('loadChat', {
                    chatId: chat.id,
                    force: true,
                })
                this.$stackRouter.push({
                    name: 'MeetingFeedback',
                    props: {
                        person: this.feedback.schedule.name,
                        message: { id: this.feedback.messageId },
                        scheduleId: this.feedback.scheduleId,
                        targetId: this.feedback.targetId,
                    },
                })
            }
        },
        async onClickUpgradeMembership() {
            const payload = {
                user_id: this.me.id,
                funnel: 'enter_membership_list',
            }
            await inquiryService.updateFunnel(payload)
            this.$router.push({
                name: 'MembershipIntroductionPage',
            })
        },
        onClickBanner() {
            this.$router.push({ name: 'InvitationFriendPage' })
        },
    },
}
</script>

<style lang="scss" scoped>
.chats {
    position: relative;
    .title {
        @include spoqa-f-bold;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 16px;
    }
    .layout {
        height: 100%;
        padding: 16px;
        @include flex;

        .top-border {
            position: absolute;
            top: 48px;
            height: 1px;
            width: 100vw;
            background-color: $grey-02;
        }
    }

    .empty {
        margin: 24px;
        text-align: center;

        img {
            width: 40px;
            margin-bottom: 16px;
        }
    }

    .chats-tab {
        .count {
            margin-top: -2px;
        }
    }

    .chat-title {
        margin: 0 16px 16px;
        font-size: 24px;
        color: black;
        line-height: normal;
        font-weight: 500;
        letter-spacing: -0.24px;
    }

    .main-title {
        font-size: 17px;
        color: #5c5c77;
        margin: 0 0 12px 4px;
        line-height: 17px;
        font-weight: 500;
    }

    .consent-text {
        font-size: 12px;
        line-height: 16.5px;
        @include f-medium;
        color: $grey-05;

        .text-top {
            display: flex;
            justify-content: space-between;
        }

        .text-bold {
            @include f-bold;
        }
    }

    .btn.btn-personal-chat {
        background-color: #5c5c77;
        height: 56px;
        margin: 0px 4px;
        font-size: 15px;
        color: white;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
    }

    .welcoming-page {
        color: #8a8a8a;
        text-align: center;
        text-decoration: underline;
    }

    .membership-wrap {
        border-radius: 8px;
        border: 1px solid #ff7070;
        // background: #FFF;
        background: #fff0ee;
        min-height: 76px;
        align-items: center;

        .membership-content {
            padding-left: 16px;

            .membership-title {
                font-weight: 600;
                line-height: 100%;
            }

            .membership-info {
                display: flex;
                margin-top: 10px;

                .valid-month {
                    @include center;
                }

                .valid-date {
                    border-radius: 4px;
                    background: #ff7070;
                    color: white;
                    width: fit-content;
                    padding: 5px;
                }
            }
        }
        .upgrade-container {
            // display: flow;
            //     min-width: 100px;
            max-width: 40%;
            @include center;
            .upgrade-btn {
                // display: flex;
                min-width: 97px;
                max-width: 40%;
            }
        }
    }
}
</style>
