<template>
    <div v-if="!$isTester()" id="ch-plugin-wrapper" :style="wrapStyle" @click="launcher" :class="{ active: active }">
        <div id="ch-plugin-launcher">
            <div class="default" v-if="defaultLauncher">
                <img class="m-r-2" src="@/assets/images/app/channel-talk.png" width="40px" height="40px" />
            </div>
            <div class="custom" v-else>
                <img
                    v-if="hasIcon"
                    class="m-r-2"
                    src="@/assets/images/icons/questionmark.png"
                    width="20px"
                    height="20px"
                />
                <span :style="textStyle">문의</span>
                <div :class="{ active: unreadCount > 0 }" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChannelTalk',
    data: () => ({
        active: false,
        unreadCount: 0,
    }),
    props: ['hasIcon', 'textStyle', 'wrapStyle', 'defaultLauncher'],
    methods: {
        launcher() {
            window.ChannelIO('showMessenger')
        },
    },
    computed: {
        channelPluginSettings() {
            return {
                pluginKey: process.env.VUE_APP_CHANNELTALK_PLUGIN_KEY,
                hideDefaultLauncher: true,
            }
        },
    },
    mounted() {
        this.$loadScript('https://cdn.channel.io/plugin/ch-plugin-web.js').then(value => {
            if (this.$isTester()) return

            if (value) {
                var ch = function () {
                    ch.c(arguments)
                }
                ch.q = []
                ch.c = function (args) {
                    ch.q.push(args)
                }
                window.ChannelIO = ch
                window.ChannelIO('boot', {
                    ...this.channelPluginSettings,
                    mobileMessengerMode: 'iframe',
                })
                window.ChannelIO('onBoot', () => {
                    this.active = true
                })
                window.ChannelIO('onChangeBadge', unreadCount => {
                    this.unreadCount = unreadCount
                })
            }
        })
    },
    beforeDestroy() {
        this.$unloadScript('https://cdn.channel.io/plugin/ch-plugin-web.js')
    },
}
</script>

<style scoped lang="scss">
#ch-plugin-wrapper {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid $grey-03;
    background: white;
    position: absolute;
    bottom: 32px;
    right: 16px;
    color: white;
    @include spoqa-f-bold;
    @include center;

    #ch-plugin-launcher {
        @include center;
    }
    #ch-plugin-badge {
        width: 24px;
        height: 24px;
        font-size: 14px;
    }
    .active {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: red;
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
</style>
