<template>
    <div class="root">
        <img class="schedule-icon" src="@/assets/images/schedule/schedule.png" />
        <span class="empty-schedule">
            아직 약속이 없어요. <br />
            상대방과 약속이 잡히면 이 곳에 표시돼요!
        </span>
    </div>
</template>

<script>
export default {
    name: 'SchedulePage',
    components: {},
}
</script>

<style lang="scss" scoped>
div.root {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px;
    letter-spacing: -0.2px;

    img.schedule-icon {
        width: 48px;
        height: 48px;
        margin-bottom: 16px;
    }

    span.empty-schedule {
        color: $grey-07;
        font-size: 14px;
        text-align: center;

        @include f-regular;
    }
}
</style>
