<template>
    <div class="user-detail-photos">
        <!-- <Carousel
            :per-page="1"
            :pagination-position="'bottom-overlay'"
            :pagination-size="8"
            :pagination-padding="4"
            :pagination-active-color="'#FFFFFF'"
            :pagination-color="'#808080'"
            :navigateTo="[initialPage, true]"
            class="carousel"
        > -->
        <!-- <Slide class="p-relative" :key="photo.id" v-for="photo in photos"> -->
        <div
            v-if="this.user.id !== this.$store.getters.me.id && !likeMe"
            class="like-icon"
            style="z-index: 1; position: absolute; right: 3%; bottom: 3%"
        >
            <img @click="onClickLikeIcon()" class="like" :src="likeIcon" alt="like-icon" width="90px" height="90px" />
        </div>
        <div class="p-relative" style="text-align: center">
            <!-- <div class="overlay top"></div> -->
            <div class="img-cover" style="position: relative">
                <img class="img-user" :src="photo.url" alt="user-photo" />
                <div class="watermark">
                    <div class="triangle">
                        <div class="one m-b-32">
                            {{ phoneNumber }}
                        </div>
                        <div class="two">
                            <div class="m-r-40">
                                {{ phoneNumber }}
                            </div>
                            <div>
                                {{ phoneNumber }}
                            </div>
                        </div>
                    </div>
                    <div class="triangle">
                        <div class="two m-b-32">
                            <div class="m-r-40">
                                {{ phoneNumber }}
                            </div>
                            <div>
                                {{ phoneNumber }}
                            </div>
                        </div>
                        <div class="one" style="">
                            {{ phoneNumber }}
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="overlay bottom"></div> -->
        </div>
        <!-- </Slide> -->
        <!-- </Carousel> -->
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'UserDetailPhotos',
    props: {
        photo: Object,
        user: {
            type: Object,
            required: true,
        },
        message: Object,
        likeActivate: Boolean,
        likeMe: Boolean,
        allPhotos: Array,
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        phoneNumber() {
            return this.$store.getters.me.phone_number
        },
        likeIcon() {
            if (
                !this.likeActivate &&
                this.user.photo_histories &&
                this.user.photo_histories.accepted_photo_url &&
                this.user.photo_histories.accepted_photo_url === this.photo.url
            ) {
                return require(`@/assets/images/icons/like_active.png`)
            } else {
                return require(`@/assets/images/icons/like_inactive.png`)
            }
        },
        chatUser() {
            return this.$store.getters.chat.user.name
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || {}
        },
    },
    data: () => ({
        initialPage: 0,
    }),
    methods: {
        async initSchedule() {
            try {
                const aUser = this.user.gender === 1 ? this.user.id : this.me.id
                const bUser = this.user.gender === 1 ? this.me.id : this.user.id
                const { res } = await scheduleService.initSchedule({
                    a_user_id: aUser,
                    b_user_id: bUser,
                })
                console.log('res', res)
                if (res === 'fail') {
                    this.$toast.error('이미 약속잡기가 진행 중이에요')
                }
            } catch (e) {
                console.log(e)
            }
        },
        async onClickLikeIcon() {
            if (this.chatUser === '고객센터' && this.me.profile.status === 'confirmed') {
                this.$modal
                    .basic({
                        body:
                            '해당 회원의 프로필을 퍼스널 매칭매니저가 다시 보내드릴 예정입니다. <br>잠시만 기다려 주세요.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 0) {
                            this.$emit('close')
                        }
                    })
                return
            }

            if (!this.likeActivate) {
                this.$modal
                    .basic({
                        body:
                            '이미 수락한 회원입니다. 상대방도 수락하면 약속 일정을 보내드립니다. (상대방이 수락하지 않는 경우 별도 연락을 드리진 않습니다.)',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 0) {
                            this.$emit('close')
                        }
                    })
            } else {
                let msg = ''
                let idx = 0

                if (!this.$store.getters.me.agree_penalty_policy) {
                    msg = await this.$modal.custom({
                        component: 'ModalAgreeLike',
                        options: {
                            type: 'after',
                        },
                    })
                } else {
                    idx = await this.$modal.basic({
                        title: '만남 의향이 있는 경우 수락해주세요 :)',
                        body: `${
                            this.user.photo_histories.is_michagam ||
                            (this.user.source_photo_histories && this.user.source_photo_histories.is_manner) ||
                            (this.userRatePlans.onGoingTicketPlan[0] &&
                                this.userRatePlans.onGoingTicketPlan[0].code.includes('unlimited'))
                                ? '나와 상대방이 서로 수락하면 약속 잡기가 시작되며, ' +
                                  `<span class="c-red">무료로 약속을 진행하는 경우에도</span>` +
                                  ' 약속 잡기가 시작 된 후 약속 잡기를 그만두면 약속 미진행 수수료 2만원, 약속이 잡힌 후 취소할 경우 취소 수수료 5만원이 부과됩니다.'
                                : '나와 상대방이 서로 수락하면 약속 잡기가 시작되며, 약속 잡기가 시작 된 후 약속 잡기를 그만두면 약속 미진행 수수료 2만원, 약속이 잡힌 후 취소할 경우 취소 수수료 5만원이 부과됩니다.'
                        }`,
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                }

                if (msg === 'agree' || (this.$store.getters.me.agree_penalty_policy && idx === 1)) {
                    const res = await this.$modal.custom({
                        component: 'ModalSendLike',
                        options: {
                            type: 'photo',
                            photo: this.photo.url,
                            target: this.user,
                            message: this.message,
                        },
                    })
                    if (res === 'sentLike') {
                        if (this.user.source_photo_histories && this.user.source_photo_histories.accepted) {
                            await this.initSchedule()
                        }

                        const ret = await this.$modal.custom({
                            component: 'ModalSelectReason',
                            options: {
                                chat: this.$store.getters.chat,
                                status: 'accepted',
                                profilePhotoMessage: this.message,
                            },
                        })

                        if (ret === 'savedReasons') {
                            this.$modal.custom({
                                component: 'ModalAppearanceScore',
                                options: {
                                    chat: this.$store.getters.chat,
                                    user: this.user,
                                    userPhotos: this.allPhotos,
                                },
                            })
                        }
                    } else if (res === 'moveInAppStorePage') {
                        this.$stackRouter.push({ name: 'InAppStorePage' })
                    }
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.watermark {
    color: rgba(255, 255, 255, 0.15);
    position: absolute;
    width: 90%;
    height: 90%;
    top: -5%;
    left: -5%;
    font-size: 14px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transform: rotate(-45deg);

    .triangle {
        display: flex;
        flex-direction: column;

        .one {
            display: flex;
            justify-content: center;
        }

        .two {
            display: flex;
            justify-content: center;
        }
    }
}
.user-detail-photos {
    position: relative;

    margin: 16px;
    .img-user {
        width: 100%;
        border-radius: 8px;
    }
}
</style>
