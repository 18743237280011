<template>
    <div class="manner-feedback-badge bg-white">
        <div class="info">
            <span class="text f-14 m-r-3">만남 후기</span>
            <div class="img-container" @click="onClickInfo()">
                <img
                    class="feedback-info"
                    src="@/assets/images/icons/profile-feedback-info.png"
                    alt=""
                    width="13"
                    height="13"
                />
            </div>
        </div>
        <div class="title f-16 m-t-8">
            {{ title }}
        </div>
        <div v-if="keywords" class="keyword m-t-16">
            <span class="keyword-title f-14">후기 키워드</span>
            <div class="keyword-content m-t-8">
                <div v-for="keyword in keywords" :key="keyword" class="keywords f-12 m-r-5">
                    {{ keyword }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MannerFeedbackComponent',
    props: {
        photo: Array,
        likeType: String,
        user: {
            type: Object,
            required: true,
        },
        message: Object,
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        title() {
            if (!this.user.user_info) return

            if (this.user.user_info.after) {
                return '애프터를 많이 받는 매력적인 회원이에요.'
            } else if (this.user.user_info.feedback) {
                return '만남 후기가 좋은 회원이에요.'
            } else if (this.user.user_info.look) {
                return '실물이 훌륭한 회원이에요.'
            } else if (this.user.user_info.manner) {
                return '매너가 좋은 회원이에요.'
            } else if (this.user.user_info.sincerity) {
                return '진정성 있는 회원이에요.'
            }
            return ''
        },
        keywords() {
            if (!this.user.manner_keywords) return

            return this.user.manner_keywords
        },
    },
    data: () => ({
        initialPage: 0,
        // title: null,
        answer: null,
    }),
    mounted() {
        // this.generateAnswer()
    },
    methods: {
        onClickInfo() {
            this.$modal.basic({
                body: `• 실제 만나봤던 회원님의 만남 후기를 바탕으로 표시되는 정보입니다.<br />• 만남 후기가 있다고 해서 <span class="c-danger" style="text-decoration: underline;">만남을 많이 가졌다는 것을 의미하지 않으니 안심하세요.</span> (만남 후기를 누적하여 표시하는 것이 아니라 비율로 책정합니다.)<br />• 여러 가지 좋은 평가를 받은 회원 분은 한 가지 후기 정보만 표시됩니다.`,
                buttons: [
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.manner-feedback-badge {
    border-radius: 8px;
    border: 1px solid var(--Cool-Grey, #e8e4e7);
    margin: 20px;
    padding: 14px;
    font-family: Pretendard;

    .info {
        display: flex;
        align-items: center;
        color: var(--Purple-Grey, #5c5c77);

        .img-container {
            display: flex;
            align-items: center;
        }
    }

    .title {
        color: var(--Purple, #151360);
        font-weight: 600;
    }

    .keyword {
        .keyword-title {
            color: var(--Purple-Grey, #5c5c77);
        }

        .keyword-content {
            display: flex;
            .keywords {
                display: inline-flex;
                padding: 6px 10px;
                align-items: flex-start;
                gap: 10px;
                border-radius: 24px;
                background: linear-gradient(180deg, #151360 0%, #2a2877 100%);
                color: #fff;
                font-weight: 700;
            }
        }
    }
}
</style>
