<template>
    <div class="edit-profile-menu">
        <ul class="list" v-for="category in Object.keys(menu)" :key="category">
            <li class="item" v-for="item in menu[category]" :key="item.key" @click="item.handler()">
                <img class="icons-outlined icon" v-if="item.icon" :src="item.icon" />
                <span class="key" v-html="$translate(item.key)" />
                <img class="m-r-2" src="@/assets/images/today-meeting/new_badge.png" width="20px" height="20px" />
                <i class="material-icons i-right">chevron_right</i>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'InAppStoreMenu',
    props: {
        stepToast: {
            type: [Boolean, Object],
        },
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        profile() {
            return (this.$store.getters.me || {}).profile
        },
        settings() {
            return this.$store.getters.settings || {}
        },
        styleCompleted() {
            const { style } = this.me

            if (!style) return false

            return Object.values(style).every(val => val !== '' && val !== null)
        },
        profileUpdateRequested() {
            return this.me.profile_update_request !== null
        },
        menu() {
            if (!this.me) return {}
            return {
                POINT_STORE: [
                    {
                        key: 'POINT_STORE',
                        handler: async () => {
                            const res = await this.$needUpdate('1.2.0')
                            if (res) {
                                return
                            }

                            if (this.stepToast && this.stepToast.step <= 3) {
                                this.$toast.success(this.stepToast.msg)
                                return
                            }

                            this.$stackRouter.push({
                                name: 'InAppStorePage',
                            })
                        },
                        icon: require('@/assets/images/store/store.svg'),
                    },
                    // {
                    //     key: 'PHOTO',
                    //     status: `${(this.me.photos || []).length}/6`,
                    //     handler: () => {
                    //         this.$stackRouter.push({
                    //             name: 'EditProfilePage',
                    //             props: { initialTab: 'PHOTO' },
                    //         })
                    //     },
                    // },
                    // {
                    //     key: 'PROFILE_INTRODUCTION',
                    //     handler: () => {
                    //         this.$stackRouter.push({
                    //             name: 'EditProfilePage',
                    //             props: { initialTab: 'PROFILE' },
                    //         })
                    //     },
                    // },
                    // {
                    //     key: 'INTEREST_TAG',
                    //     handler: () => {
                    //         this.$stackRouter.push({
                    //             name: 'EditProfilePage',
                    //             props: { initialTab: 'INTEREST_TAG' },
                    //         })
                    //     },
                    // },
                ],
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.edit-profile-menu {
    // margin-top: 10px;
    // .list {
    //     padding: 24px 16px 0;

    //     &:not(:last-child) {
    //         border-bottom: solid 1px $grey-01;
    //     }
    // }

    .category {
        color: $grey-05;
        font-size: 14px;
        font-weight: normal;
        @include f-regular;
    }

    .item {
        height: 56px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        position: relative;

        border-top: solid 1px #dcdcdc;

        .icon {
            width: 16px;
            margin-right: 12px;
        }

        i {
            position: absolute;
            right: 20px;
        }

        .key {
            font-size: 16px;
            color: black;
        }
        // &:nth-child(1) {
        //     border-bottom: solid 8px rgb(247, 248, 249);
        // }
    }
}
</style>
