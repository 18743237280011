var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-body",class:{ secret: _vm.$isPrivacy() }},[_c('FloatingComponents',{attrs:{"chat":_vm.chat}}),_c('div',{ref:"messages",staticClass:"messages ver-scroll",class:{
            'more-padding': _vm.chat.chat_type === 'users',
            'stop-scrolling': _vm.loading,
            vacation: _vm.chatType === 'pro_agent' && _vm.user.vacation,
        },on:{"scroll":_vm.onScroll}},[(_vm.showAlimiOpenChatMessage)?_c('div',{staticClass:"message-row left"},[_c('div',{staticClass:"profile-and-name flex-row"},[_c('img',{staticClass:"img-profile flex-wrap",attrs:{"src":require('@/assets/images/logo_symbol.png')}}),_c('div',{staticClass:"name flex-wrap",domProps:{"innerHTML":_vm._s(_vm.$translate('GOODPERSON'))}})]),_c('Message',{staticClass:"flex-wrap",attrs:{"message":_vm.alimiOpenChatMessage,"me":false}})],1):_vm._e(),_vm._l((_vm.messages),function(message,idx){return _c('div',{key:(message || {}).id,staticClass:"message-row",class:[_vm.shouldAddMoreMargin(idx), _vm.isMine(message) ? 'right' : 'left']},[(_vm.showUnreadSeparator(message, idx))?_c('UnreadSeparator'):_vm._e(),(
                    (_vm.chat.chat_type === 'users' || _vm.chat.chat_type === 'schedule_change') &&
                    (message.mtype === 'open-chat-introduce' || message.mtype === 'open-chat-schedule-change')
                )?_c('UserChatInfo',{attrs:{"chatType":_vm.chat.chat_type}}):_vm._e(),(_vm.showDailySeparator(idx))?_c('DailySeparator',{attrs:{"message":message}}):_vm._e(),[(!_vm.isMine(message) && message.mtype !== 'request')?_c('div',{staticClass:"profile-and-name flex-row"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.sender(message).photo_url),expression:"sender(message).photo_url"}],staticClass:"img-profile flex-wrap"}),_c('div',{staticClass:"name flex-wrap"},[(_vm.isScheduleChange)?_c('span',{domProps:{"innerHTML":_vm._s(message.user_id === 0 ? '굿퍼슨' : _vm.user.nickname || _vm.user.name)}}):_c('span',{domProps:{"innerHTML":_vm._s(message.user_id === 0 ? '알리미' : _vm.user.nickname || _vm.user.name)}}),(_vm.$isTester())?_c('i',{staticClass:"material-icons m-r f-14",on:{"click":function($event){return _vm.deleteMessage(message)}}},[_vm._v("close")]):_vm._e()])]):_vm._e(),_c('Message',{staticClass:"flex-wrap",attrs:{"message":message,"me":_vm.isMine(message),"profilePhotoMessage":_vm.profilePhotoMessage}})]],2)})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }