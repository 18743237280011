ModalFeedbackEvent.vue

<template>
    <div
        class="modal-feedback-event"
        v-lazy:background-image="require(`@/assets/images/modal/meeting-feedback-background.png`)"
        v-if="isOpen"
    >
        <div class="close-icon" @click="onClickClose()">
            <i class="material-icons">close</i>
        </div>
        <div class="feedback-content">
            <div class="title">굿퍼슨 이용후기 남기고</div>
            <div class="desc1">15만원 상당</div>
            <div class="desc2">만남권 받아가세요</div>
            <button class="eventbtn" @click="onclickModal()">이벤트 바로 참여하기></button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalFeedbackEvent',
    props: ['options'],
    data: () => ({
        isOpen: true,
    }),
    methods: {
        onClickClose() {
            this.isOpen = false
            this.$router.push('/')
        },
        onclickModal() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: 'https://form.typeform.com/to/DXt6Iwc6',
            })
        },
    },
}
</script>

<style scoped lang="scss">
.modal-feedback-event {
    height: 100vh;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120%;
    // background-size: cover;
}
.close-icon {
    position: absolute;
    top: 20px;
    left: 20px;
}
.feedback-content {
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
        margin-bottom: 31px;
        color: #faf0e1;
        text-align: center;
        font-family: Pretendard;
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; /* 33.8px */
        letter-spacing: -1.5px;
    }
    .desc1 {
        color: #fbf5eb;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: Pretendard;
        font-size: 55px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 66px */
        letter-spacing: -2px;
    }
    .desc2 {
        margin-bottom: 61px;
        color: #0fda97;
        font-family: Pretendard;
        font-size: 38px;
        font-style: normal;
        font-weight: 900;
        line-height: 120%;
        letter-spacing: -2px;
    }
    .eventbtn {
        width: 257px;
        height: 58.768px;
        flex-shrink: 0;
        border: none;
        border-radius: 3.5px;
        background: #faf0e1;
        color: #000;
        font-family: Pretendard;
        font-size: 19.297px;
        font-weight: 700;
    }
}
</style>
