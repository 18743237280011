<template>
    <div class="user-chat-floating">
        <div class="photo-container">
            <img class="my-photo" :src="photoUrl.me" alt="" />
            <img class="user-photo" :src="photoUrl.user" alt="" />
        </div>
        <div class="schedule f-14 spoqa-f-regular c-black" v-html="schedule" />
    </div>
</template>

<script>
export default {
    name: 'UserChatFloating',
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        photoUrl() {
            return {
                me: this.$store.getters.me.photos[0].url,
                user: this.chat.user.photo_url,
            }
        },
        schedule() {
            const {
                date_confirmed: dateConfirmed,
                meet_place: place,
                meet_type_confirmed: meetType,
            } = this.chat.schedule

            if (dateConfirmed !== null) {
                const date = this.$moment(dateConfirmed).format('YY.MM.DD')
                const day = this.$moment(dateConfirmed).format('dddd').toUpperCase()
                const time =
                    this.$moment(dateConfirmed).format('m') === '0'
                        ? this.$moment(dateConfirmed).format('H시')
                        : this.$moment(dateConfirmed).format('H시 m분')
                const meetConfirmed = meetType ? this.$translate(meetType.toUpperCase()) : ''

                return `${date}(${this.$translate(day)}) ${time} ${place ? `${place}(에)서` : ''} ${meetConfirmed}`
            } else {
                const meetConfirmed = meetType ? this.$translate(meetType.toUpperCase()) : ''
                return `${place ? `${place}(에)서` : ''} ${meetConfirmed}`
            }
        },
    },
}
</script>

<style scoped lang="scss">
.user-chat-floating {
    padding: 8px 16px;
    display: flex;
    align-items: center;

    .photo-container {
        position: relative;
        width: 45px;
        margin-right: 8px;
        display: flex;
        align-items: center;

        img {
            width: 28px;
            height: 28px;
            border-radius: 50%;
        }
        .my-photo {
            z-index: 2;
            //position: absolute;
            left: 0;
        }
        .user-photo {
            z-index: 1;
            position: absolute;
            right: 0;
        }
    }
}
</style>
