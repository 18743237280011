<template>
    <div class="edit-profile-detail">
        <div class="section">
            <div class="content f-15" style="line-height: 24px; margin: 24px 20px">
                Q. 돌싱 분들은 가입이 어렵습니다.<br />
                결혼 경험이 있으신가요?
            </div>
            <div class="column">
                <div
                    class="item bg-white m-l-20 m-r-20"
                    :class="{ selected: item.id === selectedIdx }"
                    v-for="item in items"
                    :key="item.id"
                    v-html="item.name"
                    @click="onClickItem(item.id)"
                />
            </div>
        </div>
        <!--        <BottomButton :disabled="disabled" @click="next" :label="$translate('NEXT')" />-->
    </div>
</template>

<script>
import signupConstant from '@/assets/constants/onboard-temp-signup'
import userService from '@/services/user'
export default {
    name: 'Marriage',
    data: () => ({
        selectedIdx: null,
    }),
    watch: {
        '$store.getters.niceData': {
            handler(newVal) {
                this.finish(newVal)
            },
            deep: true,
        },
    },
    computed: {
        tempSignupPayload() {
            return JSON.parse(localStorage.getItem('tempSignupPayload')) || {}
        },
        items() {
            return signupConstant.marry
        },
        disabled() {
            return this.selectedIdx === null
        },
        preparedPayload() {
            if (!this.tempSignupPayload.response_id) return false

            const {
                agreeMannerfeedback,
                agreeDirectionality,
                agreeVerification,
                marry,
                response_id: responseId,
            } = this.tempSignupPayload
            const payload = {
                response_id: responseId,
                agree_manner_feedback: agreeMannerfeedback,
                agree_directionality: agreeDirectionality,
                agree_verification: agreeVerification,
                marry,
                state_id: 1,
            }

            // if (this.needInput) payload.why_difficult_detail = this.whyDifficultDetail

            return payload
        },
    },
    methods: {
        checkNiceData(niceData) {
            const checkStatus = ({ gender, status, email, token }) => {
                let errMsg = ''
                // if (status === 'too_young' || this.preparedPayload.marry === 1 || this.preparedPayload.state.id > 2) {
                //     this.$modal.basic({
                //         body: `<p>${
                //             gender === '1' ? '29' : '26'
                //         }세 이상부터 가입이 가능합니다.</p><p>나이조건 미달로 서비스 이용이 불가합니다.</p>`,
                //         buttons: [
                //             {
                //                 label: 'CONFIRM',
                //                 class: 'btn-primary',
                //             },
                //         ],
                //     })
                // }
                if (status.includes('duplicated')) {
                    this.$store
                        .dispatch('signInPhoneNumber', { email, token })
                        .then(() => this.$router.push({ name: 'ChatsPage' }))
                    return false
                }
                if (status === 'fail') errMsg = '본인 인증을 다시 시도해주세요.'

                if (errMsg) {
                    this.$toast.error(errMsg)
                    return false
                }
                return true
            }

            return checkStatus(niceData)
        },
        sendEvents(gender) {
            const events = ['sendFirebaseEvent', 'sendAirbridgeEvent']
            events.forEach(e => {
                this.$nativeBridge.postMessage({
                    action: e,
                    value: {
                        category: `Complete_Signup`,
                        customAttributes: {
                            gender: gender === 0 ? 'Male' : 'Female',
                        },
                    },
                })
            })
        },
        async finish(niceData) {
            this.$set(this.tempSignupPayload, 'response_id', niceData.response_id)
            localStorage.setItem('tempSignupPayload', JSON.stringify(this.tempSignupPayload))

            if (!this.checkNiceData(niceData)) return
            try {
                const { res, user, msg } = await userService.create(this.preparedPayload)
                if (res === 'success') {
                    this.sendEvents(user.gender)
                    localStorage.removeItem('tempSignupPayload')
                    // if (user.profile.status.includes('waitlist')) {
                    //     await this.$store.dispatch('signInPhoneNumber', { ...user, noRedirect: true })
                    //     this.$router.push({
                    //         name: 'WaitlistPage',
                    //         params: {
                    //             user: user,
                    //         },
                    //     })
                    // } else {
                    await this.$store.dispatch('signInPhoneNumber', user)
                    // }
                } else this.$toast.error(msg)
            } catch (e) {
                console.log(e)
                this.$toast.error(e.data)
            }
        },
        onClickItem(idx) {
            if (idx === 1) {
                this.$modal.basic({
                    body:
                        '돌싱분들을 위한 서비스는 준비중에 있습니다. 향후 서비스 오픈 시 안내드리겠습니다. <br> 재혼자가 사실을 숨기고 서비스 이용시,  회사 차원에서 법적인 조치를 취할 수 있음을 알려드립니다.',
                    buttons: [
                        {
                            label: '확인',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else {
                this.selectedIdx = idx

                setTimeout(() => this.next(), 200)
            }
        },
        async next() {
            const payload = { ...this.tempSignupPayload, marry: this.selectedIdx }
            this.$set(this.tempSignupPayload, 'marry', this.selectedIdx)

            localStorage.setItem('tempSignupPayload', JSON.stringify(payload))

            const idx = await this.$modal.basic({
                title: '본인 인증을 진행해주세요.',
                body:
                    '가입 심사를 받은 휴대폰 번호로 본인인증을 진행해주세요.<br>본인 인증 이후 프로필 입력을 시작합니다.',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: `Popup_Verif`,
                    },
                })
                this.$nativeBridge.postMessage({ action: 'verification' })
            }
        },
    },
    mounted() {
        this.selectedIdx = this.tempSignupPayload.marry
        if (this.tempSignupPayload.marry === undefined) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Enter_MarriageStatusPage`,
                },
            })
        }
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#FAF0E1',
                bottom: '#FFF9EF',
            },
        })
    },
}
</script>
