import * as $http from 'axios'

export default {
    all: () => $http.get('v2/chats'),
    detail: chatId => $http.get(`v2/chats/${chatId}`),
    block: chatId => $http.put(`v2/chats/${chatId}/block`),
    findFriend: chatId => $http.get(`v2/chats/${chatId}/find_friends`),
    messages: (chatId, firstId) => $http.get(`v2/chats/${chatId}/messages?first_id=${firstId}`),
    deleteMessage: (chatId, messageId) => $http.put(`v2/chats/${chatId}/messages/${messageId}/delete_message`),
    // payload: { user_id: 대화 상대 (chatUser), source_id: 소개할 사람 (introducingUser) }
    sendPhotoMessage: (chatId, data) => $http.post(`v2/chats/${chatId}/send_photo`, data),
    hasPaidPhotoHistory: (chatId, messageId) =>
        $http.get(`v2/chats/${chatId}/messages/${messageId}/has_paid_photo_history`),
    checkPhotoHistory: (chatId, messageId) =>
        $http.put(`v2/chats/${chatId}/messages/${messageId}/check_photo_history`, {}),
    acceptContact: (chatId, messageId) => $http.put(`v2/chats/${chatId}/messages/${messageId}/accept_contact`, {}),
    acceptDating: (chatId, messageId) => $http.put(`v2/chats/${chatId}/messages/${messageId}/accept_dating`, {}),
    requestProfile: (chatId, userId) => $http.post(`v2/chats/${chatId}/request_profile`, { source_id: userId }),
    acceptProfile: (chatId, messageId, payload) =>
        $http.put(`v2/chats/${chatId}/messages/${messageId}/accept_status`, payload),
    checkProgress: (chatId, messageId) => $http.get(`v2/chats/${chatId}/messages/${messageId}/check_progress`),
    openProfile: (chatId, messageId) => $http.put(`v2/chats/${chatId}/messages/${messageId}/open_profile`),
    openCounselChat: userId => $http.post(`v2/chats/open_counsel_chat`, { user_id: userId }),
    requestIntroduce: (chatId, payload) => $http.post(`v2/chats/${chatId}/messages/request_introduce`, payload),
    acceptIntroduce: (chatId, messageId) => $http.put(`v2/chats/${chatId}/messages/${messageId}/accept_introduce`, {}),
    closeChat: chatId => $http.put(`v2/chats/${chatId}/close`),
    referral: (chatId, payload) => $http.post(`v2/chats/${chatId}/referral`, payload),
    userConfirmedProfile: (chatId, messageId) =>
        $http.put(`v2/chats/${chatId}/messages/${messageId}/user_confirmed_profile`, {}),
    updateAcceptReason: (chatId, messageId, payload) =>
        $http.put(`v2/chats/${chatId}/messages/${messageId}/update_accept_reason`, payload),
}
