<template>
    <div class="user-like-me p-t-32">
        <div v-if="likeType === 'personalStory'" class="like-content m-l-20 m-r-20 m-t-24">
            <div class="title-box f-22">
                <span> {{ answer }} </span>
            </div>
            <div class="description-box f-12">
                회원님의 퍼스널 스토리를 좋아했어요.
                <div v-if="this.user.source_photo_histories.comment" class="comment-box f-12 p-8">
                    <span>{{ this.user.source_photo_histories.comment }}</span>
                </div>
            </div>
        </div>
        <div v-else-if="likeType === 'photo'" class="like-content m-l-20 m-r-20 m-t-24">
            <div class="img-box">
                <img
                    class="img-user w-100 h-50"
                    :src="this.user.source_photo_histories.accepted_photo_url"
                    alt="user-photo"
                    style="position: relative; border-radius: 8px; border-bottom-left-radius: 0"
                />
            </div>
            <div class="description-box f-12">
                회원님의 사진을 좋아했어요.
                <div v-if="this.user.source_photo_histories.comment" class="comment-box f-12 p-8">
                    <span>{{ this.user.source_photo_histories.comment }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import profileService from '@/services/profile'

export default {
    name: 'UserLikeMeComponent',
    props: {
        photo: Array,
        likeType: String,
        user: {
            type: Object,
            required: true,
        },
        message: Object,
    },
    computed: {
        phoneNumber() {
            return this.$store.getters.me.phone_number
        },
        likeIcon() {
            if (!this.likeActivate && this.user.photo_histories.accepted_photo_url === this.photo.url) {
                return require(`@/assets/images/icons/like_active.png`)
            } else {
                return require(`@/assets/images/icons/like_inactive.png`)
            }
        },
        me() {
            return this.$store.getters.me
        },
        isMe() {
            return this.userId === this.me.id
        },
        userPersonalStories() {
            const res = profileService.userPersonalStories(this.user.id)
            return res.data
        },
        personalStories() {
            const personalStories = profileService.personalStories()
            return personalStories
        },
    },
    data: () => ({
        initialPage: 0,
        title: null,
        answer: null,
    }),
    mounted() {
        this.generateAnswer()
    },
    methods: {
        // init() {
        //     if (this.likeType === 'personalStory') {
        //         this.generateAnswer()
        //     }
        // },
        async generateAnswer() {
            try {
                const acceptedStoryId = this.user.source_photo_histories.accepted_story_id
                if (acceptedStoryId) {
                    await profileService.userPersonalStories(this.me.id).then(res => {
                        const answer = res.data.find(o => o.id === acceptedStoryId).content
                        this.answer = answer
                    })
                }
            } catch (e) {
                console.log(e)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.like-content {
    background: #e8e4e7;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    .title-box {
        padding: 16px 16px 0 16px;
        color: #070555;

        span {
            font-family: 'Gowun Dodum';
            width: 100%;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .description-box {
        color: #505050;
        padding: 12px 16px 16px 16px;
        .comment-box {
            background: #fff9ef;
            border-radius: 8px;
            margin-top: 8px;
            span {
                color: #070555;
            }
        }
    }

    .img-box {
        height: 200px;
        position: relative;
        overflow: hidden;
    }
}
</style>
