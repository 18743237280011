<template>
    <div class="membership-introduction">
        <div class="intro-section">
            <!-- <img src="@/assets/images/membership-intro/goodperson_text.png" alt="Logo" class="logo" /> -->
            <div class="intro-text">
                <template v-if="planDetail.name.includes('Marry Fit') && !planDetail.name.includes('Plus')">
                    <div class="intro-text-description">
                        <div>
                            <p class="title-text">마음에 드는 분과의 매칭</p>
                            <p class="title-text">혼자서는 아직 힘드신가요?</p>
                        </div>
                        <div>
                            <p class="title-text">지금 멤버십을 업그레이드하고</p>
                            <p class="title-text"><span class="highlight-text">1:1 전문 컨설턴트</span>의</p>
                            <p class="title-text"><span class="highlight-text">집중 매칭 관리</span> 받아보세요</p>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="intro-text-description">
                        <div>
                            <p class="title-text">더 좋은 사람과</p>
                            <p class="title-text">더 빠르게 매칭되길 원하시나요?</p>
                        </div>
                        <div>
                            <p class="title-text"><span class="highlight-text">멤버십 회원</span>이 되어</p>
                            <p class="title-text"><span class="highlight-text">우선 매칭 기회</span>를 누려보세요.</p>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <!-- Love Fit Membership Section -->
        <div v-if="!planDetail.name.includes('Marry Fit')" class="membership-section love-fit">
            <div class="current-label">현재 이용중</div>
            <img src="@/assets/images/membership-intro/icon1.png" alt="Heart Icon" class="icon" />
            <!-- 현재 이용중인게 Love Fit이면 이게 맞지만 Marry Fit일 경우 Marry Fit이 나와야 합니다.
        다른 컴포넌트에 있는 compute의 planDetail을 참고하여 userRatePlans 값이 없으면 Love Fit이 나오게, 있으면 planDetail.name이 나오게 작업해주세요 -->
            <p class="membership-name">Love Fit</p>
            <!-- <div v-if="showPrice" class="price-container f-24">
                <span class="price">FREE</span>
            </div> -->
            <div class="description spacing-over">
                <p class="mid-text">밸런스 매칭</p>
                <p class="big-text">진지한 연애</p>
                <div v-if="windowWidth <= 300">
                    <p class="mid-text-description">
                        기본적인 외모 + 조건 밸런스 <br />
                        나와 비슷한 분들의 매칭
                    </p>
                </div>
                <div v-else>
                    <p class="mid-text-description">
                        기본적인 외모 + 조건 밸런스 <br />
                        나와 비슷한 분들과의 매칭이 진행됩니다.
                    </p>
                </div>
            </div>
        </div>

        <!-- Marry Fit Membership Section -->
        <!-- 여기는 현재 이용중인게 Love Fit일 때만 해당되게 해주세요. -->
        <div
            class="membership-section"
            :class="{
                'marry-fit': planDetail.name.includes('Marry Fit') && !planDetail.name.includes('Plus'),
                'marry-fit-alt': !planDetail.name.includes('Marry Fit'),
            }"
        >
            <div
                v-if="planDetail.name.includes('Marry Fit') && !planDetail.name.includes('Plus')"
                class="current-label"
            >
                현재 이용중
            </div>
            <img src="@/assets/images/membership-intro/icon2.png" alt="Ring Green Icon" class="icon" />
            <p class="membership-name">Marry Fit</p>
            <!-- <div v-if="showPrice" class="price-container f-24">
                <span class="price">{{ MarryFit.selling_price.toLocaleString() }}</span
                >원
            </div> -->
            <div class="description spacing-over">
                <p class="mid-text"><span class="highlight-text">최우선 매칭 기회</span></p>
                <p class="big-text">
                    {{ '1~2년 이내 결혼 생각이 있는' }}
                </p>
                <div v-if="windowWidth <= 300">
                    <p class="mid-text-description">
                        연봉, 자산 등 모든 프로필 열람 권한과 <br />
                        이상형 "우선" 매칭의 혜택
                    </p>
                </div>
                <div v-else>
                    <p class="mid-text-description">
                        연봉, 자산, 결혼계획 등 모든 프로필 정보 열람과 함께 <br />
                        이상형 “우선" 매칭 혜택이 주어집니다.
                    </p>
                </div>
            </div>
            <button
                class="marry-fit-button"
                @click="goToDetailPage('mf_3month')"
                v-if="!(planDetail.name.includes('Marry Fit') && !planDetail.name.includes('Plus'))"
            >
                {{ '멤버십 혜택 살펴보기' }} >
            </button>
        </div>

        <!-- Marry Fit+ Membership Section -->
        <div class="membership-section marry-fit-plus">
            <!-- <div
                v-if="planDetail.name.includes('Marry Fit') && planDetail.name.includes('Plus')"
                class="current-label"
            >
                현재 이용중
            </div> -->
            <img src="@/assets/images/membership-intro/icon3.png" alt="Ring Blue Icon" class="icon" />
            <p class="membership-name">Marry Fit+</p>
            <!-- <div v-if="showPrice" class="price-container f-24">
                <span class="price">{{ MFPlus.selling_price.toLocaleString() }}</span
                >원
            </div> -->
            <div class="description spacing-over">
                <p class="mid-text"><span class="highlight-blue-text">실시간 맞춤 관리</span></p>
                <p class="big-text">
                    {{ '1년 이내 결혼 생각이 있는' }}
                </p>
                <div v-if="windowWidth <= 300">
                    <p class="mid-text-description">
                        최상위 회원님과의 매칭을 위한 <br />
                        프리미엄 관리/분석 컨설팅까지 제공
                    </p>
                </div>
                <div v-else>
                    <p class="mid-text-description">
                        최상위 회원님과의 매칭을 위한 프리미엄 정보부터<br />
                        프로필 관리/분석 및 컨설팅이 디테일하게 제공됩니다.
                    </p>
                </div>
            </div>
            <button class="marry-fit-plus-button" @click="goToDetailPage('mfplus_3month')">
                {{ '멤버십 혜택 살펴보기' }} >
            </button>
        </div>
    </div>
</template>

<script>
import { getPlanNameById } from '@/store/data/product'
import inquiryService from '@/services/inquiry'
export default {
    name: 'MembershipIntroductionPage',
    data: () => ({
        windowWidth: null,
    }),
    mounted() {
        this.$nextTick().then(() => {
            window.addEventListener('resize', this.getWindowWidth)
            this.getWindowWidth()
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getWindowWidth)
    },
    computed: {
        planDetail() {
            const userRatePlansValue = this.userRatePlans()

            if (userRatePlansValue && userRatePlansValue.onGoing && userRatePlansValue.onGoing.length > 0) {
                const plan = getPlanNameById(userRatePlansValue.onGoing[0].product_id)
                return plan
            } else {
                return { name: '' }
            }
        },
        subscriptionPlans() {
            const products = this.$store.getters.products.subscription_plan
            return products
        },
    },
    methods: {
        getWindowWidth(event) {
            this.windowWidth = window.innerWidth
        },
        back() {
            this.$stackRouter.pop()
        },
        async goToDetailPage(membershipType) {
            if (membershipType === 'mf_3month') {
                const payload = {
                    user_id: this.$store.getters.me.id,
                    funnel: 'enter_detailed_mf',
                }
                await inquiryService.updateFunnel(payload)
            } else {
                const payload = {
                    user_id: this.$store.getters.me.id,
                    funnel: 'enter_detailed_mfp',
                }
                await inquiryService.updateFunnel(payload)
            }
            this.$router.push({
                name: 'MembershipDetailPage',
                params: { selectedMembership: membershipType },
            })
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || false
        },
    },
}
</script>

<style scoped>
.membership-introduction {
    background-color: #181e37 !important;
    padding: 20px;
}

.intro-section {
    text-align: center;
    margin-bottom: 30px;
    line-height: 3%;
}

.logo {
    width: 115px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.intro-text-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.intro-text p {
    font-size: 24px;
    color: white;
    text-align: left;
    line-height: 145%;
}

.membership-section {
    border: 1px none;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 20px;
    /* margin: 20px; */
    background: #ffffff1a;
}
.love-fit {
    font-size: 24px;
    border: 1px solid white;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 20px;
    background: #181e37;
    position: relative;

    /* @media(max-width: 320px){ 
            padding: 9px;
        } */
}

.marry-fit {
    border: 1px solid #0fda97;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 20px;
    background: #181e37;
    position: relative;
}

.marry-fit-plus {
    padding: 20px;

    /* @media(max-width: 320px){ 
        padding: 9px;
    } */
}

.marry-fit-alt {
    border: 1px none;
    border-radius: 3px;
    margin-bottom: 20px;
    padding: 20px;
    /* margin: 20px; */
    background: #ffffff1a;

    /* @media(max-width: 320px){ 
        padding: 9px;
    } */
}

.membership-name {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: white;
}

.price-container {
    color: white;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 40px;
}

.price {
    font-size: 44px;
    font-weight: 800;
}

.title-text {
    font-size: 24px;
    font-weight: bold;
    color: white;
}

.intro-text p {
    @media (max-width: 320px) {
        font-size: 19px;
    }
}

.spacing-over {
    margin-top: 10%;
}

.highlight-text {
    color: #0fda97;
}

.highlight-blue-text {
    color: #35e6f1;
}

.mid-text {
    font-size: 12px;
    color: white;
    font-weight: 600;
}

.mid-text-description {
    /* white-space: nowrap; */
    line-height: 19.2px;
    font-size: 12px;
    color: white;
    font-weight: 500;

    /* @media(max-width: 320px){ 
        font-size: 10px;
    } */
}

.big-text {
    font-size: 20px;
    color: white;
    font-weight: 600;

    @media (max-width: 320px) {
        font-size: 18px;
    }
}

.small-text {
    font-size: 10px;
    color: white;
}

.current-label {
    background-color: white;
    padding: 8px 12px;
    color: #181e37;
    position: absolute;
    top: 20px;
    right: 16px;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid white;
    border-radius: 20px;

    @media (max-width: 320px) {
        top: 10px;
        right: 10px;
        font-size: 10px;
    }
}

.icon {
    width: 50px;
    display: block;
    margin: 0 auto;
}

.description p {
    text-align: left;
    margin-bottom: 10px;
}
.marry-fit-button {
    white-space: nowrap;
    background-color: #0fda97;
    color: #181e37;
}
.marry-fit-plus-button {
    white-space: nowrap;
    background-color: #35e6f1;
    color: #181e37;
}

button {
    padding: 12px 15px;
    border-radius: 3px;
    display: block;
    margin-top: 20px;
    margin-bottom: 8px;
    border: 1px none;
    border-radius: 5px;
    width: 100%;
    height: 54px;
}
</style>
