import Vue from 'vue'
import commonService from '@/services/common'
import profileService from '@/services/profile'

const defaultState = () => ({
    mannerFeedback: null,
    jobs: null,
    jobCategories: null,
    provinces: null,
    religions: null,
    schools: null,
    schoolTypes: null,
    categories: null,
    states: null,
    keywordCategories: null,
    eventRegions: null,
    profileOptions: null,
    properties: {},
    lastApiCall: null,
    refunds: null,
    terms: null,
    inactiveReasons: null,
    payloads: {
        signupData: {
            service_terms_version: '',
            privacy_terms_version: '',
            privacy_third_terms_version: '',
            optional_consent: 1,
            email: '',
            nickname: '',
            password: '',
            provider: '',
            profile_option_ids: [],
            phone_number: '',
            birthday: '',
            gender: '',
        },
        preferences: {
            min_age: null,
            max_age: null,
            min_height: null,
            max_height: null,
            friend_type: null,
            spec_type: null,
            smoking_type: null,
        },
        npsData: {
            score: null,
            feedback: '',
        },
        ntsData: {
            score: null,
            feedback: '',
        },
        refundReasons: [],
    },
    appBanners: [],
    keyboardHeight: null,
    myDatingBadge: false,
})

const state = defaultState()

const getters = {
    jobs: state => state.jobs,
    mannerFeedback: state => state.mannerFeedback,
    jobCategories: state => state.jobCategories,
    categories: state => state.categories,
    states: state => state.states,
    schools: state => state.schools,
    schoolTypes: state => state.schoolTypes,
    keywordCategories: state => state.keywordCategories,
    eventRegions: state => state.eventRegions,
    properties: state => state.properties,
    inactiveReasons: state => state.inactiveReasons,

    shapes: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'shape')
    },
    kinds: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'kind')
    },
    religions: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'religion')
    },
    mbti: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'mbti')
    },
    pet: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'pet')
    },
    exerciseFreq: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'exercise_freq')
    },
    exerciseType: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'exercise_type')
    },
    drinkFreq: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'drink_freq')
    },
    drinkStyle: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'drink_style')
    },
    income: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'income')
    },
    asset: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'asset')
    },
    houseStyle: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'house_style')
    },
    housePayment: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'house_payment')
    },
    finance: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'finance')
    },
    carType: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'car_type')
    },
    carBrand: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'car_brand')
    },
    marryPlan: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'marry_plan')
    },
    childPlan: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'child_plan')
    },
    careerPlan: state => {
        if (!state.profileOptions) return

        return state.profileOptions.filter(o => o.ptype === 'career_plan')
    },
    lastApiCall: state => state.lastApiCall,
    refunds: state => state.refunds,
    payloads: state => state.payloads,
    appBanners: state => state.appBanners,
    keyboardHeight: state => state.keyboardHeight,
    myDatingBadge: state => state.myDatingBadge,
    terms: state => state.terms,
}

const actions = {
    async loadConstants({ commit }) {
        try {
            const loadConstants = () => commonService.constants()
            const loadProfileOptions = () => profileService.profileOptions()
            const getInstantFriendExpireDay = () => commonService.properties({ key: 'instant_friend_expire_day' })

            const [constants, profileOptions, res] = await Promise.all([
                loadConstants(),
                loadProfileOptions(),
                getInstantFriendExpireDay(),
            ])
            const { pro_male, pro_female } = Vue.prototype.$mustParse(res[0].value)

            commit('setConstants', {
                constants,
                profileOptions,
                properties: {
                    proMale: pro_male,
                    proFemale: pro_female,
                },
            })
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadTerms({ commit }) {
        try {
            const data = await commonService.terms()
            commit('setTerms', data)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadRefunds({ getters, commit }, { force } = { force: false }) {
        if (!force && getters.refunds) return

        try {
            const data = await commonService.refunds()
            commit('setRefunds', data)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadAppBanners({ commit }, { category }) {
        if (!category) return

        try {
            const banners = await commonService.banners(category)
            if (!banners || banners.length === 0) return

            commit('setAppBanners', banners)
            return Promise.resolve(banners)
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

const mutations = {
    setConstants(state, { constants, profileOptions, properties }) {
        // console.log('mute', constants)
        state.jobs = constants.jobs
        state.jobCategories = constants.job_categories
        state.mannerFeedback = constants.manner_feedbacks
        state.schools = constants.schools
        state.schoolTypes = constants.school_types
        state.categories = constants.categories
        state.states = constants.states
        state.religions = constants.religions
        state.keywordCategories = constants.keyword_categories
        state.profileOptions = profileOptions
        state.eventRegions = constants.event_regions
        state.inactiveReasons = constants.inactive_reasons
        if (properties) {
            Object.keys(properties).forEach(key => {
                state.properties[key] = properties[key]
            })
        }
    },
    /*
        USAGE:
        this.$store.commit('setPayloads', {
            signupData: {
                email: 'kispi@naver.com',
            },
        }
    */
    setPayloads(state, payloads) {
        Object.keys(payloads).forEach(payload => {
            Object.keys(payloads[payload]).forEach(key => {
                state.payloads[payload][key] = payloads[payload][key]
            })
        })
    },
    setRefunds(state, refunds) {
        state.refunds = refunds
    },
    setAppBanners(state, appBanners) {
        state.appBanners = appBanners
    },
    setKeyboardHeight(state, keyboardHeight) {
        state.keyboardHeight = keyboardHeight
    },
    setMyDatingBadge(state, badge) {
        state.myDatingBadge = badge
    },
    setTerms(state, terms) {
        state.terms = terms
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
