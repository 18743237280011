<template>
    <ProfileOptionSelector
        :model="'company'"
        :nextRoute="() => 'JobPage'"/>
</template>

<script>
export default {
    name: 'CompanyPage',
}
</script>
