<template>
    <div class="action-default-layout card">
        <div class="top" v-if="message.mtype !== 'open-chat'" />
        <div class="content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ActionDefaultLayout',
    props: ['message'],
}
</script>

<style lang="scss" scoped>
.action-default-layout {
    max-width: calc(100vw - 100px);
    ::v-deep button {
        height: 36px;

        @include f-medium;
    }
}
</style>
