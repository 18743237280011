<template>
    <ProfileOptionSelector :model="'jobCategory'" :univKeys="Number(1)" :nextRoute="() => 'OnboardSchool'" />
</template>

<script>
export default {
    name: 'JobCategory',
}
</script>

<style scoped></style>
