var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"signup additional-profiles signup-check"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_vm._v("이미 가입하셨나요?")]),_c('div',{staticClass:"body"},[(_vm.checkType === 'email')?_c('div',{staticClass:"body-text-1 m-b-8",domProps:{"innerHTML":_vm._s(
                    `${
                        _vm.email.split('@')[0].substring(0, 3) + '****@' + _vm.email.split('@')[1] || ''
                    }로 가입한 적이 있나요?`
                )}}):_vm._e(),(_vm.checkType === 'phone')?_c('div',{staticClass:"body-text-1 m-b-8",domProps:{"innerHTML":_vm._s(
                    `${
                        _vm.phoneNumber.substring(0, 3) +
                            '-' +
                            _vm.phoneNumber.substring(3, 5) +
                            '**-' +
                            _vm.phoneNumber.substring(7, 9) +
                            '**' || ''
                    }로 가입한 적이 있나요?`
                )}}):_vm._e(),_c('div',{staticClass:"body-text-2 m-b-32",domProps:{"innerHTML":_vm._s(`주민등록번호를 입력하시면<br>기존 회원으로 서비스를 이용할 수 있어요!`)}}),_c('div',{staticClass:"flex-row"},[_c('div',{staticClass:"input-wrapper front"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.birth),expression:"birth"}],ref:"birth",staticClass:"flex-fill",attrs:{"type":"tel","maxlength":"6"},domProps:{"value":(_vm.birth)},on:{"keydown":_vm.onKeydown,"input":function($event){if($event.target.composing)return;_vm.birth=$event.target.value}}})]),_c('div',{staticClass:"bar"}),_c('div',{staticClass:"input-back flex-row"},[_c('div',{staticClass:"input-wrapper back"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],ref:"gender",staticClass:"flex-fill input-gender",attrs:{"type":"tel","maxlength":"1"},domProps:{"value":(_vm.gender)},on:{"input":function($event){if($event.target.composing)return;_vm.gender=$event.target.value}}})]),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"}),_c('div',{staticClass:"dot"})])])])]),_c('div',{staticClass:"button-wrapper"},[_c('button',{staticClass:"btn m-b-12 btn-primary",class:{ disabled: _vm.disabled },attrs:{"disabled":_vm.disabled},domProps:{"innerHTML":_vm._s('로그인하기')},on:{"click":_vm.signin}}),_c('div',{staticClass:"bottom-comment",domProps:{"innerHTML":_vm._s(_vm.$translate('SIGNUP_CHECK_BOTTOM_COMMENT'))}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }