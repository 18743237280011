<template>
    <div class="product-pay flex-wrap">
        <img :src="require(`@/assets/images/icons/img_loading.png`)" alt="" width="32px" />
        <div class="title f-18 f-bold m-t-8">결제중입니다.</div>
        <div class="content f-14">잠시만 기다려주세요.</div>
    </div>
</template>

<script>
import orderService from '@/services/order'

export default {
    name: 'PaymentLoadingPage',
    data: () => ({
        confirmed: false,
        jsonData: null,
        order: null,
    }),
    async mounted() {
        this.order = JSON.parse(localStorage.getItem('order'))
        const store = this.$store

        try {
            // 후속 작업 수행
            await store.dispatch('afterAuthCallbacks')
        } catch (error) {
            console.error('결제 성공 후 작업 중 오류 발생:', error)
            // 오류 처리 로직 (예: 사용자에게 오류 메시지 표시)
        }

        this.checkOrder()
    },
    beforeDestroy() {},
    computed: {},
    methods: {
        async checkOrder() {
            if (!this.order) return

            try {
                const { msg } = await orderService.get(this.order.id)

                const requestData = {
                    orderId: this.$route.query.orderId,
                    amount: this.$route.query.amount,
                    paymentKey: this.$route.query.paymentKey,
                }

                const res = await this.confirm(requestData)

                if (res === 'OK') {
                    this.$toast.success(msg)

                    setTimeout(() => {
                        this.$router.push({
                            name: 'CompletePaymentPage',
                        })
                        this.sendAirbiridgeEvents()
                        this.sendFirebaseEvents()
                    }, 3000)
                } else {
                    this.$toast.error('결제 오류가 발생했습니다. 고객센터에 문의해주세요.')
                    this.$router.push({
                        name: 'PrePaymentPage',
                    })
                }
            } catch (e) {
                this.$toast.error(e.msg)
                console.error(e)
                this.$router.push({
                    name: 'PrePaymentPage',
                })
            }
        },
        async confirm(requestData) {
            try {
                const { response, json } = await this.confirmPayment(requestData)
                console.log(json)
                if (!response.ok) {
                    this.$router.push({
                        name: 'PrePaymentPage',
                    })
                    this.$toast.error('오류가 발생했습니다.')
                } else {
                    this.confirmed = true
                    this.jsonData = json
                }

                const payload = {
                    order_num: this.order.order_num,
                    method: this.jsonData.method,
                    order_id: this.jsonData.orderId,
                    payment_key: this.jsonData.paymentKey,
                    status: this.jsonData.status,
                }
                const { msg } = await orderService.tossPayment(payload)
                return msg
            } catch (error) {
                console.error(error)
            }
        },
        async confirmPayment(requestData) {
            // TODO: 개발자센터에 로그인해서 내 결제위젯 연동 키 > 시크릿 키를 입력하세요. 시크릿 키는 외부에 공개되면 안돼요.
            // @docs https://docs.tosspayments.com/reference/using-api/api-keys
            const secretKey = process.env.VUE_APP_TOSS_PAYMENTS_SECRET_KEY

            // 토스페이먼츠 API는 시크릿 키를 사용자 ID로 사용하고, 비밀번호는 사용하지 않습니다.
            // 비밀번호가 없다는 것을 알리기 위해 시크릿 키 뒤에 콜론을 추가합니다.
            // @docs https://docs.tosspayments.com/reference/using-api/authorization#%EC%9D%B8%EC%A6%9D
            const encryptedSecretKey = btoa(secretKey + ':')

            // ------ 결제 승인 API 호출 ------
            // @docs https://docs.tosspayments.com/guides/payment-widget/integration#3-결제-승인하기
            const response = await fetch('https://api.tosspayments.com/v1/payments/confirm', {
                method: 'POST',
                headers: {
                    Authorization: `Basic ${encryptedSecretKey}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            })

            const json = await response.json()
            return { response, json }
        },
        sendAirbiridgeEvents() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `Complete_Purchase`,
                    option: {
                        value: this.order.paid_price,
                        customAttributes: {
                            gender: this.$store.getters.me.gender === 0 ? 'Male' : 'Female',
                            paid_price: this.order.paid_price,
                        },
                    },
                },
            })

            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `Complete_Payment`,
                },
            })
        },
        sendFirebaseEvents() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Complete_Purchase`,
                    option: {
                        paid_price: this.order.paid_price,
                        gender: this.$store.getters.me.gender === 0 ? 'Male' : 'Female',
                    },
                },
            })

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Complete_Payment`,
                },
            })
        },
    },
}
</script>

<style scoped lang="scss">
.product-pay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
        line-height: 28px;
        color: #313131;
    }
    .content {
        line-height: 24px;
        color: #94999e;
    }
}
</style>
