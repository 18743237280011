<template>
    <div class="empty-list">
        <img v-if="showImage" class="m-r-8" src="@/assets/images/empty.png" width="18px" height="18px"/>
        <p class="message" v-html="message" />
    </div>
</template>

<script>
export default {
    name: 'EmptyList',
    props: {
        message: {
            type: String,
            required: true,
        },
        showImage: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.empty-list {
    display: flex;
    //flex-direction: column;
    align-items: center;
    justify-content: center;

    .message {
        margin: 0;
        color: $grey-09;
    }
}
</style>
