<template>
    <div class="my-page-menu">
        <ul class="menu">
            <li class="item" v-for="item in upper" :key="item.key" @click="onClickMenu(item)">
                <img class="icons-outlined icon" v-if="item.icon" :src="item.icon" />
                <span class="key" v-html="$translate(item.key)" />
                <i class="material-icons i-right">chevron_right</i>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'MyInfoMenu',
    props: {
        stepToast: {
            type: [Boolean, Object],
        },
    },
    computed: {
        isFemale() {
            return (this.$store.getters.me || {}).gender === 1
        },
        upper() {
            return [
                {
                    key: 'CERTIFICATE_CENTER',
                    route: 'CertificatePage',
                    show: true,
                    step: 3, // 온보딩 5가지 스텝 넘버
                    icon: require(`@/assets/images/verification_center.png`),
                },
                // {
                //     key: 'IDEAL_TYPE',
                //     route: 'EditStylePage',
                //     show: true,
                //     step: 4, // 온보딩 5가지 스텝 넘버
                // },
            ]
        },
        me() {
            return this.$store.getters.me
        },
    },
    methods: {
        async onClickMenu(item) {
            if (this.me.profile.status === 'created') {
                await this.$modal.basic({
                    body: '서류 인증을 하시려면 먼저 프로필을 입력하셔야 합니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return this.$router.push({ name: 'ChatsPage' })
            }
            if (item.route === 'CustomerCenterPage') {
                this.$router.push({ name: item.route })
                return
            }
            if (this.stepToast && this.stepToast.step <= item.step) {
                this.$toast.success(this.stepToast.msg)
                return
            }
            this.$stackRouter.push({ name: item.route })
        },
    },
}
</script>

<style lang="scss" scoped>
.my-page-menu {
    // .hr.thick {
    //     background: $grey-02;
    //     border: 0;
    //     height: 8px;
    // }

    .item {
        height: 56px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        position: relative;

        border-top: solid 1px #dcdcdc;

        .icon {
            width: 16px;
            margin-right: 12px;
        }

        i {
            position: absolute;
            right: 20px;
        }

        .key {
            font-size: 16px;
            color: black;
        }
        // &:nth-child(1) {
        //     border-bottom: solid 8px rgb(247, 248, 249);
        // }
    }
}
</style>
