<template>
    <div class="user-profile">
        <div v-if="profile && type === 'beginning'" class="section name-age m-16">
            <!-- <span v-if="isMe && from !== 'MyProfileConFirm'">{{ user.name }}, </span>
            <span v-else>{{ profile.nickname}} 님, </span> -->
            <span v-if="type === 'beginning'">{{ profile.nickname }} 님</span>
            <div class="title m-t-5 m-l-15" v-if="type === 'beginning'">
                <img class="m-r-2" src="@/assets/images/icons/verified.png" width="12px" height="12px" />
                <span class="verified f-12">신원 인증 완료</span>
            </div>
            <div v-if="mannerBadge" class="right-badge">
                <img class="manner-badge" src="@/assets/images/user-detail/manner-badge.png" alt="" />
            </div>
            <!-- <span v-if="type === 'beginning'">{{ getUserAge }}세</span> -->
            <!-- <span v-if="type === 'beginning'"> ({{ $moment(profile.birthday).format('YYYY년생') }})</span> -->
        </div>
        <PersonalProfile
            v-if="profile && type === 'profile'"
            class="section"
            :userId="userId"
            :profile="profile"
            :message="message"
            :openAll="openAll"
            @updateOpenAll="handleOpenAll"
        />
        <SpecProfile
            v-if="profile && type === 'spec'"
            class="section"
            :userId="userId"
            :profile="profile"
            :message="message"
            :openAll="openAll"
            @updateOpenAll="handleOpenAll"
        />
        <!-- <EducationProfile v-if="profile" class="section" :userId="userId" :profile="profile" />
        <CareerProfile v-if="profile" class="section" :userId="userId" :profile="profile" />
        <FinancialProfile v-if="profile" class="section" :userId="userId" :profile="profile" />
        <FamilyProfile v-if="profile" class="section" :userId="userId" :profile="profile" />
        <MarriageProfile v-if="profile" class="section" :userId="userId" :profile="profile" />
        <OnlyToManager v-if="userId === me.id" class="section" :userId="userId" :profile="profile" /> -->
        <template v-if="user.keywords.length > 0">
            <hr />
            <Keywords v-if="profile && type === 'keywords'" class="section" :keywords="user.keywords" />
        </template>
    </div>
</template>

<script>
import PersonalProfile from '@/routes/user-detail/components/PersonalProfile'
import SpecProfile from '@/routes/user-detail/components/SpecProfile'
// import EducationProfile from '@/routes/user-detail/components/EducationProfile'
// import CareerProfile from '@/routes/user-detail/components/CareerProfile'
// import FinancialProfile from '@/routes/user-detail/components/FinancialProfile'
// import FamilyProfile from '@/routes/user-detail/components/FamilyProfile'
// import MarriageProfile from '@/routes/user-detail/components/MarriageProfile'
// import OnlyToManager from '@/routes/user-detail/components/OnlyToManager'
import Keywords from './Keywords'

export default {
    name: 'UserProfile',
    components: {
        PersonalProfile,
        SpecProfile,
        // EducationProfile,
        Keywords,
        // CareerProfile,
        // FinancialProfile,
        // FamilyProfile,
        // MarriageProfile,
        // OnlyToManager,
    },
    mounted() {},
    props: {
        type: String,
        user: {
            type: Object,
            required: true,
        },
        profileUpdate: {
            type: Object,
            required: false,
        },
        from: String,
        message: {
            type: Object,
        },
        openAll: {
            type: Boolean,
        },
        mannerBadge: {
            type: Boolean,
        },
    },

    computed: {
        getUserAge() {
            return this.$options.filters.asAge(this.profile.birthday)
        },
        intro() {
            const removeBlank = intro => (intro || '').trim()

            let intro = ''
            if (this.user) intro = this.user.profile.intro

            return removeBlank(intro)
        },
        userId() {
            return this.user.id
        },
        profile() {
            if (this.profileUpdate) {
                return this.profileUpdate
            } else {
                return this.user.profile
            }
        },
        verificationBadges() {
            return this.user.verification_badges || []
        },
        me() {
            return this.$store.getters.me
        },
        isMe() {
            return this.userId === this.me.id
        },
    },
    methods: {
        handleOpenAll(openedAll) {
            this.$emit('updateOpenAll', openedAll)
        },
    },
}
</script>
<style scoped lang="scss">
.title {
    .verified {
        color: #00aa77;
    }
}

.name-age {
    display: flex;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    padding: 16px 0;
    align-items: baseline;
    position: relative;

    .manner-badge {
        position: absolute;
        right: 0px;
        bottom: -10px;
    }
}
</style>
