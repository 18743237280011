<template>
    <div class="fee-information">
        <StackRouterHeaderBar
            :class="{ 'bottom-border': showHeaderTitle }"
            :title="$translate('WAIVE_REQUEST')"
            :show-title="true"
            :leftButtonHandler="back"
        />
        <div class="main">
            <div class="first-section p-12 f-14 f-medium">
                {{ firstSectionContent }}
            </div>
            <div class="content-section">
                <div class="content-titles m-t-28" v-for="item in contentTitles" :key="item.id">
                    <div class="title-container flex-row">
                        <div class="name f-medium f-17 m-b-13">
                            {{ item.title }}
                        </div>
                    </div>

                    <ul class="content-box">
                        <li class="contents f-15" v-for="content in item.contents" :key="content.id">
                            <img
                                v-if="content.id === 2 && descriptionSecond"
                                class="waive-description"
                                :src="require('@/assets/images/waive-description-1.webp')"
                                alt=""
                            />
                            <div class="center-content">
                                <span v-html="content.title" />
                                <img
                                    v-if="content.id == 2"
                                    :src="require('@/assets/images/icon-question.webp')"
                                    alt=""
                                    @click="onClickDescription(content.id)"
                                />
                            </div>
                            <span class="f-13 content-subtitle">{{
                                content.subtitle ? '(' + content.subtitle + ')' : null
                            }}</span>
                        </li>
                    </ul>
                    <div class="description m-t-11 f-11" v-html="item.description" />
                </div>
            </div>

            <ul class="attach-photos">
                <li
                    v-for="(photo, idx) in photos"
                    :key="photo.url"
                    class="attach-photo"
                    @click="onClickPhoto(idx)"
                    :class="{ mandatory: idx === 0 }"
                >
                    <img v-if="photo.url" :src="photo.url" class="photo" />
                    <img
                        v-else-if="idx === 0"
                        :src="require(`@/assets/images/icons/plus_blue.png`)"
                        width="24px"
                        height="24px"
                    />
                    <img v-else :src="require(`@/assets/images/icons/plus.png`)" width="24px" height="24px" />
                </li>

                <input
                    ref="imageUploader"
                    type="file"
                    class="image-input display-none"
                    @change="onChangeImage($event)"
                    accept="image/*"
                />
            </ul>
        </div>
        <BottomButton :needAddInfoTop="false" @click="submit" label="수수료 면제 요청하기" />
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'WaiveSubmitPage',
    props: ['product', 'reason', 'reasonId', 'scheduleId'],
    data: () => ({
        descriptionFirst: false,
        descriptionSecond: false,
        showHeaderTitle: true,
        photos: [],
        selectedIndex: '',
        selectedType: 'photo',
    }),
    beforeDestroy() {
        this.$unregisterBackHandler()
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        firstSectionContent() {
            return '증빙 자료가 없는 경우 수수료 면제가 불가합니다.'
        },
        contentTitles() {
            return [
                {
                    title: '수수료 면제가 불가한 사유',
                    description: null,
                    contents: [
                        {
                            id: 1,
                            title: '질병 및 부상',
                            subtitle: '병원 진단서, 진료 내역서 등',
                        },
                        {
                            id: 2,
                            title: '교제를 시작한 이성이 있음',
                            subtitle: '교제 사실을 증명할 수 있는 내용',
                        },
                        {
                            id: 3,
                            title: '만남이 불가한 회사 업무',
                            subtitle: '회사 공식 문서, 출장보고서 등',
                        },
                        {
                            id: 4,
                            title: '부모님, 형제의 작고',
                            subtitle: '증빙할 수 있는 서류 일체',
                        },
                    ],
                },
            ]
        },
    },
    methods: {
        back() {
            this.$stackRouter.pop()
        },
        onClickDescription(id) {
            if (id === 1) {
                this.descriptionFirst = !this.descriptionFirst
                this.descriptionSecond = false
            } else {
                this.descriptionSecond = !this.descriptionSecond
                this.descriptionFirst = false
            }
        },
        onClickPhoto(index) {
            this.selectedIndex = index
            this.$refs.imageUploader.click()
        },
        onChangeImage(event) {
            if (!event || event.target.files.length === 0) return
            const selectedPhoto = this.photos[this.selectedIndex]
            selectedPhoto.name = event.target.files[0].name
            const file = event.target.files[0]
            this.$refs.imageUploader.value = ''

            this.$modal
                .custom({
                    component: 'ModalCropper',
                    options: {
                        imgFile: file,
                        // allowFreeAspectRatio: true,
                        params: {
                            viewMode: 1,
                            dragMode: 'move',
                            cropBoxMovable: false,
                            autoCropArea: 1,
                            toggleDragModeOnDblclick: false,
                            background: false,
                            guides: false,
                            center: false,
                            minContainerWidth: window.innerWidth,
                        },
                    },
                })
                .then(croppedFile => {
                    if (!croppedFile) return
                    this.photos[this.selectedIndex].url = URL.createObjectURL(croppedFile)
                    this.photos[this.selectedIndex].blob = croppedFile
                    this.selectedIndex = null

                    this.emit()
                })
        },
        emit() {
            this.$emit('input', this.photos)
            this.$emit('onChangeDeletePhotoIds', this.deletePhotoIds)
            this.photoChanged = true
        },
        async submit() {
            if (!this.photos.some(photo => photo.blob)) {
                this.$modal.basic({
                    body: '증빙 자료를 업로드 해주세요.',
                })
                return
            }
            const idx = await this.$modal.basic({
                body: '취소 수수료 면제를 요청합니다. 취소 수수료 면제 여부는 1~2 영업일 이내에 별도 알림 드립니다.',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                const payload = new FormData()

                payload.append('schedule_id', this.$route.params.scheduleId)
                payload.append('user_id', this.me.id)
                if (this.$route.params.reasonId === 4) {
                    payload.append('waive_reason', 4)
                    payload.append('waive_etc', this.reason)
                } else {
                    payload.append('waive_reason', this.reason)
                }

                this.photos.forEach((photo, idx) => {
                    if (photo.blob) {
                        payload.append(`photo${idx + 1}`, photo.blob, photo.name)
                    }
                })

                try {
                    const res = await scheduleService.waiveRequest(payload)
                    this.$emit('close')
                    this.$toast.success(res.msg)
                    this.$router.go(-1)
                    this.$router.go(-1)
                    this.$router.push({ name: 'ChatsPage' })
                } catch (err) {
                    this.$toast.error('에러가 발생했습니다. 다시 시도해주세요')
                }
            }
        },
    },
    created() {
        this.$registerBackHandler(this.back)
        this.photos = Array(3)
            .fill({})
            .map(p => {
                return { blob: null, name: null, url: null }
            })
    },
}
</script>

<style lang="scss" scoped>
.fee-information {
    $header-height: 52px;
    overflow: hidden;
    line-height: 150%;

    .main {
        height: calc(100vh - #{$header-height} - 48px);
        padding: 20px;
        overflow-y: auto;
    }

    .first-section {
        border-radius: 8px;
        background: var(--Cool-Grey, #e8e4e7);
        color: #5c5c77;
    }

    .content-titles {
        position: relative;

        .content-box {
            border-radius: 8px;
            background: #fff;
            padding: 11px 2px 0px 2px;

            .contents:nth-child(1).contents-first {
                /* 첫 번째 자식에만 적용할 스타일 */
                padding-top: 4px;
                padding-bottom: 14px;
            }

            .contents:nth-child(2).contents-first {
                /* 두 번째 자식에만 적용할 스타일 */
                padding-top: 14px;
                padding-bottom: 4px;
            }

            .contents {
                align-items: center;
                &.contents-first {
                    margin: 0 20px;
                    .content-container {
                        display: flex;
                    }
                }

                .left {
                    display: flex;
                    align-items: center;
                }

                .center-content {
                    display: flex;
                    background-color: #fff;

                    img {
                        width: 17px;
                        height: 17px;
                        margin-top: 2px;
                        margin-left: 6px;
                    }
                }
            }

            li {
                margin-left: 30px;
                list-style-type: disc;
                padding-bottom: 11px;

                .content-subtitle {
                    color: #787878;
                }
            }
        }

        .description {
            color: #a9a9a9;
        }
    }

    .waive-description {
        position: absolute;
        width: 70%;
        top: -15px;
        right: -21px;
    }

    @media screen and (max-width: 300px) {
        .waive-description {
            top: 20px;
            right: -21px;
        }
    }

    @media screen and (min-width: 440px) and (max-width: 700px) {
        .waive-description {
            top: -89px;
            right: 38px;
        }
    }

    @media screen and (min-width: 700px) {
        .waive-description {
            top: -106px;
            right: 138px;
        }
    }

    @media screen and (min-width: 800px) {
        .waive-description {
            top: -100px;
            right: 65px;
        }
    }

    .attach-photos {
        $gap: 11px;
        $photo-size: calc((100vw - #{$gap * 2} - 32px) / 3);
        display: grid;
        grid-template-columns: repeat(3, $photo-size);
        column-gap: 11px;

        .attach-photo {
            position: relative;
            width: $photo-size;
            height: $photo-size;
            border-radius: 12px;
            border: solid 1px $grey-03;
            .material-icons {
                font-size: 24px;
                color: $grey-06;
                position: relative;
            }

            .photo {
                width: 100%;
                height: 100%;
                border-radius: 12px;
            }

            @include center;
        }
        .mandatory {
            border: 1px dashed #3da1ff;
            background: #f0f8ff;

            &::after {
                position: absolute;
                left: 4px;
                top: 4px;
                content: '필수';
                background-color: #3da1ff;
                border-radius: 8px;
                color: #ffffff;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                padding: 2px 4px;
            }
            ::v-deep .material-icons {
                color: #3da1ff;
            }
        }
    }
}
</style>
