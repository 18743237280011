<template>
    <div class="edit-profile-detail">
        <div class="title" v-html="$translate('HEIGHT')" />
        <div class="section">
            <DigitInput @input="onChangeNumberVal" :initialVal="getNumberVal()" />
        </div>
        <BottomButton @click="next" :disabled="disabled" :label="$translate('NEXT')" />
    </div>
</template>

<script>
export default {
    name: 'Height',
    data: () => ({
        height: 0,
    }),
    computed: {
        disabled() {
            return !(Number(this.height) > 140 && Number(this.height) < 200)
        },
        tempSignupPayload() {
            return JSON.parse(localStorage.getItem('tempSignupPayload')) || {}
        },
    },
    methods: {
        onChangeNumberVal(val) {
            this.height = val
        },
        getNumberVal() {
            return parseInt(this.height)
        },
        next() {
            const payload = { ...this.tempSignupPayload, height: this.height }
            localStorage.setItem('tempSignupPayload', JSON.stringify(payload))
            this.$router.push({ name: 'OnboardMarriage' })
        },
    },
    mounted() {
        this.height = Number(this.tempSignupPayload.height) || 0
        if (this.tempSignupPayload.height === undefined) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Enter_HeightPage`,
                },
            })
        }
    },
}
</script>

<style scoped lang="scss">
::v-deep .digit-input {
    .input-wrapper {
        display: flex;
        flex-direction: row;
        height: 52px;
        border-radius: 8px;
        border: 1px solid $grey-02;
        padding: 12px 16px;
        margin: 0 20px 12px;

        .material-icons {
            font-size: $icon-size;
            color: $grey-05;
            margin: 0;
        }

        input {
            font-size: 15px;
            margin: 0;
            padding: 0;
            color: black;
        }

        input::placeholder {
            color: $grey-05;
        }
    }
}
</style>
