<template>
    <div class="slider">
        <div class="range">
            <!-- <span v-if="title" class="c-black" :class="{ required }" v-html="title" /> -->
            <!-- <span class="" v-html="rangeStringify" /> -->
        </div>
        <VueSlider
            v-if="unit === 'km'"
            v-model="newValue"
            v-bind="sliderOptions"
            @change="onChange"
            :tooltip-style="{ position: 'relative', width: 'max-content' }"
        />
        <VueSlider
            v-else
            v-model="newRange"
            v-bind="sliderOptions"
            @change="onChange"
            :tooltip-style="{ position: 'relative', width: 'max-content' }"
        />
        <div class="min-max m-t-4">
            <div v-html="`${sliderOptions.min}${unit}`" />
            <div v-html="`${sliderOptions.max}${unit}` + ` +`" />
        </div>
        <div class="info-box m-t-16">
            <span class="info-box-text" v-html="rangeStringify" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Slider',
    props: {
        title: {
            type: String,
        },
        range: {
            type: Array,
            required: false,
        },
        myRange: {
            type: Array,
            required: false,
        },
        unit: {
            type: String,
            required: true,
        },
        sliderOptions: {
            type: Object,
            required: true,
        },
        required: {
            type: Boolean,
            required: false,
        },
        rangeDefault: {
            type: String,
            required: false,
        },
        value: {
            type: Number,
            required: false,
        },
    },
    data: () => ({
        newRange: [],
        newValue: null,
        updated: false,
    }),
    mounted() {
        setTimeout(() => {
            this.init()
        }, 10)
    },
    computed: {
        rangeStringify() {
            if (!this.updated && this.unit !== 'km') {
                return `<span class='default-value'>${this.rangeDefault}</span>`
            }
            if (this.unit === '세') {
                return `${this.newRange[0]}~${this.newRange[1]}${this.unit} (${
                    this.$moment().format('YYYY') - this.newRange[0] + 1
                }~${this.$moment().format('YYYY') - this.newRange[1] + 1}년생)`
            } else if (this.unit === 'cm') {
                return `${this.newRange[0]}${this.unit}~${this.newRange[1]}${this.unit}`
            } else if (this.unit === 'km') {
                return `${this.title} ${this.newValue}${this.unit}`
            } else {
                return ''
            }
        },
    },
    methods: {
        init() {
            if (this.unit === 'km') {
                console.log('1111', this.value)
                this.newValue = this.value
            } else if (this.myRange && !this.myRange[0] && !this.myRange[1]) {
                this.newRange = [...this.range]
            } else if (this.myRange) {
                this.updated = true
                this.newRange = [...this.myRange]
            }
        },
        onChange(e) {
            this.updated = true
            // this.myRange = this.newRange
            this.unit === 'km'
                ? this.$emit('change', { value: this.newValue || 0, title: this.title })
                : this.$emit('change', this.newRange)
        },
    },
}
</script>

<style lang="scss" scoped>
.slider {
    margin: 0 20px;
    .range {
        @include flex-row;
        @include flex-between;
        @include spoqa-f-medium;
        font-size: 16px;
        margin: 32px 20px 12px;
        font-size: 16px;
        line-height: 26px;
        color: #03006e;
        &::v-deep .default-value {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #b9bdc1;
        }
    }

    .vue-slider {
        z-index: 1;
    }

    .min-max {
        // margin: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: $grey-08;
    }

    .info-box {
        @include center;
        height: 48px;
        // margin: 0 20px;
        background: #faf0e1;
        border-radius: 8px;
        color: #151360;
    }

    .required::after {
        content: ' *';
        color: #ff3d6b;
    }

    // .disabled::v-deep .vue-slider-process {
    //     background-color: #c2e4e0;
    // }
}
</style>
