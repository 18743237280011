<template>
    <div class="edit-profile-detail">
        <div class="section">
            <div class="content f-15" style="line-height: 24px; margin: 24px 20px">
                Q. 굿퍼슨은 ‘좋은 사람’들을 위한 소개 커뮤니티입니다. <br />
                ‘좋은 사람’은 ‘진정성 있고, 상대방을 존중할 수 있는 사람’을 의미합니다. <br />
                커뮤니티 방향성에 동의하시나요?
            </div>
            <div class="column">
                <div
                    class="item bg-white m-l-20 m-r-20"
                    :class="{ selected: item.id === selectedIdx }"
                    v-for="item in items"
                    :key="item.id"
                    v-html="item.name"
                    @click="onClickItem(item.id)"
                />
            </div>
        </div>
        <!--        <BottomButton :disabled="disabled" @click="next" :label="$translate('NEXT')" />-->
    </div>
</template>

<script>
import signupConstant from '@/assets/constants/onboard-temp-signup'
export default {
    name: 'AgreeDirectionality',
    data: () => ({
        selectedIdx: null,
    }),
    computed: {
        tempSignupPayload() {
            return JSON.parse(localStorage.getItem('tempSignupPayload')) || {}
        },
        items() {
            return signupConstant.agreeDirectionality
        },
        disabled() {
            return this.selectedIdx === null
        },
    },
    methods: {
        onClickItem(idx) {
            if (idx === 2) {
                this.$modal.basic({
                    body: '커뮤니티 방향성에 동의하지 않는 분의 경우 가입이 어렵습니다. 죄송합니다.',
                    buttons: [
                        {
                            label: '확인',
                            class: 'btn-primary',
                        },
                    ],
                })
            } else {
                this.selectedIdx = idx

                setTimeout(() => this.next(), 200)
            }
        },
        next() {
            const payload = { ...this.tempSignupPayload, agreeDirectionality: this.selectedIdx }
            localStorage.setItem('tempSignupPayload', JSON.stringify(payload))

            this.$router.push({ name: 'OnboardAgreeVerification' })
        },
    },
    mounted() {
        this.selectedIdx = this.tempSignupPayload.agreeDirectionality
        if (this.tempSignupPayload.agreeDirectionality === undefined) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Enter_AgreeDirectionalityPage`,
                },
            })
        }
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#FAF0E1',
                bottom: '#FFF9EF',
            },
        })
    },
}
</script>
