<template>
    <div class="modal-profile-item-selector edit-profile-detail items-start bg-main cancel-schedule">
        <section class="section m-t-24">
            <div class="title">
                <div class="text m-b-10" v-html="'수수료 면제 요청하기'" />
            </div>
            <div class="content">
                <div v-if="this.$route.params.product.name === '약속 취소 수수료'">
                    약속 취소가 불가피한 부득이한 사유의 경우
                    <span class="link-style" @click="onClickFeeInformation">(자세히 보기)</span>에만 페널티 수수료가
                    면제됩니다.
                </div>
                <div v-else>
                    약속 진행이 불가했던 부득이한 사유의 경우
                    <span class="link-style" @click="onClickFeeInformation">(자세히 보기)</span>에만 페널티 수수료가
                    면제됩니다.
                </div>
            </div>
        </section>
        <section class="section m-t-12 reason-input">
            <ul class="reason flex-fill p-l-10 p-r-10">
                <li
                    @click="onClickItem(item)"
                    class="item flex-row items-center flex-between"
                    :class="{ selected: item.$$selected }"
                    :key="item.id"
                    v-for="item in reasonItems"
                >
                    <div v-html="$translate(item.name)" />
                </li>

                <div class="flex-fill" v-if="isSelected === true">
                    <span class="reason-desc"
                        >*약속 변경이 불가하면서, 정말 약속 진행이 불가한 경우에만 인정됩니다.</span
                    >
                    <TextareaWithX
                        v-model="inputValue"
                        :placeholder="placeholder"
                        :textareaRows="2"
                        :ref="'comment'"
                        :setClass="'content-area m-0'"
                    />
                </div>
            </ul>
        </section>
        <BottomButton @click="submit" :disabled="disabled" :label="'다음'" />
    </div>
</template>

<script>
export default {
    name: 'WaiveRequestPage',
    data: () => ({
        disabled: true,
        isSelected: false,
        inputValue: '',
        reasonItems: [
            {
                id: 0,
                name: '심각한 질병 및 부상',
                $$selected: false,
            },
            {
                id: 1,
                name: '교제를 시작한 이성이 있음',
                $$selected: false,
            },
            {
                id: 2,
                name: '물리적으로 만남이 불가한 회사 업무 (해외 출장 등)',
                $$selected: false,
            },
            {
                id: 3,
                name: '부모님, 형제의 작고',
                $$selected: false,
            },
            {
                id: 4,
                name: '기타 약속을 진행할 수 없는 불가피한 사유',
                $$selected: false,
            },
        ],
    }),
    methods: {
        onClickItem(item) {
            this.reasonItems.forEach(o => this.$set(o, '$$selected', false))
            this.$set(item, '$$selected', !item.$$selected)

            this.selectedValue = [item]

            if (this.selectedValue.length > 0) {
                this.disabled = false
                this.$emit('disabled', this.disabled)
            } else {
                this.disabled = true
                this.$emit('disabled', true)
            }

            this.isSelected = item.id === 4 && item.$$selected

            // setTimeout(() => {
            //     this.$stackRouter.clear()
            //     this.$router.push({ name: 'WaiveRequestPage' })
            // }, 200);
        },

        async onClickClose() {
            const idx = await this.$modal.basic({
                body: '약속 취소가 완료되지 않았습니다. <br />원래대로 약속을 진행하시겠습니까?',
                buttons: [
                    { label: '약속 취소하기', class: 'btn-default' },
                    { label: '원래대로 약속 진행', class: 'btn-primary' },
                ],
            })
            if (idx === 1) {
                this.$emit('close')

                this.$nativeBridge.postMessage({
                    action: 'setBackgroundColor',
                    value: {
                        top: '#FAF0E1',
                        bottom: '#FFF9EF',
                    },
                })
            }
        },
        async submit() {
            let reason
            let reasonId

            const selectedReason = this.reasonItems.find(item => item.$$selected)
            if (selectedReason.id === 4 && this.inputValue !== '') {
                reason = this.inputValue
                reasonId = selectedReason.id
            } else {
                reason = selectedReason ? selectedReason.name : null
                reasonId = selectedReason ? selectedReason.id : null
            }
            this.$stackRouter.push({
                name: 'WaiveSubmitPage',
                props: {
                    product: this.$route.params.product,
                    reason: reason,
                    reasonId: reasonId,
                    scheduleId: this.$route.params.scheduleId,
                },
            })
        },
        async onClickFeeInformation() {
            this.$stackRouter.clear()
            this.$stackRouter.push({
                name: 'FeeInformationPage',
            })
        },
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        placeholder() {
            return '사유를 입력해 주세요.'
        },
    },
}
</script>
<style scoped lang="scss">
.cancel-schedule {
    overflow-y: auto;

    .section {
        padding: 0 16px;
        color: black;

        .title {
            display: flex;
            align-items: center;
            font-size: 20px;
            margin-bottom: 10px;
            @include spoqa-f-medium;
            color: #151360;
            margin: auto;

            .text {
                margin: auto;
            }
        }

        ul {
            margin-bottom: 27px;

            li {
                @include center;
                padding: 15px;
                color: #888888;
                size: 14px;
                background: white;
                // margin-bottom: 8px;
                border-radius: 8px;
                border: 1px solid #efdfd2;
                font-weight: 400;

                &.selected {
                    color: #151360;
                    border: 1px solid #151360;
                    font-weight: 600;
                }
            }

            .reason-desc {
                display: inline-block;
                color: #a9a9a9;
                margin-bottom: 5px;
                padding: 10px;
            }

            .textarea-wrapper {
                height: 100px;
            }
        }

        .content {
            font-size: 16px;
            padding: 10px;
            color: #787878;

            .type-btn {
                font-size: 14px;
                color: $grey-06;
                padding-left: 16px;
                border: 1px solid $grey-02;

                &.selected {
                    border: 1px solid $blue-primary !important;
                    background-color: #f0f8ff;
                    color: $blue-primary;
                }
            }
            .desc {
                &.li-dot {
                    display: list-item;
                    align-items: flex-start;
                    margin-left: 16px;
                }
            }

            .place-map {
                margin-left: 4px;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 69px;
                width: fit-content;
                height: 24px;
                background: $grey-03;
                border-radius: 100px;
                color: #111;
            }
            .place-wrap {
                display: flex;
                align-items: center;
            }

            .link-style {
                color: #555;
                text-decoration: underline;
            }
        }
    }
    ::v-deep .bottom-button {
        border: 1px solid white;
    }
}
</style>
