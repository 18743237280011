<template>
    <div class="modal-profile-item-selector edit-profile-detail items-start bg-main">
        
        <ul class="result flex-fill" :class="{ 'grid-col': displayGrid }">
            <li
                @click="onClickItem(item)"
                class="item flex-row items-center flex-between"
                :class="{ selected: item.$$selected }"
                :key="item.id"
                v-for="item in profileItems"
            >
                <div v-html="$translate(item.name)" />
                <img
                    v-if="item.name"
                    class="i-right"
                    :src="require(`@/assets/images/icons/arrow_right.svg`)"
                    width="16px"
                    height="16px"
                />
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'State',
    computed: {
        tempSignupPayload() {
            return JSON.parse(localStorage.getItem('tempSignupPayload')) || {}
        },
        title() {
            return this.$translate(this.$case.toConst( this.model))
        },
        displayGrid() {
            return !['state', 'hometown'].includes(this.model)
        },
    },
    data: () => ({
        model: 'state',
        nextRoute: 'OnboardAgreeDirectionality',
        profileItems: [
            {
                id: 1,
                name: "서울",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '서울' ? true : false,
            },
            {
                id: 2,
                name: "경기",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '경기' ? true : false,
            },
            {
                id: 3,
                name: "인천",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '인천' ? true : false,
            },
            {
                id: 4,
                name: "부산",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '부산' ? true : false,
            },
            {
                id: 5,
                name: "대구",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '대구' ? true : false,
            },
            {
                id: 6,
                name: "대전",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '대전' ? true : false,
            },
            {
                id: 7,
                name: "광주",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '광주' ? true : false,
            },
            {
                id: 8,
                name: "울산",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '울산' ? true : false,
            },
            {
                id: 9,
                name: "세종",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '세종' ? true : false,
            },
            {
                id: 10,
                name: "강원",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '강원' ? true : false,
            },
            {
                id: 11,
                name: "충북",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '충북' ? true : false,
            },
            {
                id: 12,
                name: "충남",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '충남' ? true : false,
            },
            {
                id: 13,
                name: "경북",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '경북' ? true : false,
            },
            {
                id: 14,
                name: "경남",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '경남' ? true : false,
            },
            {
                id: 15,
                name: "전북",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '전북' ? true : false,
            },
            {
                id: 16,
                name: "전남",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '전남' ? true : false,
            },
            {
                id: 17,
                name: "제주",
                $$selected: JSON.parse(localStorage.getItem('tempSignupPayload')) && JSON.parse(localStorage.getItem('tempSignupPayload')).state.name === '제주' ? true : false,
            },
        ]
    }),
    mounted() {
        if (this.tempSignupPayload.state === undefined) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Enter_StatePage`,
                },
            })
        }
    },
    methods: {
        onClickClose() {
            if (this.chooseOneMoreAllowed) {
                // 중복 선택 가능
                this.$emit(
                    'close',
                    this.profileItems.filter(o => o.$$selected)
                )
                this.profileItems.forEach(o => this.$set(o, '$$selected', false))
            } else if (this.moneyInputAllowed || this.heightInputAllowed) {
                this.$emit('close', this.numberValue)
            } else {
                this.$emit('close')
            }
        },

        async onlyCity() {
            const idx = await this.$modal.basic({
                body: '현재 수도권 서비스만 제공하고 있습니다. 빠른 시일 내에 확장할 수 있도록 하겠습니다. 감사합니다. ',
                buttons: [
                    {
                        label: '확인',
                        class: 'btn-primary',
                    },
                ],
            })
        },
        onClickItem(item) {
            if (this.model === 'state' && item.name !== '서울' && item.name !== '경기' && item.name !== '인천') {
                this.onlyCity()
            } else {
                setTimeout(() => {
                        this.profileItems.forEach(o => this.$set(o, '$$selected', false))
                        this.$set(item, '$$selected', !item.$$selected)
                        this.selectedValue = item
                        console.log(item)
                }, 200)

                const tempSignupPayload = this.tempSignupPayload

                tempSignupPayload[this.$case.toSnake(this.model)] = item
                localStorage.setItem('tempSignupPayload', JSON.stringify(tempSignupPayload))
                this.$router.push({ name: this.nextRoute })
            }
        },
    },
}
</script>

<style scoped></style>
