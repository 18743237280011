<template>
    <div class="money-input">
        <div class="mode-line" v-if="mode === 'line'">
            <div class="display" :class="{ focus, empty: !value }" @click="inputFocus" v-html="inputParsed" />
            <div class="display-krw">
                <span v-if="String(value).length > 4" v-html="krwParsed" />
            </div>
        </div>
        <div @click="inputFocus" class="mode-box" v-html="inputParsed" v-else />
        <input
            ref="input"
            type="number"
            maxlength="13"
            v-model="value"
            @input="onInput"
            @blur="onBlur"
            @focus="onFocus"
        />
    </div>
</template>

<script>
export default {
    name: 'MoneyInput',
    data: () => ({
        value: '',
        focus: false,
    }),
    props: {
        initialVal: {
            type: Number,
            default: 0,
        },
        mode: {
            type: String,
            default: 'line',
        },
        reset: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        initialVal() {
            this.value = this.initialVal
        },
        reset() {
            this.value = this.initialVal
        },
    },
    mounted() {
        if (this.initialVal) {
            this.value = this.initialVal
        }
        this.inputFocus()
    },
    computed: {
        input() {
            return this.$refs.input
        },
        inputParsed() {
            if (!this.value) return '금액을 입력하세요'

            return `${Number(this.value).toLocaleString()} 만원`
        },
        krwParsed() {
            const val = String(this.value)
            if (val.length > 8) {
                const zo = val.slice(0, val.length - 8)
                const billion = val.slice(val.length - 8, val.length - 4)
                const rest = val.slice(val.length - 4, val.length)

                return `${zo}조 ${Number(billion) ? `${Number(billion).toLocaleString()}억 ` : ''}${
                    Number(rest) ? `${Number(rest).toLocaleString()}만원` : ''
                }`
            }
            if (val.length > 4) {
                const billion = val.slice(0, val.length - 4)
                const rest = val.slice(val.length - 4, val.length)

                return `${Number(billion) ? `${Number(billion).toLocaleString()}억 ` : ''}${
                    Number(rest) ? `${Number(rest).toLocaleString()}만원` : ''
                }`
            }

            return `${this.value.toLocaleString()} 만원`
        },
    },
    methods: {
        onBlur() {
            this.focus = false
            this.$emit('focus')
        },
        onFocus() {
            this.focus = true
            this.$emit('blur')
        },
        inputFocus() {
            this.input.focus()
            this.$emit('focus', true)
        },
        onInput() {
            this.value = this.input.value
            this.$emit('input', this.value)
        },
    },
}
</script>

<style scoped lang="scss">
.mode-box {
    width: 100%;
    padding: 12px;
    border: 1px solid $grey-04;
    border-radius: 8px;
}
.display {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: black;
    padding-left: 8px;
    border-bottom: 1px solid $grey-03;

    &.focus {
        border-bottom: 1px solid $blue-primary;
    }
    &.empty {
        color: $grey-03;
        @include f-medium;
    }
}
.display-krw {
    margin-top: 4px;
    font-size: 14px;
    height: 16px;
    color: $blue-primary;
}
input {
    opacity: 0;
    height: 1px;
}
</style>
