<template>
    <div class="chatroom">
        <Loading :loading="loading" />
        <ChatHeaderBar />
        <ChatBody :unreadCount="unreadCount" :chat="chat" class="flex-fill" />
        <ChatScheduleButtons v-if="this.chat.chat_type == 'schedule_change'" :chat="chat" />
        <ChatInput class="flex-wrap" />
    </div>
</template>

<script>
import ChatBody from './components/ChatBody'
import ChatInput from './components/ChatInput'
import ChatHeaderBar from '@/components/app/ChatHeaderBar'
import ChatScheduleButtons from './components/ChatScheduleButtons'

export default {
    name: 'ChatroomPage',
    components: {
        ChatBody,
        ChatInput,
        ChatHeaderBar,
        ChatScheduleButtons,
    },
    data: () => ({
        unreadCount: 0,
        agent: null,
        loading: true,
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
    },
    watch: {
        chat(newChat) {
            // chat의 변화 감지 및 로딩 상태 업데이트
            if (newChat && Object.keys(newChat).length > 0) {
                this.loading = false
            } else {
                this.loading = true
            }
        },
    },
    mounted() {
        this.init()
        this.showModal()
        this.unreadCount = this.$route.params.unreadCount || 0
        this.$bus.$on('reloadMessage', this.reloadMessages)
        if (this.chat && Object.keys(this.chat).length > 0) {
            this.loading = false
        }
    },
    beforeDestroy() {
        if (this.unreadCount > 0) this.updateUnreadCount()
        this.$nativeBridge.postMessage({ action: 'allowSwipeGesture', value: true })
        this.$bus.$off('reloadMessage', this.reloadMessages)
    },
    methods: {
        onClickPhotoInputPage() {
            this.$router.push({ name: 'UploadPhotoPage' })
        },
        init() {
            if (this.chat.chat_type === undefined || (this.chat.chat_type === 'pro_agent' && !this.chat.user)) {
                this.$router.push({ name: 'HomePage' })
                return
            }
            if (this.chat.agent_id === 1) {
                this.$nativeBridge.postMessage({
                    action: 'setBackgroundColor',
                    value: {
                        top: '#FAF0E1',
                        bottom: '#FFF9EF',
                    },
                })
            } else {
                this.$nativeBridge.postMessage({
                    action: 'setBackgroundColor',
                    value: '#FAF0E1',
                })
            }
            // 클릭 이벤트 리스너를 달아놓으면 chatInput 포커스 된 상태에서 다른 영역 클릭 시 자동으로 포커스 해제됨
            const chatroomElement = document.querySelector('.chatroom')
            chatroomElement.addEventListener('click', event => {})

            this.updateUnreadCount()
        },
        reloadMessages() {
            const chat = this.$store.getters.chat

            if (!chat) return

            this.$nextTick(async () => {
                const unread = await this.$store.dispatch('loadChat', {
                    chatId: chat.id,
                    force: true,
                    updateUnread: false,
                })

                this.unreadCount = unread
            })
        },
        updateUnreadCount() {
            this.$bus.$emit('updateUnread')
        },
        showModal() {},
    },
}
</script>

<style lang="scss" scoped>
.chatroom {
    background: #fff9ef;
    position: relative;
    display: flex;
    flex-direction: column;

    .header {
        position: relative;
        border-bottom: solid 1px $grey-03;
        //height: 70px;
    }
}
.temp-btn {
    width: 169px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;

    margin: 16px auto;

    i {
        color: black;
    }
}
</style>
