<template>
    <div class="signup basics birthday">
        <div class="content">
            <div class="title">생년월일</div>
            <div class="body">
                <div class="input-wrapper birthday-year">
                    <!-- <input
                        ref="birth0"
                        class="flex-fill"
                        type="number"
                        pattern="\d*"
                        placeholder="Y"
                        :maxlength="1"
                        v-model="birth[0]"
                        @keydown="onKeydown"
                    />
                    <input
                        ref="birth1"
                        class="flex-fill"
                        type="number"
                        pattern="\d*"
                        placeholder="Y"
                        :maxlength="1"
                        v-model="birth[1]"
                        @keydown="onKeydown"
                    />
                    <input
                        ref="birth2"
                        class="flex-fill"
                        type="number"
                        pattern="\d*"
                        placeholder="Y"
                        :maxlength="1"
                        v-model="birth[2]"
                        @keydown="onKeydown"
                    />
                    <input
                        ref="birth3"
                        class="flex-fill"
                        type="number"
                        pattern="\d*"
                        placeholder="Y"
                        :maxlength="1"
                        v-model="birth[3]"
                        @keydown="onKeydown"
                    />
                    <div class="slash flex-fill" v-html="'/'" />
                    <input
                        ref="birth4"
                        class="flex-fill"
                        type="number"
                        pattern="\d*"
                        placeholder="M"
                        :maxlength="1"
                        v-model="birth[4]"
                        @keydown="onKeydown"
                    />
                    <input
                        ref="birth5"
                        class="flex-fill"
                        type="number"
                        pattern="\d*"
                        placeholder="M"
                        :maxlength="1"
                        v-model="birth[5]"
                        @keydown="onKeydown"
                    />
                    <div class="slash flex-fill" v-html="'/'" />
                    <input
                        ref="birth6"
                        class="flex-fill"
                        type="number"
                        pattern="\d*"
                        placeholder="D"
                        :maxlength="1"
                        v-model="birth[6]"
                        @keydown="onKeydown"
                    />
                    <input
                        ref="birth7"
                        class="flex-fill"
                        type="number"
                        pattern="\d*"
                        placeholder="D"
                        :maxlength="1"
                        v-model="birth[7]"
                        @keydown="onKeydown"
                    /> -->
                    <input
                        ref="birthday"
                        class="flex-fill"
                        type="tel"
                        placeholder="ex) 19960531"
                        maxlength="8"
                        v-model="birthday"
                        @keydown="onKeydown"
                    />
                </div>
                <div class="ok" v-if="nicknameOk">
                    <i class="material-icons c-primary">check_circle</i>
                    {{ nicknameOk }}
                </div>
                <div class="error" v-if="error">
                    <i class="material-icons">error_outline</i>
                    {{ error }}
                </div>
            </div>
        </div>
        <div class="button-wrapper">
            <button
                class="btn btn-primary"
                :class="{ disabled: disabled }"
                @click="next"
                v-html="$translate('NEXT')"
            ></button>
        </div>
    </div>
</template>

<script>
import debounce from '@/modules/debounce'

export default {
    name: 'BirthdayPage',
    data: () => ({
        nicknameOk: null,
        error: null,
        disabled: true,
        // birth: ['', '', '', '', '', '', '', ''],
        birthday: '',
    }),
    mounted() {
        this.$nextTick(() => this.$refs.birthday.focus())
        // setTimeout(() => {
        //     this.$refs.birthday.focus()
        // }, 500)

        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'AgePage_Enter_AgePage',
                option: {
                    provider: this.$store.getters.payloads.signupData.provider || 'email',
                },
            },
        })
    },
    methods: {
        onKeydown: debounce(function (input) {
            // const length = this.birthLength()
            this.birthday = this.$refs.birthday.value
            if (this.birthday.length >= 7 && this.birthday.length <= 9) {
                this.disabled = false
            } else {
                this.disabled = true
            }
            // if (input && input.key === 'Backspace') {
            //     const index = Math.max(length - 1, 0)
            //     this.birth[index] = ''
            //     this.$refs[`birth${index}`].value = ''

            //     this.$refs[`birth${index}`].focus()
            //     this.disabled = true
            // } else {
            //     const index = Math.min(length - 1, 7)
            //     this.birth[index] = this.$refs[`birth${index}`].value

            //     if (index < 7) {
            //         this.$refs[`birth${index + 1}`].focus()
            //     } else {
            //         this.$refs.birth7.blur()
            //     }

            //     if (this.birthLength() >= 8 || index >= 7) {
            //         this.disabled = false
            //     }
            // }
        }, 0),

        // onKeydown: debounce(function (input) {
        //     const length = this.birthLength()
        //     if (input && input.key === 'Backspace') {
        //         const index = Math.max(length - 1, 0)
        //         this.birth[index] = ''
        //         this.$refs[`birth${index}`].value = ''

        //         this.$refs[`birth${index}`].focus()
        //         this.disabled = true
        //     } else {
        //         const index = Math.min(length - 1, 7)
        //         this.birth[index] = this.$refs[`birth${index}`].value

        //         if (index < 7) {
        //             this.$refs[`birth${index + 1}`].focus()
        //         } else {
        //             this.$refs.birth7.blur()
        //         }

        //         if (this.birthLength() >= 8 || index >= 7) {
        //             this.disabled = false
        //         }
        //     }
        // }, 0),
        birthLength() {
            return this.birth.filter(b => b !== '').length
        },
        next() {
            if (this.error || this.disabled) return

            if (this.birthday.length < 8) return

            const signupData = this.$store.getters.payloads.signupData
            signupData.birthyear = this.birthday.substring(0, 4)
            signupData.birthday = this.birthday.substring(4, 8)
            const date = new Date()
            const age = date.getFullYear() - signupData.birthyear + 1
            if (age < 20) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Toast_AgeError',
                        option: {
                            birth: this.birthday,
                        },
                    },
                })

                this.$toast.error(
                    '바닐라브릿지에서는 미성년자를 위한 서비스를 제공하고 있지 않습니다. 양해 부탁드립니다.'
                )
                return
            } else if (age > 49) {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'Toast_AgeError',
                        option: {
                            birth: this.birthday,
                        },
                    },
                })

                this.$toast.error(
                    '바닐라브릿지는 현재 20~49세의 회원을 대상으로 서비스를 운영중이며, 향후 서비스 범위 확장을 계획중입니다. 감사합니다.'
                )
                return
            }

            this.$store.commit('setPayloads', { signupData })

            // this.$router.push({ name: 'GenderPage' })
            this.$router.push({ name: 'NicknamePage' })
        },
    },
}
</script>

<style lang="scss" scoped>
.birthday {
    .input-wrapper {
        // border: none;

        div {
            text-align: center;
            color: $grey-04;
        }
        input {
            // border-bottom: 1px solid $grey-03;
            // border-radius: 8px;
            // text-align: center;
            // margin-right: 4px;
            // margin-left: 4px;
            // font-size: 17px;
        }
    }
}
</style>
