<template>
    <div class="input-wrapper1">
        <textArea v-if="formtype" ref="userInput" rows="5" :placeholder="placeholder" @input="onInput" v-model="val" />
        <select
            v-else-if="selecttype"
            ref="userInput"
            :placeholder="placeholder"
            @input="onInput"
            v-model="val"
            class="select"
        >
            <option value="">선택</option>
            <option v-for="emp_type in employmentType" :key="emp_type">
                {{ emp_type }}
            </option>
        </select>
        <input v-else ref="userInput" class="user-input" :placeholder="placeholder" @input="onInput" v-model="val" />
        <i v-if="val" class="material-icons flex-wrap" @click="val = null">cancel</i>
    </div>
</template>

<script>
export default {
    name: 'UserInpupt',
    data: () => ({
        val: '',
    }),
    props: {
        initialVal: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: '입력해 주세요',
        },
        formtype: {
            type: Boolean,
            required: false,
            default: false,
        },
        selecttype: {
            type: Boolean,
            required: false,
            default: false,
        },
        employmentType: {
            type: Array,
            required: false,
            default: () => {
                return []
            },
        },
    },
    watch: {
        initialVal() {
            this.val = this.initialVal
        },
    },

    mounted() {
        if (this.initialVal) this.val = this.initialVal

        this.$refs.digitInput.focus()
    },
    methods: {
        onInput() {
            this.$emit('input', this.val)
        },
    },
}
</script>

<style scoped lang="scss">
.input-wrapper1 {
    height: 48px;
    width: 100%;
    .user-input {
        border: 1px solid #ced5d9;
        border-radius: 8px;
        width: 100%;
        padding: 12px;
    }
    .select {
        background-color: red;
        width: 100% !important;
    }
}
</style>
