<template>
    <div v-if="!loading" class="basic-profile bg-white">
        <ul class="bp">
            <li style="z-index: 2" class="items m-l-13 m-r-13" v-for="item in personalProfile" :key="item.id">
                <div class="item m-b-16">
                    <div class="key">
                        <img
                            class="m-l-18 m-r-10"
                            :src="require(`@/assets/images/icons/profile_${item.key}.png`)"
                            width="20px"
                            height="20px"
                        />
                    </div>
                    <div class="value">
                        <div class="content" v-if="item.key === 'age'" v-html="item.value" />

                        <div v-else-if="isPremium || !item.locked" class="content">
                            <span>{{ item.value }}</span>
                        </div>

                        <div v-else @click="showItemValues(item)" class="content">
                            <span class="upgrade-membership">{{ item.value }}</span>
                            <img
                                class="unlock m-r-18"
                                :src="require(`@/assets/images/icons/fi-sr-lock.png`)"
                                alt="locked"
                                width="18px"
                            />
                        </div>
                        <!-- <div v-else class="content">
                            <span class="upgrade-membership" @click="upgradeMembership">{{ item.value }}</span>
                            <img
                                class="unlock m-r-18"
                                :src="require(`@/assets/images/icons/fi-sr-lock.png`)"
                                alt="locked"
                                width="18px"
                            />
                        </div> -->
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
// import inquiryService from '@/services/inquiry'
import userService from '@/services/user'

export default {
    name: 'PersonalProfile',
    props: {
        profile: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
        message: {
            type: Object,
        },
        openAll: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        loading: true,
        profileItems: {
            regionName: { locked: true },
            smoking: { locked: true },
            mbti: { locked: true },
            marryPlan: { locked: true },
        },
        source: null,
    }),
    watch: {
        openAll(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.checkLocked()
            }
        },
    },
    created() {
        this.init()
    },
    computed: {
        isMe() {
            return this.$store.getters.me.id === this.userId
        },
        getTitle() {
            return `<i class="material-icons">person</i> 인적사항`
        },
        getProductName() {
            const products = this.$store.getters.products
            const mine = this.$store.getters.userRatePlans.onGoing.find(o => o.ptype === 'subscription_plan')

            if (mine) {
                return products.all.find(p => p.id === mine.product_id).name
            } else {
                return null
            }
        },
        isPremium() {
            if (this.getProductName && this.getProductName.includes('Marry Fit')) {
                return true
            } else {
                return false
            }
        },
        personalProfile() {
            const items = [
                {
                    id: 1,
                    key: 'age',
                    value: this.$options.filters.asAge(this.profile.birthday),
                    result: this.$options.filters.asAge(this.profile.birthday),
                },
                {
                    id: 2,
                    key: 'height',
                    value: this.profile.height,
                    result: `${this.profile.height}cm`,
                },
                {
                    id: 3,
                    key: 'regionName',
                    profileType: 'open_region',
                    value: this.initValue('regionName'),
                },
                {
                    id: 4,
                    key: 'religion',
                    value: this.profile.religion ? this.profile.religion : this.profile.religion_name,
                },
                {
                    id: 5,
                    key: 'smoking',
                    profileType: 'open_smoking',
                    value: this.initValue('smoking'),
                },
                {
                    id: 6,
                    key: 'mbti',
                    profileType: 'open_mbti',
                    value: this.initValue('mbti'),
                },
                {
                    id: 7,
                    key: 'marryPlan',
                    profileType: 'open_marry_plan',
                    value: this.marryPlanValue(),
                },
                {
                    id: 8,
                    key: 'marry',
                    value: this.marryValue(),
                },
            ]
            const result = items
                .filter(item => Number.isInteger(item.value) || typeof item.value === 'boolean' || item.value)
                .map(item => {
                    let locked = false
                    if (
                        this.profileItems[item.key] &&
                        Object.hasOwnProperty.call(this.profileItems[item.key], 'locked')
                    ) {
                        locked = this.profileItems[item.key].locked
                    }

                    return { key: item.key, value: item.value, profileType: item.profileType, locked }
                })

            return result
        },
        likeIcon() {
            return require(`@/assets/images/icons/like_inactive.png`)
        },
    },
    methods: {
        async init() {
            try {
                const source = await this.$store.dispatch('loadUserDetail', { userId: this.userId })
                this.source = source
                await this.checkLocked()
            } catch (e) {
                console.error('init error: ', e)
            } finally {
                this.loading = false
            }
        },
        checkLocked() {
            const openAll = this.isMe
                ? true
                : this.source.photo_histories.item_histories.filter(o => o.code === 'open_all').length > 0 ||
                  this.openAll

            const regionNameLocked =
                !this.isPremium &&
                !openAll &&
                this.source.photo_histories.item_histories.filter(o => o.code === 'open_region').length === 0
            const smokingLocked =
                !this.isPremium &&
                !openAll &&
                this.source.photo_histories.item_histories.filter(o => o.code === 'open_smoking').length === 0
            const mbtiLocked =
                !this.isPremium &&
                !openAll &&
                this.source.photo_histories.item_histories.filter(o => o.code === 'open_mbti').length === 0
            const marryPlanLocked =
                !this.isPremium &&
                !openAll &&
                this.source.photo_histories.item_histories.filter(o => o.code === 'open_marry_plan').length === 0
            this.profileItems.regionName.locked = regionNameLocked
            this.profileItems.smoking.locked = smokingLocked
            this.profileItems.mbti.locked = mbtiLocked
            this.profileItems.marryPlan.locked = marryPlanLocked
        },
        initValue(key) {
            if (key === 'regionName') {
                if (this.isPremium) {
                    return this.profile.region_name
                } else if (this.profile.region && this.profileItems.regionName.locked) {
                    return `${this.profile.region.name}: 세부지역 오픈하기`
                } else if (
                    !this.profile.region &&
                    this.profile.station &&
                    this.profile.station.region &&
                    this.profileItems.regionName.locked
                ) {
                    return `${this.profile.station.region}: 세부지역 오픈하기`
                } else {
                    return this.profile.region_name
                }
            } else if (key === 'smoking') {
                return this.profileItems.smoking.locked && !this.isPremium
                    ? '흡연 여부: 오픈하기'
                    : this.profile.smoking === 0
                    ? '비흡연'
                    : '흡연'
            } else if (key === 'mbti') {
                return this.profileItems.mbti.locked && !this.isPremium ? 'MBTI: 오픈하기' : this.profile.mbti
            }
        },
        toggleLock(key) {
            if (this.profileItems[key]) {
                this.profileItems[key].locked = !this.profileItems[key].locked
            }
        },
        showBadge(key) {
            if (!this.isMe) return false
            return !this.profile.privacy_setting[`show_${key}`]
        },
        marryPlanValue() {
            if (!this.isPremium && this.profileItems.marryPlan.locked) {
                return '결혼희망시기: 오픈하기'
            } else {
                if (this.profile.marry_plan.includes('가급적 빨리')) {
                    return '결혼희망시기: 가급적 빨리'
                } else if (this.profile.marry_plan.includes('1~2년 정도')) {
                    return '결혼희망시기: 1~2년 이내'
                } else if (this.profile.marry_plan.includes('3~4년 정도')) {
                    return '결혼희망시기: 3~4년 이내'
                } else if (this.profile.marry_plan.includes('언제든 가능')) {
                    return '결혼희망시기: 언제든 가능'
                } else if (this.profile.marry_plan.includes('여유롭게 생각')) {
                    return '결혼희망시기: 여유롭게 생각중'
                } else {
                    return ''
                }
            }
        },
        marryValue() {
            if (this.profile.marry) {
                return '돌싱'
            }
        },
        async showItemValues(item) {
            const res = await this.$modal.custom({
                component: 'ModalOpenProfileDetail',
                options: {
                    profileType: item.profileType,
                },
            })
            if (res) {
                const payload = {
                    profile_type: res === 'all' ? 'open_all' : item.profileType,
                    photo_history_id: this.source.photo_histories.id,
                }
                try {
                    const result = await userService.openProfile(this.$store.getters.chat.id, this.message.id, payload)
                    this.$toast.success(result.msg)

                    if (res === 'all') {
                        this.$emit('updateOpenAll', true)
                    }

                    this.toggleLock(item.key)
                    await this.init()
                } catch (err) {
                    const idx = await this.$modal.basic({
                        body: err.data.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-default',
                            },
                            {
                                label: '충전하러 가기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) {
                        this.$stackRouter.push({ name: 'InAppStorePage' })
                    }
                }
            }
        },
    },
}
</script>
<style scoped lang="scss">
ul li {
    border-bottom: 1px solid #f4f4f4;
    .item {
        align-items: center;
    }
}

ul li:last-child {
    border-bottom: none;
}

.upgrade-membership {
    padding-right: 40px;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.unlock {
    position: absolute;
    right: 10px;
}
</style>
