<template>
    <div class="edit-profile-detail">
        <ProfileOptionSelector :model="'station'" :nextRoute="() => 'OnboardJobCategory'" />
    </div>
</template>

<script>
export default {
    name: 'Station',
}
</script>

<style scoped></style>
