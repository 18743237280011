<template>
    <div class="qna-header" :class="{ whiteMode }">
        <div class="left-btn">
            <div class="left-btn-container" v-if="showLeftBtn">
                <!-- <i @click="clickLeftBtn" class="material-icons">{{ leftBtnIcon }}</i> -->
                <img
                    class="m-r-2"
                    :src="require(`@/assets/images/icons/${leftBtnIcon}.png`)"
                    width="24px"
                    height="24px"
                    @click="clickLeftBtn"
                />
                <span class="f-18 m-l-8" v-html="title" />
            </div>
        </div>
        <!-- <div class="right-btn" @click="clickRightBtn">
            <img class="m-r-2" src="@/assets/images/icons/questionmark.png" width="20px" height="20px" />
            <span class="f-14 m-l-4">{{ rightBtnCta || `문의` }}</span>
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'QnaHeader',
    props: {
        showLeftBtn: {
            type: Boolean,
            default: false,
        },
        leftBtnIcon: {
            type: String,
            default: 'close',
        },
        leftBtnHandler: {
            type: Function,
        },
        title: {
            type: String,
        },
        whiteMode: {
            type: Boolean,
        },
        rightBtnHandler: {
            type: Function,
        },
        rightBtnCta: {
            type: String,
        },
    },
    methods: {
        clickLeftBtn() {
            if (this.leftBtnHandler) {
                this.leftBtnHandler()
                return
            }

            this.$router.go(-1)
        },
        clickRightBtn() {
            if (this.rightBtnHandler) {
                this.rightBtnHandler()
                return
            }
            this.clickPlusFriend()
        },
        clickPlusFriend() {
            this.$modal
                .basic({
                    body: '굿퍼슨 고객팀에 문의하시겠습니까?',
                    buttons: [
                        {
                            label: '아니요',
                            class: 'btn-default',
                        },
                        {
                            label: '예',
                            class: 'c-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx)
                        this.$nativeBridge.postMessage({
                            action: 'openInAppBrowser',
                            value: `https://pf.kakao.com/_gYDxdb/chat`,
                        })
                })
        },
    },
}
</script>

<style scoped lang="scss">
.qna-header {
    @include between;
    color: white;
    height: $header-height;
    padding: 16px;
    position: sticky;
    background: #111111;
    top: 0;
    left: 0;

    &.whiteMode {
        background: #fff9ef;
        color: black;

        i {
            color: black;
        }
    }

    i {
        color: white;
        margin: 0 !important;
    }
    .left-btn-container {
        @include center;
    }

    .right-btn {
        @include center;
    }
}
</style>
