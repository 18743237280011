<template>
    <section class="section">
        <div class="title m-b-8">쿠폰</div>
        <TextareaWithX
            placeholder="쿠폰 코드를 입력해주세요"
            v-model="code"
            @click="onClickCouponBtn"
            :btn="couponBtn"
            :is-input-mode="true"
            :disabled="selectedCoupon"
        />
        <div class="msg-container" v-if="err || selectedCoupon">
            <p v-if="err" class="err">{{ err }}</p>
            <div v-if="!err && selectedCoupon" class="msg">
                <span class="f-12 c-grey">적용쿠폰 : </span>
                <span class="">{{ selectedCoupon.discount.name }}</span>
            </div>
        </div>
        <TextareaWithX
            class="m-t-8"
            placeholder="포인트 금액을 입력해주세요"
            v-model="point"
            @click="onClickPointBtn"
            @input="onChangePoint"
            @blur="pointAdjust"
            :btn="pointBtn"
            :is-input-mode="true"
        />
        <div class="msg-container">
            <div class="msg">
                <span class="f-12 c-grey">잔여 포인트 : </span>
                <span class="">{{ `${mileage.toLocaleString()}원` }}</span>
            </div>
        </div>
    </section>
</template>

<script>
import productService from '@/services/product'

export default {
    name: 'UseCoupon',
    data: () => ({
        err: null,
        code: null,
        point: null,
    }),
    computed: {
        couponBtn() {
            return {
                label: this.$translate('REGISTER'),
                class: 'btn-primary',
            }
        },
        pointBtn() {
            return {
                label: '모두 사용',
                class: 'btn-grey-fit',
            }
        },
        selectedCoupon() {
            return this.$store.getters.selectedCoupon
        },
        mileage() {
            // return false
            if (this.$store.getters.me) {
                return this.$store.getters.me.mileage
            } else {
                return 0
            }
        },
        price() {
            return this.$store.getters.price
        },
    },
    mounted() {
        //  this.$store.commit('setSelectedCoupon', null)
        if (this.selectedCoupon) this.code = this.selectedCoupon.discount_code
    },
    watch: {
        selectedCoupon: {
            handler() {
                this.pointAdjust()
            },
            deep: true,
        },
    },
    methods: {
        onChangePoint(val) {
            this.point = val
            this.$store.commit('setSelectedPoint', this.point)
        },
        async onClickCouponBtn() {
            this.$store.commit('setSelectedCoupon', null)
            try {
                if (
                    this.$route.params.categoryName.ptype !== 'subscription_plan' &&
                    this.$route.params.categoryName.ptype !== 'package_plan' &&
                    this.code === 'marry2023'
                ) {
                    this.$modal.basic({
                        body: '멤버십 상품에만 적용되는 코드입니다',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    return
                }
                const res = await productService.discountUsers(this.code)
                if (!res.length) {
                    this.err = res.msg
                    return
                }
                this.$store.commit('setSelectedCoupon', res[res.length - 1])
                this.err = null
            } catch (e) {
                console.log(e)
            }
        },
        pointAdjust() {
            const totalPrice =
                (Number(this.price.originalPrice.replace(',', '')) / 11) * 10 -
                Number(this.price.couponDiscount.replace(',', '')) -
                Number(this.price.eventDiscount.replace(',', ''))
            if (Number(this.point) > totalPrice) {
                this.point = totalPrice
                this.$store.commit('setSelectedPoint', this.point)
            }
        },
        onClickPointBtn() {
            const originalPrice = (Number(this.price.originalPrice.replace(',', '')) / 11) * 10

            if (this.price.couponDiscount) {
                const couponDiscount = Number(this.price.couponDiscount.replace(',', ''))
                if (originalPrice - couponDiscount < this.mileage) {
                    this.point = originalPrice - couponDiscount
                } else {
                    this.point = this.mileage
                }
            } else {
                if (originalPrice < this.mileage) {
                    this.point = originalPrice
                } else {
                    this.point = this.mileage
                }
            }
            this.$store.commit('setSelectedPoint', this.point)
        },
    },
}
</script>

<style scoped lang="scss">
.msg-container {
    height: 32px;
    display: flex;
    align-items: center;

    .msg {
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;

        .c-grey {
            color: #666b70;
        }
    }
    .err {
        @extend .msg;
        color: $brand-danger;
    }
}
</style>
