export default {
    checkPassed: [
        { id: 1, name: '네, 가입 심사에 합격했어요.' },
        { id: 2, name: '아니오, 아직 가입 심사를 받지 않았어요.' },
    ],
    // marry: [
    //     { id: 0, name: '미혼이에요.' },
    //     { id: 1, name: '돌싱이에요.' },
    // ],
    haveChild: [
        { id: 0, name: '자녀 없음' },
        { id: 1, name: '자녀 있음, 본인이 양육' },
        { id: 2, name: '자녀 있음, 본인이 양육하지 않음' },
    ],
    marryPlan: [
        { id: 0, name: '가급적 빨리 하고싶어요' },
        { id: 1, name: '1~2년 정도 연애하고 결혼하고 싶어요' },
        { id: 2, name: '3~4년 정도 연애하고 결혼하고 싶어요' },
        { id: 3, name: '결혼할만한 상대와 연애하고 싶지만,<br>결혼 시기는 여유롭게 생각하고 있어요' },
    ],
    useManager: [
        { id: 1, name: '결혼정보회사' },
        { id: 2, name: '소개팅앱' },
        { id: 3, name: '둘다 사용해봄' },
        { id: 0, name: '둘다 사용 안해봄' },
    ],
    whyDifficult: [
        { id: 1, name: '이성을 만날 기회가 없어요' },
        { id: 2, name: '조건을 맞춰 이성을 만나기 어려워요' },
        { id: 3, name: '외모 수준을 맞춰 이성을 만나기 어려워요' },
        { id: 4, name: '어플에는 진정성 있는 사람이 적어요' },
        { id: 0, name: '지금 생각나는 어려움은 없어요' },
        { id: 5, name: '기타(직접 입력)' },
    ],
    contractIntro: [
        { id: 1, name: '무료 2회 만남 서비스는 3개월 이내에 사용해야 합니다.' },
        { id: 2, name: '허위 프로필로 피해가 발생할 경우 법적인 조치를 취할 수 있습니다.' },
        { id: 3, name: '이성 교제, 결혼을 하게 되는 경우에는 반드시 회사에 통지하여야 합니다.' },
    ],
    agreeDirectionality: [
        { id: 1, name: '네, 동의합니다' },
        { id: 2, name: '아니오, 동의하지 않습니다' },
    ],
    agreeVerification: [
        { id: 1, name: '네, 동의합니다' },
        { id: 2, name: '아니오, 동의하지 않습니다' },
    ],
    agreeMannerFeedback: [
        { id: 1, name: '네, 동의합니다' },
        { id: 2, name: '아니오, 동의하지 않습니다' },
    ],
}
