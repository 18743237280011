import * as $http from 'axios'

export default {
    initSchedule: payload => $http.post(`v2/schedules`, payload),
    createSchedule: (scheduleId, payload) => $http.put(`v2/schedules/${scheduleId}`, payload),
    // 약속만남 피드백
    mannerFeedbacks: payload => $http.post('v2/user_manner_feedbacks', payload),
    scheduleList: userId => $http.get(`v2/users/${userId}/get_user_schedules`),
    addReservation: payload => $http.put('v2/schedules/add_reservation', payload),
    changeScheduleRequest: payload => $http.put('v2/schedules/change_schedule_request', payload),
    changeScheduleConfirm: payload => $http.put('v2/schedules/change_schedule_confirm', payload),
    getRegionList: name => $http.get(`v2/schedule_regions?name=${name}`),
    getScheduleRequest: payload => $http.get(`v2/schedule_changes`, { params: payload }),
    waiveRequest: payload => $http.put('v2/schedule_changes/request_waive', payload),
}
