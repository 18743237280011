<template>
    <div v-if="me" class="container">
        <StackRouterHeaderBar :title="'인증센터'" :show-title="true" />
        <main class="wrapper">
            <div class="read-me">
                <div class="readme-item" v-for="i in [1]" :key="i">
                    <div class="star">*</div>
                    <div class="text" v-html="$translate(`CERT_CENTER_${i}`)" />
                </div>
            </div>
            <div v-if="declined.length" class="check-declined" @click="onClickDeclineReason">
                <div class="left">
                    <img :src="require(`@/assets/images/certi-center/reject.png`)" width="24px" height="24px" />
                    <span class="m-l-8">반려사유 확인하기</span>
                </div>
                <div class="right">
                    <img :src="require(`@/assets/images/icons/arrow_right.svg`)" widsth="16px" height="16px" />
                </div>
            </div>
            <!-- <div class="title m-b-8">필수 인증 항목</div> -->
            <div class="required m-b-20">
                <div class="item" @click="onSelectItem(item)" v-for="item in filteredRequiredItems" :key="item.id">
                    <div class="item-desc flex flex-column">
                        <span class="m-t-8" v-html="item.name" />
                        <span class="f-13 m-t-5 c-primary"> {{ verificationText(item.id) }} </span>
                        <span class="f-11 m-t-9 c-pink-deep">{{ needAddInfo(item) }}</span>
                    </div>
                    <div class="right">
                        <div
                            v-if="item.status !== 'NOT_YET'"
                            class="item-badge"
                            :class="{
                                confirmed: item.status === 'CONFIRMED' || item.status === 'IMMUNITY',
                                declined: item.status === 'DECLINED',
                            }"
                            v-html="$translate(item.status)"
                        />
                        <img :src="require(`@/assets/images/icons/arrow_right.svg`)" width="16px" height="16px" />
                    </div>
                </div>
            </div>
            <div class="mandate" v-if="extend">
                <div class="header">
                    <span class="f-16">대리로 발급되는 서류</span>
                    <img
                        @click="extend = false"
                        :src="require(`@/assets/images/icons/close.png`)"
                        width="16px"
                        height="16px"
                    />
                </div>
                <div class="items">
                    <div class="mandate-item" v-for="item in verifyStatus.mandated" :key="item.id">
                        <span v-html="item.name" />
                        <div
                            v-if="item.status !== 'NOT_YET'"
                            class="item-badge"
                            :class="{ confirmed: item.status === 'CONFIRMED' }"
                            v-html="$translate(item.status)"
                        />
                    </div>
                </div>
                <div class="notice-wrapper">
                    <div class="notice">* 직접 발급을 원하신다면 매니저에게 문의해주세요.</div>
                    <div class="notice">** 대리 발급은 무료로 진행됩니다</div>
                </div>
            </div>
        </main>
        <BottomButton :needAddInfoTop="false" :disabled="disabled" @click="submit" :label="$translate('SAVE')" />
    </div>
</template>

<script>
export default {
    name: 'CertificatePage',
    data: () => ({
        extend: false,
        disabled: false,
    }),
    // props: {
    //     onCertiAllSubmittedMove: {
    //         type: Function,
    //     },
    // },
    mounted() {
        this.init()
    },
    // watch: {
    //     certiSubmitted(newValue) {
    //         if (newValue) {
    //             this.onCertiAllSubmitted()
    //         }
    //     },
    // },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        verifyStatus() {
            const badges = this.$store.getters.verificationBadges
            if (!badges || !badges.length) return false
            return this.$store.getters.verifyStatus
        },
        myBadges() {
            return this.$store.getters.myVerificationBadges || []
        },
        declined() {
            return this.$store.getters.declined
        },
        certiSubmitted() {
            const requiredVeri = [13, 14, 15]
            const hasAllCerties = requiredVeri.every(r => {
                return (this.$store.getters.myVerificationBadges || []).some(b => b.verification_badge_id === r)
            })
            return hasAllCerties
        },
        filteredRequiredItems() {
            if (this.me.profile.status === 'confirmed') {
                return this.verifyStatus.required.filter(item => [14, 45].includes(item.id))
            } else {
                return this.verifyStatus.required.filter(item => [13, 15].includes(item.id))
            }
        },
    },
    methods: {
        submit() {
            this.$modal.basic({
                title:
                    this.me.profile.status === 'confirmed'
                        ? '인증 심사에는 1~2 영업일 정도 소요됩니다.'
                        : '가입 심사에는 1~2 영업일 정도 소요되며, 심사에 통과하신 분에 한해서 연락드릴 예정입니다.',
                body: '(심사를 통과하지 못한 경우 제출한 모든 개인 정보는 즉시 파기됩니다.)',
                showCloseButton: false,
                buttons: [
                    {
                        label: '확인',
                        class: 'btn-primary',
                    },
                ],
            })
            this.save().then(() => this.$stackRouter.pop())
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#FAF0E1',
                    bottom: '#FFFCF6',
                },
            })
        },
        async init() {
            if (!this.verifyStatus) {
                await this.$store.dispatch('loadVerificationBadges')
            }
            await this.$store.dispatch('loadMyVerificationBadges')
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#FAF0E1',
                    bottom: '#FFF9EF',
                },
            })
        },
        async save() {
            try {
                await this.$store.dispatch('loadMyVerificationBadges')
                this.$store.dispatch('loadMe')
                this.$store.dispatch('loadSettings')
            } catch (e) {
                console.log(e)
            }
        },
        onClickCertMethod() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://vbproduct.notion.site/ac8a5e85d1e04f0e92a45f879fd289a1`,
            })
        },
        onClickDeclineReason() {
            this.$modal.custom({
                component: 'ModalBadgeDeclined',
                options: { reasons: this.declined, hideBottomBtn: true },
            })
        },
        async onSelectItem(item) {
            if (['PENDING', 'CONFIRMED', 'IMMUNITY'].includes(item.status)) return

            if (item.id === 14) {
                this.$stackRouter.push({ name: 'CertificateIncomesPage' })
            } else if (item.id === 15) {
                this.$stackRouter.push({ name: 'CertificateCompaniesPage' })
            } else if (item.id === 45) {
                this.$stackRouter.push({ name: 'CertificateDetailPage' })
            } else {
                const verificationBadge = this.$store.getters.verificationBadges.find(
                    badge => badge.name === '신원 인증'
                )
                this.$modal.custom({
                    component: 'ModalCertificate',
                    options: {
                        verificationBadge,
                        menuId: 1,
                    },
                })
            }
        },
        // async onCertiAllSubmitted() {
        //     await this.$store.dispatch('loadMyVerificationBadges')
        //     if (this.onCertiAllSubmittedMove) {
        //         this.$store.dispatch('loadMe')
        //         this.$store.dispatch('loadSettings')
        //         this.onCertiAllSubmittedMove()
        //     } else {
        //     }
        // },
        back() {
            this.$stackRouter.pop()
        },
        verificationText(id) {
            if (id === 13) {
                return '10초만에 인증 가능'
            } else if (id === 14) {
                return '연소득 4천만원 이상 인증'
            } else if (id === 15) {
                return '명함/사원증 등으로 인증 가능'
            } else {
                return '서류가 없어도 대리 발급해드려요!'
            }
        },
        needAddInfo(item) {
            if (this.me.profile.school.name === '고등학교 졸업' && item.id === 45) {
                item.status = 'IMMUNITY'
                return `*고등학교 졸업자의 경우 편의상 학력인증이 생략됩니다.`
            } else if (this.me.profile.company[0].job_category_name === '프리랜서' && item.id === 15) {
                item.status = 'IMMUNITY'
                return `*프리랜서의 경우 편의상 직장 인증이 생략됩니다.`
            }
        },
    },
    created() {
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.save()
        this.$unregisterBackHandler()
    },
}
</script>

<style lang="scss" scoped>
.container {
    font-family: bold;
}
.bottom-border {
    border-bottom: 1px solid $grey-02;
}
.item-badge {
    padding: 7px;
    color: $blue-primary;
    background: #f0f8ff;
    border-radius: 100px;
    font-size: 13px;
    white-space: nowrap;
    // margin-top: 13px;

    &.confirmed {
        color: $grey-08;
        background: $grey-01;
    }
    &.declined {
        color: $pink-deep;
        background: $bg-danger;
    }
}
.container {
    color: #111111;

    .wrapper {
        padding: 24px 16px;

        .read-me {
            padding: 16px 12px;
            background: $grey-01;
            border-radius: 4px;
            font-size: 14px;
            margin-bottom: 24px;

            .readme-item {
                display: flex;
                line-height: 20px;
            }
        }
        .check-declined {
            @include between;
            padding: 8px;
            background: $bg-danger;
            border-radius: 8px;
            margin-bottom: 24px;

            .left {
                @include center;
            }
        }

        .title {
            // @include spoqa-f-bold;
            font-size: 16px;
            line-height: 26px;
        }
        .sub-title {
            font-size: 14px;
            color: $grey-08;
            display: flex;
            align-items: center;
        }
        .item {
            border: 1px solid $grey-04;
            padding: 20px 10px;
            margin-bottom: 10px;
            border-radius: 8px;
            font-size: 16px;
            color: black;
            @include between;

            .right {
                font-size: 12px;
                @include center;
            }
        }
        .mandate {
            width: calc(100vw - 36px);
            margin: 0 auto;
            border: 1px solid $grey-04;
            border-radius: 8px;
            padding: 18px 12px;
            font-size: 14px;

            .header {
                @include between;
                font-size: 14px;
                color: black;
                margin-bottom: 10px;
            }
            .items {
                .mandate-item {
                    @include between;
                    margin-bottom: 8px;
                    color: black;
                }
            }
            .notice-wrapper {
                color: $grey-08;
                line-height: 20px;
            }
        }
    }
}
</style>
