<template>
    <div>
        <Loading :loading="loading" />
        <div v-if="!loading">
            <div class="front-header">
                <ChannelTalk
                    :wrapStyle="wrapStyle"
                    :textStyle="{ color: 'black', 'font-weight': 'normal' }"
                    :hasIcon="true"
                />
            </div>
            <div class="waitlist">
                <div class="waitlist-title waitlist-content">대기 명단에 등록되었습니다.</div>
                <div class="waitlist-desc waitlist-content" v-html="waitlistDesc"></div>
                <div class="waitlist-card waitlist-content">
                    <div>서비스 오픈까지</div>
                    <div>
                        <span class="until-open-count">{{ untilOpen }}</span
                        >명 남음
                    </div>
                </div>
                <div class="waitlist-bye waitlist-content">서비스 오픈시 SMS를 통해 안내 드리겠습니다 :)</div>
                <div class="waitlist-delete" @click="onClickNotWait">기다리지 않기</div>
            </div>
        </div>
    </div>
</template>
<script>
import userService from '@/services/user'
import ChannelTalk from '@/components/app/ChannelTalk'

export default {
    name: 'WaitlistPage',
    components: { ChannelTalk },

    data: () => ({
        count: 0,
        loading: true,
    }),
    mounted() {
        this.getCount()
    },
    computed: {
        status() {
            return this.$route.params.user.profile.status || this.$store.getters.me.profile.status
        },
        me() {
            return this.$route.params.user || this.$store.getters.me
        },
        reasonForWaiting() {
            if (this.status === 'waitlist_state') {
                return '회원님의 지역에 충분한 회원이'
            } else if (this.status === 'waitlist_marry') {
                return '재혼 회원이 충분히'
            } else if (this.status === 'waitlist_age') {
                return '회원님의 나이대의 회원이 충분히'
            }
            return ''
        },
        waitlistDesc() {
            return `원활한 서비스 이용을 위해<br> <b>${this.reasonForWaiting}</b><br>가입한 이후 서비스를 개시할 예정입니다.`
        },
        untilOpen() {
            return 500 - this.count
        },
        // leftCount: {
        //     get() {
        //         return this.count
        //     },
        //     set(val) {
        //         this.count = val
        //     },
        // },
        wrapStyle() {
            return {
                bottom: '0px',
                position: 'static',
                'background-color': 'transparent',
                'font-size': '14px',
                'font-family': 'SpoqaHanSansNeo-Regular',
            }
        },
    },
    methods: {
        async getCount() {
            //     // this.count = count
            //     const count = await userService.waitlist()
            //     this.leftCount = count[`${this.status}_count`]
            const count = await userService.waitlist()
            this.count = count[`${this.status}_count`]
            this.loading = false
        },
        async onClickNotWait() {
            const confirmmodal = await this.$modal.basic({
                title: '정말로 기다리지 않으시겠어요?',
                body:
                    '서비스 오픈까지 얼마 남지 않았습니다. 그 동안 회원님의 본인인증 정보가 타인에게 노출되는 경우는 전혀 없습니다.',
                buttons: [
                    {
                        label: '취소',
                        class: 'btn-default',
                    },
                    {
                        label: '확인',
                        class: 'btn-primary',
                    },
                ],
            })
            if (confirmmodal) {
                const deletewait = await this.$modal.basic({
                    body: '본인인증 정보를 포함한 모든 입력 정보가 삭제됩니다. 나중에 다시 찾아주세요 :)',
                    buttons: [
                        {
                            label: '취소',
                            class: 'btn-default',
                        },
                        {
                            label: '확인',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (deletewait) {
                    this.deleteWaitlist()
                }
            }
        },
        async deleteWaitlist() {
            try {
                const drop = await userService.dropoutUser(this.me.id)
                if (drop.msg === 'success') {
                    this.$router.push({ name: 'FrontPage' })
                }
            } catch (e) {
                this.$toast.error(e.msg)
                console.log('waitlist page dropout error', e)
            }
        },
    },
}
</script>
<style scoped lang="scss">
.front-header {
    background: white;
    color: black;
    height: $header-height;
    padding: 14px;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include spoqa-f-regular;
}
.waitlist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 52px);
    .waitlist-content:first-child {
        margin-bottom: 32px;
    }
    .waitlist-content:not(:first-child):not(:last-child) {
        margin-bottom: 48px;
    }

    .waitlist-title {
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #111111;
    }
    .waitlist-desc {
        font-size: 16px;
        line-height: 26px;
        text-align: center;
    }
    .waitlist-card {
        filter: drop-shadow(0px 2px 6px rgba(136, 136, 143, 0.2));
        width: calc(100vw - 200px);
        div:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #393d44;
            border-radius: 16px 16px 0px 0px;
            color: #ffffff;
            height: 40px;
        }
        div:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 92px;
            background: #ffffff;
            border-radius: 0px 0px 16px 16px;
            .until-open-count {
                color: $blue-primary;
                font-weight: bold;
                font-size: 32px;
                line-height: 44px;
            }
        }
    }
    .waitlist-bye {
        font-size: 14px;
        line-height: 24px;
    }
    .waitlist-delete {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        bottom: 36px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        text-decoration-line: underline;
        color: #666b70;
    }
}
</style>
