<template>
    <div class="blind-replies">
        <div class="content" @scroll="onScroll">
            <img class="img-replie" src="@/assets/images/invitation-friend/blind-review.svg" alt="" />
        </div>
        <div class="content-box m-b-28">
            <div class="title f-17 f-medium">참여방법</div>
            <div class="text f-15 m-t-16">
                1. 블라인드에 굿퍼슨 후기를 남겨주세요.<br /><span class="m-l-14"
                    >('굿퍼슨' 혹인 'ㄱㅍㅅ' 키워드 필수)</span
                >
            </div>
            <div class="text f-15">
                2. 글 작성 <span class="content-highlight">24시간 후에</span> 캡쳐본을 업로드 해주세요.
            </div>
        </div>
        <div class="content-box m-b-20">
            <div class="title f-17 f-medium">후기 유형</div>
            <div class="subtitle f-15 f-medium m-t-20">유형1 - 자유형</div>
            <div class="description m-t-12 m-b-12 p-12">
                • 굿퍼슨 장단점<br />
                • 굿퍼슨 시스템/기능 설명<br />
                • 기타 자유 주제
            </div>
            <div class="example-img">
                <img src="@/assets/images/invitation-friend/review-img-1.png" alt="" />
            </div>
            <div class="subtitle f-15 f-medium m-t-24">유형2 - 만남 후기형</div>
            <div class="description m-t-12 m-b-12 p-12">
                만남 후기의 경우 신상을 특정 가능한 개인정보를 적거나, 비판하는 내용을 담을 수 없습니다.
            </div>
            <div class="example-img">
                <img src="@/assets/images/invitation-friend/review-img-2.png" alt="" />
            </div>
        </div>
        <div class="content-box">
            <div class="title f-17 f-medium m-b-12">필수 조건</div>
            <div class="text f-15">• '굿퍼슨', 'ㄱㅍㅅ' 키워드 중 한 가지가 꼭 들어가야 합니다.</div>
            <div class="text f-15">• 4줄 미만의 글은 인정되지 않습니다.</div>
            <div class="text f-15">• 최소 24시간 이상 글을 유지한 뒤에 캡쳐번을 올려주세요.</div>
            <div class="text f-15">• '추천코드/프리퀀시' 게시판은 인정되지 않습니다.</div>
            <div class="text f-15">
                • 추천코드를 기재한 글은 제외됩니다.
                <span class="subtext f-13">(자연스러운 느낌의 후기글을 적어주세요.)</span>
            </div>
            <div class="text f-15">
                • 만남 후기의 경우 신상을 특정 가능한 개인정보를 적거나, 비판하는 내용을 담는 경우 지급되지 않습니다.
            </div>
            <div class="text f-15">• 기존에 작성한 후기를 중복으로 업로드 한 게시물은 인정되지 않습니다.</div>
            <div class="text f-15">• 후기 적립금은 최대 2회까지 받을 수 있습니다.</div>
        </div>
        <div class="bottom-button">
            <input
                ref="imageUploader"
                type="file"
                class="image-input display-none"
                @change="handleFileUpload"
                accept="image/*"
            />
            <button class="btn-send-reason f-18" @click="triggerFileUpload">후기 캡쳐본 올리기</button>
        </div>
    </div>
</template>

<script>
import mileageService from '@/services/mileage'

export default {
    name: 'BlindReviewsPage',
    components: {},
    data: () => ({
        // repliesUploaded: [],
        photo: {
            url: '',
            blob: null,
        },
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
    },
    // mounted() {
    //     this.init()
    // },
    beforeDestroy() {},
    methods: {
        async triggerFileUpload() {
            try {
                const payload = {
                    user_id: this.me.id,
                    category: 'review',
                }

                // 첫 번째 모달 창
                const idx = await this.$modal.basic({
                    body:
                        '댓글 작성 후 24시간이 지나지 않은 댓글이나, 유의사항 만족하지 않는 댓글은 인정되지 않습니다.',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-basic',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })

                // CONFIRM 버튼이 클릭되었는지 확인
                if (idx === 1) {
                    const res = await mileageService.checkLimit(payload)
                    if (res.res === 'exceeded') {
                        // 두 번째 모달 창 - 제한 알림
                        await this.$modal.basic({
                            body: res.msg,
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'c-primary',
                                },
                            ],
                        })
                    } else {
                        // 이미지 업로더 클릭
                        this.$refs.imageUploader.click()
                    }
                }
            } catch (e) {
                console.error(e)
            }
        },
        handleFileUpload(event) {
            const file = event.target.files[0]
            if (file) {
                console.log('Selected file:', file)
                this.photo = {
                    url: URL.createObjectURL(file),
                    blob: file,
                }

                console.log('File URL:', this.photo.url)
                console.log('File Blob:', this.photo.blob)

                this.uploadFile(file)
            }
        },
        async uploadFile(file) {
            try {
                this.$loading(true)
                console.log('Uploading file:', file)
                this.photo = {
                    url: URL.createObjectURL(file),
                    blob: file,
                }

                const payload = new FormData()
                payload.append(`user_id`, this.$store.getters.me.id)
                payload.append(`category`, 'review')
                payload.append(`photo`, this.photo.blob, this.photo.name)

                console.log('payload: ', payload)

                const res = await mileageService.create(payload)

                if (res.res === 'success') {
                    this.$loading(false)
                    await this.$modal
                        .basic({
                            title: '업로드 완료',
                            body:
                                '관리자가 확인 후 적립금 지급 조건을 모두 만족한 경우 적립금을 지급해드립니다. 감사합니다.',
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'c-primary',
                                },
                            ],
                        })
                        .then(async () => {
                            this.$loading(true)
                            this.$store.dispatch('loadMe')
                            this.$loading(false)
                        })
                }

                // this.$router.go(-1)
            } catch (e) {
                this.$loading(false)
                console.error(e)
                this.$toast.error(e.data.msg)
            } finally {
                this.$router.go(-1)
            }
        },

        onScroll(event) {
            if (event.target.scrollTop >= 52) {
                this.showHeaderTitle = true
            } else {
                this.showHeaderTitle = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.blind-replies {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
    overflow-y: auto; /* Scrollable area */

    .content {
        flex: 1; /* Grow to fill available space */
        padding: 10px 16px;
    }

    .img-replie {
        width: 100%;
        display: block; /* Use block to avoid flex behavior */
    }

    .content-box {
        padding: 0px 20px;
        font-family: pretendard;
        // .title {
        // }

        .subtitle {
            color: #5c5c77;
        }

        .description {
            color: #8a8a8a;
            line-height: 150%;
            background-color: #faf0e1;
            border-radius: 8px;
        }

        .text {
            line-height: 150%;
            color: #3a3a3a;

            .subtext {
                color: #787878;
            }
        }

        .example-img {
            width: 100%;

            img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
            }
        }
    }

    .content-box:nth-of-type(4) {
        margin-bottom: 100px; // 원하는 스타일
    }

    .bottom-button {
        padding: 12px 20px;
        bottom: 0;
        width: 100%;
        background: #fff; /* Optional: Background for contrast */
        position: fixed;
    }

    .btn-send-reason {
        height: 48px;
        width: 100%;
        border: none;
        border-radius: 36px;
        background: #151360;
        color: white;
        cursor: pointer; /* Add cursor pointer */
    }
}
</style>
