<template>
    <div></div>
</template>

<script>
export default {
    name: 'ProfileOptionSelector',
    props: ['model', 'nextRoute', 'univKeys'],
    mounted() {
        this.openModal()
    },
    computed: {
        tempSignupPayload() {
            const signupData = window.localStorage.getItem('tempSignupPayload') || '{}'
            return JSON.parse(signupData)
        },
    },
    methods: {
        openModal() {
            this.$modal
                .custom({
                    component: 'ModalProfileItemSelector',
                    options: {
                        model: this.model,
                        icon: 'back',
                        profile: this.tempSignupPayload,
                    },
                })
                .then(result => {
                    if (!result) {
                        this.$router.go(-1)
                        throw Error
                    }
                    const tempSignupPayload = this.tempSignupPayload

                    tempSignupPayload[this.$case.toSnake(this.model)] = result
                    localStorage.setItem('tempSignupPayload', JSON.stringify(tempSignupPayload))
                    this.$router.push({ name: this.nextRoute })
                })
                .catch(() => {})
        },
    },
}
</script>
