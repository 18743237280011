<template>
    <div class="blind-replies">
        <div class="content" @scroll="onScroll">
            <img class="img-replie" src="@/assets/images/invitation-friend/blind-reply.svg" alt="" />
        </div>
        <div class="content-box m-b-28">
            <div class="title f-17 f-medium">참여방법</div>
            <div class="text f-15 m-t-16">1. 블라인드에 타 회원이 작성한 글에 좋아요, 댓글 남기기를 해주세요.</div>
            <div class="text f-15">
                2. 댓글 작성 후 <span class="content-highlight">24시간</span>이 지난 캡쳐본을 업로드 해주세요.
            </div>
        </div>
        <div class="content-box">
            <div class="title f-17 f-medium m-b-12">유의사항</div>
            <div class="text f-15">• ‘굿퍼슨’ 관련 게시글에 댓글 달기 + 좋아요</div>
            <span class="subtext f-13 m-l-14">• 자유롭게 댓글을 달아주세요. (굿퍼슨 키워드가 없어도 됩니다.)</span>
            <span class="subtext f-13 m-l-14">• 댓글에 초대코드를 적는 경우 인정되지 않습니다.</span>
            <div class="text f-15">• 연애/소개팅 관련 게시글에 댓글 달기</div>
            <span class="subtext f-13 m-l-14">• 댓글에 ‘굿퍼슨’ 혹은 ‘ㄱㅍㅅ’ 키워드가 반드시 포함되어야 합니다.</span>
            <div class="text f-15">• 본인이 쓴 글, 초대코드/프리퀀시 게시판에 쓴 글에 단 댓글은 제외됩니다.</div>
            <div class="text f-15">• 댓글/좋아요 적립금은 최대 10회까지 받을 수 있습니다.</div>
        </div>
        <div class="bottom-button">
            <input
                ref="imageUploader"
                type="file"
                class="image-input display-none"
                @change="handleFileUpload"
                accept="image/*"
            />
            <button class="btn-send-reason f-18" @click="triggerFileUpload">댓글 캡쳐본 올리기</button>
        </div>
    </div>
</template>

<script>
import mileageService from '@/services/mileage'

export default {
    name: 'BlindRepliesPage',
    components: {},
    data: () => ({
        // repliesUploaded: [],
        photo: {
            url: '',
            blob: null,
        },
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
    },
    // mounted() {
    //     this.init()
    // },
    beforeDestroy() {},
    methods: {
        async triggerFileUpload() {
            try {
                const payload = {
                    user_id: this.me.id,
                    category: 'reply',
                }
                // 첫 번째 모달 창
                const idx = await this.$modal.basic({
                    body:
                        '댓글 작성 후 24시간이 지나지 않은 댓글이나, 유의사항 만족하지 않는 댓글은 인정되지 않습니다.',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-basic',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })

                // CONFIRM 버튼이 클릭되었는지 확인
                if (idx === 1) {
                    const res = await mileageService.checkLimit(payload)
                    if (res.res === 'exceeded') {
                        // 두 번째 모달 창 - 제한 알림
                        await this.$modal.basic({
                            body: res.msg,
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'c-primary',
                                },
                            ],
                        })
                    } else {
                        // 이미지 업로더 클릭
                        this.$refs.imageUploader.click()
                    }
                }
            } catch (e) {
                console.error(e)
            }
        },
        handleFileUpload(event) {
            const file = event.target.files[0]
            if (file) {
                console.log('Selected file:', file)
                this.photo = {
                    url: URL.createObjectURL(file),
                    blob: file,
                }

                console.log('File URL:', this.photo.url)
                console.log('File Blob:', this.photo.blob)

                this.uploadFile(file)
            }
        },
        async uploadFile(file) {
            try {
                this.$loading(true)
                console.log('Uploading file:', file)
                this.photo = {
                    url: URL.createObjectURL(file),
                    blob: file,
                }

                const payload = new FormData()
                payload.append(`user_id`, this.$store.getters.me.id)
                payload.append(`category`, 'reply')
                payload.append(`photo`, this.photo.blob, this.photo.name)

                console.log('payload: ', payload)

                const res = await mileageService.create(payload)

                if (res.res === 'success') {
                    this.$loading(false)
                    await this.$modal
                        .basic({
                            title: '업로드 완료',
                            body:
                                '관리자가 확인 후 적립금 지급 조건을 모두 만족한 경우 적립금을 지급해드립니다. 감사합니다.',
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'c-primary',
                                },
                            ],
                        })
                        .then(async () => {
                            this.$loading(true)
                            this.$store.dispatch('loadMe')
                            this.$loading(false)
                        })
                }
            } catch (e) {
                this.$loading(false)
                console.error(e)
                this.$toast.error(e.data.msg)
            }
        },

        onScroll(event) {
            if (event.target.scrollTop >= 52) {
                this.showHeaderTitle = true
            } else {
                this.showHeaderTitle = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.blind-replies {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
    overflow-y: auto; /* Scrollable area */

    .content {
        // flex: 1; /* Grow to fill available space */
        padding: 10px 16px;
    }

    .img-replie {
        width: 100%;
        display: block; /* Use block to avoid flex behavior */
    }

    .content-box {
        padding: 0px 20px;
        font-family: pretendard;
        // .title {
        // }
        .text {
            line-height: 150%;
            color: #3a3a3a;

            .subtext {
                color: #787878;
            }
        }
    }

    .content-box:nth-of-type(4) {
        margin-bottom: 100px; // 원하는 스타일
    }

    .bottom-button {
        padding: 12px 20px;
        bottom: 0;
        width: 100%;
        background: #fff; /* Optional: Background for contrast */
        position: fixed;
    }

    .btn-send-reason {
        height: 48px;
        width: 100%;
        border: none;
        border-radius: 36px;
        background: #151360;
        color: white;
        cursor: pointer; /* Add cursor pointer */
    }
}
</style>
