<template>
    <div class="add-item" @click="onClickItem">
        <i class="material-icons">add</i>
        <span v-html="addItemText" :class="{ required: requiredItem }" />
        <div v-if="needMoreInfo" class="need-edit">작성중</div>
    </div>
</template>

<script>
export default {
    name: 'AddItemComponent',
    props: {
        text: {
            type: String,
            default: '항목',
        },
        needMoreInfo: {
            type: Boolean,
            default: false,
        },
        requiredItem: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        addItemText() {
            return `${this.text} 추가`
        },
    },
    methods: {
        onClickItem() {
            this.$emit('click')
        },
    },
}
</script>

<style scoped lang="scss">
// .add-item {
//     @include center;
//     border-radius: 8px;
//     font-size: 14px;
//     width: 100%;
//     padding: 8px;
//     border: 1px dashed #ced5d9;
// }
// .need-edit {
//     @include center;
//     background: $pink-deep;
//     color: white;
//     font-size: 12px;
//     border-radius: 100px;
//     padding: 0 4px;
//     margin-left: 8px;
// }
.required::after {
    content: ' *';
    color: $blue-primary;
}
</style>
