<template>
    <div ref="userDetail" class="user-detail bg-main" @scroll="onScroll">
        <Loading :loading="loading" />
        <template v-if="user">
            <UserDetailHeaderBar
                :from="from"
                :user="user"
                :userId="userId"
                :photos="userPhotos"
                :show="showHiddenHeader"
                :nickname="user.profile.nickname"
                :customHeader="header"
                @open="openActionSheet"
            />
            <!-- 선수락 컴포넌트 -->
            <UserLikeMeComponent v-if="likeMe" :user="user" :photo="userPhotos" :likeType="likeType" />
            <!-- 이름 or 한마디로 표현 / 회원 태그 / 인증 표시 -->
            <UserProfile
                :user="user"
                :from="from"
                :type="'beginning'"
                :mannerBadge="hasFeedback && user.id !== this.$store.getters.me.id"
            />
            <MannerFeedbackComponent v-if="hasFeedback && user.id !== this.$store.getters.me.id" :user="user" />
            <!-- 프로필 청크 1 -->
            <UserDetailPhotos
                :user="user"
                :photo="userPhotos[0]"
                :allPhotos="userPhotos"
                :message="profilePhotoMessage"
                :likeActivate="likeActivate"
                :likeMe="likeMe"
            />
            <!-- 퍼스널 스토리 1 -->
            <UserPersonalStories
                :user="user"
                :from="from"
                :allPhotos="userPhotos"
                type="first"
                :message="profilePhotoMessage"
                :likeActivate="likeActivate"
                :likeMe="likeMe"
            />
            <UserProfile
                :user="user"
                :from="from"
                :type="'profile'"
                :message="message ? message : profilePhotoMessage"
                :openAll="openAll"
                @updateOpenAll="openAll = $event"
            />
            <!-- 프로필 청크 1 -->
            <UserDetailPhotos
                v-if="userPhotos.length > 1"
                :user="user"
                :photo="userPhotos[1]"
                :allPhotos="userPhotos"
                :message="profilePhotoMessage"
                :likeActivate="likeActivate"
                :likeMe="likeMe"
            />
            <UserDetailPhotos
                v-if="userPhotos.length > 3"
                :user="user"
                :photo="userPhotos[2]"
                :allPhotos="userPhotos"
                :message="profilePhotoMessage"
                :likeActivate="likeActivate"
                :likeMe="likeMe"
            />
            <!-- 퍼스널 스토리 2 -->
            <UserPersonalStories
                :user="user"
                :from="from"
                :allPhotos="userPhotos"
                type="second"
                :message="profilePhotoMessage"
                :likeActivate="likeActivate"
                :likeMe="likeMe"
            />
            <UserProfile
                :user="user"
                :from="from"
                :type="'spec'"
                :message="message ? message : profilePhotoMessage"
                :openAll="openAll"
                @updateOpenAll="openAll = $event"
            />
            <!-- 프로필 청크 2 -->
            <UserDetailPhotos
                v-if="userPhotos.length >= 2 && userPhotos.length === 3"
                :user="user"
                :photo="userPhotos[2]"
                :allPhotos="userPhotos"
                :message="profilePhotoMessage"
                :likeActivate="likeActivate"
                :likeMe="likeMe"
            />
            <UserDetailPhotos
                v-else-if="userPhotos.length > 3"
                :user="user"
                :photo="userPhotos[3]"
                :allPhotos="userPhotos"
                :message="profilePhotoMessage"
                :likeActivate="likeActivate"
                :likeMe="likeMe"
            />
            <!-- 퍼스널 스토리 3 -->
            <UserPersonalStories
                :user="user"
                :from="from"
                :allPhotos="userPhotos"
                type="third"
                :message="profilePhotoMessage"
                :likeActivate="likeActivate"
                :likeMe="likeMe"
            />
            <UserDetailPhotos
                v-if="userPhotos.length > 4"
                :user="user"
                :photo="userPhotos[4]"
                :allPhotos="userPhotos"
                :message="profilePhotoMessage"
                :likeActivate="likeActivate"
                :likeMe="likeMe"
            />
            <UserDetailPhotos
                v-if="userPhotos.length > 5"
                :user="user"
                :photo="userPhotos[5]"
                :allPhotos="userPhotos"
                :message="profilePhotoMessage"
                :likeActivate="likeActivate"
                :likeMe="likeMe"
            />
            <!-- <UserProfile :user="user" :from="from" type='keywords' /> -->
            <!-- <ProfilePassAcceptButtons
                class="bottom-btn-container message-btn"
                v-if="showProfilePassAcceptButtons"
                :count="remainProfilePhotoMessages.length"
                :message="profilePhotoMessage"
                :user="user"
                @action="onClickProfileActionButton"
            /> -->
            <BottomButton
                v-if="showConfirmMyProfileButton"
                :blackBtn="true"
                :label="confirmMyProfileButtonLabel"
                :disabled="disabledConfirmMyProfileButton"
                class="my-profile-confirm"
                @click="confirmProfile"
            />

            <div
                v-if="user.id !== this.$store.getters.me.id && likeActivate !== false"
                class="close-icon"
                style="z-index: 10; left: 5%; bottom: 5%; position: absolute"
            >
                <img
                    @click="onLike('rejected')"
                    class="like"
                    :src="closeIcon"
                    alt="close-icon"
                    width="100px"
                    height="100px"
                />
            </div>
            <div
                v-if="likeMe === true && likeActivate !== false"
                class="close-icon"
                style="z-index: 10; right: 5%; bottom: 5%; position: absolute"
            >
                <img
                    @click="onLike('accepted')"
                    class="like"
                    :src="likeIcon"
                    alt="close-icon"
                    width="100px"
                    height="100px"
                />
            </div>
        </template>
    </div>
</template>

<script>
import chatService from '@/services/chat'
import datingService from '@/services/dating'
import scheduleService from '@/services/schedule'
// import ProfilePassAcceptButtons from './components/ProfilePassAcceptButtons'
import UserDetailHeaderBar from './components/UserDetailHeaderBar'
import UserDetailPhotos from './components/UserDetailPhotos'
import UserProfile from './components/UserProfile'
import UserPersonalStories from './components/UserPersonalStories'
import UserLikeMeComponent from './components/UserLikeMeComponent'
import MannerFeedbackComponent from './components/MannerFeedbackComponent'
// import FloatingComponentsVue from '../chat/chatroom/components/floating-components/FloatingComponents.vue'

export default {
    name: 'UserDetailPage',
    components: {
        // ProfilePassAcceptButtons,
        UserDetailHeaderBar,
        UserDetailPhotos,
        UserProfile,
        UserPersonalStories,
        UserLikeMeComponent,
        MannerFeedbackComponent,
    },
    props: {
        from: String,
        type: String,
        userId: {
            type: Number,
            required: true,
        },
        agent: Object,
        agentId: Number,
        photos: {
            type: Array,
            default: () => [],
        },
        agentPhoto: String,
        agentMessage: String,
        profilePhotoMessage: Object,
        status: String,
        header: {
            type: Object,
        },
        message: {
            type: Object,
        },
        chat: {
            type: Object,
        },
    },
    data: () => ({
        user: null,
        showHiddenHeader: false,
        loading: false,
        userPhotos: [],
        profileConfirmed: false,
        likeActivate: true,
        likeMe: null,
        likeType: null,
        openAll: false,
        hasFeedback: false,
    }),
    watch: {
        userId: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.init()
            }
        },
    },
    // created() {
    //     this.init(),
    //     this.popManner()
    // },
    mounted() {
        this.init()
        // const message = this.chat.$$messages.find(
        //         message => message.id === this.profilePhotoMessage.id
        //     )
        // const content = this.$mustParse(message.content)
    },
    computed: {
        dom() {
            return this.$refs.userDetail
        },
        showProfilePassAcceptButtons() {
            return this.fromProfilePhotoComponent
        },
        showConfirmMyProfileButton() {
            return this.from === 'MyProfileConFirm'
        },
        disabledConfirmMyProfileButton() {
            if (!this.from) return false
            if (this.profileConfirmed) return true
            return this.$mustParse(this.message.content).user_confirmed
        },
        confirmMyProfileButtonLabel() {
            if (!this.from) return false
            if (this.disabledConfirmMyProfileButton) {
                return '프로필 확정완료'
            } else {
                return '이 프로필로 확정'
            }
        },
        fromProfilePhotoComponent() {
            return this.from === 'ProfilePhoto'
        },
        remainProfilePhotoMessages() {
            if (!this.fromProfilePhotoComponent) return []

            return (this.$store.getters.chat.$$messages || [])
                .filter(message => {
                    if (message.mtype !== 'profile-photo' || this.profilePhotoMessage.id >= message.id) {
                        return false
                    }

                    const content = this.$mustParse(message.content)
                    // show_photo 는 undefined 이거나 false 임
                    return !content.opened && !content.accept_status && content.show_photo === undefined
                })
                .reverse()
        },
        closeIcon() {
            return require(`@/assets/images/icons/pass_profile.png`)
        },
        likeIcon() {
            return require(`@/assets/images/icons/like_inactive.png`)
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || {}
        },
        validTickets() {
            return this.$store.getters.validTickets
        },
        settings() {
            return this.$store.getters.settings || {}
        },
    },
    methods: {
        async init() {
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: '#FFF9EF',
            })
            if (!this.userId) {
                this.$stackRouter.pop()
                // return
            }
            this.loading = true
            try {
                this.user = await this.$store.dispatch('loadUserDetail', { userId: this.userId })
                this.$store.dispatch('loadMe')

                if (this.photos.length === 0) {
                    this.userPhotos = [...this.user.photos] || []
                } else {
                    const parsedPhotos = this.photos.map(p => {
                        // if (p.url.indexOf('thumb') > 0) {
                        //     const idx = p.url.indexOf('thumb')
                        //     const front = p.url.slice(0, idx)
                        //     const back = p.url.slice(idx + 5)

                        //     return {
                        //         url: front + 'medium' + back,
                        //     }
                        // }
                        if (p.url.indexOf('medium') > 0) {
                            const idx = p.url.indexOf('medium')
                            const front = p.url.slice(0, idx)
                            const back = p.url.slice(idx + 6)

                            return {
                                url: front + 'large' + back,
                            }
                        }
                        return { ...p }
                    })

                    this.userPhotos = [...parsedPhotos]
                }

                if (this.user.photo_histories) {
                    if (this.user.photo_histories.accepted === true) {
                        this.likeActivate = false
                    } else {
                        this.likeActivate = true
                    }

                    if (this.user.photo_histories.source_accepted === true) {
                        this.likeMe = true
                        if (this.user.source_photo_histories.accepted_photo_url !== null) {
                            this.likeType = 'photo'
                        } else if (this.user.source_photo_histories.accepted_story_id !== null) {
                            this.likeType = 'personalStory'
                        }
                    } else {
                        this.likeMe = false
                    }

                    if (this.user.photo_histories.is_michagam) {
                        this.$modal.basic({
                            body: `(미차감) 안녕하세요, ${this.user.name.slice(
                                0,
                                1
                            )}OO님의 매니저입니다.<br />두 분이 잘 어울리실 것 같아 매니저 재량으로 만남권 사용 없이 만남 도와드리려고 해요.<br />수락 시 만남권 차감이 되지 않으니 프로필 살펴보시고 편하게 수락해주세요 :)`,
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                    }

                    if (this.user.source_photo_histories && this.user.source_photo_histories.is_manner) {
                        this.$modal.basic({
                            title: '매너 수락 (만남권 미차감)',
                            body: `${this.$store.getters.me.name.substring(
                                1
                            )}님을 꼭 한번 뵙고 싶다는 의미로 상대방이 매너 수락하였습니다. 매너 수락의 경우 만남권 사용 없이 약속 진행이 가능합니다.`,
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                    }

                    if (
                        this.user.photo_histories.item_histories.length > 0 &&
                        this.user.photo_histories.item_histories.filter(o => o.code === 'open_all').length > 0
                    ) {
                        this.openAll = true
                    }
                }

                if (
                    this.user.user_info &&
                    (this.user.user_info.manner ||
                        this.user.user_info.look ||
                        this.user.user_info.feedback ||
                        this.user.user_info.sincerity ||
                        this.user.user_info.after)
                ) {
                    this.hasFeedback = true
                }
            } catch (e) {
                this.$toast.error(e.data)
                this.$stackRouter.pop()
                // return
            } finally {
                this.loading = false
            }

            this.$scroll.up(this.dom, true)
        },
        onScroll(event) {
            this.showHiddenHeader = event.target.scrollTop > 56 // 56 is @header-height
        },
        asString(obj) {
            return typeof obj === 'string' ? obj : obj.name
        },
        onClose() {
            this.$stackRouter.pop()
        },
        async initSchedule() {
            console.log('init schedule started')
            try {
                const aUser = this.user.gender === 1 ? this.user.id : this.$store.getters.me.id
                const bUser = this.user.gender === 1 ? this.$store.getters.me.id : this.user.id
                const { res } = await scheduleService.initSchedule({
                    a_user_id: aUser,
                    b_user_id: bUser,
                })
                console.log('res', res)
                if (res === 'fail') {
                    this.$toast.error('이미 약속잡기가 진행 중이에요')
                }
                // this.$emit('close')
            } catch (e) {
                // this.$toast.error(e)
                console.log(e)
            }
        },
        async sendLike(status) {
            try {
                const chat = this.$store.getters.chat
                const payload = {
                    accept_status: status,
                }
                await chatService.acceptProfile(chat.id, this.profilePhotoMessage.id, payload)
                const message = chat.$$messages.find(message => message.id === this.profilePhotoMessage.id)
                const content = this.$mustParse(message.content)

                content.accept_status = status

                message.content = JSON.stringify(content)
                return 'success'
            } catch (e) {
                if (e.data.key === 'not_enough_point') {
                    const idx = await this.$modal.basic({
                        body: e.data.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-default',
                            },
                            {
                                label: '충전하러 가기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx) {
                        this.$stackRouter.push({ name: 'InAppStorePage' })
                    }
                } else {
                    console.error(e)
                    this.$toast.error(e)
                }
                return 'error'
            }
        },
        async onLike(status) {
            try {
                const respondedProfile = this.$store.getters.respondedProfile || []
                this.$store.commit('updateRespond', [...respondedProfile, this.profilePhotoMessage.id])

                if (status === 'rejected') {
                    const res = await this.$modal.custom({
                        component: 'ModalSelectReason',
                        options: {
                            chat: this.$store.getters.chat,
                            status: status,
                            profilePhotoMessage: this.profilePhotoMessage,
                        },
                    })
                    if (res === 'savedReasons') {
                        this.$modal.custom({
                            component: 'ModalAppearanceScore',
                            options: {
                                chat: this.$store.getters.chat,
                                user: this.user,
                                userPhotos: this.userPhotos,
                            },
                        })
                    }
                } else {
                    let msg = ''

                    if (!this.$store.getters.me.agree_penalty_policy) {
                        msg = await this.$modal.custom({
                            component: 'ModalAgreeLike',
                            options: {
                                type: 'after',
                            },
                        })
                    } else {
                        const idx = await this.$modal.basic({
                            title: '만남 의향이 있는 경우 수락해주세요 :)',
                            body: `${
                                this.user.photo_histories.is_michagam ||
                                (this.user.source_photo_histories && this.user.source_photo_histories.is_manner) ||
                                (this.userRatePlans.onGoingTicketPlan[0] &&
                                    this.userRatePlans.onGoingTicketPlan[0].code.includes('unlimited'))
                                    ? '나와 상대방이 서로 수락하면 약속 잡기가 시작되며, ' +
                                      `<span class="c-red">무료로 약속을 진행하는 경우에도</span>` +
                                      ' 약속 잡기가 시작 된 후 약속 잡기를 그만두면 약속 미진행 수수료 2만원, 약속이 잡힌 후 취소할 경우 취소 수수료 5만원이 부과됩니다.'
                                    : '나와 상대방이 서로 수락하면 약속 잡기가 시작되며, 약속 잡기가 시작 된 후 약속 잡기를 그만두면 약속 미진행 수수료 2만원, 약속이 잡힌 후 취소할 경우 취소 수수료 5만원이 부과됩니다.'
                            }`,
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        console.log('aaaa', idx)

                        if (idx === 1) {
                            msg = 'agree'
                        }
                    }
                    if (msg === 'agree') {
                        this.$modal
                            .basic({
                                title: `${
                                    this.$store.getters.me.send_like_charge
                                        ? this.settings.point.send_like + ' 포인트를 소모하여 '
                                        : ''
                                }"${this.user.profile.nickname}"님과의 만남을 수락합니다.`,
                                body: '수락 시, 약속잡기가 진행됩니다.',
                                buttons: [
                                    {
                                        label: 'CANCEL',
                                        class: 'btn-default',
                                    },
                                    {
                                        label: 'CONFIRM',
                                        class: 'btn-primary',
                                    },
                                ],
                            })
                            .then(idx => {
                                if (idx === 1) {
                                    this.$modal
                                        .basic({
                                            title: '약속 잡기를 시작합니다.',
                                            body: "'약속' 메뉴에서 진행 상태를 확인할 수 있어요!",
                                            buttons: [
                                                {
                                                    label: 'CONFIRM',
                                                    class: 'btn-primary',
                                                },
                                            ],
                                        })
                                        .then(async idx => {
                                            if (idx === 0) {
                                                const r = await this.sendLike(status)
                                                console.log(r)
                                                if (r !== 'success') {
                                                    return
                                                }
                                                this.$loading(true)

                                                await this.initSchedule()

                                                this.$toast.success('약속잡기가 시작됐습니다.')
                                                this.$emit('close')
                                                this.$loading(false)
                                                this.$stackRouter.pop()

                                                const res = await this.$modal.custom({
                                                    component: 'ModalSelectReason',
                                                    options: {
                                                        chat: this.$store.getters.chat,
                                                        status: status,
                                                        profilePhotoMessage: this.profilePhotoMessage,
                                                    },
                                                })

                                                if (res === 'savedReasons') {
                                                    this.$modal.custom({
                                                        component: 'ModalAppearanceScore',
                                                        options: {
                                                            chat: this.$store.getters.chat,
                                                            user: this.user,
                                                            userPhotos: this.userPhotos,
                                                        },
                                                    })
                                                }
                                            }
                                        })
                                }
                            })
                    }
                    // }
                    // this.$emit('action', status)
                    // }
                }
            } catch (e) {
                this.$loading(false)
                console.error(e)
                this.$toast.error(e)
            }
        },
        async onClickProfileActionButton(status) {
            if (status === 'undo') return

            if (this.remainProfilePhotoMessages.length > 0) {
                const photos = () => {
                    let photos = []
                    if (content.url) {
                        photos.push(content.url)
                    }

                    if (content.urls) {
                        photos = photos.concat(content.urls)
                    }

                    return photos.map(p => ({ url: p }))
                }

                const nextMessage = this.remainProfilePhotoMessages[0]
                const content = this.$mustParse(nextMessage.content)

                await chatService.openProfile(this.$store.getters.chat.id, nextMessage.id)
                content.opened = true
                const message = this.$store.getters.chat.$$messages.find(message => message.id === nextMessage.id)
                message.content = JSON.stringify(content)

                this.$stackRouter.replace({
                    name: 'UserDetailPage',
                    props: {
                        userId: content.source_id,
                        photos: photos(),
                        from: 'ProfilePhoto',
                        profilePhotoMessage: nextMessage,
                    },
                })
            } else {
                this.$stackRouter.pop()
            }
        },
        async confirmProfile() {
            // api 연결 await

            const res = await chatService.userConfirmedProfile(this.chat.id, this.message.id)
            console.log(res)
            this.profileConfirmed = true
            this.$toast.success('확정되었습니다.')
            this.$store.dispatch('loadChat', {
                chatId: this.chat.id,
                force: true,
            })
            // this.$stackRouter.pop()
        },
        openActionSheet() {
            this.$actionSheet({
                buttons: [
                    {
                        label: 'LEAVE_DATING_FEEDBACK',
                        handler: async () => {
                            try {
                                this.$loading(true)
                                const dating = await datingService.datings(this.user.id)

                                if ((dating || []).length === 0) {
                                    this.$toast.error('소개팅 성사된 회원끼리만 피드백을 남길 수 있어요!')

                                    return
                                }

                                if (dating[0].is_feedback) {
                                    this.$modal.basic({
                                        title: 'MODAL_DEFAULT_TITLE',
                                        body: this.$translate('ALREADY_SUBMIT_FEEDBACK'),
                                        buttons: [
                                            {
                                                label: 'CONFIRM',
                                                class: 'btn-primary',
                                            },
                                        ],
                                    })

                                    return
                                }

                                const dateDiff = this.$options.filters.dateDiff(dating[0].created_at)

                                if (dateDiff > 20) {
                                    this.$toast.error('피드백은 소개팅 성사 후 20일 동안만 가능해요')

                                    return
                                }
                                this.$stackRouter.push({
                                    name: 'DatingStatusCheckPage',
                                    props: {
                                        user: this.user,
                                        photos: this.userPhotos,
                                        dating: dating[0],
                                    },
                                })
                            } catch (e) {
                                console.error(e)
                            } finally {
                                this.$loading(false)
                            }
                        },
                    },
                    {
                        label: 'REPORT_PROFILE',
                        class: 'c-red',
                        handler: () => {
                            this.$stackRouter.push({
                                name: 'ReportSelectReasonPage',
                                props: {
                                    type: 'user',
                                    target: this.user,
                                },
                            })
                        },
                    },
                ],
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.user-detail {
    overflow-y: auto;
    overflow-x: hidden;
    // width: 100vw;

    hr {
        border-bottom: solid 1px $grey-02;
    }

    // .user-profile {
    //     padding-bottom: 40px;
    // }

    ::v-deep .bottom-btn-container {
        position: sticky;
        bottom: 0;
        width: 100%;
        background: white;
        border-top: 1px solid $grey-02;
        padding: 16px;
        z-index: 10;

        .desc {
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            margin-bottom: 12px;
        }

        button {
            height: 48px;
            font-size: 15px;
            opacity: 1;

            &.btn-reject {
                width: 48px;
                background: $grey-05;

                i {
                    color: white;
                }
            }
        }

        &.message-btn {
            .btn-brd {
                flex: 1;
                margin-right: 8px;

                &.btn-pass {
                    max-width: 100px;
                }
            }

            .btn-primary {
                flex: 2;
                box-shadow: none;
            }
        }
    }
    .my-profile-confirm {
        padding: 16px 16px !important;
        z-index: 4;
    }
}
</style>
