<template>
    <ProfileOptionSelector :model="'university'" :univKeys="univKeys" :nextRoute="() => `JobCategoryPage`" />
</template>

<script>
export default {
    name: 'SchoolSelectAllPage',
    computed: {
        signupData() {
            const signupData = window.localStorage.getItem('signupData') || '{}'
            return JSON.parse(signupData)
        },
        school() {
            return this.signupData.school
        },
        univKeys() {
            if ([5, 6].includes(this.school.id)) {
                return ['doctor', 'master', 'bachelor']
            } else if ([3, 4].includes(this.school.id)) {
                return ['master', 'bachelor']
            } else {
                return ['bachelor']
            }
        },
    },
}
</script>
