<template>
    <div class="product-pay">
        <div class="container">
            <section class="section">
                <div v-if="this.product.name === '미진행 수수료'" class="pay-desc">
                    <div class="pay-desc-title m-t-15 m-b-15">
                        약속 미진행 수수료 <br />{{ productPrice }}만원을 납부해주세요.
                    </div>
                </div>
                <div v-else class="pay-desc">
                    <div class="pay-desc-title m-t-15 m-b-15">
                        취소 페널티 수수료 <br />{{ productPrice }}만원을 납부해주세요.
                    </div>
                </div>

                <div class="cancel-schedule-desc">
                    <div class="cancel-schedule-title m-l-4">
                        <div>취소한 약속</div>
                    </div>
                    <div class="cancel-schedule-content" v-if="this.$route.params.scheduleDate !== null">
                        {{ scheduleDate }}
                    </div>
                    <div class="cancel-schedule-content">'{{ targetName }}' 님과의 약속</div>
                </div>

                <div v-if="this.product.name === '미진행 수수료'" class="pay-desc m-b-20">
                    <div class="waive-desc">
                        약속을 잡을 수 없는 부득이한 사유
                        <span class="link-style c-red f-bold" @click="onClickFeeInformation">(자세히 보기)</span>
                        에 해당하는 경우 ,증빙 자료 제출을 통해 수수료를 면제해드립니다.
                    </div>
                </div>
                <div v-else class="pay-desc m-b-20">
                    <div class="waive-desc">
                        약속 취소가 불가피했던 사유
                        <span class="link-style c-red f-bold" @click="onClickFeeInformation">(자세히 보기)</span>
                        에 해당하는 경우, 증빙 자료 제출을 통해 수수료를 면제해드립니다.
                    </div>
                </div>

                <span @click="onClickWaive()" class="request-Waive">수수료 면제 요청하기 ></span>
            </section>
            <!-- <SelectPayMethod @select="clickPayMethod" /> -->
            <div id="payment-method" class="m-t-20"></div>
            <!-- 이용약관 UI -->
            <div id="agreement"></div>
        </div>
        <div class="purchase-btn">
            <button class="bottom-btn f-18" @click="clickPay" v-html="label" />
        </div>
    </div>
</template>

<script>
import { loadPaymentWidget } from '@tosspayments/payment-widget-sdk'
import { nanoid } from 'nanoid'
import productService from '@/services/product'
// import orderService from '@/services/order'

export default {
    name: 'PaymentCancelFeePage',
    data: () => ({
        order: null,
        selected: null,
        paymentWidget: null,
        paymentMethodWidget: null,
        // TODO: clientKey는 개발자센터의 결제위젯 연동 키 > 클라이언트 키로 바꾸세요.
        // TODO: customerKey는 구매자와 1:1 관계로 무작위한 고유값을 생성하세요.
        clientKey: null,
        customerKey: null,
    }),
    async mounted() {
        window.addEventListener('message', this.onMessageFromRN)
        document.addEventListener('message', this.onMessageFromRN)

        // ------  결제위젯 초기화 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
        this.clientKey = process.env.VUE_APP_TOSS_PAYMENTS_CLIENT_KEY
        this.customerKey = this.$store.getters.me.shorten
        console.log(this.customerKey)
        this.paymentWidget = await loadPaymentWidget(this.clientKey, this.customerKey, {
            brandpay: {
                // Access Token 발급에 사용되는 리다이렉트 URL
                redirectUrl: process.env.VUE_APP_API_URL + 'v2/users/toss_payments_auth',
            },
        })
        // ------  결제 UI 렌더링 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
        this.paymentMethodWidget = await this.paymentWidget.renderPaymentMethods(
            '#payment-method',
            { value: this.product.selling_price },
            { variantKey: 'widgetA' }
        )
        // ------  이용약관 UI 렌더링 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
        this.paymentWidget.renderAgreement('#agreement', { variantKey: 'AGREEMENT' })

        this.paymentMethodWidget.on('ready', () => {
            this.inputEnabled = true
        })
    },
    beforeDestroy() {
        window.removeEventListener('message', this.onMessageFromRN)
        document.removeEventListener('message', this.onMessageFromRN)
    },
    computed: {
        // selectedProduct() {
        //     return this.$store.getters.selectedProduct
        // },
        // price() {
        //     return this.$store.getters.price
        // },
        // selectedCoupon() {
        //     return this.$store.getters.selectedCoupon
        // },
        // selectedPoint() {
        //     return this.$store.getters.selectedPoint
        // },
        // category() {
        //     return this.$store.getters.selectedPlan
        // },
        label() {
            return `수수료 납부하기`
        },
        isPostPaid() {
            //  후불제일때 product_id===42 로 구별하는데 조건 더 필요
            return (this.order || {}).product_id === 42
        },
        productPrice() {
            return this.product.selling_price / 10000 || this.product[0].selling_price / 10000
        },
        scheduleDate() {
            const date = new Date(this.$route.params.scheduleDate)

            let month = date.getMonth() + 1
            let day = date.getDate()
            const year = Number(date.getFullYear())

            month = month < 10 ? '0' + month : month
            day = day < 10 ? '0' + day : day

            const formattedDate = `${year}년 ${month}월 ${day}일`

            return formattedDate
        },
        targetName() {
            return this.$route.params.targetName[0] + 'OO'
        },
        scheduleDateConfirmed() {
            const dateConfirmed = this.content.date_confirmed
            return dateConfirmed
        },
        product() {
            if (this.$route.params.product && this.$route.params.product.id) {
                return this.$route.params.product
            } else {
                let matchingProduct = null
                console.log('length: ', this.$route.params.length > 0)
                if (this.$route.params.length > 0) {
                    matchingProduct = this.$store.getters.products.penalty.find(product => {
                        return product.selling_price === this.$route.params.product.penalty_price
                    })
                }
                return matchingProduct
            }
        },
    },
    methods: {
        onMessageFromRN(event) {
            const data = this.$mustParse(event.data)
            if (!data || !data.action) return

            if (data.action === 'orderComplete') {
                this.$loading(true)
                // setTimeout(() => {
                //     this.checkOrder()
                // }, 1000)
            }
        },
        sendAirbiridgeEvents() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `Complete_Payment`,
                },
            })
        },
        // async checkOrder() {
        //     if (!this.order) return

        //     try {
        //         const { msg } = await orderService.get(this.order.id)
        //         await this.$store.dispatch('loadMe')
        //         await this.$store.dispatch('loadChats', true)
        //         this.$loading(false)

        //         localStorage.setItem('check-expire-notice', false)
        //         localStorage.setItem('order', JSON.stringify(this.order))
        //         setTimeout(() => {
        //             this.$router.push({
        //                 name: 'CompletePaymentPage',
        //             })
        //             this.sendAirbiridgeEvents()
        //         }, 2000)
        //         this.$router.push({
        //             name: 'PaymentLoadingPage',
        //         })
        //         console.log(msg)
        //     } catch (e) {
        //         this.$toast.error(e.data.msg)
        //     }
        // },
        clickPayMethod(id) {
            this.selected = id
        },
        clickPay() {
            try {
                if (this.paymentWidget) {
                    const payload = {
                        payment_type: 'toss_payments',
                        name: this.$store.getters.me.name,
                        phone_number: this.$store.getters.me.phone_number,
                    }
                    // if (this.selectedCoupon) {
                    //     this.paymentMethodWidget.updateAmount(selling_price))
                    // }
                    productService.createOrder(this.product.id, payload).then(async ({ order, checkout_page }) => {
                        this.order = order
                        const orderId = nanoid()
                        // this.$nativeBridge.postMessage({
                        //     action: 'pgPurchase',
                        //     value: checkout_page,
                        // })
                        localStorage.setItem('check-expire-notice', false)
                        localStorage.setItem('order', JSON.stringify(this.order))
                        localStorage.setItem('orderId', orderId)

                        // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
                        // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
                        // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
                        // @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
                        await this.paymentWidget.requestPayment({
                            orderId: orderId,
                            orderName: this.product.name,
                            customerName: this.$store.getters.me.name,
                            customerEmail: this.$store.getters.me.email,
                            customerMobilePhone: this.$store.getters.me.phone_number,
                            successUrl: `${window.location.origin}${
                                this.$router.resolve({ path: '/pre-payment/loading', query: { status: 'success' } })
                                    .route.fullPath
                            }`,
                            failUrl: `${window.location.origin}${
                                this.$router.resolve({ path: '/chats' }).route.fullPath
                            }`,
                        })
                    })
                }
            } catch (e) {
                console.error(e)
                this.$toast.error(e.data)
            }
        },
        async onClickWaive() {
            this.$stackRouter.clear()
            this.$stackRouter.push({ name: 'WaiveRequestPage', props: { product: this.$route.params.product } })
        },
        // onClickCheck() {
        //     this.checked = !this.checked
        // },
        onClickFeeInformation() {
            this.$stackRouter.clear()
            this.$stackRouter.push({
                name: 'FeeInformationPage',
            })
        },
    },
}
</script>

<style scoped lang="scss">
.product-pay {
    .section {
        // display: flex;
        // justify-content: center;
        .pay-desc {
            display: flex;
            flex-direction: column;
            color: #7c7c7c;
            // justify-content: center;
            // margin: auto;
            .pay-desc-title {
                color: #000;
                display: flex;
                align-items: center;
                text-align: center;
                font-size: 20px;
                margin-bottom: 10px;
                @include spoqa-f-medium;
                margin: auto;
            }
        }

        .waive-desc {
            font-size: 15px;
            font-weight: 500;
            line-height: 19.5px;

            .link-style {
                text-decoration: underline;
            }
        }

        .request-Waive {
            margin-top: 20px;
            color: #5c5c77;
            text-decoration: underline;
        }
    }
    .container {
        height: calc(100% - 80px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 16px 100px 16px;

        .refund {
            .refund-title {
                margin-top: 56px;
                color: #666b70;
                font-weight: 400;
            }
            .refund-content {
                color: #94999e;
                font-weight: 500;
                line-height: 23.4px;

                .refund-highlight {
                    color: #7b7b7b;
                    font-weight: 600;
                }
            }
        }

        .cancel-schedule-desc {
            margin: 9px 0 24px;
            padding: 8px 12px;
            width: 100%;
            background: #ffffff;
            border-radius: 12px;
            .cancel-schedule-title {
                margin: 9px 0 0;
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 17px;
                line-height: 24px;
                color: #03006e;
            }
            .cancel-schedule-content {
                color: #5c5c77;
                margin: 8px 4px;
                font-weight: 400;
                font-size: 15px;
                line-height: 21px;
            }
        }
    }
    ::v-deep.title {
        font-size: 16px;
        line-height: 26px;
        color: black;
        margin-bottom: 8px;
    }

    .grey-box {
        margin-top: 8px;
        background: #f8eee4;
        padding: 12px;
        font-size: 16px;
        line-height: 26px;
        border-radius: 12px;
        display: flex;
        align-items: center;

        .img-container {
            width: 64px;
            color: $grey-04;
            margin-right: 8px;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .purchase-btn {
        margin: 16px 20px;
        height: 48px;
        .bottom-btn {
            width: 100%;
            height: 100%;
            border: none;
            background: #151360;
            border-radius: 36px;
            color: #f7f7f4;
        }
    }

    .check {
        margin-top: 30px;
        text-align: center;
        @include center;
    }
}
</style>
