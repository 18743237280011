import productService from '@/services/product'
import { store as $store } from '@/store'
import Vue from 'vue'

export const parsePidToPlanName = (pid, upper = true) => {
    const products = $store.getters.products
    const result = products.prepaid.some(item => item.id === pid) ? 'prepaid' : 'unlimited'
    return upper ? result.toUpperCase() : result
}

export const getPlanInfo = (planName, pid) => {
    const plans = $store.getters.products[planName.toLowerCase()]
    const res = plans.find(item => item.id === pid)

    return res || $store.getters.products.all.find(item => item.id === pid)
}

export const getPlanNameById = pid => {
    const plans = $store.getters.products.all
    return plans.find(item => item.id === pid)
}

const defaultState = () => ({
    products: null,
    selectedProduct: null,
    plans: [
        {
            id: 1,
            category: 'prepaid',
            desc: '구매한 횟수만큼 매칭(만남)을 가질 수 있는 상품입니다.',
            rate: 4.7,
            users: 2245,
            discount: 33,
            selling_price: 44444,
            img: require('@/assets/images/product-image/prepaid.png'),
            imgTagged: require('@/assets/images/product-image/prepaid-tagged.png'),
        },
        {
            id: 2,
            category: 'unlimited',
            desc: '정해진 기간동안 매칭(만남)횟수 제한없이 사용할 수 있는 상품입니다.',
            rate: 4.5,
            users: 1248,
            discount: 57,
            selling_price: 33333,
            img: require('@/assets/images/product-image/unlimited.png'),
            imgTagged: require('@/assets/images/product-image/unlimited-tagged.png'),
        },
    ],
    selectedPlan: null,
    selectedCoupon: null,
    selectedPoint: 0,
    userProducts: null,
    userRatePlans: null,
    orderedProduct: null,
    inAppHistory: null,
})

const state = defaultState()

const getters = {
    products: state => state.products,
    selectedProduct: state => state.selectedProduct,
    price: state => {
        const selectedProduct = state.selectedProduct
        const selectedCoupon = state.selectedCoupon
        const selectedPoint = state.selectedPoint
        console.log(
            '66 : ',
            selectedProduct,
            selectedPoint,
            selectedProduct.selling_price,
            selectedProduct.selling_price - selectedPoint
        )
        if (!selectedCoupon) {
            return {
                originalPrice: (selectedProduct.original_price * (10 / 11)).toLocaleString(),
                sellingPrice: Math.round(
                    ((selectedProduct.original_price * (10 / 11) - selectedProduct.discount - selectedPoint) * 11) / 10
                ).toLocaleString(),
                // price: (selectedProduct.price - selectedPoint).toLocaleString(),
                tax: Math.round(
                    (selectedProduct.original_price * (10 / 11) - selectedProduct.discount - selectedPoint) / 10
                ).toLocaleString(),

                priceExcludeTax: (((selectedProduct.selling_price - selectedPoint) / 11) * 10).toLocaleString(),
                eventDiscount: selectedProduct.discount.toLocaleString(),
            }
        } else {
            if (selectedCoupon.discount.discount_type === 'percent') {
                const price =
                    (selectedProduct.selling_price / 11) * 10 * ((100 - selectedCoupon.discount.amount) / 100) -
                    selectedPoint

                const couponDiscount =
                    (selectedProduct.selling_price / 11) * 10 * (selectedCoupon.discount.amount / 100)
                return {
                    originalPrice: (selectedProduct.original_price * (10 / 11)).toLocaleString(),
                    sellingPrice: Math.round(price + price / 10).toLocaleString(),
                    tax: Math.round(price / 10).toLocaleString(),
                    couponDiscount: couponDiscount.toLocaleString(),
                    eventDiscount: (
                        (selectedProduct.original_price / 11) * 10 -
                        (selectedProduct.selling_price / 11) * 10
                    ).toLocaleString(),
                }
            } else if (selectedCoupon.discount.discount_type === 'quantity') {
                const price = (selectedProduct.selling_price / 11) * 10 - selectedCoupon.discount.amount - selectedPoint

                const couponDiscount = selectedCoupon.discount.amount
                return {
                    originalPrice: (selectedProduct.original_price * (10 / 11)).toLocaleString(),
                    sellingPrice: Math.round(price + price / 10).toLocaleString(),
                    tax: Math.round(price / 10).toLocaleString(),
                    couponDiscount: couponDiscount.toLocaleString(),
                    eventDiscount: (
                        (selectedProduct.original_price / 11) * 10 -
                        (selectedProduct.selling_price / 11) * 10
                    ).toLocaleString(),
                }
            } else if (selectedCoupon.discount.discount_type === 'fix_price') {
                const price = selectedCoupon.discount.amount

                const couponDiscount =
                    (selectedProduct.selling_price / 11) * 10 - selectedCoupon.discount.amount - selectedPoint
                return {
                    originalPrice: (selectedProduct.original_price * (10 / 11)).toLocaleString(),
                    sellingPrice: Math.round(price + price / 10).toLocaleString(),
                    tax: Math.round(price / 10).toLocaleString(),
                    couponDiscount: couponDiscount.toLocaleString(),
                    eventDiscount: (
                        (selectedProduct.original_price / 11) * 10 -
                        (selectedProduct.selling_price / 11) * 10
                    ).toLocaleString(),
                }
            }
        }
    },
    plans: state => state.plans,
    selectedPlan: state => state.selectedPlan,
    selectedCoupon: state => state.selectedCoupon,
    selectedPoint: state => state.selectedPoint,
    userProducts: state => state.userProducts,
    userRatePlans: state => {
        const userProducts = state.userProducts
        if (!userProducts) return false

        const ratePlans = userProducts.products.filter(item => item.ptype === 'rate_plan')
        const subscriptionPlans = userProducts.products.filter(item => item.ptype === 'subscription_plan')
        const ticketPlans = userProducts.products.filter(item => item.ptype === 'ticket_plan')
        const tickets = $store.getters.me.tickets
        const m = Vue.prototype.$moment
        const allPlans = [...ratePlans, ...subscriptionPlans]
        return {
            introduceDay: userProducts.introduceDay,
            onGoing: subscriptionPlans.filter(item => {
                if (!item.enabled) return false
                const dateValid = item.valid_from && m().isBefore(m(item.valid_until))
                const ticketsValid = tickets.filter(t => {
                    return item.id === t.related_user_product_id
                })
                const plan = parsePidToPlanName(item.product_id)
                if (!dateValid) return false

                return plan === 'UNLIMITED' ? true : ticketsValid.length
            }),
            onGoingTicketPlan: [...ticketPlans, ...ratePlans],
            expired: allPlans.filter(item => {
                if (!item.enabled) return true
                const dateExpired = item.valid_from && m().isAfter(m(item.valid_until))
                // const ticketsValid = tickets.filter(t => {
                //     return item.id === t.related_user_product_id
                // })
                const ticketsValid = tickets.filter(t => t.enabled)
                const plan = parsePidToPlanName(item.product_id)
                if (dateExpired) return true

                return plan === 'UNLIMITED' ? false : ticketsValid.length === 0
            }),
            pending: allPlans.filter(item => !item.valid_from && !item.valid_until),
        }
    },
    validTickets: (state, getters) => {
        const userRatePlans = getters.userRatePlans
        // const userProducts = state.userProducts.products
        const tickets = $store.getters.me.tickets
        // const expireLatest = userRatePlans.expired.length
        //     ? userRatePlans.expired[userRatePlans.expired.length - 1]
        //     : false

        if (userRatePlans.onGoingTicketPlan.length) {
            // return $store.getters.me.tickets.filter(
            //     item => item.related_user_product_id === userRatePlans.onGoingTicketPlan[0].id
            // )
            // } else if (userRatePlans.onGoing.length) {
            //     return tickets
            // } else if (userRatePlans.pending.length) {
            //     const pendingLatest = userRatePlans.pending[userRatePlans.pending.length - 1]
            //     return userProducts.filter(
            //         item =>
            //             item.ptype === 'ticket' &&
            //             item.related_user_product_id === pendingLatest.id &&
            //             item.product_id !== 11
            //     )
            // } else if (expireLatest) {
            //     return userProducts.filter(
            //         item =>
            //             item.ptype === 'ticket' &&
            //             item.related_user_product_id === expireLatest.id &&
            //             item.product_id !== 11
            //     )
            const validTickets = tickets.filter(t => t.ticket_type !== 'manner_ticket' && t.enabled)
            return validTickets
        } else {
            return []
        }
    },
    mannerTickets: state => {
        const tickets = $store.getters.me.tickets

        return tickets.filter(item => item.ticket_type === 'manner_ticket')
    },
}

const mutations = {
    setProducts(state, value) {
        state.products = {
            all: [...value.rate_plan, ...value.ticket_plan, ...value.subscription_plan, ...value.package_plan],
            unlimited: value.rate_plan.filter(item => item.code.includes('unlimited_') || item.amount === 0),
            prepaid: value.rate_plan.filter(item => item.code.includes('prepaid_') || item.amount > 0),
            ticket: value.ticket.filter(item => item.ptype === 'ticket'),
            ticket_plan: value.ticket_plan,
            subscription_plan: value.subscription_plan,
            package_plan: value.package_plan,
            penalty: value.penalty,
        }
    },
    setSelectedProduct(state, value) {
        state.selectedProduct = value
    },
    setSelectedPlan(state, value) {
        state.selectedPlan = value
    },
    setSelectedCoupon(state, value) {
        state.selectedCoupon = value
    },
    setSelectedPoint(state, value) {
        state.selectedPoint = value
    },
    setUserProducts(state, value) {
        state.userProducts = value
    },
    setOrderedProduct(state, product) {
        if (!product) return
        window.localStorage.setItem('lastOrderedProduct', JSON.stringify(product))
        state.orderedProduct = product
    },
    removeOrderedProduct(state) {
        window.localStorage.removeItem('lastOrderedProduct')
        state.orderedProduct = null
    },
    setInAppHistory(state, ih) {
        state.inAppHistory = ih
    },
}

const actions = {
    requestInAppPurchase({ commit }, product) {
        commit('setOrderedProduct', product)

        Vue.prototype.$loading(true)
        Vue.prototype.$nativeBridge.postMessage({
            action: 'inAppPurchase',
            value: product,
        })
    },
    async purchaseInAppProduct({ state, commit }, data) {
        try {
            const { platform, receipt, purchase } = data

            let payload = { receipt }

            if (platform === 'ios') {
                const { transactionId, productId } = purchase
                payload = { ...payload, transactionId, productId }
            } else if (platform === 'android') {
                const { signatureAndroid: signature, productId } = purchase
                payload = { ...payload, signature, productId }
            }

            let product = state.orderedProduct

            if (!product) {
                product = JSON.parse(window.localStorage.getItem('lastOrderedProduct'))
            }

            const ih = await productService.inAppPurchase(product.id, payload)
            commit('setInAppHistory', ih)

            Vue.prototype.$nativeBridge.postMessage({
                action: 'confirmInAppPurchase',
                value: purchase,
            })
        } catch (e) {
            return e
        }
    },
    async finishInAppPurchase({ state, dispatch, commit }) {
        try {
            const product =
                state.orderedProduct || Vue.prototype.$mustParse(window.localStorage.getItem('lastOrderedProduct'))
            const ih = state.inAppHistory

            const { msg } = await productService.inAppConfirm(product.id, ih)

            Vue.prototype.$toast.success(msg)
            dispatch('loadBadges')
            dispatch('loadMe')
            commit('removeOrderedProduct')
            commit('setInAppHistory', null)
            Vue.prototype.$loading(false)
        } catch (e) {
            console.error(e)
        }
    },
    async getProducts({ commit }) {
        // const { rate_plan } = await productService.all()
        const products = await productService.all()

        commit('setProducts', products)
    },
}
export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
