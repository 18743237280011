import * as $http from 'axios'

export default {
    recommendation: {
        all: () => $http.get('v2/card_recommendations'),
        loadMore: (type) => $http.get(`v2/card_recommendations/load_more?type=${type}`),
        increaseSeenCount: (cardRecommendationId) => $http.put(`v2/card_recommendations/${cardRecommendationId}`),
        obtainableVanilla: () => $http.get('v2/card_recommendations/point'),
    },
    interest: {
        findRating: (userId, sourceId) => $http.get(`v2/card_interests/find_rating?user_id=${userId}&source_id=${sourceId}`),
        create: (userId, sourceId, rating) => $http.post('v2/card_interests', { user_id: userId, source_id: sourceId, rating }),
        update: (cardRecommendationId, rating) => $http.post(`v2/card_recommendations/${cardRecommendationId}/card_interests`, { rating }),
    },
}
