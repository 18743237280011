<template>
    <div class="my-page" @scroll="onScroll">
        <div class="top-border" v-if="scrollTop !== 0" />
        <div class="padding" />
        <div class="my-page-basic-profile p-l-0" @click="onClickMyProfile">
            <div class="contents-wrapper">
                <div class="img-wrapper">
                    <div class="img-profile" v-if="mainPhoto" v-img-cover="$store.getters.mainPhoto">
                        <i class="material-icons">search</i>
                    </div>
                    <div class="alter-profile" v-else>
                        <img :src="alterPhoto" alt="" />
                        <i class="material-icons">search</i>
                    </div>
                </div>
                <div class="name-wrapper m-l-14">
                    <div v-if="me.name" class="name-nickname">
                        <span class="name" v-html="`${me.name} 님`" />
                        <span v-if="isTest">({{ me.id }})</span>
                        <!-- <i class="material-icons f-18 c-black">edit</i> -->
                        <img
                            v-if="me.is_privacy"
                            class="super-privacy-icon"
                            :src="require(`@/assets/images/super-privacy/security.svg`)"
                            width="20px"
                            height="20px"
                            @click="onClickSuperPrivacy"
                        />
                    </div>
                    <div class="nickname" v-html="`프로필엔 '${me.profile.nickname}'님으로 표시됩니다.`" />
                </div>
            </div>
        </div>

        <MyTicketMenu v-if="!$isTester() && profile.status === 'confirmed'" :stepToast="currentStepToastMessage" />
        <InAppStoreMenu :stepToast="currentStepToastMessage" />
        <EditProfileMenu :stepToast="currentStepToastMessage" />
        <EditFavorite v-if="!$isTester()" :stepToast="currentStepToastMessage" />
        <FeeInformation v-if="!$isTester()" :stepToast="currentStepToastMessage" />
        <MyInfoMenu :stepToast="currentStepToastMessage" />
        <MyPageMenu :stepToast="currentStepToastMessage" />
    </div>
</template>

<script>
import MyPageMenu from './components/MyPageMenu'
import InAppStoreMenu from './components/InAppStoreMenu'
import MyTicketMenu from './components/MyTicketMenu'
import EditProfileMenu from './components/EditProfileMenu'
import FeeInformation from './components/FeeInformation'
import EditFavorite from './components/EditFavorite'
import MyInfoMenu from './components/MyInfoMenu'
import { getPlanNameById } from '@/store/data/product'

export default {
    name: 'MyPage',
    components: {
        MyPageMenu,
        InAppStoreMenu,
        EditProfileMenu,
        FeeInformation,
        MyTicketMenu,
        EditFavorite,
        MyInfoMenu,
    },
    data: () => ({
        cta: `프로필 인증하면 <span class='f-bold'>바닐라 최대 250개</span>`,
        showVerificationBanner: false,
        scrollTop: 0,
        isPrivacy: false,
    }),
    computed: {
        me() {
            return this.$store.getters.me || { profile: { nickname: null } }
        },
        device() {
            return this.$store.getters.device || {}
        },
        mainPhoto() {
            return this.$store.getters.mainPhoto || false
        },
        alterPhoto() {
            return require('@/assets/images/person.png')
        },
        profile() {
            return (this.$store.getters.me || {}).profile
        },
        needEssentialProfile() {
            return this.$store.getters.submitAllRequired === false
        },
        profileConfirmed() {
            return (this.me.profile || {}).status !== 'created'
        },
        isVerifiedUser() {
            return (this.$store.getters.myVerificationBadges || []).some(badge => badge.name === '실명인증')
        },
        gender() {
            return this.me.gender
        },
        isTest() {
            return process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development'
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans
        },
        userPlansList() {
            const plansList = []
            if (
                (this.userRatePlans.expired || {}).length &&
                !this.userRatePlans.onGoing &&
                !(this.userRatePlans.pending || {}).length
            ) {
                const recentExpired = this.userRatePlans.expired.reduce(function (prev, current) {
                    const prevDate = new Date(prev.valid_until)
                    const currentDate = new Date(current.valid_until)
                    return prevDate > currentDate ? prev : current
                })
                const expired = getPlanNameById(recentExpired.product_id)
                Object.assign(expired, { status: 'expired' })
                plansList.push(expired)
            }

            if (this.userRatePlans.onGoing) {
                const onGoing = getPlanNameById(this.userRatePlans.onGoing.product_id)
                Object.assign(onGoing, { status: 'in_use' })
                plansList.push(onGoing)
            }
            if ((this.userRatePlans.pending || {}).length) {
                const pending = this.userRatePlans.pending.map(plan => {
                    return {
                        ...getPlanNameById(plan.product_id),
                        status: 'intent_to_use',
                    }
                })
                plansList.push(...pending)
            }
            return plansList
        },
        certiSubmitted() {
            const requiredVeri = [13, 14, 15]
            const hasAllCerties = requiredVeri.every(r => {
                return (this.$store.getters.myVerificationBadges || []).some(b => b.verification_badge_id === r)
            })
            return hasAllCerties
        },
        currentStepToastMessage() {
            // return {step : 2, msg : '먼저 계약서를 작성해주세요'}
            // if (!this.me.user_contract) return false
            // if (!this.me.user_contract.is_complete)
            //     return { step: 1, msg: '먼저 계약서를 작성해주세요', title: 'contract' }
            // if (
            //     (((this.userContract || {}).plan_type === 'prepaid' && (this.userContract || {}).version >= 3) ||
            //         (this.userContract || {}).plan_type === 'postpaid') &&
            //     !this.me.user_contract.truth_complete
            // )
            //     return { step: 2, msg: '먼저 진실성 담보 약정서를 작성해주세요', title: 'truth_contract' }
            // else if (this.profile.status === 'created')
            //     return { step: 3, msg: '먼저 프로필을 작성해주세요', title: 'profile' }
            // else if (!this.certiSubmitted) return { step: 4, msg: '먼저 서류를 제출해주세요', title: 'certi' }
            // else return false
            return true
        },
    },
    created() {
        this.loadMe()
        if (!this.$store.getters.verificationBadges) {
            this.$store.dispatch('loadVerificationBadges')
            this.$store.dispatch('loadMyVerificationBadges')
        }
    },
    mounted() {
        this.init()
        this.$bus.$emit('setRootHeader', '마이페이지')
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#FAF0E1',
                bottom: '#FFFCF6',
            },
        })
    },
    methods: {
        async onClickMyProfile() {
            if (!this.currentStepToastMessage && this.needEssentialProfile) {
                const idx = await this.$modal.basic({
                    body: '먼저 필수 프로필을 입력해 주세요',
                    buttons: [
                        {
                            label: '취소',
                            class: 'btn-default',
                        },
                        {
                            label: '입력하기',
                            class: 'btn-primary',
                        },
                    ],
                })
                if (idx) this.$goProfileRegister()
                return
            }

            if (this.currentStepToastMessage && this.currentStepToastMessage.step <= 2) {
                this.$toast.success(this.currentStepToastMessage.msg)
                return
            }

            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    userId: this.me.id,
                },
            })
        },
        async onClickSuperPrivacy(event) {
            event.stopPropagation()

            this.$stackRouter.push({
                name: 'SuperPrivacyIntroductionPage',
            })
        },
        init() {
            if (this.me.is_privacy !== undefined) {
                this.isPrivacy = this.me.is_privacy
            }
        },
        async onScroll({ target }) {
            this.scrollTop = target.scrollTop
        },
        async loadMe() {
            await this.$store.dispatch('loadMe')
        },
    },
    beforeDestroy() {
        this.$bus.$emit('setRootHeader', '')
        // this.$nativeBridge.postMessage({
        //     action: 'setBackgroundColor',
        //     value: '#FFF9EF',
        // })
    },
}
</script>

<style lang="scss" scoped>
.my-page {
    .padding {
        height: 8px;
    }

    .top-border {
        position: absolute;
        top: 48px;
        height: 1px;
        width: 100vw;
        background-color: $grey-02;
    }

    .my-page-basic-profile {
        // @include center;
        // align-items: center;
        margin: 0 20px;
        padding: 10px 16px 17px;

        .contents-wrapper {
            display: flex;
            .img-wrapper {
                // display: flex;
                width: fit-content;
                // justify-content: center;
                // position: relative;

                .img-profile {
                    width: 67px;
                    height: 67px;
                    border: solid 1px white;
                    border-radius: 8px;
                    position: relative;
                }

                .alter-profile {
                    @extend .img-profile;
                    background: white;
                    @include center;
                    border-radius: 8px;
                    padding: 22px;
                    position: relative;

                    img {
                        width: 28px;
                        height: 28px;
                    }
                }

                .material-icons {
                    position: absolute;
                    // border: solid 2px $grey-01;
                    border-radius: 50%;
                    background-color: #151360;
                    color: white;
                    font-size: 18px;
                    padding: 1px;
                    bottom: 0;
                    right: 0;
                }
            }

            .name-nickname {
                margin-top: 13px;
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                // justify-content: center;
            }

            .name {
                color: black;
                font-size: 18px;
                font-weight: 600;

                // @include f-medium;
            }

            .nickname {
                // margin-left: 4px;
                font-size: 12px;
                color: #5c5c77;

                @include f-demilight;
            }

            .age-height-gender {
                font-size: 15px;
                color: $grey-09;

                ::v-deep span {
                    color: $grey-04;
                }
            }
        }
    }
}
</style>
