<template>
    <div class="signup basics asset">
        <ProgressBar :step="$case.toSnake('income')" />
        <div class="content">
            <div class="category m-b-6">Financial</div>
            <div class="title m-b-16">연소득</div>
            <div class="guide">
                <div class="text flex-row" v-for="i in [1, 2]" :key="i">
                    <p class="dot m-r-8 m-b-3" />
                    <span v-html="$translate(`SIGNUP_INCOME_GUIDE_${i}`)" />
                </div>
            </div>
            <MoneyInput
                :initVal="initVal"
                @input="onChangeVal"
                @focus="needAddInfo = true"
                @blur="needAddInfo = false"
            />
        </div>
        <BottomButton
            :disabled="!value"
            :label="$translate('NEXT')"
            @click="next"
            :needAddInfo="needAddInfo || value"
        />
    </div>
</template>

<script>
export default {
    name: 'IncomePage',
    data: () => ({
        initVal: 0,
        value: 0,
        needAddInfo: false,
    }),
    computed: {
        signupData() {
            const signupData = window.localStorage.getItem('signupData') || '{}'
            return JSON.parse(signupData)
        },
    },
    created() {
        if (this.signupData.income) this.initVal = this.signupData.income
    },
    methods: {
        onChangeVal(val) {
            this.value = val
        },
        next() {
            const signupData = this.signupData
            signupData.income = Number(this.value)
            window.localStorage.setItem('signupData', JSON.stringify(signupData))
            this.$router.push({ name: 'AssetPage' })
        },
    },
}
</script>
