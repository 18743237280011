<template>
    <div
        ref="message"
        class="message-text"
        :class="{ 'mouse-down': mouseDown }"
        @mousedown="onMouseDown"
        @mouseup="onMouseUp"
        v-html="msg"
    ></div>
</template>
<script>
export default {
    name: 'MessageText',
    props: ['message'],
    data: () => ({
        mouseDown: false,
    }),
    computed: {
        msg() {
            if (!this.isVariationTester) return (this.message.content || '').trim()

            return (this.message.content || '').trim()
        },
        me() {
            return this.$store.getters.me
        },
    },
    mounted() {
        this.initTouchEvents()
    },
    beforeDestroy() {
        this.removeTouchEvents()
    },
    methods: {
        initTouchEvents() {
            const message = this.$refs.message
            message.addEventListener('touchstart', this.onMouseDown)
            message.addEventListener('touchend', this.onMouseUp)
            message.addEventListener('mouseleave', this.onMouseUp)
        },
        removeTouchEvents() {
            const message = this.$refs.message
            message.removeEventListener('touchstart', this.onMouseDown)
            message.removeEventListener('touchend', this.onMouseUp)
            message.removeEventListener('mouseleave', this.onMouseUp)
        },
        onMouseDown() {
            this.copyMessage(this.$refs.message.innerHTML)
        },
        onMouseUp() {
            this.cancelCopyAction()
        },
        copyMessage(message) {
            this.copyAction = setTimeout(() => {
                this.$copyText(message)
                this.$toast.success('메시지가 복사되었습니다.')
            }, 700)
        },
        cancelCopyAction() {
            clearTimeout(this.copyAction)
        },
    },
}
</script>

<style lang="scss" scoped>
.premium-me {
    background-image: $yellow-premium-grad !important;
    color: black !important;
}
.premium-agent {
    background: $brand-premium-blue !important;
    color: white !important;
}
</style>
