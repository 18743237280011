<template>
    <div class="complete-payment">
        <div class="header">
            <div class="text-center">
                <div class="img-container">
                    <img width="48px" height="48px" :src="require('@/assets/images/icons/complete.png')" alt="" />
                </div>
                <div class="h3">결제가 완료되었습니다!</div>
            </div>
            <div class="title">결제 {{ order.ptype === 'penalty' ? '항목' : '상품' }}</div>
            <div class="grey-box">
                <span>{{ order.product_name }}</span>
            </div>
        </div>
        <main class="main">
            <!-- <div class="add-info m-b-40">
                <div class="title f-16">안내사항</div>
                <div class="content">
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">궁금한 점이 있으시면 앱 내 고객센터로 문의해주세요</div>
                    </div>
                </div>
            </div> -->
            <!-- <BottomButton label="홈으로 가기" @click="onClickBtn" /> -->
            <div class="purchase-btn m-4 m-b-12">
                <button class="bottom-btn f-18 p-14" @click="onClickBtn">홈으로 가기</button>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: 'CompletePaymentPage',
    computed: {
        order() {
            return JSON.parse(localStorage.getItem('order'))
        },
    },
    methods: {
        onClickBtn() {
            if (['subscription_plan', 'package_plan'].includes(this.order.ptype)) {
                this.$router.push({ name: 'CompleteMembershipPaymentPage' })
            } else {
                this.$router.push({ name: 'ChatroomPage' })
            }
        },
    },
    mounted() {},
}
</script>

<style scoped lang="scss">
.complete-payment {
    .photo-container {
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }
    .main {
        padding: 0 16px 16px 16px;
        overflow-y: auto;
    }
    h2 {
        font-size: 24px;
        line-height: 36px;
        margin: 16px 0 40px 0;
    }
    .title {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 8px;
    }
    .item {
        display: flex;
        font-size: 14px;
        margin-bottom: 6px;
        line-height: 24px;

        .key {
            width: 72px;
            color: $grey-06;

            &.dotted {
                width: fit-content;
                margin-right: 4px;
                color: #111111;
            }
        }
        .value {
            font-size: 16px;
            line-height: 26px;
        }
    }
    .header {
        padding: 16px 16px 32px 16px;
        .text-center {
            .img-container {
                @include center;
            }
            .h3 {
                text-align: center;
                font-size: 18px;
                margin: 11px 0 24px 0;
                @include spoqa-f-bold;
            }
        }
    }
    .grey-box {
        background: #f8eee4;
        padding: 12px;
        font-size: 16px;
        line-height: 24px;
        border-radius: 12px;
        display: flex;
        align-items: center;

        .img-container {
            width: 64px;
            color: $grey-04;
            margin-right: 8px;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .purchase-btn {
        bottom: 0px;
        width: calc(100% - 40px);
        // margin: 16px 0;
        // padding: 0 4px;
        // height: 48px;
        position: fixed;
        .bottom-btn {
            width: 100%;
            // height: 100%;
            border: none;
            background: #151360;
            border-radius: 36px;
            color: #f7f7f4;
        }
    }
}
</style>
