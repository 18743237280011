<template>
    <div class="floating-components bg-main">
        <template>
            <div class="instant-room" v-if="isInstantChat">
                <InstantUser />
            </div>
            <SpecialFeats
                v-if="!isInstantChat && planDetail.name.includes('Love Fit') && agentName === '퍼스널 매칭매니저'"
                :chat="chat"
            />
            <!-- <ChatExtendBanner
                v-if="showBanner === 'extend' && chat.agent_id"
                @move-to-productlist="moveToProductList"
            /> -->
            <!-- <ChatRepurchaseBanner
                v-if="showBanner === 'repurchase' && chat.agent_id"
                @move-to-productlist="moveToProductList"
            /> -->
            <UserChatFloating v-if="chat.schedule" @move-to-productlist="moveToProductList" />
        </template>
    </div>
</template>

<script>
import InstantUser from './InstantUser'
import UserChatFloating from '@/routes/chat/chatroom/components/floating-components/UserChatFloating'
import SpecialFeats from '@/routes/chat/chatroom/components/floating-components/SpecialFeats.vue'
// import ChatExtendBanner from '@/routes/chat/chatroom/components/floating-components/ChatExtendBanner'
// import ChatRepurchaseBanner from '@/routes/chat/chatroom/components/floating-components/ChatRepurchaseBanner'
import { getPlanNameById } from '@/store/data/product'

export default {
    name: 'FloatingComponents',
    components: {
        InstantUser,
        SpecialFeats,
        UserChatFloating,
        // ChatExtendBanner,
        // ChatRepurchaseBanner,
    },
    props: {
        chat: {
            type: Object,
            required: true,
        },
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        isPrivacy() {
            if (!this.$store.getters.me) return false

            return this.$isPrivacy()
        },
        isMale() {
            return this.$store.getters.me.gender === 0
        },
        agentName() {
            return this.chat.user.name
        },
        agentId() {
            return this.chat.agent_id
        },
        isInstantChat() {
            return this.$isInstantChat(this.chat)
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans
        },
        myPlan() {
            return this.userRatePlans.onGoing.find(o => o.ptype === 'subscription_plan')
        },
        planDetail() {
            if (this.userRatePlans.onGoing.length > 0) {
                return getPlanNameById(this.myPlan.product_id)
            } else {
                return null
            }
        },
        validTickets() {
            return this.$store.getters.validTickets
        },
        showBanner() {
            if (!(this.userRatePlans.pending || {}).length) {
                if (!this.userRatePlans.onGoing) {
                    // 다시 시작
                    return 'repurchase'
                } else if (
                    this.$moment(this.userRatePlans.onGoing.valid_until).diff(this.$moment(), 'days') <= 7 &&
                    this.$moment(this.userRatePlans.onGoing.valid_until).diff(this.$moment(), 'days') >= 0
                ) {
                    // 갱신하기
                    // 기간제, 횟수제 분기
                    return 'extend'
                    // } else if (
                    //     getPlanNameById(this.userRatePlans.onGoing.product_id).amount >= 2 &&
                    //     this.validTickets.length === 1
                    // ) {
                    //     // 횟수제 1회 남았을 때 갱신하기
                    //     return 'extend'
                } else {
                    return false
                }
            } else {
                return false
            }
        },
    },
    methods: {
        onClickPrivacy() {
            this.$modal.custom({
                component: 'ModalAlertNoX',
                options: {
                    title: 'SUPER_PRIVACY_ON',
                    body: `회원님의 동의가 없으면 누구에게도 프로필이 공개되지 않습니다.`,
                },
            })
        },
        privacyRemainDay() {
            const privacy = this.$store.getters.me.products.filter(p => p.ptype === 'privacy' && p.enabled === true)
            if (privacy === null || privacy === []) return

            const validUntil = privacy[0].valid_until.split('T')[0]

            return this.$moment(validUntil).diff(this.$moment(), 'days')
        },
        moveToProductList() {
            this.$router.push({ name: 'PrePaymentPage' })
        },
    },
}
</script>

<style lang="scss" scoped>
.floating-components {
    background: white;
    position: absolute;
    //top: 42px;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 2px;
    z-index: 3;
}
</style>
