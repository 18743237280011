import Vue from 'vue'
import axios from '@/modules/axios'
import router from '@/router'
import userService from '@/services/user'
import profileService from '@/services/profile'

const transformLoginResponseToHeader = ({ user, device }) => ({
    'X-User-Email': user.email,
    'X-User-Token': user.token,
    'X-User-Role': 'user',
    'X-User-OS': device ? device.os : '',
    'X-User-Agent': device ? JSON.stringify(device) : '',
})

const setHeaderAndRedirectToMain = async ({ getters, commit, dispatch, data, noRedirect }) => {
    if (!data) {
        return Promise.reject(data)
    }

    const header = transformLoginResponseToHeader(data)
    axios.setHeader(header)
    commit('setHeader', header)

    try {
        await dispatch('afterAuthCallbacks')
    } catch (e) {
        Vue.prototype.$toast.error(e.data.msg)
        return Promise.reject(e)
    }

    if (!noRedirect) {
        const { productId } = data.user
        if (productId) localStorage.setItem('product_id', productId)
        router.push({ name: 'HomePage' })
    }
}

const defaultState = () => ({
    me: null,
    profile: null,
    profileUpdateRequests: {},
    requiredKey: [
        'age',
        'height',
        'state',
        'smoking',
        // 'health_status',
        'religion',
        // 'intro',
        'income',
        'asset',
        // 'family_relations',
        // 'parent_status',
        // 'family_asset',
        // 'family',
        'marry',
        'marry_plan',
        // 'child_plan',
        // 'career_plan',
        'company',
    ],
    settings: null,
    badges: null,
    header: JSON.parse(localStorage.getItem('header')) || null,
    notifications: null,
    device: null,
    items: null,
    signupFriends: null,
    blockedCollege: [],
    blockedName: [],
    blockedCompany: [],
    profileStep: 1,
})

const state = defaultState()

const getters = {
    me: state => state.me,
    profile: state => state.profile,
    profileUpdateRequests: state => {
        const pur = state.profileUpdateRequests
        const res = {}
        const exclude = ['id', 'updated_at', 'user_id', 'created_at', 'enabled', 'photos', 'photo_ids', 'confirmed']
        Object.keys(pur).forEach(k => {
            if (!exclude.includes(k) && pur[k] !== null) {
                if (['company', 'family'].includes(k)) res[k] = JSON.parse(pur[k])
                else res[k] = pur[k]
            }
        })
        return res
    },
    submitAllRequired: state => {
        if (!state.profile) return false
        const {
            bachelor_u_name: bName,
            // high_school_name: hName,
            // high_school_start: hStart,
            // high_school_end: hEnd,
            // high_school_status: hStatus,
            // bachelor_c_name: bcName,
            // bachelor_m_name: bmName,
            // bachelor_status: bStatus,
            // bachelor_start: bStart,
            // bachelor_end: bEnd,
        } = state.profile

        const schoolItems = [bName]
        // const schoolYears = [hStart, hEnd, bStart, bEnd]

        const schoolYes = schoolItems.every(i => i !== null && i !== undefined && i !== '')
        // schoolItems.every(i => i !== null && i !== undefined && i !== '') && schoolYears.every(i => i > 0)
        if (!schoolYes) {
            return false
        }
        for (const key of state.requiredKey) {
            // if (key === 'family_relations') {
            //     const { brother_count: bro, sister_count: sis, order_of_children: ooc } = state.profile
            //     if (!(bro !== null && sis !== null && ooc !== null)) return false
            // } else if (key === 'family') {
            //     const { family } = state.profile
            //     if (family.length < 2) return false
            //     else {
            //         const father = family.some(item => item.family_type === 0)
            //         const mother = family.some(item => item.family_type === 1)
            //         if (!(father && mother)) return false
            //     }
            if (key === 'company') {
                const { company } = state.profile
                if (!company.length) return false
            } else if (key === 'religion') {
                if (state.profile[key] === null) {
                    return false
                }
            } else if (key !== 'age') {
                if (state.profile[key] === null || state.profile[key] === undefined || state.profile[key] === '') {
                    return false
                }
            }
        }

        return true
    },
    emptyRequiredField: state => {
        if (!state.profile) return false
        const items = []
        // const { brother_count: bro, sister_count: sis, order_of_children: ooc } = state.profile
        // const siblingYes = bro !== null && sis !== null && ooc !== null ? true : false
        // const siblingCnt = bro + sis - 1
        const {
            bachelor_u_name: bName,
            // high_school_name: hName,
            // high_school_start: hStart,
            // high_school_end: hEnd,
            // high_school_status: hStatus,
            // bachelor_c_name: bcName,
            // bachelor_m_name: bmName,
            // bachelor_status: bStatus,
            // bachelor_start: bStart,
            // bachelor_end: bEnd,
        } = state.profile
        // const schoolItems = [hName, hStatus, bName, bcName, bmName, bStatus]
        // const schoolYears = [hStart, hEnd, bStart, bEnd]
        const schoolItems = [bName]

        const schoolYes = schoolItems.every(i => i !== null && i !== undefined && i !== '')
        // schoolItems.every(i => i !== null && i !== undefined && i !== '') && schoolYears.every(i => i > 0)
        if (!schoolYes) {
            items.push('education')
        }

        for (const key of state.requiredKey) {
            // if (key === 'family_relations') {
            //     if (!siblingYes) {
            //         items.push('family_relations')
            //     }
            // } else if (key === 'family') {
            //     const { family } = state.profile
            //     if (family.length < 2) {
            //         items.push('family')
            //     } else if (!family.find(f => f.family_type === 0)) {
            //         items.push('family')
            //     } else if (!family.find(f => f.family_type === 1)) {
            //         items.push('family')
            //     }
            if (key === 'company') {
                const { company } = state.profile
                if (!company.length) items.push(key)
            } else if (key === 'religion') {
                if (state.profile[key] === null) {
                    items.push('religion')
                }
            } else if (key !== 'age') {
                if (state.profile[key] === null || state.profile[key] === undefined || state.profile[key] === '') {
                    items.push(key)
                }
            }
        }
        return items
    },
    needContract: state => {
        if ((state.me || {}).id <= 1725) return false
        if (!((state.me || {}).profile || {}).status) return false

        return ((state.me || {}).profile || {}).status !== 'confirmed'
    },
    blockedCompany: state => state.blockedCompany,
    blockedCollege: state => state.blockedCollege,
    blockedName: state => state.blockedName,
    hasStyle: state => {
        if (!state.me) return

        return !!state.me.style
    },
    settings: state => state.settings,
    mainPhoto: state => {
        if (!state.me || (state.me.photos || []).length === 0) return

        return state.me.photos[0].url
    },
    badges: state => state.badges,
    header: state => state.header,
    notifications: state => state.notifications,
    device: state => state.device,
    items: state => state.items,
    signupFriends: state => state.signupFriends,
    profileStep: state => state.profileStep,
}

const actions = {
    async loadAuthToken({ commit, dispatch, getters }) {
        const header = JSON.parse(window.localStorage.getItem('header'))
        if (!header) {
            window.localStorage.removeItem('header')
            return Promise.reject({ status: 401 })
        }

        if (header['X-User-Role'] !== 'user') return dispatch('signOut')

        if ((!header['X-User-OS'] || !header['X-User-Agent']) && getters.device) {
            header['X-User-OS'] = getters.device.os
            header['X-User-Agent'] = JSON.stringify(getters.device)
        }
        axios.setHeader(header)
        commit('setHeader', header)
    },
    async loadMe({ commit, getters }) {
        try {
            // header 로컬스토리지에 있는데 참조 못하는 경우
            axios.setHeader(getters.header)
            const me = await userService.me()
            commit('setMe', me)
            commit('setProfile', me.profile)
            commit('setUserProducts', { products: me.products, introduceDay: me.introducing_day })
            if (me.is_dormant && router.currentRoute.name !== 'DormantPage') {
                router.push({ name: 'DormantPage' })
                return Promise.reject()
            }
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadSettings({ commit }) {
        try {
            const settings = await userService.settings()
            commit('setSettings', settings)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadBadges({ state, commit }) {
        if (!state.me) return

        const data = await userService.badges(state.me.id)
        commit('setBadges', data)
    },
    async signIn({ commit, getters, dispatch }, payload) {
        const data = await Vue.prototype.$http.post('v2/users/login', payload)

        if (data.role === 'agent') {
            Vue.prototype.$toast.error(data.msg)
        } else {
            Vue.prototype.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: 'user_login',
                },
            })
            Vue.prototype.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'user_login',
                },
            })
            data.device = getters.device

            setHeaderAndRedirectToMain({ getters, commit, dispatch, data, noRedirect: payload.noRedirect })
        }
    },
    async signInPhoneNumber({ commit, getters, dispatch }, payload) {
        const data = { user: payload }
        data.device = getters.device
        setHeaderAndRedirectToMain({ getters, commit, dispatch, data, noRedirect: payload.noRedirect || false })
    },
    async signOut({ commit, getters }) {
        // 디바이스 정보를 잠시 기억해놓고 스토어 지워진 다음 다시 set device 해줘야 다시 로그인할때 디바이스 정보 남아있음
        const device = getters.device

        window.localStorage.clear()

        commit('initAppData')
        commit('setDevice', device)

        if (router.currentRoute.name !== 'FrontPage') {
            router.push({ name: 'FrontPage' })
        }

        if (getters.deviceToken) {
            userService.deleteToken(getters.deviceToken)
        }
    },
    async loadNotifications({ state, commit }, offset) {
        try {
            const data = await userService.chunkedNotifications(
                offset === 0 ? offset : (state.notifications || []).length
            )
            commit('setNotifications', {
                offset: offset,
                items: data.notifications,
            })
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadItems({ commit }) {
        try {
            const { bag } = await userService.items()
            commit('setItems', bag.item)
        } catch (e) {
            return Promise.reject(e)
        }
    },
    async loadProfile({ commit }) {
        const profile = await profileService.me()
        commit('setProfile', profile)

        return profile
    },
    async loadPur({ state, getters, commit }) {
        const pur = await userService.getProfileUpdateRequest(state.me.id)
        const profile = getters.profile
        if (!pur || pur.msg === 'fail') {
            return Promise.reject()
        }

        if (pur.res) {
            commit('setProfileUpdateRequests', pur.res)

            Object.keys(getters.profileUpdateRequests).forEach(k => {
                if (k === 'state_id') {
                    profile.state = {
                        id: getters.profileUpdateRequests[k],
                        is_station: [1, 2].includes(getters.profileUpdateRequests[k]),
                    }
                } else if (k === 'station_id') {
                    profile.station = { id: getters.profileUpdateRequests[k] }
                } else if (k === 'region_id') {
                    profile.region = { id: getters.profileUpdateRequests[k] }
                } else if (k === 'hometown_state_id') {
                    profile.hometown_state = {
                        id: getters.profileUpdateRequests[k],
                        is_station: [1, 2].includes(getters.profileUpdateRequests[k]),
                    }
                } else if (k === 'hometown_station_id') {
                    profile.hometown_station = { id: getters.profileUpdateRequests[k] }
                } else if (k === 'hometown_region_id') {
                    profile.hometown_region = { id: getters.profileUpdateRequests[k] }
                } else profile[k] = getters.profileUpdateRequests[k]
            })
            commit('setProfile', profile)
        }
    },
}

const mutations = {
    setHeader(state, header) {
        state.header = header
    },
    setMe(state, me) {
        state.me = me
    },
    setProfile(state, profile) {
        state.profile = profile
    },
    setProfileUpdateRequests(state, pur) {
        state.profileUpdateRequests = pur
    },
    setBlockedCollege(state, value) {
        state.blockedCollege = value
    },
    setBlockedCompany(state, value) {
        state.blockedCompany = value
    },
    setBlockedName(state, value) {
        state.blockedName = value
    },
    setSettings(state, settings) {
        state.settings = settings
    },
    setBadges(state, badges) {
        state.badges = badges
    },
    setNotifications(state, payload) {
        if (!payload) return

        if (payload.offset === 0 || !state.notifications) {
            state.notifications = payload.items
        } else {
            state.notifications = state.notifications.concat(payload.items)
        }
    },
    setDevice(state, device) {
        if (!device) return

        state.device = device
    },
    setItems(state, items) {
        state.items = items
    },
    setSignupFriends(state, signupFriends) {
        state.signupFriends = signupFriends
    },
    setProfileStep(state, profileStep) {
        state.profileStep = profileStep
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    defaultState,
}
