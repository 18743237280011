export default {
    productInfo: {
        title: '상품안내',
        content: [
            {
                id: 1,
                title: '매니저의 1:1 만남 케어',
                desc: '프로필 제공부터 약속 선정, 만남 후 피드백까지 매니저의 전반적인 1:1 케어',
            },
            {
                id: 2,
                title:
                    '만남 성사 실패시 100% 환불' +
                    ' <span class="spoqa-f-regular center m-l-4"' +
                    ' style="height: 20px; color: white;font-size: 12px; background: #FF3D6B; padding: 0 4px; border-radius: 100px;"' +
                    '>업계최초</span>',
                desc: '기한 내 만남권을 모두 사용하지 않은 경우 <span class="spoqa-f-bold">100% 환불 진행</span>',
            },
            {
                id: 3,
                title: '알바 보상제, 1000만원 보상!',
                desc: '만약 만남을 가진 회원이 저희가 고용한 아르바이트 생이라면 1,000만원 보상',
            },
        ],
    },
    refundPolicy: {
        title: '환불 정책',
        // content: [
        //     {
        //         id: 1,
        //         title: '1회 이상 매칭된 경우',
        //         desc: ['- 환불 금액: 결제금액 - 매칭권 1장의 정가 * 사용한 매칭권 장수'],
        //     },
        //     {
        //         id: 2,
        //         title: '상대방의 귀책으로 만남 진행이 불가능한 경우',
        //         desc: ['- 매칭권 재지급'],
        //     },
        //     {
        //         id: 3,
        //         title: '본인 귀책 혹은 단순 변심으로 진행이 불가능한 경우',
        //         desc: [
        //             '- 책임감 있는 문화를 위해 1회 만남 횟수 차감으로 간주하며, 아래 기준에 따라 적립금을 지급',
        //             '- 약속 확정 후 ~ 만남 예정일 7일전까지 만남 취소: 매칭권 1장 정가의 50% 금액만큼 적립금 지급',
        //             '- 만남 예정일 6일전~3일전까지 만남 취소: 매칭권 1장 정가 30% 금액만큼 적립금 지급',
        //             '- 만남 예정일 2일전 ~ 만남 예정일 당일 취소: 적립급 지금 없음',
        //         ],
        //     },
        //     {
        //         id: 4,
        //         title:
        //             '단, 공식적으로 교제를 시작한 사람이 생기거나(썸 관계는 인정 X), 천재 지변, 코로나 감염 등으로 개인이 통제할 수 없는 불가피한 일이 발생한 경우, 매칭권 재지급 해드립니다. (증빙 자료 필요)',
        //         desc: [],
        //     },
        // ],
        content: {
            prepaid: [
                {
                    id: 1,
                    title: '회원가입 후 프로필 제공 전에 탈퇴하는 경우',
                    body: '결제금액의 90% 환불',
                },
                {
                    id: 2,
                    title: '회원가입 후 프로필 제공 후 만남 일자 확정 전에 탈퇴하는 경우',
                    body: '결제금액의 85% 환불',
                },
                {
                    id: 3,
                    title: '회원가입 후 회사가 주선한 만남 일자 확정 이후에 탈퇴하는 경우',
                    body: '결제금액의 80% 환불',
                },
                {
                    id: 4,
                    title: '1회 만남 이후에 탈퇴하는 경우',
                    body: '결제금액의 80% × (잔여횟수/총횟수)',
                },
            ],
            unlimited: [
                {
                    id: 1,
                    title: '회원가입 후 프로필 제공 전에 탈퇴하는 경우',
                    body: '결제금액의 90% 환불',
                },
                {
                    id: 2,
                    title: '회원가입 후 프로필 제공 후 만남 일자 확정 전에 탈퇴하는 경우',
                    body: '결제금액의 85% 환불',
                },
                {
                    id: 3,
                    title: '회원가입 후 회사가 주선한 만남 일자 확정 이후에 탈퇴하는 경우',
                    body: '결제금액의 80% 환불',
                },
                {
                    id: 4,
                    title: '1회 만남 이후에 탈퇴하는 경우',
                    body: '결제금액의 80% × (잔여개월수/총개월수)',
                },
            ],
        },
        notice: {
            prepaid: ['서비스로 제공된 무료 만남횟수는 총 횟수에 포함하지 않습니다.'],
            unlimited: [
                '서비스로 제공된 무료 기간은 총 개월수에 포함하지 않습니다.',
                '1개월은 4주로 간주합니다.',
                '잔여 개월은 4주 단위로 측정합니다.',
            ],
        },
    },
}
