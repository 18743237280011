<template>
    <div class="edit-profile-menu">
        <!-- <ul class="list" v-for="category in Object.keys(menu)" :key="category">
            <h3 class="category" v-html="$translate(category)" />
            <li class="item" v-for="item in menu[category]" :key="item.key" @click="item.handler()">
                <div class="key">{{ $translate(item.key) }}</div>
                <div class="status">{{ item.status }}</div>
            </li>
        </ul> -->
        <ul class="list" v-for="category in Object.keys(menu)" :key="category">
            <li class="item" v-for="item in menu[category]" :key="item.key" @click="item.handler()">
                <i class="icons-outlined icon material-icons">{{ 'info' }} </i>
                <span class="key" v-html="$translate(item.key)" />
                <i class="right-icon material-icons i-right">chevron_right</i>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'FeeInformation',
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        menu() {
            if (!this.me) return {}
            return {
                EDIT_FAVORITE: [
                    {
                        key: 'FEE_INFORMATION',
                        handler: () => {
                            this.$stackRouter.push({
                                name: 'FeeInformationPage',
                            })
                        },
                    },
                ],
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.edit-profile-menu {
    // margin-top: 10px;
    // .list {
    //     padding: 24px 16px 0;

    //     &:not(:last-child) {
    //         border-bottom: solid 1px $grey-01;
    //     }
    // }

    .category {
        color: $grey-05;
        font-size: 14px;
        font-weight: normal;
        @include f-regular;
    }

    .item {
        height: 56px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        position: relative;

        border-top: solid 1px #dcdcdc;

        .icon {
            width: 16px;
            margin-left: -5px;
            margin-right: 17px;
            color: #5b588b;
        }

        .right-icon {
            position: absolute;
            right: 20px;
        }

        .key {
            font-size: 16px;
            color: black;
        }
        // &:nth-child(1) {
        //     border-bottom: solid 8px rgb(247, 248, 249);
        // }
    }
}
</style>
