<template>
    <div class="schedule-item">
        <div class="header-wrap">
            <span class="status-badge" :class="schedule.schedule_status.style">
                {{ schedule.schedule_status.label }}
            </span>
            <!-- <div v-if="isPromiseBtn" class="flex-row">
                <div class="promise-change-btn" @click="onChangeBtnClick">약속 변경하기</div>
                <div class="promise-cancel-btn" @click="onCancelBtnClick">약속 취소하기</div>
            </div> -->
        </div>
        <div class="profile">
            <div class="user-image">
                <img class="active" v-if="schedule.user_status === 'active'" :src="schedule.photo" alt="" />
                <img
                    v-else
                    class="non-active"
                    src="@/assets/images/icons/nonactive_user.png"
                    width="35px"
                    height="35px"
                />
                <div v-if="schedule.schedule_status.style === 'disabled'"></div>
            </div>
            <div
                class="user-info"
                :class="[schedule.schedule_status.style, { disabled: schedule.user_status !== 'active' }]"
            >
                <div class="title-wrap list-comma" v-if="schedule.user_status === 'active'">
                    <div class="user-detail" v-if="schedule.job">
                        {{ schedule.job }}
                    </div>
                    <span v-if="schedule.age" v-html="schedule.age"></span>
                </div>
                <div class="title-wrap" v-if="schedule.user_status !== 'active'">
                    <div class="user-detail">휴면 회원입니다.</div>
                </div>
                <div class="schedule-detail">
                    <div class="label">일시</div>
                    <div class="content">{{ schedule.schedule_date }}</div>
                </div>
                <div class="schedule-detail">
                    <div class="label">지역</div>
                    <div class="content list-comma">
                        <span v-if="schedule.schedule_place">
                            {{ schedule.schedule_place }}
                        </span>
                        <span v-if="schedule.schedule_type">
                            {{ schedule.schedule_type }}
                        </span>
                    </div>
                </div>
                <div class="schedule-detail">
                    <div class="label">장소</div>
                    <div v-if="schedule.restaurant_name" class="content">
                        <span>
                            {{ schedule.restaurant_name }}
                        </span>
                        <div class="place-map">
                            <img
                                :src="require('@/assets/images/icons/location_black.png')"
                                alt=""
                                widtt="16"
                                height="16"
                            />

                            <div @click="showMap">지도보기</div>
                        </div>
                    </div>
                    <div v-else-if="$store.getters.me.gender === 1" class="content">
                        <span>남성분이 장소를 정한 후에 입력될 예정입니다.</span>
                    </div>
                    <div v-else-if="$store.getters.me.gender === 0" class="content">
                        <button class="enter-place f-bold" @click="onClickPlace">입력하기</button>
                    </div>
                </div>
                <!-- <div class="promise-btn">
                    <div v-if="isPromiseChangeBtn" class="promise-change-btn btn" @click="onChangeBtnClick">
                        약속 변경하기
                    </div>
                    <div v-if="isPromiseCancelBtn" class="promise-cancel-btn btn" @click="onCancelBtnClick">
                        약속 취소하기
                    </div>
                </div> -->
            </div>
        </div>
        <div v-if="isPromiseBtn" class="promise-btn-wrap">
            <div class="promise-change-btn" @click="onChangeBtnClick">약속 변경하기</div>
            <div class="promise-cancel-btn" @click="onCancelBtnClick">약속 취소하기</div>
        </div>
    </div>
</template>
<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ScheduleItem',
    data: () => ({
        disabled: false,
    }),
    props: {
        schedule: {
            type: Object,
        },
    },
    methods: {
        showMap() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: this.schedule.restaurant_url,
            })
        },
        onClickPlace() {
            if (this.schedule.schedule_status.label === '약속 확정') {
                this.$modal.custom({
                    component: 'ModalMeetingPlaceInput',
                    options: {
                        schedule_id: this.schedule.schedule_id,
                    },
                })
            } else {
                this.$modal.basic({
                    title: '약속이 확정되면 입력할 수 있습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })
            }
        },
        async onChangeBtnClick() {
            if (this.schedule.schedule_status.label !== '약속 확정') {
                let message = '약속 변경은 약속이 확정된 후 가능합니다.'
                if (this.schedule.schedule_status.label === '약속 변경중') {
                    message = '상대방과 약속 변경이 진행중입니다.'
                }
                await this.$modal.basic({
                    body: message,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })
                return
            }

            this.getChangeReason()
        },
        async getChangeReason() {
            const idx = await this.$modal.basic({
                body: `약속 변경을 요청하였을 때, 상대방이 약속을 취소하실 경우 취소 수수료의 책임은 <span class="c-red f-bold">약속 변경을 신청하신 본인</span>에게 있습니다. 그래서 부득이한 경우가 아니면 약속 변경은 가급적 하지 않도록 권고드려요.<br />
                약속 변경을 요청하시겠어요?`,
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: '변경 요청하기',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                await this.$modal.custom({
                    component: 'ModalChangeSchedule',
                    options: {
                        schedule: this.schedule,
                    },
                })
            }
        },
        async openChatRoom() {
            const idx = await this.$modal.basic({
                title: '내용을 꼼꼼하게 확인해주세요.',
                body: `고객센터 운영 시간이 얼마 남지 않아 고객센터에서 약속 변경을 조율해드리기 어렵습니다. 상대방과 직접 앱 내 채팅방을 통해 앱 약속을 변경하셔야 합니다.<br /><br />
                앱 내 채팅방에서 연락처를 교환하는 것은 엄격히 금지되며, 약속 변경 사실을 향후 고객센터에 알려야할 의무가 있습니다. (약속 변경 사실을 알리지 않는 경우 의도적인 것으로 간주하여 엄격한 페널티가 적용됩니다.)<br /><br />
                이와 별개로, 약속일 3일 이전에 약속을 변경하는 경우 변경 페널티 수수료가 부과됩니다.`,
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: '채팅방 열기',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                const payload = {
                    id: this.schedule.schedule_id,
                    user_id: this.me.user_id,
                    change_type: 'change',
                }
                try {
                    await scheduleService.changeScheduleRequest(payload)
                    this.$router.push({ name: 'ChatsPage' })
                    this.$store.dispatch('loadChats', true)
                    this.$toast.success('채팅방이 열렸습니다.')
                } catch (err) {
                    console.error(err)
                }
            }
        },
        onCancelBtnClick() {
            this.getCancelReason()
        },
        async getCancelReason() {
            if (this.schedule.schedule_status.label === '약속 취소') {
                this.$modal.basic({
                    body: '이미 취소된 약속입니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return
            } else if (this.schedule.schedule_status.label === '약속 변경중') {
                this.$modal.basic({
                    body: '약속 변경 채팅방에 있는 약속 취소 버튼을 통해 신청해주세요.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })
                return
            } else if (this.schedule.schedule_status.label !== '약속 확정') {
                this.$modal.basic({
                    body: '약속 취소는 약속이 확정된 후 가능합니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'c-primary',
                        },
                    ],
                })
                return
            }
            const idx = await this.$modal.basic({
                body: `약속 취소를 요청하시겠어요?`,
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: '약속 취소하기',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                const res = await this.$modal.custom({
                    component: 'ModalCancelSchedule',
                    options: {
                        schedule: this.schedule,
                    },
                })

                if (res === 'canceled') {
                    this.$emit('canceled')
                }
            }
        },
    },
    computed: {
        scheduleDate() {
            const scheduleDate = new Date(this.schedule.date_confirmed)
            return scheduleDate
        },
        isPromiseBtn() {
            if (this.schedule.date_confirmed && new Date().getTime() > this.scheduleDate.getTime()) return false
            return true
        },
        me() {
            return this.$store.getters.me
        },
    },
}
</script>
<style lang="scss" scoped>
.schedule-item {
    // width: 100%;
    padding: 24px 0;

    .header-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .status-badge {
            background: #ffffff;
            border-radius: 100px;
            margin: 4px 0px;
            padding: 2px 6px;
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
            flex: none;
            display: inline-block;
            color: #393d44;
            &.primary {
                color: #03006e;
                background: #ffffff;
            }
            &.disabled {
                color: #94999e;
                background: #ffffff;
            }
        }
        .promise-change-btn,
        .promise-cancel-btn {
            font-size: 12px;
            color: #777;
            text-decoration: underline;
        }
        .promise-change-btn {
            margin-right: 10px;
        }
        .promise-cancel-btn {
            margin-right: 10px;
        }
    }
    .profile {
        display: flex;
        align-items: center;
        .user-image {
            width: 80px;
            height: 80px;
            aspect-ratio: 1/1;
            position: relative;
            background: $grey-03;
            border-radius: 8px;
            .active {
                border: 1px solid $grey-03;
                box-sizing: border-box;
                border-radius: 8px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
            }
            .non-active {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            div {
                border: 1px solid $grey-03;
                box-sizing: border-box;
                border-radius: 8px;
                background: #c4c4c4;
                z-index: 2;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0.7;
            }
        }
        .user-info {
            margin-left: 12px;
            color: #111111;
            width: 100%;
            .title-wrap {
                width: 100%;
                display: flex;
                white-space: no-wrap;
                width: 100%;
                align-items: center;
                font-size: 16px;
                line-height: 26px;
                font-weight: 700;
                .user-detail {
                    -webkit-line-clamp: 1;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                span {
                    display: inline;
                    white-space: pre;
                    word-break: keep-all;
                }
            }
            .schedule-detail {
                display: flex;
                align-items: flex-start;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                width: 100%;
                .label {
                    color: #94999e;
                    margin-right: 8px;
                    word-break: keep-all;
                }
                .content {
                    // span:not(:last-child)::after{
                    //     content :', '
                    // }
                    width: 100%;
                    display: flex;

                    .place-map {
                        margin-left: 4px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: #ffffff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 2px 4px;
                        min-width: 69px;
                        width: fit-content;
                        height: 24px;
                        padding: 2px 4px;
                        background: $grey-03;
                        border-radius: 100px;
                        color: #111;
                    }

                    .enter-place {
                        margin-left: 4px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 20px;
                        color: #f7f7f4;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 2px 4px;
                        min-width: 69px;
                        width: fit-content;
                        height: 24px;
                        padding: 2px 4px;
                        background: #03006e;
                        border: none;
                        border-radius: 100px;
                    }
                }
            }
            /* .promise-btn {
                display: flex;
                margin-top: 8px;
                .btn {
                    margin-left: 4px;
                    font-weight: 400;
                    font-size: 12px;
                    color: #f7f7f4;
                    padding: 2px 4px;
                    min-width: 69px;
                    height: 24px;
                    width: 80px;
                    background: #03006e;
                    border: none;
                    border-radius: 100px;
                }
                .promise-change-btn {
                    margin-right: 4px;
                }
            } */
        }
    }
    .promise-btn-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        .promise-change-btn,
        .promise-cancel-btn {
            font-size: 12px;
            color: #555;
            text-decoration: underline;
        }
        .promise-change-btn {
            margin-right: 50px;
        }
    }
    &.disabled {
        color: #94999e;
    }
    .list-comma :not(:last-child)::after {
        content: ',';
        margin-right: 3.5px;
    }
}
.schedule-item:not(:last-child) {
    border-bottom: 1px solid $grey-03;
}
</style>
