<template>
    <div class="product-template">
        <div
            class="product-item"
            :class="categoryItem.code.includes('unlimited') ? [categoryItem.code] : [categoryItem.code, gender]"
        >
            <div class="image-tag">
                <img class="img-detail" :src="imageTag" alt="" />
            </div>
            <div class="item-content">
                <div
                    class="name f-17 m-t-28"
                    :class="categoryItem.code.includes('unlimited') ? 'unlimited' : gender"
                    v-html="$translate(categoryItem.code.toUpperCase())"
                />
                <div class="per-price f-8 m-t-14">
                    <span>
                        <span class="price-per-amount f-20">{{ pricePerAmount }}</span>
                        {{ categoryItem.code.includes('unlimited') ? '/ 1개월' : '/ 1장' }}
                    </span>
                </div>
            </div>
            <div class="price-description f-7">
                <div class="pre-price m-t-30">
                    정가
                    <span class="price f-12">
                        {{ Number(categoryItem.original_price * (10 / 11)).toLocaleString() }}원
                    </span>
                </div>
                <div class="discount-rate f-12" :class="categoryItem.code.includes('unlimited') ? 'unlimited' : gender">
                    {{ discountRate }}% 할인
                </div>
                <div class="result-price">
                    할인가
                    <span class="price f-12">
                        {{ Number(categoryItem.selling_price * (10 / 11)).toLocaleString() }}원
                    </span>
                </div>
            </div>
        </div>
        <div class="unlimited-description f-10 m-t-4" v-if="categoryItem.code === 'unlimited_female'">
            3개월 동안 횟수에 관계 없이 만날 수 있는 상품입니다.<br />
            비용애 구애받지 않고 여러 사람을 만나보고 싶은 분에게 추천드려요.
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductItem',
    props: ['categoryItem', 'gender'],
    computed: {
        pricePerAmount() {
            const price = this.categoryItem.selling_price * (10 / 11)
            let amount = 0
            if (this.categoryItem.code.includes('prepaid') || this.categoryItem.ptype === 'ticket_plan') {
                amount = this.categoryItem.amount
            } else if (this.categoryItem.code.includes('unlimited')) {
                amount = this.categoryItem.valid_date / 30
            }
            return (Math.round(price / amount) / 10000).toFixed(1)
        },
        discountRate() {
            return Math.round((this.categoryItem.discount / (this.categoryItem.original_price * (10 / 11))) * 100)
        },
        imageTag() {
            if (
                (this.categoryItem.code === 'ticket_10' || this.categoryItem.code === 'prepaid_male_3') &&
                this.gender === 'male'
            ) {
                return require(`@/assets/images/icons/good_price_male.png`)
            } else if (
                (this.categoryItem.code === 'ticket_10' || this.categoryItem.code === 'prepaid_female_3') &&
                this.gender === 'female'
            ) {
                return require(`@/assets/images/icons/good_price_female.png`)
            } else if (this.categoryItem.code.includes('_A_6month') || this.categoryItem.code.includes('_B_6month')) {
                return require(`@/assets/images/icons/good_price_unlimited.png`)
            } else if (
                this.categoryItem.code.includes('_5') ||
                this.categoryItem.code === 'prepaid_female_2' ||
                this.categoryItem.code === 'prepaid_male_2' ||
                [
                    'unlimited_male_A_3month',
                    'unlimited_male_B_3month',
                    'unlimited_female_A_3month',
                    'unlimited_female_B_3month',
                ].includes(this.categoryItem.code)
            ) {
                return require(`@/assets/images/icons/popular_price.png`)
            } else {
                return ''
            }
        },
    },
}
</script>

<style scoped lang="scss">
.product-item {
    position: relative;
    display: flex;
    margin-top: 16px;
    background: #ffffff;
    height: 80px;
    border-radius: 8px;

    &.unlimited_male_A_1month,
    &.unlimited_male_B_1month,
    &.unlimited_female_A_1month,
    &.unlimited_female_B_1month {
        height: 100px;
        border: 1px solid #b598d2;
    }

    &.unlimited_male_A_3month,
    &.unlimited_male_B_3month,
    &.unlimited_female_A_3month,
    &.unlimited_female_B_3month {
        height: 100px;
        border: 1px solid #9565c5;
        .image-tag {
            position: absolute;
            background: #9565c5;
            border-top-left-radius: 8px;
            border-bottom-right-radius: 8px;
            width: 45px;
            height: 19px;
            @include center;

            .img-detail {
                width: 30px;
            }
        }
    }

    &.unlimited_male_A_6month,
    &.unlimited_male_B_6month,
    &.unlimited_female_A_6month,
    &.unlimited_female_B_6month {
        height: 100px;
        border: 1px solid #663399;
        .image-tag {
            position: absolute;
            background: #663399;
            border-top-left-radius: 8px;
            border-bottom-right-radius: 8px;
            width: 45px;
            height: 19px;
            @include center;

            .img-detail {
                width: 35px;
            }
        }
    }

    &.ticket_10 {
        &.male {
            height: 100px;
            border: 1px solid #2143d4;
            .image-tag {
                position: absolute;
                background: #2143d4;
                border-top-left-radius: 8px;
                border-bottom-right-radius: 8px;
                width: 45px;
                height: 19px;
                @include center;

                .img-detail {
                    width: 35px;
                }
            }
        }
        &.female {
            height: 100px;
            border: 1px solid #ff8c8c;
            .image-tag {
                position: absolute;
                background: #ff8c8c;
                border-top-left-radius: 8px;
                border-bottom-right-radius: 8px;
                width: 45px;
                height: 19px;
                @include center;
                .img-detail {
                    width: 35px;
                }
            }
        }
    }

    &.prepaid_male_3 {
        &.male {
            height: 100px;
            border: 1px solid #2143d4;
            .image-tag {
                position: absolute;
                background: #2143d4;
                border-top-left-radius: 8px;
                border-bottom-right-radius: 8px;
                width: 45px;
                height: 19px;
                @include center;

                .img-detail {
                    width: 35px;
                }
            }
        }
    }

    &.prepaid_female_3 {
        &.female {
            height: 100px;
            border: 1px solid #ff8c8c;
            .image-tag {
                position: absolute;
                background: #ff8c8c;
                border-top-left-radius: 8px;
                border-bottom-right-radius: 8px;
                width: 45px;
                height: 19px;
                @include center;
                .img-detail {
                    width: 35px;
                }
            }
        }
    }

    &.ticket_5 {
        &.male {
            height: 100px;
            border: 1px solid #a8b9ff;
            .image-tag {
                position: absolute;
                background: #a8b9ff;
                border-top-left-radius: 8px;
                border-bottom-right-radius: 8px;
                width: 45px;
                height: 19px;
                @include center;

                .img-detail {
                    width: 30px;
                }
            }
        }
        &.female {
            height: 100px;
            border: 1px solid #ffaaaa;
            .image-tag {
                position: absolute;
                background: #ffaaaa;
                border-top-left-radius: 8px;
                border-bottom-right-radius: 8px;
                width: 45px;
                height: 19px;
                @include center;

                .img-detail {
                    width: 30px;
                }
            }
        }
    }

    &.prepaid_male_2 {
        &.male {
            height: 100px;
            border: 1px solid #a8b9ff;
            .image-tag {
                position: absolute;
                background: #a8b9ff;
                border-top-left-radius: 8px;
                border-bottom-right-radius: 8px;
                width: 45px;
                height: 19px;
                @include center;

                .img-detail {
                    width: 30px;
                }
            }
        }
    }

    &.prepaid_female_2 {
        &.female {
            height: 100px;
            border: 1px solid #ffaaaa;
            .image-tag {
                position: absolute;
                background: #ffaaaa;
                border-top-left-radius: 8px;
                border-bottom-right-radius: 8px;
                width: 45px;
                height: 19px;
                @include center;

                .img-detail {
                    width: 30px;
                }
            }
        }
    }

    &.ticket_3 {
        border: 1px solid #ced8ff;
        &.female {
            border: 1px solid #ffc2c2;
        }
        .name {
            margin-top: 16px !important;
        }
        .pre-price {
            margin-top: 16px !important;
        }
    }

    &.ticket_1 {
        border: 1px solid #ced8ff;
        &.female {
            border: 1px solid #ffc2c2;
        }
        .name {
            margin-top: 16px !important;
        }
        .pre-price {
            margin-top: 16px !important;
        }
    }

    &.prepaid_male_1 {
        border: 1px solid #ced8ff;
        .name {
            margin-top: 16px !important;
        }
        .pre-price {
            margin-top: 16px !important;
        }
    }

    &.prepaid_female_1 {
        border: 1px solid #ffc2c2;
        .name {
            margin-top: 16px !important;
        }
        .pre-price {
            margin-top: 16px !important;
        }
    }

    .item-content {
        margin: 0 20px;
        .name {
            font-weight: 700;
            &.unlimited {
                color: #663399;
            }

            &.male {
                color: #2143d4;
            }

            &.female {
                color: #ff8c8c;
            }
        }

        .per-price {
            color: #030068;
            line-height: 12px;
            font-weight: 500;

            .price-per-amount {
                font-weight: 600;
            }
        }
    }

    .price-description {
        margin: 0 20px;
        position: absolute;
        right: 0;
        text-align: right;
        color: #5c5c77;

        .discount-rate {
            margin: 4px 0;
            font-weight: 600;
            &.unlimited {
                color: #663399;
            }

            &.male {
                color: #2143d4;
            }

            &.female {
                color: #ff8c8c;
            }
        }

        .pre-price {
            .price {
                font-weight: 600;
                text-decoration: line-through;
            }
        }

        .result-price {
            .price {
                font-weight: 600;
            }
        }
    }
}
.unlimited-description {
    color: #ff7070;
    line-height: 14px;
    background: #ff707033;
    height: 44px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    border-radius: 8px;
}
</style>
