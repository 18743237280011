<template>
    <div class="on-board flex-column justify-content-between">
        <swiper :options="swiperOptions" ref="mySwiper">
            <swiper-slide v-for="idx in [1, 2, 3]" :key="idx">
                <component :is="`OnBoard${idx}`" />
            </swiper-slide>
        </swiper>
        <div class="pagination">
            <div
                class="pagination-item"
                :class="{ selected: idx === curIdx }"
                v-for="idx in [0, 1, 2]"
                :key="`pg-${idx}`"
                @click.stop="clickPagination(idx)"
            />
        </div>
        <div class="bottom_btn">
            <button
                class="btn"
                :disabled="curIdx !== 2"
                :style="curIdx === 2 ? { background: '#06de9c' } : { background: '#ccf4e2' }"
                @click="clickNext"
            >
                <p class="label f-regular">시작하기</p>
            </button>
        </div>
    </div>
</template>

<script>
// import ChannelTalk from '@/components/app/ChannelTalk'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
    name: 'OnBoardingPage',
    // components: { ChannelTalk },
    data: () => ({
        curIdx: 0,
    }),
    components: {
        swiper,
        swiperSlide,
        OnBoard1: () => import('./components/OnBoard1'),
        OnBoard2: () => import('./components/OnBoard2'),
        OnBoard3: () => import('./components/OnBoard3'),
    },
    methods: {
        clickPagination(idx) {
            this.curIdx = idx
            this.swiperComponent.slideTo(idx, 1000, false)
        },
        clickNext() {
            this.$stackRouter.pop()
        },
    },
    computed: {
        swiperComponent() {
            return this.$refs.mySwiper.swiper
        },
        swiperOptions() {
            return {
                onSlideChange: function (e) {
                    console.log(e)
                },
            }
        },
    },
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#fffaf3',
                bottom: '#fffaf3',
            },
        })
        this.swiperComponent.on('slideChange', e => {
            this.curIdx = this.swiperComponent.activeIndex
        })
    },
}
</script>

<style scoped lang="scss">
.pagination {
    touch-action: none;
    @include center;

    .pagination-item {
        width: 8px;
        height: 8px;
        background: $grey-04;
        margin-right: 15px;
        border-radius: 8px;

        &.selected {
            background: #06de9c;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}
.on-board {
    height: 100vh;
    background-color: #fffaf3;
    justify-content: space-between;
}
::v-deep .swiper-container {
    height: 70vh;
    margin-top: 50px;
    .swiper-slide {
        touch-action: none;
    }
    .swiper-img-wrapper {
        margin-top: 10px;
    }
    .swiper_img {
        width: 100%;
    }
}
.bottom_btn {
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // right: 0;
    background: #fffaf3;
    padding: 60px 20px 16px 20px;
    z-index: 3;
    font-family: Pretendard;
}
.btn {
    width: 85%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #06de9c;
    padding: 30px 90px;
    z-index: 3;
    color: #fff;
    margin: 37px 30px;
    border-radius: 40px;
    font-size: 16.5px;
    font-weight: 600;
    font-family: Pretendard;
}
</style>
