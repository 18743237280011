<template>
    <div class="update-box">
        <span class="box-title">{{ title }}</span>
        <div class="update-list">
            <div class="update-item" :key="key" v-for="(item, key) in updateItems">
                <span class="item-date" v-html="item.date" />
                <span class="item-description" v-html="item.description" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UpdateBox',
    props: {
        title: String,
        updateItems: Array,
    },
}
</script>

<style scoped lang="scss">
div.update-box {
    width: 100%;
    //height: 100%;
    background-color: $blue-background;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 22px 16px;

    span.box-title {
        color: black;
        font-size: 20px;
        letter-spacing: -0.2px;

        @include f-medium;
    }

    div.update-list {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        div.update-item {
            padding: 16px 0;
            @include f-regular;

            &:not(:last-child),
            &:first-child {
                border-bottom: 1px solid #0000000a;
            }

            span.item-date {
                color: $grey-06;
                display: block;
                font-size: 13px;
                padding-bottom: 4px;
            }
            span.item-description {
                color: $grey-09;
                display: block;
                font-size: 15px;
            }
        }
    }
}
</style>
