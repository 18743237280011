<template>
    <div class="container">
        <StackRouterHeaderBar :title="'슈퍼 프라이버시'" :show-title="true" />
        <main class="wrapper">
            <div class="title f-bold">슈퍼<br />프라이버시</div>
            <div class="image-container">
                <!-- <img
                    class="gray-scale-image"
                    :src="require(`@/assets/images/super-privacy/super-privacy-img1.png`)"
                    width="60%"
                /> -->
                <img
                    class="gray-scale-image"
                    :src="require(`@/assets/images/super-privacy/security.svg`)"
                    width="60%"
                />
            </div>
            <div class="center">
                <div class="body-text"><span class="text-bold">내가 선택한 이성</span>에게만</div>
                <div class="body-text">프로필을 공개해요</div>
            </div>
            <div class="read-me">
                <div class="readme-item" v-for="i in [1]" :key="i">
                    <!-- <div class="star">*</div> -->
                    <div class="text" v-html="$translate(`SUPER_PRIVACY_DESC`)" />
                </div>
            </div>
        </main>
        <BottomButton @click="submit" :label="isPrivacy ? '해제하기' : '적용하기'" />
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'SuperPrivacyIntroductionPage',
    data() {
        return {
            isPrivacy: false,
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
    },
    methods: {
        async submit() {
            try {
                const ret = await userService.changePrivacy({
                    user_id: this.me.id,
                    is_privacy: !this.isPrivacy,
                })

                await this.$store.dispatch('loadMe')

                let modalBody = '적용되었습니다'

                if (ret.msg === 'success') {
                    this.isPrivacy = !this.isPrivacy

                    if (this.isPrivacy) {
                        modalBody = '슈퍼프라이버시 적용이 완료되었습니다.'
                    } else {
                        modalBody = '슈퍼프라이버시 해제가 완료되었습니다.'
                    }

                    this.$modal
                        .basic({
                            body: modalBody,
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(() => {
                            this.$emit('close')
                            this.$stackRouter.pop()
                        })
                    // this.$router.go(-1)
                }
            } catch (err) {
                console.error(err)
            }
            // this.save().then(() => this.$stackRouter.pop())
        },
        init() {
            if (this.me.is_privacy !== undefined) {
                this.isPrivacy = this.me.is_privacy
            }
        },
        onClickCertMethod() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://vbproduct.notion.site/ac8a5e85d1e04f0e92a45f879fd289a1`,
            })
        },
        back() {
            this.$stackRouter.pop()
        },
    },
    created() {
        this.$registerBackHandler(this.back)
    },
    beforeDestroy() {
        this.$unregisterBackHandler()
    },
}
</script>

<style lang="scss" scoped>
.container {
    font-family: bold;
    color: #111111;

    .wrapper {
        padding: 5vw 4vw;
        padding-bottom: 45px;

        .title {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 8px;
            font-size: 6vw;
            line-height: 8vw;
            background: var(--Cool-Grey, #e8e4e7);
            color: #5c5c77;
            margin-bottom: 4vw;
            padding: 1vw 1vw;
            width: 60%;
            margin: 0 auto;
        }
        .read-me {
            padding: 16px 12px;
            background: $grey-01;
            border-radius: 12px;
            font-size: 3.5vw;
            margin-bottom: 4vw;

            .readme-item {
                display: flex;
                line-height: 6vw;
                word-break: break-all;
            }
        }
        .image-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 4vw;
            .gray-scale-image {
                filter: grayscale(100%) brightness(200%);
            }
        }
        .center {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 4vw;
            .body-text {
                color: #a3a2a0;
                font-size: 8vw;
                line-height: 12vw;
            }
            .text-bold {
                @include f-bold;
            }
        }
    }
}
</style>
