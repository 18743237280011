<template>
    <div class="chat-schedule-btn" v-if="me.id === schedule_cancel_user">
        <button @click="onClickContinueSchedule" class="chat-button">그대로 진행</button>
        <button @click="onClickChangeSchedule" class="chat-button">약속 변경</button>
        <button @click="onClickCancelSchedule" class="chat-button">약속 취소</button>
    </div>
    <div class="chat-schedule-btn" v-else>
        <button @click="onClickCancelSchedule" class="chat-button">약속취소</button>
    </div>
</template>

<script>
import scheduleService from '@/services/schedule'

export default {
    name: 'ChatScheduleButtons',
    props: {
        chat: Object,
    },
    data: () => ({
        schedule_cancel_user: null,
    }),
    mounted() {
        this.schedule_cancel_user = this.chat.schedule_change.requestor_id
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
    },
    methods: {
        async onClickContinueSchedule() {
            const date = new Date(this.chat.schedule.date_confirmed)
            const formattedDate = `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}(${this.getDaySchedule(
                date.getDay()
            )}) ${date.getHours()}시 ${date.getMinutes()}분`
            const meetPlace = this.chat.schedule.meet_place
            const restaurantName = this.chat.schedule.restaurant_name

            let placeInfo = ''
            if (restaurantName) {
                placeInfo = '장소: ' + restaurantName + '<br />'
            }

            const idx = await this.$modal.basic({
                body:
                    '약속을 변경하지 않고, 기존 약속 일정 그대로 진행하시겠습니까? <br /><br />' +
                    '일정:' +
                    formattedDate +
                    '<br />' +
                    '지역:' +
                    meetPlace +
                    '<br />' +
                    placeInfo +
                    '<br />' +
                    '확인을 클릭하시면, 채팅방은 종료됩니다.',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })
            if (idx === 1) {
                const payload = {
                    id: this.chat.schedule.id,
                    user_id: this.me.id,
                    status: 'change_rejected',
                    date_confirmed: this.chat.schedule.date_confirmed,
                    meet_place: meetPlace,
                    restaurant_name: restaurantName,
                    restaurant_url: this.chat.schedule.restaurant_url,
                }
                try {
                    await scheduleService.changeScheduleConfirm(payload)
                    this.isClicked = true
                } catch (err) {
                    console.error(err)
                }

                const index = await this.$modal.basic({
                    body: '채팅방이 종료되었습니다.' + '약속을 변경하지 않고, 기존 약속 일정으로 진행됩니다.',
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })

                if (index === 1) {
                    this.$router.go(-1)
                    this.$router.push({ name: 'ScheduleListPage' })
                }
            }
        },
        onClickChangeSchedule() {
            this.$stackRouter.push({
                name: 'ChangeScheduleDetailPage',
                props: {
                    chat: this.chat,
                },
            })
        },
        async onClickCancelSchedule() {
            await this.$modal.custom({
                component: 'ModalCancelSchedule',
                options: {
                    chat: this.chat,
                    schedule: this.chat.schedule,
                    // 약속 변경에서 취소 할 경우
                    check: 'change_cancel',
                },
            })
        },
        getDaySchedule(day) {
            const days = ['일', '월', '화', '수', '목', '금', '토']
            return days[day]
        },
    },
}
</script>

<style scoped lang="scss">
.chat-schedule-btn {
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #fff9ee;
    border-top: 2px solid #faeee1;
    padding: 11px 3px;

    button.chat-button {
        all: unset;
        height: auto;
        display: flex;
        justify-content: space-between;
        background-color: #eeeeee;
        border-radius: 8px;
        padding: 11px 17px;
        font-size: 12px;

        @media (max-width: 338px) {
            padding: 8px 8px;
        }
    }

    button.chat-button:nth-child(2) {
        background-color: #c8daf8;
    }
}
</style>
