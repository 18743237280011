<template>
    <div class="product-page-container">
        <StackRouterHeaderBar
            v-if="from && from === 'modalAgreeLike'"
            :class="{ 'bottom-border': true }"
            :title="$translate('PRE_PAYMENT_PAGE')"
            :show-title="true"
        />
        <div class="product-page" v-if="plans">
            <!-- 기간제 -->
            <div class="introduce-product">
                <h3 class="m-b-16">부담 없이 구매하세요</h3>
                <div
                    class="introduce-card"
                    v-for="item in introduceProducts.filter(p => p.code === 'unlimited')"
                    :key="item.id"
                >
                    <div class="title f-16" v-html="item.title" />
                    <div class="content f-12 m-t-8" v-html="item.content" />
                </div>
            </div>
            <div v-if="me.gender === 0" class="items">
                <h3 class="m-t-40 m-b-16">남성 회원 기간권</h3>
                <div class="gray-box f-14">
                    <span>✔️프로필 수락 무료 이용 가능</span> <br />
                    <span>✔️기간 내 약속 잡기(만남) 무료 이용 가능</span>
                </div>
                <ProductItem v-for="item in unlimitedForMale" :key="item.id" :category-item="item" :gender="'male'" />
                <div class="tax-description m-t-9 f-12">*모든 상품은 부가세 별도입니다.</div>
            </div>
            <div v-else class="items">
                <h3 class="m-t-40 m-b-16">여성 회원 기간권</h3>
                <div class="gray-box f-14">
                    <span>✔️프로필 수락 무료 이용 가능</span> <br />
                    <span>✔️기간 내 약속 잡기(만남) 무료 이용 가능</span>
                </div>
                <ProductItem
                    v-for="item in unlimitedForFemale"
                    :key="item.id"
                    :category-item="item"
                    :gender="'female'"
                />
                <div class="tax-description m-t-9 f-12">*모든 상품은 부가세 별도입니다.</div>
            </div>
            <!-- 만남권 -->
            <div class="introduce-product m-t-40" v-if="me.id < 9708">
                <h3 class="m-b-16">안심하고 구매하세요</h3>
                <div
                    class="introduce-card"
                    v-for="item in introduceProducts.filter(p => p.code === 'ticket')"
                    :key="item.id"
                >
                    <div class="title f-16" v-html="item.title" />
                    <div class="content f-12 m-t-8" v-html="item.content" />
                </div>
            </div>
            <div class="items" v-if="me.id < 9708">
                <h3 class="m-t-40 m-b-16">남성 회원 만남권</h3>
                <div class="gray-box f-14">
                    <span>✔️프로필 수락 무료 이용 가능</span>
                </div>
                <ProductItem v-for="item in forMale" :key="item.id" :category-item="item" :gender="'male'" />
                <div class="tax-description m-t-9 f-12">*모든 상품은 부가세 별도입니다.</div>
            </div>
            <div class="items" v-if="me.id < 9708">
                <h3 class="m-t-40 m-b-16">여성 회원 만남권</h3>
                <div class="gray-box f-14">
                    <span>✔️프로필 수락 무료 이용 가능</span>
                </div>
                <ProductItem v-for="item in forFemale" :key="item.id" :category-item="item" :gender="'female'" />
                <div class="tax-description m-t-9 f-12">*모든 상품은 부가세 별도입니다.</div>
            </div>
            <div class="blank"></div>
            <div class="purchase-button">
                <button class="purchase-btn f-18" @click="onClickPurchase()">구매하기</button>
            </div>
        </div>
    </div>
</template>

<script>
// import ChannelTalk from '@/components/app/ChannelTalk'
import ProductItem from '@/routes/prepay/components/ProductItem'

export default {
    name: 'PrePaymentPage',
    components: { ProductItem },
    props: ['from'],
    data: () => ({
        selected: null,
        introduceProducts: [
            {
                id: 1,
                title: '만남 100% 보장',
                code: 'ticket',
                content: `마음에 드는 분이 없거나, <br/>매칭이 되지 않을 경우 100% 전액 환불 해드립니다.`,
            },
            {
                id: 2,
                title: '지금만 누릴 수 있는, 업계 최저가',
                code: 'unlimited',
                content: `합리적인 가격으로 진정성 있는 만남을 보장해드려요.`,
            },
        ],
    }),
    methods: {
        async onClickPurchase() {
            if (this.me.profile.status === 'confirmed') {
                let products = []
                if (this.me.gender === 0) {
                    products = [...this.unlimitedForMale, ...this.forMale]
                } else {
                    products = [...this.unlimitedForFemale, ...this.forFemale]
                }
                console.log('products: ', products)

                this.$modal.custom({
                    component: 'ModalPurchaseProduct',
                    options: {
                        products: products,
                    },
                })
            } else {
                this.$modal.basic({
                    body: '서류인증이 완료된 후 구매할 수 있습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
            }
        },
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        products() {
            return this.$store.getters.products
        },
        plans() {
            return this.$store.getters.plans
        },
        programs() {
            const prepaidPrograms = this.products.prepaid.concat(
                this.products.unlimited.filter(o => o.code !== 'unlimited_male' && o.code !== 'unlimited_female')
            )
            // const subscriptionPrograms = this.products.subscription_plan
            const ticketPrograms = this.products.ticket_plan.filter(o => o.code !== 'ticket_manner_like')
            // const packagePrograms = this.products.package_plan

            // const programs = [...prepaidPrograms, ...subscriptionPrograms, ...ticketPrograms, ...packagePrograms]
            const programs = [...prepaidPrograms, ...ticketPrograms]
            const program = programs.slice().sort(function (a, b) {
                return a.id < b.id ? 1 : a.id > b.id ? -1 : 0
            })
            return program
        },
        variation() {
            if (this.me.id > 9707) {
                this.$nativeBridge.postMessage({
                    action: 'setFirebaseVariationName',
                    value: 'unlimited_test',
                })
                return this.$store.getters.firebaseVari || 'A'
            } else {
                return 'A'
            }
        },
        forMale() {
            if (this.me.id > 4348) {
                return this.programs.filter(
                    o =>
                        o.ptype === 'ticket_plan' &&
                        (o.target === 'male' || o.target === 'all') &&
                        !o.code.includes('unlimited')
                )
            } else {
                return this.programs.filter(
                    o =>
                        o.ptype === 'rate_plan' &&
                        (o.target === 'male' || o.target === 'all') &&
                        !o.code.includes('unlimited')
                )
            }
        },
        forFemale() {
            if (this.me.id > 4348) {
                return this.programs.filter(
                    o =>
                        o.ptype === 'ticket_plan' &&
                        (o.target === 'female' || o.target === 'all') &&
                        !o.code.includes('unlimited')
                )
            } else {
                return this.programs.filter(
                    o =>
                        o.ptype === 'rate_plan' &&
                        (o.target === 'female' || o.target === 'all') &&
                        !o.code.includes('unlimited')
                )
            }
        },
        unlimitedForMale() {
            const unlimited = this.programs.filter(
                o =>
                    o.ptype === 'rate_plan' &&
                    (o.target === 'male' || o.target === 'all') &&
                    o.code.includes('unlimited')
            )
            if (this.variation) {
                return unlimited.filter(u => u.code.includes(`_male_${this.variation}`))
            } else {
                return ''
            }
        },
        unlimitedForFemale() {
            const unlimited = this.programs.filter(
                o =>
                    o.ptype === 'rate_plan' &&
                    (o.target === 'female' || o.target === 'all') &&
                    o.code.includes('unlimited')
            )
            if (this.variation) {
                return unlimited.filter(u => u.code.includes(`_female_${this.variation}`))
            } else {
                return ''
            }
        },
    },
}
</script>

<style scoped lang="scss">
.product-page {
    padding: 20px;

    .introduce-card {
        background-color: #fdfcfa;
        box-shadow: 0px 2px 20px 0px #0000001a;
        border-radius: 4px;
        padding: 14px 12px;

        .title {
            color: #151360;
            font-weight: 700;
        }

        .content {
            color: #7c7c7c;
            line-height: 18px;
        }
    }

    .gray-box {
        background-color: $grey-03;
        border-radius: 8px;
        padding: 10px 20px;
    }

    .tax-description {
        font-weight: 400;
        color: #a9a9a9;
    }

    // .purchase-button {
    //     margin-top: 40px;
    //     .purchase-btn {
    //         width: 100%;
    //         background: #151360;
    //         border-radius: 36px;
    //         color: #F7F7F4;
    //         height: 48px;
    //     }
    // }

    .blank {
        height: 100px;
    }

    .purchase-button {
        bottom: 0px;
        padding-bottom: 20px;
        width: calc(100% - 40px);
        // margin: 16px 0;
        // padding: 0 4px;
        height: 68px;
        position: fixed;
        background: #fff9ef;
        .purchase-btn {
            width: 100%;
            height: 100%;
            border: none;
            background: #151360;
            border-radius: 36px;
            color: #f7f7f4;
        }
    }
}
</style>
