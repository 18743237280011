<template>
    <div v-if="!loading" class="basic-profile bg-white">
        <ul class="bp">
            <li style="z-index: 2" class="items m-r-10" v-for="item in personalProfile" :key="item.id">
                <div v-if="item.value !== ''" class="item m-b-16">
                    <div class="key">
                        <img
                            class="m-l-18 m-r-10"
                            :src="require(`@/assets/images/icons/profile_${item.key}.png`)"
                            width="20px"
                            height="20px"
                            v-if="item.key !== ''"
                        />
                    </div>

                    <div class="value">
                        <div v-if="!item.locked || !item.unlockable || isPremium" class="content">
                            <span class="f-13">{{ item.value }}</span>
                            <div class="verified" v-if="item.id >= 3 && item.id < 7">
                                <div class="verified">
                                    <img
                                        class="m-r-3"
                                        src="@/assets/images/icons/check-circle.svg"
                                        width="12px"
                                        height="12px"
                                    />
                                    <span class="text f-12">인증완료</span>
                                </div>
                            </div>
                        </div>

                        <div v-else @click="showItemValue(item)" class="content">
                            <span class="upgrade-membership">{{ item.value }}</span>
                            <img
                                class="unlock m-r-18"
                                :src="require(`@/assets/images/icons/fi-sr-lock.png`)"
                                alt="locked"
                                width="18px"
                            />
                        </div>

                        <!-- <div v-else class="content">
                            <span
                                class="upgrade-membership f-13"
                                v-if="item.id === 7 || item.id === 8"
                                @click="upgradeMembership"
                                >{{ item.value }}</span
                            >

                            <div class="verified" v-if="item.id === 7 || item.id === 8">
                                <img
                                    class="m-r-3"
                                    src="@/assets/images/icons/check-circle.svg"
                                    width="12px"
                                    height="12px"
                                />
                                <span class="text f-12">인증완료</span>
                            </div>
                        </div> -->
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import profileService from '@/services/profile'
// import inquiryService from '@/services/inquiry'
import userService from '@/services/user'

export default {
    name: 'SpecProfile',
    props: {
        profile: {
            type: Object,
            required: true,
        },
        userId: {
            type: Number,
            required: true,
        },
        message: {
            type: Object,
        },
        openAll: {
            type: Boolean,
            required: true,
        },
    },
    data: () => ({
        loading: true,
        // university: null,
        bachelorModified: null,
        masterModified: null,
        doctorModified: null,
        companyNameModified: null,
        source: null,
        bachelor: null,
        master: null,
        doctor: null,
        profileItems: {
            companyName: { locked: true, unlockable: true },
            schoolBachelor: { locked: true, unlockable: true },
            schoolMaster: { locked: true, unlockable: true },
            schoolDoctor: { locked: true, unlockable: true },
            income: { locked: true, unlockable: true },
            asset: { locked: true, unlockable: true },
        },
    }),
    watch: {
        openAll(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.checkLocked()
            }
        },
    },
    created() {
        this.init()
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        isMe() {
            return this.$store.getters.me.id === this.userId
        },
        getTitle() {
            return `<i class="material-icons">person</i> 인적사항`
        },
        verifyStatus() {
            const badges = this.$store.getters.verificationBadges
            if (!badges || !badges.length) return false
            return this.$store.getters.verifyStatus.mandated
        },
        getProductName() {
            const products = this.$store.getters.products
            const mine = this.$store.getters.userRatePlans.onGoing.find(o => o.ptype === 'subscription_plan')

            if (mine) {
                return products.all.find(p => p.id === mine.product_id).name
            } else {
                return null
            }
        },
        isPremium() {
            if (this.getProductName && this.getProductName.includes('Marry Fit')) {
                return true
            } else {
                return false
            }
        },
        // content() {
        //     return this.$mustParse(this.message.content)
        // },
        employmentType() {
            return this.$profile.employmentType[this.profile.company[0].employment_type]
        },
        personalProfile() {
            const items = [
                {
                    id: 1,
                    key: 'job_detail',
                    value:
                        this.profile.company[0].company_name === '대학원생' || !this.employmentType
                            ? ''
                            : this.isPremium
                            ? `${this.profile.company[0].job_name} (${this.employmentType.title})`
                            : this.profile.company[0].job_name,
                },
                {
                    id: 2,
                    key: 'company',
                    value: this.profile.company[0].job_category_name,
                    result:
                        this.profile.company[0].company_name === '대학원생' || !this.employmentType
                            ? ''
                            : `${this.profile.company[0].job_category_name}`,
                },
                {
                    id: 3,
                    key: 'companyName',
                    profileType: 'open_company',
                    value:
                        this.profile.company[0].company_name === '대학원생' || !this.employmentType
                            ? ''
                            : this.isPremium && this.profile.company[0].company_state_name
                            ? `${this.profile.company[0].company_name} (${
                                  this.profile.company[0].company_state_name === '경기'
                                      ? this.profile.company[0].region_shorten
                                      : this.profile.company[0].company_state_name
                              })`
                            : !this.isPremium && this.profileItems.companyName.locked
                            ? '직장명: 오픈하기'
                            : `${this.profile.company[0].company_name} (${
                                  this.profile.company[0].company_state_name === '경기'
                                      ? this.profile.company[0].region_shorten
                                      : this.profile.company[0].company_state_name
                              })`,
                },

                {
                    id: 4,
                    key: 'schoolBachelor',
                    profileType: 'open_bachelor',
                    value: this.schoolValue('bachelor'),
                },
                {
                    id: 5,
                    key: 'schoolMaster',
                    profileType: 'open_master',
                    value: this.profile.school.id <= 3 ? this.schoolValue('master') : '',
                },
                {
                    id: 6,
                    key: 'schoolDoctor',
                    profileType: 'open_doctor',
                    value: this.profile.school.id === 1 ? this.schoolValue('doctor') : '',
                },
                {
                    id: 7,
                    key: 'income',
                    profileType: 'open_income',
                    value: this.krwParsedIncome,
                },
                {
                    id: 8,
                    key: 'asset',
                    profileType: 'open_asset',
                    value: this.krwParsedAsset,
                },
            ]

            // return items

            const result = items
                .filter(
                    item =>
                        (item.show === undefined || item.show) &&
                        (Number.isInteger(item.value) || typeof item.value === 'boolean' || item.value)
                )
                .map(item => {
                    let locked = false
                    let unlockable = true

                    if (
                        this.profileItems[item.key] &&
                        Object.hasOwnProperty.call(this.profileItems[item.key], 'locked') &&
                        Object.hasOwnProperty.call(this.profileItems[item.key], 'unlockable')
                    ) {
                        locked = this.profileItems[item.key].locked
                        unlockable = this.profileItems[item.key].unlockable
                    }

                    return {
                        id: item.id,
                        key: item.key,
                        profileType: item.profileType || undefined,
                        value: item.value,
                        locked,
                        unlockable,
                    }
                })

            return result
        },
        likeIcon() {
            return require(`@/assets/images/icons/like_inactive.png`)
        },
        krwParsedIncome() {
            if (!this.isPremium && this.profileItems.income.locked) {
                return '소득: 오픈하기'
            }

            const value = this.profile.income
            if (value === 0) {
                return '연소득 없음'
            }
            const val = String(value)
            if (val.length > 8) {
                const zo = val.slice(0, val.length - 8)
                const billion = val.slice(val.length - 8, val.length - 4)
                const rest = val.slice(val.length - 4, val.length)

                return `연소득: ${zo}조 ${Number(billion) ? `${Number(billion).toLocaleString()}억 ` : ''}${
                    Number(rest) ? `${Number(rest).toLocaleString()}만원` : ''
                }`
            }
            if (val.length > 4) {
                const billion = val.slice(0, val.length - 4)
                const rest = val.slice(val.length - 4, val.length)

                return `연소득: ${Number(billion) ? `${Number(billion).toLocaleString()}억 ` : ''}${
                    Number(rest) ? `${Number(rest).toLocaleString()}만원` : ''
                }`
            }

            return `연소득: ${value.toLocaleString()} 만원`
        },
        krwParsedAsset() {
            if (!this.isPremium && this.profileItems.asset.locked) {
                return '자산: 오픈하기'
            }

            const value = this.profile.asset
            if (value === 0) {
                return '자산 없음'
            }
            const val = String(value)
            if (val.length > 8) {
                const zo = val.slice(0, val.length - 8)
                const billion = val.slice(val.length - 8, val.length - 4)
                const rest = val.slice(val.length - 4, val.length)

                return `자산: ${zo}조 ${Number(billion) ? `${Number(billion).toLocaleString()}억 ` : ''}${
                    Number(rest) ? `${Number(rest).toLocaleString()}만원` : ''
                }`
            }
            if (val.length > 4) {
                const billion = val.slice(0, val.length - 4)
                const rest = val.slice(val.length - 4, val.length)

                return `자산: ${Number(billion) ? `${Number(billion).toLocaleString()}억 ` : ''}${
                    Number(rest) ? `${Number(rest).toLocaleString()}만원` : ''
                }`
            }

            return `자산: ${value.toLocaleString()} 만원`
        },
    },
    methods: {
        async init() {
            try {
                const source = await this.$store.dispatch('loadUserDetail', { userId: this.userId })
                this.source = source
                await this.checkLocked()
                await this.initUniversities()
            } catch (e) {
                console.error('init error: ', e)
            } finally {
                this.loading = false
            }
        },
        checkLocked() {
            const openAll = this.isMe
                ? true
                : this.source.photo_histories.item_histories.filter(o => o.code === 'open_all').length > 0 ||
                  this.openAll

            const companyNameLocked =
                !this.isPremium &&
                !openAll &&
                this.source.photo_histories.item_histories.filter(o => o.code === 'open_company').length === 0
            const bachelorLocked =
                !this.isPremium &&
                !openAll &&
                this.source.photo_histories.item_histories.filter(o => o.code === 'open_bachelor').length === 0
            const masterLocked =
                !this.isPremium &&
                !openAll &&
                this.source.photo_histories.item_histories.filter(o => o.code === 'open_master').length === 0
            const doctorLocked =
                !this.isPremium &&
                !openAll &&
                this.source.photo_histories.item_histories.filter(o => o.code === 'open_doctor').length === 0
            const incomeLocked =
                !this.isPremium &&
                !openAll &&
                this.source.photo_histories.item_histories.filter(o => o.code === 'open_income').length === 0
            const assetLocked =
                !this.isPremium &&
                !openAll &&
                this.source.photo_histories.item_histories.filter(o => o.code === 'open_asset').length === 0
            this.profileItems.companyName.locked = companyNameLocked
            this.profileItems.schoolBachelor.locked = bachelorLocked
            this.profileItems.schoolMaster.locked = masterLocked
            this.profileItems.schoolDoctor.locked = doctorLocked
            this.profileItems.income.locked = incomeLocked
            this.profileItems.asset.locked = assetLocked
        },

        toggleLock(key) {
            if (this.profileItems[key]) {
                this.profileItems[key].locked = !this.profileItems[key].locked
            }
        },

        async showItemValue(item) {
            // console.log('111: ', item)
            if (this.$parent.$parent.$options.name === 'ModalSpecScore') {
                return
            }

            const res = await this.$modal.custom({
                component: 'ModalOpenProfileDetail',
                options: {
                    profileType: item.profileType,
                },
            })
            if (res) {
                const payload = {
                    profile_type: res === 'all' ? 'open_all' : item.profileType,
                    photo_history_id: this.source.photo_histories.id,
                }
                try {
                    const result = await userService.openProfile(this.$store.getters.chat.id, this.message.id, payload)
                    this.$toast.success(result.msg)

                    if (res === 'all') {
                        this.$emit('updateOpenAll', true)
                    }

                    this.toggleLock(item.key)
                    await this.init()
                } catch (err) {
                    const idx = await this.$modal.basic({
                        body: err.data.msg,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-default',
                            },
                            {
                                label: '충전하러 가기',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    if (idx === 1) {
                        this.$stackRouter.push({ name: 'InAppStorePage' })
                    }
                }
            }
        },
        // unlockText() {
        //     return '멤버십 업그레이드하고 보기'
        // },

        async initUniversities() {
            if (this.profile.school.id === 1) {
                this.doctor = await profileService.universities({
                    schoolTypeId: null,
                    name: this.profile.doctor_u_name,
                })
                if (this.doctor.length > 0) {
                    if (this.doctor[0].spec_grade && this.doctor[0].spec_grade === 4) {
                        this.doctorModified = `박사: ${this.doctor[0].utype} (학교명, 전공 오픈하기)`
                    } else if (this.doctor[0].spec_grade >= 5) {
                        if (
                            [
                                '해외 명문대(세계 100위권)',
                                '해외명문대(세계 30위권)',
                                '해외명문대',
                                '아이비리그',
                                '해외대',
                            ].includes(this.doctor[0].utype)
                        ) {
                            this.doctorModified = `박사: ${this.profile.doctor_u_name} (전공 오픈하기)`
                        } else {
                            this.doctorModified = `박사: ${this.profile.doctor_u_name} (전공 오픈하기)`
                        }
                    } else {
                        if (this.doctor[0].utype === '해외대') {
                            this.doctorModified = `박사: ${this.doctor[0].utype} (${this.profile.doctor_m_name} 전공)`
                        } else {
                            this.doctorModified = '박사: 대학원 졸업'
                            this.profileItems.schoolDoctor.unlockable = false
                        }
                    }
                } else {
                    if (this.profile.doctor_u_name) {
                        this.doctorModified = `박사: ${this.profile.doctor_u_name} (전공 오픈하기)`
                    } else {
                        this.doctorModified = ''
                    }
                }
            }

            if (this.profile.school.id < 4) {
                this.master = await profileService.universities({
                    schoolTypeId: null,
                    name: this.profile.master_u_name,
                })
                if (this.master.length > 0) {
                    if (this.master[0].spec_grade && this.master[0].spec_grade === 4) {
                        this.masterModified = `석사: ${this.master[0].utype} (학교명, 전공 오픈하기)`
                    } else if (this.master[0].spec_grade >= 5) {
                        if (
                            [
                                '해외 명문대(세계 100위권)',
                                '해외명문대(세계 30위권)',
                                '해외명문대',
                                '아이비리그',
                                '해외대',
                            ].includes(this.master[0].utype)
                        ) {
                            this.masterModified = `석사: ${this.profile.master_u_name} (전공 오픈하기)`
                        } else {
                            this.masterModified = `석사: ${this.profile.master_u_name} (전공 오픈하기)`
                        }
                    } else {
                        if (this.master[0].utype === '해외대') {
                            this.masterModified = `석사: ${this.master[0].utype} (${this.profile.master_m_name} 전공)`
                        } else {
                            this.masterModified = '석사: 대학원 졸업'
                            this.profileItems.schoolMaster.unlockable = this.profile.school.id === 1
                        }
                    }
                } else {
                    if (this.profile.master_u_name) {
                        this.masterModified = `석사: ${this.profile.master_u_name} (전공 오픈하기)`
                    } else {
                        this.masterModified = ''
                    }
                }
            }
            if (this.profile.school.id) {
                this.bachelor = await profileService.universities({
                    schoolTypeId: null,
                    name: this.profile.bachelor_u_name,
                })
                if (this.bachelor.length > 0 && this.bachelor[0].spec_grade) {
                    if (this.profile.school.id === 9) {
                        this.bachelorModified = ''
                    } else if (this.bachelor[0].spec_grade === 4) {
                        this.bachelorModified = `학사: ${this.bachelor[0].utype} (학교명, 전공 오픈하기)`
                    } else if (this.bachelor[0].spec_grade >= 5) {
                        if (
                            [
                                '해외 명문대(세계 100위권)',
                                '해외명문대(세계 30위권)',
                                '해외명문대',
                                '아이비리그',
                                '해외대',
                            ].includes(this.bachelor[0].utype)
                        ) {
                            this.bachelorModified = `학사: ${this.profile.bachelor_u_name} (전공 오픈하기)`
                        } else {
                            this.bachelorModified = `학사: ${this.profile.bachelor_u_name} (전공 오픈하기)`
                        }
                    } else {
                        if (this.bachelor[0].utype === '해외대') {
                            this.bachelorModified = `학사: ${this.bachelor[0].utype} (${this.profile.bachelor_m_name} 전공)`
                        } else {
                            this.bachelorModified = '대학교 졸업'
                            if (this.profile.school.id >= 5) {
                                this.profileItems.schoolBachelor.unlockable = false
                            }
                        }
                    }
                } else {
                    if (this.profile.bachelor_u_name) {
                        this.bachelorModified = `학사: ${this.profile.bachelor_u_name} (전공 오픈하기)`
                    } else {
                        this.bachelorModified = ''
                    }
                }
            }
        },
        schoolValue(key) {
            if (key === 'bachelor') {
                if (this.isMe || this.isPremium) {
                    if (this.profile.school_id === 9) {
                        return ''
                    } else if (
                        this.bachelor.length > 0 &&
                        this.bachelor[0].spec_grade &&
                        this.bachelor[0].spec_grade >= 5 &&
                        [
                            '해외 명문대(세계 100위권)',
                            '해외명문대(세계 30위권)',
                            '해외명문대',
                            '아이비리그',
                            '해외대',
                        ].includes(this.bachelor[0].utype)
                    ) {
                        return `학사: ${this.profile.bachelor_u_name} (${this.bachelor[0].utype}) (${this.profile.bachelor_m_name} 전공)`
                    } else {
                        return `학사: ${this.profile.bachelor_u_name} (${this.profile.bachelor_m_name} 전공)`
                    }
                } else {
                    if (this.profileItems.schoolBachelor.locked) {
                        if (this.profile.school_id < 5) {
                            return '학사: 오픈하기'
                        } else {
                            return this.bachelorModified
                        }
                    } else {
                        if (this.profile.school_id === 9) {
                            return ''
                        } else if (this.profile.school.id >= 5 && this.bachelorModified === '대학교 졸업') {
                            return this.bachelorModified
                        } else if (
                            this.bachelor.length > 0 &&
                            this.bachelor[0].spec_grade &&
                            this.bachelor[0].spec_grade >= 5 &&
                            [
                                '해외 명문대(세계 100위권)',
                                '해외명문대(세계 30위권)',
                                '해외명문대',
                                '아이비리그',
                                '해외대',
                            ].includes(this.bachelor[0].utype)
                        ) {
                            return `학사: ${this.profile.bachelor_u_name} (${this.bachelor[0].utype}) (${this.profile.bachelor_m_name} 전공)`
                        } else {
                            return `학사: ${this.profile.bachelor_u_name} (${this.profile.bachelor_m_name} 전공)`
                        }
                    }
                }
            } else if (key === 'master') {
                if (this.isMe || this.isPremium) {
                    if (
                        this.master.length > 0 &&
                        this.master[0].spec_grade &&
                        this.master[0].spec_grade >= 5 &&
                        [
                            '해외 명문대(세계 100위권)',
                            '해외명문대(세계 30위권)',
                            '해외명문대',
                            '아이비리그',
                            '해외대',
                        ].includes(this.master[0].utype)
                    ) {
                        return `석사: ${this.profile.master_u_name} (${this.master[0].utype}) (${this.profile.master_m_name} 전공)`
                    } else {
                        return `석사: ${this.profile.master_u_name} (${this.profile.master_m_name} 전공)`
                    }
                } else {
                    if (this.profileItems.schoolMaster.locked) {
                        if (this.profile.school_id < 3 && this.profileItems.schoolMaster.unlockable) {
                            return '석사: 오픈하기'
                        } else {
                            return this.masterModified
                        }
                    } else {
                        if (
                            this.master.length > 0 &&
                            this.master[0].spec_grade &&
                            this.master[0].spec_grade >= 5 &&
                            [
                                '해외 명문대(세계 100위권)',
                                '해외명문대(세계 30위권)',
                                '해외명문대',
                                '아이비리그',
                                '해외대',
                            ].includes(this.master[0].utype)
                        ) {
                            return `석사: ${this.profile.master_u_name} (${this.master[0].utype}) (${this.profile.master_m_name} 전공)`
                        } else {
                            return `석사: ${this.profile.master_u_name} (${this.profile.master_m_name} 전공)`
                        }
                    }
                }
            } else if (key === 'doctor') {
                if (this.isMe || this.isPremium) {
                    if (
                        this.doctor.length > 0 &&
                        this.doctor[0].spec_grade &&
                        this.doctor[0].spec_grade >= 5 &&
                        [
                            '해외 명문대(세계 100위권)',
                            '해외명문대(세계 30위권)',
                            '해외명문대',
                            '아이비리그',
                            '해외대',
                        ].includes(this.doctor[0].utype)
                    ) {
                        return `박사: ${this.profile.doctor_u_name} (${this.doctor[0].utype}) (${this.profile.doctor_m_name} 전공)`
                    } else {
                        return `박사: ${this.profile.doctor_u_name} (${this.profile.doctor_m_name} 전공)`
                    }
                } else {
                    if (this.profileItems.schoolDoctor.locked) {
                        if (this.profileItems.schoolMaster.unlockable) {
                            return this.doctorModified
                        } else {
                            return '박사: 오픈하기'
                        }
                    } else {
                        if (
                            this.doctor.length > 0 &&
                            this.doctor[0].spec_grade &&
                            this.doctor[0].spec_grade >= 5 &&
                            [
                                '해외 명문대(세계 100위권)',
                                '해외명문대(세계 30위권)',
                                '해외명문대',
                                '아이비리그',
                                '해외대',
                            ].includes(this.master[0].utype)
                        ) {
                            return `박사: ${this.profile.doctor_u_name} (${this.doctor[0].utype}) (${this.profile.doctor_m_name} 전공)`
                        } else {
                            return `박사: ${this.profile.doctor_u_name} (${this.profile.doctor_m_name} 전공)`
                        }
                    }
                }
            }
        },
        showBadge(key) {
            if (!this.isMe) return false
            return !this.profile.privacy_setting[`show_${key}`]
        },
    },
}
</script>
<style scoped lang="scss">
ul li {
    border-bottom: 1px solid #f4f4f4;
    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .content-detail {
            display: flex;
            align-content: space-between;
            color: rgb(29, 47, 101);
        }

        .verified {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .text {
                white-space: nowrap;
                color: #00aa77;
            }
        }
    }
}

ul li:last-child {
    border-bottom: none;
}

.upgrade-membership {
    padding-right: 40px;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.unlock {
    position: absolute;
    right: 10px;
}
</style>
