<template>
    <div class="front" v-if="!this.loading" v-lazy:background-image="require(`@/assets/images/front/img_front_bg.png`)">
        <div class="entrance">
            <div class="head f-light" @click="increaseCounter">
                <div class="head-line center spoqa-f-bold">
                    <img :src="require('@/assets/images/front_logo.png')" alt style="width: 60%" />
                </div>
            </div>
            <div class="foot">
                <button class="btn" @click="signupPage">
                    <p class="label f-regular">신규 회원가입하기</p>
                </button>
                <div class="hr m-t-16" />
                <div class="signup-wrap">
                    <div class="move-web" @click="startWithPhone">이미 사용중인 회원이세요?</div>
                </div>
                <div class="hr m-t-16" />
                <div class="consent-text">
                    <span class="text" v-html="'회원가입, 로그인을 완료하면 '" />
                    <span class="text-bold" @click="openConsent('service')" v-html="'이용약관'" />
                    <span class="text" v-html="', <br>'" />
                    <span class="text-bold" @click="openConsent('privacy')" v-html="'개인정보처리방침'" />
                    <span class="text" v-html="'에 동의하는 것으로 간주합니다.'" />
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <Loading :loading="loading" />
    </div>
</template>

<script>
export default {
    name: 'FrontPage',
    data: () => ({
        counter: 0,
        counterInterv: null,
        provider: null,
        coupleCnt: null,
        loading: true,
    }),
    watch: {
        counter(newVal) {
            if (newVal !== 5) return

            this.$modal.custom({
                component: 'ModalSelectEnv',
                options: {
                    preventCloseOnMousedownMask: true,
                },
            })
        },
    },
    computed: {},
    methods: {
        openConsent(type) {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://jj-api.vanillabridge.com/api/users/terms?term_type=${type}`,
            })
        },
        watchCounter() {
            this.counterInterv = setInterval(() => {
                if (this.counter > 0) this.counter -= 1
            }, 1000)
        },
        increaseCounter() {
            if (this.counter < 5) this.counter++
        },
        async signupPage() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `FrontPage_Click_Signup`,
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `FrontPage_Click_Signup`,
                },
            })
            this.$router.push({ name: 'OnboardCheckPassed' })
        },
        startWithPhone() {
            this.$router.push({ name: 'StartWithPhonePage' })
        },
        login() {
            this.$router.push({ name: 'LoginPage' })
        },
    },
    async mounted() {
        setTimeout(async () => {
            this.loading = false
        }, 1000)

        this.$store.dispatch('loadConstants')
        this.$nativeBridge.postMessage({
            action: 'sendFirebaseEvent',
            value: {
                category: 'Enter_FrontPage',
            },
        })

        setTimeout(() => {
            const date = new Date()
            const year = Number(date.getFullYear())
            const month = Number(date.getMonth())
            const day = Number(date.getDay())

            this.coupleCnt = ((year * (month + day)) % 67) + 60
        }, 500)

        this.watchCounter()
    },
    beforeDestroy() {
        if (this.counterInterv) {
            clearInterval(this.counterInterv)
            this.counterInterv = null
        }
    },
}
</script>

<style lang="scss" scoped>
.front {
    $footer-height: 152px;
    height: 100vh !important;
    position: relative;
    overflow: hidden !important;
    width: 100%;
    background-size: 100%;

    .front-header {
        color: black;
        height: $header-height;
        padding: 14px;
        position: sticky;
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include spoqa-f-regular;
    }

    .entrance {
        height: 100%;
        padding: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .head {
            color: white;
            padding-top: 210px;

            &-line {
                .img-wrapper {
                    color: white;
                    padding-top: 210px;
                }
                .img {
                    width: 130px;
                    height: auto;
                }
            }
        }
        .foot {
            padding-bottom: 100px;
            .divider {
                border-bottom: solid 1px rgba(255, 255, 255, 0.2);
            }

            .bar {
                color: $grey-04;
                margin: 10px;
            }

            button {
                height: 52px;
                width: 100%;
                font-size: 15px;
                background: #151360;
                color: white;
                border-radius: 32px;
                @include spoqa-f-medium;

                &.signup-email {
                    color: $grey-09;
                    font-size: 14px;

                    @include f-regular;

                    .material-icons {
                        margin-right: 10px;
                        color: $blue-primary;
                    }
                }
                &.signup {
                    background: $blue-primary;
                }
            }

            .hr {
                width: 100vw;
                margin-left: -20px;
                height: 1px;
                background: rgba(255, 255, 255, 0.2);
            }

            .consent-text {
                text-align: center;
                font-size: 12px;
                line-height: 16.5px;
                @include f-medium;
                color: $grey-05;

                .text-bold {
                    text-decoration: underline;
                    @include f-bold;
                }
            }

            .signup-wrap {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                color: #393d44;
                text-align: center;

                .move-web {
                    color: #151360;
                    line-height: 13px;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    text-decoration-line: underline;
                    font-family: Pretendard;
                }
            }
        }
    }
}
</style>
