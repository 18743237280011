<template>
    <div class="nps-main">
        <h2 class="question f-bold" v-html="question" />
        <NPS @select="onSelectScore" />
    </div>
</template>

<script>
import NPS from './components/NPS'

export default {
    name: 'NPSMainPage',
    components: { NPS },
    data: () => ({
        question: '바닐라브릿지를<br>친구나 동료에게<br>추천할 의향이 얼마나 있나요?',
    }),
    mounted() {
        this.$registerBackHandler(this.backHandler)
        const date = new Date()
        const userId = this.$store.getters.me.id
        window.localStorage.setItem(`nps${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}${userId}`, true)
    },
    methods: {
        onSelectScore(score) {
            this.$store.commit('setPayloads', {
                npsData: { score },
            })
            setTimeout(() => {
                this.$router.push({ name: 'NPSFeedbackPage', params: { score } })
            }, 500)
        },
        backHandler() {
            this.$modal.basic({
                title: 'NPS_CANCEL_TITLE',
                body: this.$translate('NPS_CANCEL_BODY'),
                buttons: [{
                    label: 'NPS_BUTTON_CANCEL',
                    class: 'btn-default',
                }, {
                    label: 'NPS_BUTTON_EVALUATE',
                    class: 'btn-primary',
                }],
            }).then(idx => {
                if (idx === 0) {
                    this.$unregisterBackHandler()
                    this.$router.go(-1)
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .nps-main {
        padding: 20px;

        .question {
            margin: 0;
            margin-bottom: 80px;
            font-size: 24px;
            line-height: 1.5;
            color: black;
            font-weight: normal;
        }
    }
</style>
