<template>
    <div class="root">
        <span class="page-title" @click="onClickReport">
            말씀해 주신 의견은 최대한 반영해 <br />
            빠르게 처리할 수 있도록 할게요 :)
        </span>
        <span class="page-report-title">건의 & 불편사항 신고</span>
        <UpdateBox title="업데이트 내용" :updateItems="items" />
    </div>
</template>

<script>
import UpdateBox from './components/UpdateBox'

export default {
    name: 'CommunicationPage',
    components: {
        UpdateBox,
    },
    methods: {
        onClickReport() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value:
                    'https://docs.google.com/forms/d/e/1FAIpQLSc8UBJEBzG2ZtvsaPLW6CqKNmIM75NI_78xugkvfHCwbRji5w/viewform?usp=sf_link',
            })
        },
    },
    data: () => ({
        items: [
            {
                date: '21.11.17',
                description: '약속잡기 기능이 추가되었습니다!<br />주선자를 통해 약속을 더 편하게 잡을 수 있어요☺️',
            },
            {
                date: '21.10.05',
                description: '스카이메리가 출시되었습니다!<br />친구분들에게 널리 알려주세요☺️',
            },
        ],
    }),
}
</script>

<style lang="scss" scoped>
div.root {
    width: 100%;
    height: 100%;
    color: $blue-primary;
    display: flex;
    flex-direction: column;
    padding: 16px;
    letter-spacing: -0.2px;

    span.page-title {
        color: black;
        font-size: 22px;
        letter-spacing: -0.2px;
        padding-bottom: 16px;

        @include f-medium;
    }

    span.page-report-title {
        color: currentColor;
        font-size: 14px;
        text-decoration: underline;
        letter-spacing: -0.2px;
        padding-bottom: 40px;
    }
}
</style>
