<template>
    <div class="my-page-menu">
        <ul class="menu">
            <li class="item" v-for="item in upper" :key="item.key" @click="onClickMenu(item.route)">
                <img class="icons-outlined icon" v-if="item.icon" :src="item.icon" />
                <span class="key" v-html="$translate(item.key)" />
                <i class="material-icons i-right">chevron_right</i>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'MyPageMenu',
    props: {
        stepToast: {
            type: [Boolean, Object],
        },
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        isFemale() {
            return (this.$store.getters.me || {}).gender === 1
        },
        showInviation() {
            const current = new Date()
            const createdAt = new Date(this.me.created_at)
            const limitDate = new Date(2024, 2, 7) // 초대 코드 배포 날짜 (3월 7일)
            const hasInvitation = this.me.has_entered_invitation_code
            const oneDay = 24 * 60 * 60 * 1000
            const diffDays = Math.floor(Math.abs((current.getTime() - createdAt.getTime()) / oneDay))

            if ((diffDays >= 1 && hasInvitation === 0) || createdAt <= limitDate || hasInvitation > 0) return false
            else {
                return true
            }
        },
        upper() {
            const items = [
                {
                    key: 'BLOCK_FRIEND',
                    route: 'BlockUserPage',
                    icon: require(`@/assets/images/block_friends.png`),
                    show: true,
                },
                {
                    key: 'ENTER_INVITE_FRIEND',
                    route: 'EnterCodePage',
                    icon: require(`@/assets/images/rateticket.png`),
                    show: this.showInviation,
                },
                {
                    key: 'SETTINGS',
                    route: 'SettingsPage',
                    icon: require(`@/assets/images/setting.png`),
                    show: true,
                },
                // {
                //     // key: this.chats.some(chat => chat.agent_id === 4) ? 'CUSTOMER_CENTER' : 'SUPPORT_MANAGER',
                //     key: 'CUSTOMER_CENTER',
                //     route: 'ChatroomPage',
                // },
                // {
                //     key: 'IMPORT_CONTRACT',
                //     route: 'MakeContractPage',
                // },
                // {
                //     key: 'IMPORT_TRUTH_CONTRACT',
                //     route: 'MakeTruthContractPage',
                // },
                {
                    key: 'COUPLE_REVIEW',
                    route: 'COUPLE_REVIEW',
                    icon: require(`@/assets/images/icons/profile_marryPlan.png`),
                    show: true,
                },
            ]
            return items.filter(item => item.show)
        },
        userContract() {
            return (this.$store.getters.me || {}).user_contract
        },
        // showContractTab() {
        //     return key => {
        //         if (['IMPORT_CONTRACT', 'IMPORT_TRUTH_CONTRACT'].indexOf(key) < 0) return true
        //         else if (this.userContract) return true
        //         // else if (key === 'IMPORT_CONTRACT') return true
        //         // // 진실성 담보 계약서 계약서 버전별로 보여주는것 분기
        //         // else if (this.userContract.plan_type === 'postpaid') return true
        //         // else if (this.userContract.plan_type === 'prepaid' && this.userContract.version >= 3) return true
        //         // else return false
        //     }
        // },
        chats() {
            return (this.$store.getters.chats || []).filter(chat => chat.users !== null) || []
        },
    },
    methods: {
        async onClickMenu(route) {
            if (route === 'CustomerCenterPage') {
                this.$router.push({ name: route })
                return
            }
            if (route === 'ChatroomPage') {
                const chat =
                    this.chats.find(chat => chat.agent_id === 2) || this.chats.find(chat => chat.agent_id === 7)
                const unreadCount = chat.unread
                await this.$store.dispatch('loadChat', {
                    chatId: chat.id,
                    force: true,
                })
                this.$router.push({
                    name: route,
                    params: { unreadCount },
                })
                return
            }

            if (route === 'MakeContractPage') {
                if (this.stepToast && this.stepToast.step <= 1) {
                    this.$toast.success(this.stepToast.msg)
                    return
                }
            }

            if (route === 'COUPLE_REVIEW') {
                this.$nativeBridge.postMessage({
                    action: 'openInAppBrowser',
                    value: 'https://goodpersonlove.oopy.io/',
                })
                return
            }

            this.$stackRouter.push({ name: route })
        },
        onClickBack() {
            console.log('yes')
        },
    },
}
</script>

<style lang="scss" scoped>
.my-page-menu {
    // .hr.thick {
    //     background: $grey-02;
    //     border: 0;
    //     height: 8px;
    // }

    // .hr {
    //     height: 1px;
    //     background: $grey-01;

    //     &.thick {
    //         height: 8px;
    //     }
    // }

    .item {
        height: 56px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        position: relative;

        border-bottom: solid 1px #dcdcdc;
        &:first-child {
            border-top: solid 1px #dcdcdc;
        }

        .icon {
            width: 16px;
            margin-right: 12px;
            color: #5a588a;
            color: $grey-09;
        }

        i {
            position: absolute;
            right: 20px;
        }

        .key {
            font-size: 16px;
            color: black;
        }
        // &:nth-child(1) {
        //     border-bottom: solid 8px rgb(247, 248, 249);
        // }
    }
}
</style>
