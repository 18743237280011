<template>
    <div class="edit-profile-detail">
        <div class="section">
            <div class="content f-15" style="line-height: 24px; margin: 24px 20px">Q. 가입 심사에 합격하셨나요?</div>
            <div class="column">
                <div
                    class="item bg-white m-l-20 m-r-20"
                    :class="{ selected: item.id === selectedIdx }"
                    v-for="item in items"
                    :key="item.id"
                    v-html="item.name"
                    @click="onClickItem(item.id)"
                />
            </div>
        </div>
        <!--        <BottomButton :disabled="disabled" @click="next" :label="$translate('NEXT')" />-->
    </div>
</template>

<script>
import signupConstant from '@/assets/constants/onboard-temp-signup'
export default {
    name: 'CheckPassed',
    data: () => ({
        selectedIdx: null,
        nextRoute: 'OnboardAgreeDirectionality',
    }),
    computed: {
        tempSignupPayload() {
            return JSON.parse(localStorage.getItem('tempSignupPayload')) || {}
        },
        items() {
            return signupConstant.checkPassed
        },
        disabled() {
            return this.selectedIdx === null
        },
    },
    mounted() {
        this.selectedIdx = this.tempSignupPayload.checkPassed
        if (this.tempSignupPayload.checkPassed === undefined) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Enter_CheckPassedPage`,
                },
            })
        }
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: {
                top: '#FAF0E1',
                bottom: '#FFF9EF',
            },
        })
    },
    methods: {
        onClickItem(idx) {
            if (idx === 2) {
                this.$modal
                    .basic({
                        body: '지금 바로 프로필을 등록해주시면, 가입 심사 진행 후 합격 안내를 드리겠습니다.',
                        buttons: [
                            {
                                label: '취소',
                                class: 'btn-default',
                            },
                            {
                                label: '확인',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(i => {
                        if (i === 1) {
                            // this.$nativeBridge.postMessage({
                            //     action: 'openInAppBrowser',
                            //     value: `https://goodperson.date`,
                            // })
                            setTimeout(() => this.next(), 200)
                        }
                    })
            } else {
                this.selectedIdx = idx

                setTimeout(() => this.next(), 200)
            }
        },
        next() {
            const payload = { ...this.tempSignupPayload, checkPassed: this.selectedIdx }
            localStorage.setItem('tempSignupPayload', JSON.stringify(payload))

            this.$router.push({ name: 'OnboardAgreeDirectionality' })
        },
    },
}
</script>
