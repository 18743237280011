<template>
    <section class="section" :class="{ disabled }">
        <div class="title m-b-8">결제 수단</div>
        <div class="payment-buttons grid-col">
            <div
                v-for="item in paymentMethod"
                :key="item.id"
                class="btn btn-normal"
                :class="{ selected: selected === item.id }"
                @click="clickPayMethod(item.id)"
                v-html="item.kor"
            />
        </div>
    </section>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
        },
    },
    data: () => ({
        selected: null,
        paymentMethod: [
            {
                id: 0,
                name: 'card',
                kor: `카드 <span style="font-size: 10px;">(무이자 할부)</span>`,
            },
            {
                id: 1,
                name: 'bank_account',
                kor: `무통장 입금`,
            },
        ],
    }),
    methods: {
        clickPayMethod(id) {
            this.selected = id
            this.$emit('select', id)
        },
    },
}
</script>

<style scoped lang="scss">
.btn-normal {
    border: 1px solid #b9bdc1 !important;
    background: #ffffff;
    color: black !important;
    height: px;
    display: flex;
    flex-direction: column;
}
.card_text {
    font-size: 7px;
}

.inactive {
    border: 1px solid #b9bdc1 !important;
    background: #ffffff;
    color: $grey-04 !important;
}
.selected {
    border: 1px solid #b9bdc1 !important;
    color: #151360 !important;
    background: #e8e4e7;
}
.disabled * {
    color: #e9e9e9 !important;
}
</style>
