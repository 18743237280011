<template>
    <div class="edit-profile-detail">
        <div class="title">자녀여부</div>
        <div class="section">
            <div class="column">
                <div
                    class="item"
                    :class="{ selected: item.id === selectedIdx }"
                    v-for="item in items"
                    :key="item.id"
                    v-html="item.name"
                    @click="onClickItem(item.id)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import signupConstant from '@/assets/constants/onboard-temp-signup'

export default {
    name: 'HaveChild',
    computed: {
        tempSignupPayload() {
            return JSON.parse(localStorage.getItem('tempSignupPayload')) || {}
        },
        items() {
            return signupConstant.haveChild
        },
        disabled() {
            return this.selectedIdx === null
        },
    },
    data: () => ({
        selectedIdx: null,
    }),
    methods: {
        onClickItem(idx) {
            this.selectedIdx = idx

            setTimeout(() => this.next(), 200)
        },
        next() {
            const payload = { ...this.tempSignupPayload, have_child: this.selectedIdx }
            localStorage.setItem('tempSignupPayload', JSON.stringify(payload))

            this.$router.push({ name: 'OnboardMarryPlan' })
        },
    },
    mounted() {
        this.selectedIdx = this.tempSignupPayload.have_child || null
        if (this.tempSignupPayload.have_child === undefined) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Enter_ChildrenPage`,
                },
            })
        }
    },
}
</script>

<style scoped></style>
