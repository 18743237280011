var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"membership-introduction"},[_c('div',{staticClass:"intro-section"},[_c('div',{staticClass:"intro-text"},[(_vm.planDetail.name.includes('Marry Fit') && !_vm.planDetail.name.includes('Plus'))?[_vm._m(0)]:[_vm._m(1)]],2)]),(!_vm.planDetail.name.includes('Marry Fit'))?_c('div',{staticClass:"membership-section love-fit"},[_c('div',{staticClass:"current-label"},[_vm._v("현재 이용중")]),_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/membership-intro/icon1.png"),"alt":"Heart Icon"}}),_c('p',{staticClass:"membership-name"},[_vm._v("Love Fit")]),_c('div',{staticClass:"description spacing-over"},[_c('p',{staticClass:"mid-text"},[_vm._v("밸런스 매칭")]),_c('p',{staticClass:"big-text"},[_vm._v("진지한 연애")]),(_vm.windowWidth <= 300)?_c('div',[_vm._m(2)]):_c('div',[_vm._m(3)])])]):_vm._e(),_c('div',{staticClass:"membership-section",class:{
            'marry-fit': _vm.planDetail.name.includes('Marry Fit') && !_vm.planDetail.name.includes('Plus'),
            'marry-fit-alt': !_vm.planDetail.name.includes('Marry Fit'),
        }},[(_vm.planDetail.name.includes('Marry Fit') && !_vm.planDetail.name.includes('Plus'))?_c('div',{staticClass:"current-label"},[_vm._v(" 현재 이용중 ")]):_vm._e(),_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/membership-intro/icon2.png"),"alt":"Ring Green Icon"}}),_c('p',{staticClass:"membership-name"},[_vm._v("Marry Fit")]),_c('div',{staticClass:"description spacing-over"},[_vm._m(4),_c('p',{staticClass:"big-text"},[_vm._v(" "+_vm._s('1~2년 이내 결혼 생각이 있는')+" ")]),(_vm.windowWidth <= 300)?_c('div',[_vm._m(5)]):_c('div',[_vm._m(6)])]),(!(_vm.planDetail.name.includes('Marry Fit') && !_vm.planDetail.name.includes('Plus')))?_c('button',{staticClass:"marry-fit-button",on:{"click":function($event){return _vm.goToDetailPage('mf_3month')}}},[_vm._v(" "+_vm._s('멤버십 혜택 살펴보기')+" > ")]):_vm._e()]),_c('div',{staticClass:"membership-section marry-fit-plus"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/images/membership-intro/icon3.png"),"alt":"Ring Blue Icon"}}),_c('p',{staticClass:"membership-name"},[_vm._v("Marry Fit+")]),_c('div',{staticClass:"description spacing-over"},[_vm._m(7),_c('p',{staticClass:"big-text"},[_vm._v(" "+_vm._s('1년 이내 결혼 생각이 있는')+" ")]),(_vm.windowWidth <= 300)?_c('div',[_vm._m(8)]):_c('div',[_vm._m(9)])]),_c('button',{staticClass:"marry-fit-plus-button",on:{"click":function($event){return _vm.goToDetailPage('mfplus_3month')}}},[_vm._v(" "+_vm._s('멤버십 혜택 살펴보기')+" > ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro-text-description"},[_c('div',[_c('p',{staticClass:"title-text"},[_vm._v("마음에 드는 분과의 매칭")]),_c('p',{staticClass:"title-text"},[_vm._v("혼자서는 아직 힘드신가요?")])]),_c('div',[_c('p',{staticClass:"title-text"},[_vm._v("지금 멤버십을 업그레이드하고")]),_c('p',{staticClass:"title-text"},[_c('span',{staticClass:"highlight-text"},[_vm._v("1:1 전문 컨설턴트")]),_vm._v("의")]),_c('p',{staticClass:"title-text"},[_c('span',{staticClass:"highlight-text"},[_vm._v("집중 매칭 관리")]),_vm._v(" 받아보세요")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro-text-description"},[_c('div',[_c('p',{staticClass:"title-text"},[_vm._v("더 좋은 사람과")]),_c('p',{staticClass:"title-text"},[_vm._v("더 빠르게 매칭되길 원하시나요?")])]),_c('div',[_c('p',{staticClass:"title-text"},[_c('span',{staticClass:"highlight-text"},[_vm._v("멤버십 회원")]),_vm._v("이 되어")]),_c('p',{staticClass:"title-text"},[_c('span',{staticClass:"highlight-text"},[_vm._v("우선 매칭 기회")]),_vm._v("를 누려보세요.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mid-text-description"},[_vm._v(" 기본적인 외모 + 조건 밸런스 "),_c('br'),_vm._v(" 나와 비슷한 분들의 매칭 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mid-text-description"},[_vm._v(" 기본적인 외모 + 조건 밸런스 "),_c('br'),_vm._v(" 나와 비슷한 분들과의 매칭이 진행됩니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mid-text"},[_c('span',{staticClass:"highlight-text"},[_vm._v("최우선 매칭 기회")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mid-text-description"},[_vm._v(" 연봉, 자산 등 모든 프로필 열람 권한과 "),_c('br'),_vm._v(" 이상형 \"우선\" 매칭의 혜택 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mid-text-description"},[_vm._v(" 연봉, 자산, 결혼계획 등 모든 프로필 정보 열람과 함께 "),_c('br'),_vm._v(" 이상형 “우선\" 매칭 혜택이 주어집니다. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mid-text"},[_c('span',{staticClass:"highlight-blue-text"},[_vm._v("실시간 맞춤 관리")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mid-text-description"},[_vm._v(" 최상위 회원님과의 매칭을 위한 "),_c('br'),_vm._v(" 프리미엄 관리/분석 컨설팅까지 제공 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mid-text-description"},[_vm._v(" 최상위 회원님과의 매칭을 위한 프리미엄 정보부터"),_c('br'),_vm._v(" 프로필 관리/분석 및 컨설팅이 디테일하게 제공됩니다. ")])
}]

export { render, staticRenderFns }