<template>
    <div class="star-rating">
        <div class="star-rating-wrapper flex-row">
            <div
                @click.stop="toggleRating(star)"
                class="star-wrapper flex-wrap"
                :key="idx"
                v-for="(star, idx) in stars">
                <img
                    class="star-icon"
                    :src="star.active ? active : inactive">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StarRating',
    props: [
        'icon',
        'length',
        'default',
        'fixed',
        'inactiveIcon',
        'activeIcon',
    ],
    data: () => ({
        inactive: null,
        active: null,
        stars: [],
    }),
    computed: {
        defaultIcon() {
            return {
                star: {
                    active: require('@/assets/images/rating/icon_star_rate_active.svg'),
                    inactive: require('@/assets/images/rating/icon_star_rate_inactive.svg'),
                },
                heart: {
                    active: require('@/assets/images/rating/icon_heart_rate_active.svg'),
                    inactive: require('@/assets/images/rating/icon_heart_rate_inactive.svg'),
                },
                whiteStar: {
                    active: require('@/assets/images/rating/white_star.svg'),
                    inactive: require('@/assets/images/rating/white_star.svg'),
                },
            }
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            const initIcons = () => {
                this.inactive = this.inactiveIcon || this.defaultIcon[this.icon || 'star'].inactive
                this.active = this.activeIcon || this.defaultIcon[this.icon || 'star'].active
            }

            const initStars = () => {
                const d = Math.round(this.default || 0)
                for (let i = 0; i < (this.length || 5); i += 1) {
                    this.stars.push({ position: i, active: d > i })
                }
            }

            initIcons()
            initStars()
        },
        toggleRating(star) {
            if (this.fixed) return

            this.stars.forEach((s) => { s.active = s.position <= star.position })
            this.$emit('onRating', star.position + 1)
        },
    },
}
</script>
