<template>
    <div class="user-chat-info" :class="{ 'schedule-change': chatType === 'schedule_change' }">
        <div class="item flex-row" v-for="(item, i) in infos" :key="i">
            <div class="dot m-r-4" />
            <div class="desc" v-html="item" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserChatInfo',
    props: ['chatType'],
    computed: {
        infos() {
            if (this.chatType === 'users') {
                return [
                    '간단한 인사만 나누시고, 이야기는 내일 만나서 나누시는걸 추천드려요.',
                    `(중요) 프라이버시 보호를 위해 <span class="c-red" style="text-decoration: underline;">연락처와 카톡 공유는 권장되지 않습니다.</span>`,
                    '(연락처 교환) 만난 다음날(D+1일) 만남 후기를 작성할 때 애프터 의향을 물어보는 질문이 있어요. 양쪽이 둘다 YES 한 경우에 저희가 연락처를 전달드립니다.',
                ]
            } else if (this.chatType === 'schedule_change') {
                return [
                    '채팅방에서는 약속 일정 조율 관련 이야기만 나눠주세요.',
                    '일정 관리를 위해 매니저가 채팅방의 내용을 확인할 수 있습니다.',
                ]
            }
            return []
        },
    },
}
</script>

<style scoped lang="scss">
.user-chat-info {
    background: $grey-02;
    border-radius: 4px;
    padding: 8px 12px;

    &.schedule-change {
        margin-top: 50px;
    }
    .item {
        @include spoqa-f-regular;
        font-size: 12px;
        color: #666b70;
        line-height: 20px;
    }
}
</style>
