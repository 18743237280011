import * as $http from 'axios'

export default {
    all: () => $http.get('v2/products'),
    buy: (data, productId) => $http.post(`v2/products/${productId}/buy`, data),
    createOrder: (productId, payload) => $http.post(`v2/products/${productId}/orders`, payload),
    deleteWithdrawOrder: () => $http.put(`v2/products/delete_withdraw_order`),
    discountUsers: code => $http.get(`v2/user_discounts?discount_code=${code}`),
    inAppPurchase: (productId, payload) => $http.post(`v2/products/${productId}/inapp_purchase`, payload),
    inAppConfirm: (productId, payload) => $http.post(`v2/products/${productId}/inapp_confirm`, payload),
}
