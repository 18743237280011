import Vue from 'vue'

export const findChatByMessage = (chats, message) => {
    let chatId = message.chat_id
    if (!chatId) {
        /*
            If message.chat_id doesn't exist, it's a message
            directly coming from the websocket with wrapper.
        */
        chatId = message.message.chat_id
    }
    return (chats || []).find(c => c.id === chatId)
}

export const canSkipApiCall = (state, data) => {
    if (!state.lastApiCall) {
        Vue.set(state, 'lastApiCall', {})
    }

    const initLastApiCallTime = () => Vue.set(state.lastApiCall, data, Vue.prototype.$moment())
    // If it's never called, can't skip API call.
    if (!state.lastApiCall[data]) {
        initLastApiCallTime()
        return false
    }

    const diff = Vue.prototype.$moment().diff(state.lastApiCall[data], 'seconds')
    if (diff <= 60 * 5) {
        return true
    }
    initLastApiCallTime()
    return false
}
