<template>
    <div v-if="keywords.length > 0" class="keywords">
        <h3 class="title">
            <img class="m-r-2" src="@/assets/images/icons/tag.png" width="24px" height="24px" />
            <span>외적 매력</span>
        </h3>
        <ul class="list" v-if="lookKeywords">
            <!-- <li class="item" v-for="keyword in lookKeywords" :key="keyword.id"> -->
            <li class="item">
                <div class="tags">
                    <span
                        class="tag f-medium"
                        v-for="keyword in lookKeywords"
                        :class="{ selected: selectedKeywords.includes(keyword.id) }"
                        :key="keyword.id"
                        @click="selectKeyword(keyword.id)"
                        >{{ keyword.name }}</span
                    >
                </div>
            </li>
        </ul>
        <h3 class="title">
            <img class="m-r-2" src="@/assets/images/icons/tag.png" width="24px" height="24px" />
            <span>성격 매력</span>
        </h3>
        <ul class="list" v-if="characterKeywords">
            <li class="item">
                <div class="tags">
                    <span
                        class="tag f-medium"
                        v-for="keyword in characterKeywords"
                        :class="{ selected: selectedKeywords.includes(keyword.id) }"
                        :key="keyword.id"
                        @click="selectKeyword(keyword.id)"
                        >{{ keyword.name }}</span
                    >
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Keywords',
    props: {
        keywords: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data: () => ({
        // keywordCategories: [],
        lookKeywords: [],
        characterKeywords: [],
        selectedKeywords: [],
    }),
    watch: {
        keywords() {
            this.initKeywords()
        },
    },
    computed: {
        getTitle() {
            return `<i class="material-icons cyan-text">tag</i> 취향태그`
        },
    },
    methods: {
        initKeywords() {
            // this.keywordCategories = this.$store.getters.keywordCategories || []
            this.keywords.forEach(keyword => {
                if (keyword.gender !== this.$store.getters.me.gender && keyword.feedback_type === 'look_keyword') {
                    this.lookKeywords.push(keyword)
                } else if (
                    keyword.gender !== this.$store.getters.me.gender &&
                    keyword.feedback_type === 'character_keyword'
                ) {
                    this.characterKeywords.push(keyword)
                }
            })
        },
        selectKeyword(idx) {
            if (this.selectedKeywords.includes(idx)) {
                const index = this.selectedKeywords.indexOf(idx)

                this.selectedKeywords.splice(index, 1)
            } else {
                if (this.selectedKeywords.length === 3) {
                    this.$modal.basic({
                        body: '키워드는 최대 3개까지 선택 가능합니다.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    return
                } else {
                    this.selectedKeywords.push(idx)
                }
            }
            this.$emit('keywordSelected', this.selectedKeywords)
        },
        // categoryIdToString(id) {
        //     const numId = Number(id)
        //     const matched = this.keywordCategories.find(c => c.id === numId)

        //     if (!matched) return

        //     return matched.title
        // },
    },
    mounted() {
        this.initKeywords()
    },
}
</script>

<style lang="scss" scoped>
.keywords {
    border: none;
    .title::v-deep {
        margin-bottom: 24px;
        color: black;
        font-weight: 500;
        display: flex;
        align-items: center;
        @include f-medium;
        .material-icons {
            color: black;
        }
    }

    .item {
        margin-bottom: 24px;
    }

    .key {
        margin-bottom: 12px;
        font-size: 14px;
        color: $grey-07;

        @include f-medium;
    }

    .tags {
        display: flex;
        flex-wrap: wrap;

        .tag {
            margin: 0 8px 8px 0;
            padding: 6px 12px;
            border-radius: 8px;
            background-color: #ffffff;
            border: 1px solid $blue-primary;
            color: $blue-primary;
            font-size: 14px;

            &.selected {
                background-color: $blue-primary;
                color: #ffffff;
            }
        }
    }
}
</style>
