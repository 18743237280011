const chat = {
    CHANNEL_USER: 'UserChannel',

    MESSAGE_TYPE_WELCOME: 'welcome',
    MESSAGE_TYPE_CONFIRM_SUBSCRIPTION: 'confirm_subscription',
    MESSAGE_TYPE_PING: 'ping',
    MESSAGE_TYPE_OPEN_CHAT: 'open-chat',
    MESSAGE_TYPE_INTRODUCE_PRODUCTS: 'introduce-product',
    MESSAGE_TYPE_ALIMI: 'alimi',
    MESSAGE_TYPE_PREMIUM_START: 'premium-start',
    MESSAGE_TYPE_MULTI_PHOTO: 'multi-photo',
    MESSAGE_TYPE_PREMIUM_CHAT: 'premium-chat',
    MESSAGE_TYPE_PREMIUM_SALES: 'premium-sales',
    MESSAGE_TYPE_SOCIAL_INFO: 'social-info',
    MESSAGE_TYPE_TEXT: 'text',

    COMMAND_SUBSCRIBE: 'subscribe',
    COMMAND_MESSAGE: 'message',

    MESSAGE_ACTION_ALERT: 'alert',
    MESSAGE_ACTION_BANNED_WORD: 'banned_word',
    MESSAGE_ACTION_MESSAGE: 'message',
    MESSAGE_ACTION_UNREAD: 'unread',
}

chat.SKIP_TYPE = [chat.MESSAGE_TYPE_WELCOME, chat.MESSAGE_TYPE_CONFIRM_SUBSCRIPTION, chat.MESSAGE_TYPE_PING]

export default chat
