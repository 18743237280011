<template>
    <div class="chat-input" v-show="showChatInput">
        <p v-if="showSendContact" class="send-contact" @click="onClickSendContact">
            {{ 'SEND_CHECK_TITLE' | translate }}
        </p>

        <div class="textarea-wrapper" :class="{ 'more-padding': moreInputPadding, secret: $isPrivacy() }">
            <div ref="height-limiter" class="flex-row items-center height-limiter">
                <!-- <i class="add-icon material-icons function-icon flex-wrap" v-if="!isAI" @click="onClickFunctions"> add_circle </i> -->
                <img
                    class="function-icon"
                    :src="require('@/assets/images/add-button.png')"
                    @click="onClickFunctions"
                    height="32px"
                    width="32px"
                />
                <div class="input-wrapper flex-fill">
                    <textarea
                        id="txt"
                        ref="chat-input-textarea"
                        class="chat-input-textarea"
                        :disabled="isAI"
                        :placeholder="$translate(isAI ? 'DISABLED_CHAT_ALIMI' : 'INPUT_MESSAGE')"
                        :maxlength="maxlength"
                        :value="text"
                        @focus="onFocus"
                        @blur="onBlur"
                        @keydown="onKeydown"
                        @paste="sendPhotoWhenClipboardContainsImage"
                        @input="onInput"
                    />
                    <button v-show="text && text.length > 0" @click="send(text.trim())" class="btn-send">
                        <i class="material-icons">arrow_back</i>
                    </button>
                </div>
            </div>
            <!--            <input-->
            <!--                ref="imageUploader"-->
            <!--                type="file"-->
            <!--                class="image display-none"-->
            <!--                accept="image/*"-->
            <!--                @change="onChangeImage"-->
            <!--            />-->
        </div>
        <input ref="imageUploader" type="file" class="image display-none" accept="image/*" @change="onChangeImage" />
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'ChatInput',
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        connected() {
            return this.$store.getters.connected
        },
        textLength() {
            if (!this.text) return

            return `(${this.text.length}/${this.maxlength})`
        },
        maxlength() {
            return 1024
        },
        dom() {
            return this.$refs['chat-input-textarea']
        },
        isAI() {
            return this.agentId === 6
        },
        instantChat() {
            return this.$isInstantChat(this.chat)
        },
        moreInputPadding() {
            if (this.$isAndroid() || !this.inputFocused) return

            return true
        },
        agentId() {
            return this.chat.agent_id
        },
        showChatInput() {
            if (this.chat.agent_id && ![3, 8, 9, 12, 17].includes(this.chat.agent_id)) {
                return false
            } else {
                return ['pro_agent', 'social_group', 'real_friend', 'temp', 'users', 'schedule_change'].includes(
                    this.chat.chat_type
                )
            }
        },
        showSendContact() {
            return this.chat.chat_type === 'real_friend' && this.chat.introduce.user_id === this.$store.getters.me.id
        },
    },
    data: () => ({
        text: '',
        previousText: '',
        isFirstChar: false,
        inputFocused: false,
        templateUsed: false,
    }),
    watch: {
        chat(newVal, oldVal) {
            if (newVal && oldVal && newVal.id === oldVal.id) return

            setTimeout(_ => {
                if (this.dom) {
                    this.dom.focus()
                }
            }, 500)
        },
        connected(newVal) {
            if (this.dom) {
                this.dom.blur()
            }
        },
    },
    methods: {
        onInput() {
            this.text = this.dom.value

            if (this.$isIOS()) {
                if (this.dom.value.charAt(0) === this.previousText && this.isFirstChar) {
                    this.text = this.text.substring(1)
                    this.isFirstChar = false
                }
            }
        },
        onKeydown() {
            setTimeout(() => {
                this.resetTextareaHeight()
            })
        },
        onFocus() {
            this.inputFocused = true
            this.$bus.$emit('chatInputFocus')
        },
        onBlur() {
            this.inputFocused = false
            this.$bus.$emit('chatInputBlur')
        },
        resetTextareaHeight() {
            const dom = this.$refs['height-limiter']
            console.log(this.dom.scrollHeight)
            if (!this.text || this.dom.scrollHeight < 30) {
                dom.style.height = 'auto'
                dom.style.height = '44px'
            } else {
                dom.style.height = 'auto'
                dom.style.height = `${this.dom.scrollHeight + 20}px`
            }
        },
        send(text) {
            if (!this.showChatInput || !this.text || this.text.trim().length === 0) return

            if (this.dom.value) {
                this.sendAirbridgeEvent()
                this.$bus.$emit('onSendTextMessage', {
                    text,
                    is_template: this.templateUsed,
                })
                this.text = ''

                if (this.$isIOS()) {
                    this.previousText = text.slice(-1)
                    this.isFirstChar = true
                }
            }

            this.resetTextareaHeight()
            this.dom.focus()
        },
        onClickFunctions() {
            if (this.isAI) return

            this.$actionSheet({
                buttons: [
                    {
                        label: '사진 보내기 (갤러리)',
                        handler: () => {
                            if ((this.$store.getters.device || {}).app_version < '1.2.5') {
                                this.$refs.imageUploader.click()
                            } else {
                                this.$openGallery()
                            }
                        },
                    },
                ],
                style: {
                    'padding-bottom': '30px',
                },
            })
        },
        onChangeImage(event) {
            const sendPhoto = () => {
                let imgFile

                if (this.$store.getters.device.app_version >= '1.2.5') {
                    imgFile = event.blob
                } else {
                    imgFile = event.target.files[0]
                    this.$refs.imageUploader.value = ''
                }

                this.$modal
                    .custom({
                        component: 'ModalCropper',
                        options: {
                            imgFile,
                            allowFreeAspectRatio: true,
                        },
                    })
                    .then(croppedFile => {
                        if (!croppedFile) return

                        const data = new FormData()
                        data.append('attachment', croppedFile)
                        chatService.sendPhotoMessage(this.chat.id, data)
                    })
            }

            if (this.chat.chat_type === 'real_friend') {
                this.$modal
                    .basic({
                        title: 'MODAL_DEFAULT_TITLE',
                        body:
                            '연락처나 SNS ID가 포함된 사진을 보낸 경우, 어뷰징 행위로 간주되어 계정이 영구 정지 될 수 있습니다.<br/>서로 마음에 들 경우, 연락처 교환 기능을 이용해주세요.',
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'SEND',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 1) {
                            sendPhoto()
                        } else {
                            this.$refs.imageUploader.value = ''
                        }
                    })
            } else {
                sendPhoto()
            }
        },
        sendPhotoWhenClipboardContainsImage(event) {
            const clipboardDataType = (event.clipboardData.items[0] || {}).type
            if (!(clipboardDataType || '').includes('image')) return

            const file = event.clipboardData.items[0].getAsFile()
            if (!file) return

            const onConfirm = () => {
                const data = new FormData()
                data.append('attachment', file)
                chatService.sendPhotoMessage(this.chat.id, data)
            }

            this.$modal
                .basic({
                    body: `<img class="btn-brd w-100" src=${URL.createObjectURL(file)}>`,
                    buttons: [
                        {
                            label: 'CANCEL',
                            class: 'btn-default',
                        },
                        {
                            label: 'SEND',
                            class: 'btn-primary',
                        },
                    ],
                })
                .then(idx => {
                    if (idx === 1) {
                        onConfirm()
                    }
                })
        },
        sendAirbridgeEvent() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: 'user_send_message',
                },
            })
        },
        businessHours() {
            // const agent10to19 = [17531, 17532, 17533, 5257]
            const agent13to22 = [17696, 17697, 17698]
            // 프리미엄 주선자 업무시간 관련 처리인데 디비에 칼럼추가하기그래서 주선자 아이디랑 업무 시간을 하드코딩으로 박아놓음
            // 나중에 프로퍼티나 어드민 추가해서 처리하거나..
            if (this.agentId && agent13to22.find(i => i === this.agentId)) {
                return '1322'
            } else {
                return '1019'
            }
        },
        onClickSendContact() {
            this.$stackRouter.push({ name: 'SendContactPage' })
        },
    },
    mounted() {
        this.$bus.$on('onClickSendPicture', this.onSendPictureRequested)
        this.$bus.$on('onRequestPhotoFromGallery', this.onChangeImage)
    },
    beforeDestroy() {
        this.$bus.$off('onClickSendPicture', this.onSendPictureRequested)
        this.$bus.$off('onRequestPhotoFromGallery', this.onChangeImage)
    },
}
</script>

<style lang="scss" scoped>
.chat-input {
    position: relative;
    // border-top: solid 1px $grey-03;
    background-color: white;

    .send-contact {
        text-align: center;
        font-size: 13px;
        padding-top: 10px;
        padding-bottom: 6px;

        @include f-medium;
    }

    .textarea-wrapper {
        border-radius: 0;
        padding: 16px;
        border: none;
        background: #faf0e1;

        &.secret {
            background: $grey-10;
            i {
                color: white;
            }
        }

        &.more-padding {
            padding-bottom: 28px;
        }

        .height-limiter {
            height: 44px;
            max-height: 76px;
            display: flex;
            position: relative;

            .function-icon {
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;
                // margin-right: 8px;
                margin-left: 4px;
            }

            .input-wrapper {
                height: 100%;
                border-radius: 62px;
                border: 1px solid $grey-03;
                background: white;
                display: flex;
                margin-left: 44px;
                padding: 9px 20px;
                align-items: center;

                textarea {
                    background: none;
                    height: 100%;
                    -webkit-appearance: none;
                    overflow: hidden;
                    line-height: normal;

                    &::placeholder {
                        font-size: 15px;
                        padding-top: 3px;
                    }
                }
            }
        }
    }

    .btn-send {
        height: 28px;
        width: 28px;
        background-color: $blue-primary;
        border-radius: 50%;
        border: 0;
        margin-bottom: 4px;
        margin-right: 4px;
        padding: 0;

        @include center;
        @include flex-wrap;

        .material-icons {
            font-size: 20px;
            transform: rotate(90deg);
            color: white;
        }
    }
}
</style>
