var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"}},[(!_vm.preventModal)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.transparent),expression:"!transparent"}],staticClass:"modal",on:{"mousedown":_vm.closeOnMousedownMask}},[_c('div',{class:[
                    'ModalBasic',
                    'ModalForceCheck',
                    'ModalBadgeDeclined',
                    'ModalForceFeedback',
                    'ModalOpenProfileDetail',
                ].includes(_vm.modal.component)
                    ? 'modal-container-basic'
                    : _vm.modal.component === 'ModalSelectDate'
                    ? 'modal-container-calendar'
                    : _vm.modal.component === 'ModalContact'
                    ? 'modal-container-contact'
                    : ['ModalHowToUseFeats'].includes(_vm.modal.component)
                    ? 'modal-black-screen'
                    : _vm.modal.component === 'ModalCompanyAddInfo'
                    ? 'modal-bottom-sheet'
                    : _vm.modal.component === 'ModalSendLike'
                    ? 'modal-send-like'
                    : _vm.modal.component === 'ModalPurchaseProduct' || _vm.modal.component === 'ModalPurchaseMembership'
                    ? 'modal-purchase-product'
                    : _vm.modal.component === 'ModalSelectReason' ||
                      _vm.modal.component === 'ModalAppearanceScore' ||
                      _vm.modal.component === 'ModalSpecScore' ||
                      _vm.modal.component === 'ModalSelectDormantReason' ||
                      _vm.modal.component === 'ModalDropout'
                    ? 'modal-select-reason'
                    : _vm.modal.component === 'ModalAgreeLike' || _vm.modal.component === 'ModalMannerLikeInfo'
                    ? 'modal-agree-like'
                    : 'modal-container'},[_c(_vm.modal.component,{tag:"component",attrs:{"options":_vm.modal.options},on:{"close":_vm.onClose,"update:options":function($event){return _vm.$set(_vm.modal, "options", $event)}}})],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }