<template>
    <div class="nps-feedback">
        <h2 class="header f-bold" v-html="header" />
        <p class="description" v-html="description" />
        <textarea
            :placeholder="'내용을 작성해주세요'"
            :value="feedback"
            @input="onInput"
            @focus="inputFocused = true"
            @blur="inputFocused = false"
        />
        <div class="buttons">
            <button
                class="btn btn-primary btn-submit"
                :disabled="isEmpty"
                @click="onClickSubmit"
            >
                제출하기
            </button>
            <button
                class="btn btn-brd btn-skip"
                v-show="isEmpty"
                @click="onClickSkip"
            >
                건너뛰기
            </button>
        </div>
    </div>
</template>

<script>
import npsService from '@/services/nps'

export default {
    name: 'NPSFeedbackPage',
    data: () => ({
        feedback: '',
        inputFocused: false,
        insertedNps: null,
    }),
    computed: {
        isNegative() {
            return this.$route.params.score < 9
        },
        header() {
            if (this.isNegative) {
                return '어떤 부분이<br>아쉬우셨나요?'
            }

            return '친구에게 추천하고 싶은<br>이유는 무엇인가요?'
        },
        description() {
            if (this.isNegative) {
                return '더 나은 서비스로 개선할 수 있도록<br>솔직한 피드백을 남겨주세요 🙏'
            }

            return '더 만족할 수 있는 서비스를 만들겠습니다 ☺️<br>감사합니다!'
        },
        isEmpty() {
            return this.feedback.length === 0
        },
    },
    mounted() {
        this.feedback = this.$store.getters.payloads.npsData.feedback || ''
    },
    methods: {
        async onClickSubmit() {
            await this.createNPS()
            if (this.isNegative) {
                this.goBack()
                return
            }

            this.openAppReviewModal()
        },
        async onClickSkip() {
            await this.createNPS()
            if (this.isNegative) {
                this.goBack()
                return
            }

            this.openAppReviewModal()
        },
        async createNPS() {
            try {
                const { msg, nps } = await npsService.createNPS({
                    score: this.$route.params.score,
                    feedback: this.feedback,
                })
                this.insertedNps = nps
                this.$toast.success(msg)
            } catch (e) {}
        },
        openAppReviewModal() {
            this.$modal
                .custom({
                    component: 'ModalRequestAppReview',
                    options: {
                        feedback: this.feedback,
                        nps: this.insertedNps,
                    },
                })
                .then(() => {
                    this.goBack()
                })
        },
        goBack() {
            this.$router.go(-2)
        },
        onInput(event) {
            this.feedback = event.target.value
            this.$store.commit('setPayloads', {
                npsData: { feedback: this.feedback },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.nps-feedback {
    padding: 20px;

    .header {
        margin: 0;
        margin-bottom: 16px;
        font-size: 24px;
        line-height: 1.5;
        color: black;
        font-weight: normal;
    }

    .description {
        font-size: 15px;
        color: $grey-09;
        line-height: normal;
        margin: 0;
        margin-bottom: 32px;
    }

    textarea {
        width: 100%;
        height: 260px;
        padding: 16px;
        -webkit-appearance: none;
    }

    .buttons {
        width: calc(100vw - 40px);
        margin-top: 20px;

        .btn {
            width: 100%;
            height: 48px;
            font-size: 15px;
        }

        .btn.btn-skip {
            color: $grey-08;
        }

        .btn.btn-submit {
            &:disabled {
                color: $grey-05;
            }
            box-shadow: none;
        }

        .btn:first-child {
            margin-bottom: 10px;
        }
    }
}
</style>
