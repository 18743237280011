<template>
    <div class="onboard-img-container">
        <div class="content">
            <div class="title">환영합니다!</div>
        </div>
        <div class="img-wrapper">
            <img :src="require('@/assets/images/front_bi.png')" alt="" />
        </div>
        <div class="content">
            <div class="desc">
                <p>진정성 있고, 검증된 좋은 사람과</p>
                <p>마지막 연애하세요.</p>
                <br>
                <p>약속까지 책임지는 서비스를 제공합니다. </p>
                <!-- <p>Full care 서비스를 제공합니다.</p> -->
            </div>
        </div>
        <BottomButton @click="clickTempSignup" :blackBtn="true" :label="'가입하기'" />
    </div>
</template>

<script>
export default {
    name: 'OnBoardWelcomePage',
    methods: {
        clickTempSignup() {
            this.$router.push({ name: 'OnboardState' })
        },
    },
}
</script>
<style scoped lang="scss">
.onboard-img-container {
    width: 100%;
    height: calc(100vh - 81px) !important;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 100px 0;

    .img-wrapper {
        @include center;
        img {
            // height: 280px;
            width: 60%;
        }
    }

    .content {
        text-align: center;

        .title {
            font-size: 24px;
            margin-bottom: 8px;
            @include spoqa-f-bold;
        }
        .desc {
            font-size: 16px;
            line-height: 26px;
        }
    }
}
</style>
