<template>
    <div class="message-action p-relative" :class="message.mtype">
        <ActionDefaultLayout v-if="useDefaultLayout" :message="message">
            <component :is="componentByMessageType" :message="message" />
        </ActionDefaultLayout>
        <component v-else :is="componentByMessageType" :message="message" :profilePhotoMessage="profilePhotoMessage" />
    </div>
</template>
<script>
import ActionDefaultLayout from './ActionDefaultLayout'

export default {
    name: 'MessageAction',
    props: ['message', 'profilePhotoMessage'],
    components: {
        ActionDefaultLayout,
        ActionOpenChat: () => import('./message-actions/ActionOpenChat'),
        ActionIntroduceProduct: () => import('./message-actions/ActionIntroduceProduct'),
        ActionNoti: () => import('./message-actions/ActionNoti'),
        ActionAlimiBasic: () => import('./message-actions/ActionAlimiBasic'),
        ActionWorkingTime: () => import('./message-actions/ActionWorkingTime'),
        ActionHowtoUse: () => import('./message-actions/ActionHowtoUse'),
        ActionPrepareVerification: () => import('./message-actions/ActionPrepareVerification'),
        ActionAiMatched: () => import('./message-actions/ActionAiMatched'),
        ActionUnknown: () => import('./message-actions/ActionUnknown'),
        // 포토메시지
        ActionPhoto: () => import('./message-actions/ActionPhoto'),
        ActionMoreProfile: () => import('./message-actions/ActionMoreProfile'),
        // 약속잡기
        ActionScheduleStart: () => import('./message-actions/ActionScheduleStart'),
        ActionScheduleFinish: () => import('./message-actions/ActionScheduleFinish'),
        ActionScheduleConfirm: () => import('./message-actions/ActionScheduleConfirm'),
        ActionScheduleReservationUser: () => import('./message-actions/ActionScheduleReservationUser'),
        ActionScheduleReservationComplete: () => import('./message-actions/ActionScheduleReservationComplete'),
        ActionScheduleComplete: () => import('./message-actions/ActionScheduleComplete'),
        ActionRescheduleSent: () => import('./message-actions/ActionRescheduleSent'),
        ActionRescheduleFinA: () => import('./message-actions/ActionRescheduleFinA'),
        ActionRescheduleStart: () => import('./message-actions/ActionRescheduleStart'),
        ActionScheduleChangeAdmin: () => import('./message-actions/ActionScheduleChangeAdmin'),
        ActionScheduleBreakAdmin: () => import('./message-actions/ActionScheduleBreakAdmin'),
        ActionOpenChangeSchedule: () => import('./message-actions/ActionOpenChangeSchedule'),
        ActionScheduleCancel: () => import('./message-actions/ActionScheduleCancel'),
        ActionRequestCancel: () => import('./message-actions/ActionRequestCancel'),
        ActionScheduleChangeCancel: () => import('./message-actions/ActionScheduleChangeCancel'),
        ActionWaiveConfirmed: () => import('./message-actions/ActionWaiveConfirmed'),
        // 프로필 입력 완료
        ActionUpdatedProfile: () => import('./message-actions/ActionUpdatedProfile'),
        ActionConfirmedProfile: () => import('./message-actions/ActionConfirmedProfile'),
        // welcome
        ActionOnboard: () => import('./message-actions/ActionOnboard'),
        // 만남 피드백
        ActionMeetingFeedback: () => import('./message-actions/ActionMeetingFeedback'),
    },
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        componentByMessageType() {
            // return 'ActionScheduleReservationUser'
            if (this.unknown) return 'ActionUnknown'
            if (this.message.mtype.includes('introduce-product')) return 'ActionIntroduceProduct'
            if (this.message.mtype.includes('openchat-onboarding')) return 'ActionOnboard'
            // 피드백
            if (this.message.mtype.includes('manner-feedback')) return 'ActionMeetingFeedback'
            if (this.message.mtype.includes('noti')) return 'ActionNoti'
            if (this.message.mtype.includes('photo')) return 'ActionPhoto'
            if (this.message.mtype.includes('confirmed-profile-ai')) return 'ActionConfirmedProfile'
            if (this.message.mtype.includes('howto-use')) return 'ActionHowtoUse'
            // 약속잡기
            if (this.message.mtype.includes('schedule')) {
                if (this.message.mtype.includes('restart')) return 'ActionRescheduleStart'
                else if (this.message.mtype.includes('start')) return 'ActionScheduleStart'
                else if (this.message.mtype === 'schedule-change') return 'ActionRescheduleSent'
                else if (this.message.mtype.includes('refin-a')) return 'ActionRescheduleFinA'
                else if (this.message.mtype.includes('refin-noti')) return 'ActionRescheduleFinNoti'
                else if (this.message.mtype.includes('reoffer')) return 'ActionRescheduleSent'
                else if (this.message.mtype.includes('finish')) return 'ActionScheduleFinish'
                else if (this.message.mtype.includes('confirm')) return 'ActionScheduleConfirm'
                else if (this.message.mtype.includes('reservation')) {
                    if (this.message.mtype.includes('user')) {
                        return 'ActionScheduleReservationUser'
                    } else if (this.message.mtype.includes('complete')) {
                        return 'ActionScheduleReservationComplete'
                    }
                } else if (this.message.mtype.includes('complete')) return 'ActionScheduleComplete'
                else if (this.message.mtype.includes('change-admin')) return 'ActionScheduleChangeAdmin'
                else if (this.message.mtype.includes('break-admin')) return 'ActionScheduleBreakAdmin'
                else if (this.message.mtype.includes('open-chat-schedule-change')) return 'ActionOpenChangeSchedule'
                else if (this.message.mtype.includes('schedule-break-user')) return 'ActionScheduleChangeCancel'
                else if (this.message.mtype.includes('request-cancel-fee')) return 'ActionRequestCancel'
                else return 'ActionUnknown'
            }
            if (this.message.mtype.includes('waive')) return 'ActionWaiveConfirmed'

            return 'Action' + this.$case.toPascal(this.message.mtype, '-', 2)
        },
        useDefaultLayout() {
            return (
                ['alimi', 'photo'].every(key => !this.message.mtype.includes(key)) &&
                !this.unknown &&
                this.message.mtype !== 'accept-introduce'
            )
        },
        mtypes() {
            return [
                'open-chat',
                'open-chat-pro',
                'introduce-product',
                'photo',
                'multi-photo',
                'profile-photo',
                'self-profile-photo',
                'recommend-reject',
                'request',
                'share-done',
                'share-request',
                'text',
                'confirm-meeting',
                'open-chat-introduce',
                'more-profile',
                'working-time',
                'prepare-verification',
                // 약속잡기
                'schedule-start-a',
                'schedule-finish-a',
                'schedule-start-b',
                'schedule-finish-b',
                'schedule-confirm',
                'schedule-complete',
                'updated-profile',
                'confirmed-profile',
                'confirmed-profile-ai',
                'openchat-onboarding',
                'manner-feedback',
                'schedule-change',
                'schedule-reoffer',
                'schedule-refin-a',
                'schedule-refin-noti',
                'schedule-restart-a',
                'schedule-restart-b',
                'howto-use',
                'ai-matched',
                'schedule-reservation-user',
                'schedule-reservation-complete',
                'schedule-change-admin',
                'schedule-break-admin',
                'open-chat-schedule-change',
                'schedule-break-user',
                'request-cancel-fee',
                'waive-confirmed',
                'waive-rejected',
            ]
        },
        unknown() {
            return (
                this.mtypes.indexOf(this.message.mtype) === -1 &&
                !this.message.mtype.includes('noti') &&
                !this.message.mtype.includes('alimi')
            )
        },
    },
}
</script>
