<template>
    <div class="edit-profile-detail">
        <ProfileOptionSelector :model="'university'" :nextRoute="() => `OnboardWhyDifficult`" />
    </div>
</template>

<script>
export default {
    name: 'School',
    mounted() {
        if (this.tempSignupPayload.height === undefined) {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Enter_UniversityPage`,
                },
            })
        }
    },
}
</script>

<style scoped></style>
